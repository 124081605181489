/***********************
 * CALLS TABLE
 * (TABLE + IMPORT PDF/XLSX)
 ************************/

import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Form,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Card,
  CardBody,
  Tooltip,
} from "reactstrap"
import PropTypes from "prop-types"

import { formatDateFull, timeFormatter } from "utils/general"

import { TreeSelect, ConfigProvider } from "antd"
const { SHOW_PARENT } = TreeSelect

// Toast
import toastr from "toastr"
import "toastr/build/toastr.min.css"

//Moment
import moment, { duration } from "moment"

// Datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import "../../datatables.scss"

// Search field
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"

//Excel export
import XLSX from "xlsx"

//Autotable PDF
import jsPDF from "jspdf"
import "jspdf-autotable"

//Translation
import { withTranslation } from "react-i18next"

const TableCalls = props => {
  //Excel and pdf name
  const [title, setTitle] = useState(props.t("Calls"))
  //Table data state
  const [series, setSeries] = useState([])
  const [origSeries, setOrigSeries] = useState([])
  // Filter value
  const [value, setValue] = useState(["all"])
  //Dropdown button state
  const [menu, setMenu] = useState(false)
  // Tooltip state for download button
  const [TTdownloadstatstable, setTTdownloadstatstable] = useState(false)
  // Customized select
  const [customize, setCustomize] = useState(true)

  // Filter by selected types
  useEffect(() => {
    if (origSeries != undefined) {
      setSeries([])
      if (value == "all") {
        setSeries(origSeries)
      }
      if (value != "all" && value != "" && value != undefined) {
        for (let i = 0; i <= value.length; i++) {
          origSeries.filter(data => {
            if (data.type?.includes(value[i])) {
              setSeries(series => [...series, data])
            }
          })
        }
      }
    }
  }, [value])

  const treeData = [
    {
      title: props.t("All"),
      value: "all",
      key: "all",

      children: [
        {
          title: props.t("Tracker"),
          value: "tracker",
          key: "tracker",
        },
        {
          title: props.t("Carephone"),
          value: "carephone",
          key: "carephone",
        },
        {
          title: props.t("Video"),
          value: "video",
          key: "video",
        },
        {
          title: props.t("Group video call"),
          value: "group",
          key: "group",
        },
        {
          title: props.t("Phone"),
          value: "phone",
          key: "phone",
        },
      ],
    },
  ]
  // Changing the value of the filter
  const onChange = newValue => {
    setValue(newValue)
  }
  const customizeRenderEmpty = () => (
    <div className="mt-3" style={{ textAlign: "center" }}>
      <p>{props.t("Call type not found")}</p>
    </div>
  )
  const tProps = {
    treeData,
    value,
    onChange,
    allowClear: true,
    treeDefaultExpandAll: true,
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    style: {
      width: "100%",
    },
    placeholder: props.t("Filter"),
  }

  //Show toast
  const [showEasing, setshowEasing] = useState("swing")
  const [hideEasing, sethideEasing] = useState("linear")
  const [showMethod, setshowMethod] = useState("fadeIn")
  const [hideMethod, sethideMethod] = useState("fadeOut")
  const [showDuration, setshowDuration] = useState(300)
  const [hideDuration, sethideDuration] = useState(1000)
  const [timeOut, settimeOut] = useState(5000)
  const [extendedTimeOut, setextendedTimeOut] = useState(1000)
  let positionClass = "toast-bottom-left"

  function showToast(m) {
    toastr.options = {
      positionClass: positionClass,
      timeOut: timeOut,
      extendedTimeOut: extendedTimeOut,
      closeButton: true,
      progressBar: true,
      showEasing: showEasing,
      hideEasing: hideEasing,
      showMethod: showMethod,
      hideMethod: hideMethod,
      showDuration: showDuration,
      hideDuration: hideDuration,
    }
    toastr.success(m)
  }

  //Setting data state
  useEffect(() => {
    console.log("Data table calls: " + JSON.stringify(props.data.list))
    props.data.list.forEach(el => {
      let end = moment(el.date).add(el.duration, "minutes")
      el.end_date = end
    })
    setSeries(props.data.list)
    setOrigSeries(props.data.list)
  }, [props.data])

  // Table structure
  const columns = [
    {
      dataField: "date",
      text: props.t("Start time"),
      sort: true,
      formatter: formatDateFull,
    },
    {
      dataField: "end_date",
      text: props.t("End time"),
      sort: true,
      formatter: formatDateFull,
    },
    {
      dataField: "client_name",
      text: props.t("Customers name"),
      sort: true,
    },
    {
      dataField: "group",
      text: props.t("Group"),
      sort: true,
    },
    {
      dataField: "caller",
      text: props.t("Caller"),
      sort: true,
    },
    {
      dataField: "unit_info",
      text: props.t("Unit info"),
      sort: true,
    },
    {
      dataField: "type",
      text: props.t("Type"),
      sort: true,
      formatter: typeFormatter,
    },
    {
      dataField: "duration",
      text: props.t("Duration"),
      sort: true,
      formatter: timeFormatter,
    },
  ]
  // Can be used to modify type
  function typeFormatter(cell) {
    if (cell == "group") {
      return props.t("Group video call")
    } else {
      return props.t(cell)
    }
  }

  // Export PDF table
  const exportPDF = () => {
    let message = props.t("PDF file exported succesfully!")
    const unit = "pt"
    const size = "A4" // Use A1, A2, A3 or A4
    const orientation = "landscape" // portrait or landscape
    const theme = "grid" //striped,  plain

    const marginLeft = 40
    const doc = new jsPDF(orientation, unit, size, theme)

    doc.setFontSize(14)

    const data = series.map(elt => [
      formatDateFull(elt.date),
      formatDateFull(elt.date_end),
      elt.client_name,
      elt.group,
      elt.caller,
      elt.unit_info,
      typeFormatter(elt.type),
      timeFormatter(elt.duration),
      elt.spat_code,
    ])

    let content = {
      startY: 50,
      head: [
        [
          props.t("Start time"),
          props.t("End time"),
          props.t("Customers name"),
          props.t("Group"),
          props.t("Caller"),
          props.t("Unit info"),
          props.t("Type"),
          props.t("Duration"),
          props.t("SPAT code"),
        ],
      ],
      body: data,
    }

    doc.text(title, marginLeft, 40)
    doc.autoTable(content)
    doc.save(title + ".pdf")

    //Show toast
    showToast(message)
  }

  // Creating Excel workbook+worksheet+download
  const downloadExcel = () => {
    let message = props.t("Excel file exported succesfully!")
    const wb = XLSX.utils.book_new()

    const Heading = [
      [
        props.t("Start time"),
        props.t("End time"),
        props.t("Customers name"),
        props.t("Group"),
        props.t("Caller"),
        props.t("Unit info"),
        props.t("Type"),
        props.t("Duration"),
        props.t("SPAT code"),
      ],
    ]
    const data = series.map(elt => [
      formatDateFull(elt.date),
      formatDateFull(elt.date_end),
      elt.client_name,
      elt.group,
      elt.caller,
      elt.unit_info,
      typeFormatter(elt.type),
      timeFormatter(elt.duration),
      elt.spat_code,
    ])

    // creating sheet and adding data from 2nd row of column A.
    // Leaving first row to add Heading
    const ws = XLSX.utils.json_to_sheet(data, {
      origin: "A2",
      skipHeader: true,
    })
    XLSX.utils.sheet_add_aoa(ws, Heading, { origin: "A1" })
    XLSX.utils.book_append_sheet(wb, ws, title)
    XLSX.write(wb, { bookType: "xlsx", type: "buffer" })
    XLSX.writeFile(wb, title + " (" + new Date() + ")" + ".xlsx")

    //Show toast
    showToast(message)
  }

  const defaultSorted = [
    {
      dataField: "date",
      order: "desc",
    },
  ]

  // Table size options
  const pageOptions = {
    custom: true,
    sizePerPageList: [
      { text: "20", value: 20 },
      { text: "50", value: 50 },
      { text: props.t("All"), value: series?.length },
    ],
  }

  // Custom Pagination Toggle
  const sizePerPageList = [
    { text: "10", value: 10 },
    { text: "20", value: 20 },
  ]
  // TODO: Search bar, not ready
  const { SearchBar } = Search

  return (
    <React.Fragment>
      <div>
        <Row className=" mt-2 mb-2">
          <div className="d-flex flex-wrap">
            <span>
              <h5 className="mt-1">{props.t("Calls")}</h5>
            </span>
          </div>
        </Row>
        <Card className="mt-2">
          <CardBody>
            <Row>
              <Col className="col-12">
                <PaginationProvider
                  pagination={paginationFactory(pageOptions)}
                  sizePerPage={SizePerPageDropdownStandalone(sizePerPageList)}
                  keyField="id"
                  columns={columns}
                  data={series}
                >
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                      keyField="id"
                      columns={columns}
                      data={series}
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          <Col lg="12">
                            <Row className="mb-1">
                              <Col md={3} className="mt-1 mb-1">
                                <ConfigProvider
                                  renderEmpty={
                                    customize && customizeRenderEmpty
                                  }
                                >
                                  <TreeSelect {...tProps} />
                                </ConfigProvider>
                              </Col>
                              <Col md={3}>
                                <div className="mt-2 pt-1">
                                  {series?.length} {props.t("found results")}
                                </div>
                              </Col>
                              <Col md="6" sm="12">
                                {/* <div className="mt-2 text-muted"> */}
                                <span className="float-end mt-1">
                                  <Tooltip
                                    placement="bottom"
                                    isOpen={TTdownloadstatstable}
                                    target="downloadstatstable"
                                    toggle={() => {
                                      setTTdownloadstatstable(
                                        !TTdownloadstatstable
                                      )
                                    }}
                                  >
                                    {props.t("Download statistics")}
                                  </Tooltip>
                                  <Dropdown
                                    id="printButtonServicesStats"
                                    isOpen={menu}
                                    toggle={() => setMenu(!menu)}
                                    className="download-btn text-center"
                                    type="button"
                                  >
                                    <DropdownToggle
                                      id="downloadstatstable"
                                      className="text-muted"
                                      tag="i"
                                    >
                                      <i className="mdi mdi-download" />
                                    </DropdownToggle>
                                    <DropdownMenu>
                                      {/* TODO: Can not choose directory file right now */}
                                      <DropdownItem
                                        onClick={() => downloadExcel()}
                                      >
                                        {props.t("Download .xlsx")}
                                      </DropdownItem>
                                      <DropdownItem onClick={() => exportPDF()}>
                                        {props.t("Download .pdf")}
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </Dropdown>
                                </span>
                                {/* </div> */}
                                <Form className="mt-sm-0 float-sm-end d-flex text-muted align-items-center">
                                  <div className="search-box ms-3 me-4">
                                    <div className="position-relative">
                                      <SearchBar
                                        placeholder={props.t(" ")}
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search search-icon" />
                                    </div>
                                  </div>
                                </Form>
                              </Col>
                            </Row>
                          </Col>

                          <Row>
                            <Col xl="12">
                              <div className="table-responsive table-scroll-window">
                                <BootstrapTable
                                  id="services-table"
                                  keyField={"id"}
                                  responsive
                                  bordered={false}
                                  striped={false}
                                  pagination={sizePerPageList}
                                  defaultSorted={defaultSorted}
                                  classes={"table align-middle table-nowrap"}
                                  headerWrapperClasses={"thead-light"}
                                  {...toolkitProps.baseProps}
                                  {...paginationTableProps}
                                />
                              </div>
                            </Col>
                          </Row>

                          <Row className="align-items-md-center mt-3 mt-30">
                            <Col className="inner-custom-pagination d-flex">
                              <div className="">
                                <SizePerPageDropdownStandalone
                                  pagination={sizePerPageList}
                                  {...paginationProps}
                                />
                              </div>
                              <Col className="pagination justify-content-end inner-custom-pagination">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  )
}

TableCalls.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
  type: PropTypes.string,
  data: PropTypes.any,
}

export default withTranslation()(TableCalls)
