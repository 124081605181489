import React, { useEffect, useRef, useState } from "react"
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  AccordionBody,
  AccordionItem,
  Form,
  UncontrolledTooltip,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  Button,
  Accordion,
} from "reactstrap"
import PropTypes from "prop-types"
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import { Route } from "react-router-dom"
import { withTranslation } from "react-i18next"
import moment from "moment"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import "../../datatables.scss"
import { queryGatewayLogs } from "helpers/backend_helper"
import { showToast } from "utils/general"
import CustomRangePicker from "components/DatePickers/RangePicker"
import { downloadExcel, exportPDF } from "utils/general"

const GatewayLogs = props => {
  const [logs, setLogs] = useState([])
  const [filteredLogs, setFilteredLogs] = useState([])
  const [ongoingSearch, setOngoingSearch] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [filtersOpen, setFiltersOpen] = useState("")
  const [firstUpdate, setFirstUpdate] = useState(true)
  const [currentPage, setCurrentPage] = useState(1)
  const [dateRange, setDateRange] = useState([
    moment().subtract(1, "days"),
    moment(),
  ])
  const [title, setTitle] = useState(props.t("Gateway logs"))
  const [menu, setMenu] = useState(false) //Dropdown button state

  const abortRef = useRef(null)
  useEffect(() => {
    document.title = title + " | Suvanto Care"
    abortRef.current = new AbortController()
    return () => abortRef.current.abort()
  }, [])

  useEffect(() => {
    getLogs()
  }, [dateRange])

  // Get gateway event logs
  const getLogs = () => {
    if (!ongoingSearch) {
      setOngoingSearch(true)
      queryGatewayLogs(
        abortRef.current,
        500,
        dateRange[0].valueOf(),
        dateRange[1].valueOf()
      )
        .then(result => {
          //console.log("Logs: ", result.entries)
          if (result.statusCode == 200 && result.entries) {
            setLogs(result.entries)
          } else {
            setLogs([])
            showToast(props.t("Error"), "error")
          }
        })
        .catch(e => {
          console.log(e)
          setLogs([])
          showToast(props.t("Error"), "error")
        })
        .finally(() => {
          setOngoingSearch(false)
        })
    }
  }

  // Search or filter changed
  useEffect(() => {
    setCurrentPage(1)
    handleSearch()
  }, [searchText, logs])

  // Handle search function
  const handleSearch = () => {
    let result = logs.filter(
      data =>
        data.name?.toLowerCase().includes(searchText) ||
        data.event?.toLowerCase().includes(searchText) ||
        data.key?.toLowerCase().includes(searchText)
    )
    setFilteredLogs(result)
  }

  const defaultSorted = [
    {
      dataField: "timestamp",
      order: "desc",
    },
  ]

  const pageOptions = {
    custom: true,
    pageStartIndex: 1,
    totalSize: filteredLogs.length,
    page: currentPage,
    sizePerPageList: [
      { text: "10", value: 10 },
      { text: "50", value: 50 },
      { text: "100", value: 100 },
      { text: props.t("All"), value: filteredLogs?.length || 0 },
    ],
    onPageChange: (page, sizePerPage) => {
      setCurrentPage(page)
    },
    onSizePerPageChange: (sizePerPage, page) => {
      setCurrentPage(1)
    },
  }

  const columns = [
    {
      dataField: "timestamp",
      text: props.t("Time"),
      sort: true,
      formatter: dateFormatter,
    },
    {
      dataField: "event",
      text: props.t("Event"),
      sort: true,
    },
    {
      dataField: "name",
      text: props.t("Name"),
      sort: true,
    },
    {
      dataField: "key",
      text: props.t("GUID"),
      sort: true,
    },
  ]

  function dateFormatter(value) {
    if (!value) return ""
    var dateString = moment(value).format("DD.MM.YYYY HH:mm:ss")
    return dateString
  }

  const onDateSelection = value => {
    if (value != null) {
      let start = new Date(value[0])
      let startUnix = start.getTime()

      let end = new Date(value[1])
      let endUnix = end.getTime()

      setStartDate(startUnix)
      setEndDate(endUnix)
    }
  }

  // Export values
  const generateExcel = () => {
    const heading = [
      [props.t("Time"), props.t("Event"), props.t("Name"), props.t("GUID")],
    ]
    const data = filteredLogs.map(elt => [
      dateFormatter(elt.timestamp),
      elt.event,
      elt.name,
      elt.key,
    ])

    downloadExcel(title, heading, data)
    showToast(props.t("Excel file exported succesfully!"), "success")
  }

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title={props.t("Gateway logs")} />

          <Card>
            <CardBody>
              <Accordion
                flush
                open={filtersOpen}
                toggle={id => {
                  id === filtersOpen ? setFiltersOpen("") : setFiltersOpen(id)
                }}
              >
                <AccordionItem>
                  <AccordionBody
                    accordionId="1"
                    className="mb-3"
                    style={{
                      backgroundColor: "#e8f0fe",
                      border: "1px solid #ced4da",
                    }}
                  >
                    <Row>
                      <Col></Col>
                    </Row>
                  </AccordionBody>
                </AccordionItem>
              </Accordion>
              <Row>
                <Col>
                  {logs != null && (
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          columns={columns}
                          data={filteredLogs}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-1">
                                {" "}
                                <Col className="col-auto pe-0">
                                  <Form
                                    className="mt-sm-0 d-flex align-items-center"
                                    onSubmit={e => {
                                      e.preventDefault()
                                      return false
                                    }}
                                  >
                                    <div className="search-box me-2 mb-2 d-inline-block float-end">
                                      <div className="position-relative">
                                        <Input
                                          type="text"
                                          onChange={event =>
                                            setSearchText(
                                              event.target.value.toLowerCase()
                                            )
                                          }
                                          placeholder={props.t("Search")}
                                        />
                                        <i className="bx bx-search-alt search-icon" />
                                      </div>
                                    </div>
                                  </Form>
                                </Col>
                                <Col>
                                  <CustomRangePicker
                                    value={dateRange}
                                    rangeChanged={onDateSelection}
                                    showTime={true}
                                    allowClear={false}
                                  />
                                </Col>
                                <Col className="col-auto">
                                  <Button
                                    //className="ms-3"
                                    style={{ borderRadius: "100px" }}
                                    color="primary"
                                    disabled={ongoingSearch}
                                    onClick={() => {
                                      //getLogs()
                                      setDateRange([dateRange[0], moment()])
                                    }}
                                  >
                                    <i className="mdi mdi-refresh me-2" />
                                    {props.t("Refresh data")}
                                  </Button>
                                </Col>
                                <Col>
                                  <span className="float-end ms-2">
                                    <UncontrolledTooltip
                                      placement="bottom"
                                      target="downloadstatstable"
                                    >
                                      {props.t("Download")}
                                    </UncontrolledTooltip>
                                    <Dropdown
                                      id="printButtonServicesStats"
                                      isOpen={menu}
                                      toggle={() => setMenu(!menu)}
                                      className="download-btn text-center"
                                      type="button"
                                    >
                                      <DropdownToggle
                                        id="downloadstatstable"
                                        className="text-muted"
                                        tag="i"
                                      >
                                        <i className="mdi mdi-download" />
                                      </DropdownToggle>
                                      <DropdownMenu>
                                        {/* TODO: Can not choose directory file right now */}
                                        <DropdownItem
                                          onClick={() => generateExcel()}
                                        >
                                          {props.t("Download .xlsx")}
                                        </DropdownItem>
                                      </DropdownMenu>
                                    </Dropdown>
                                  </span>
                                  <div className="mt-2 float-end">
                                    <p>
                                      {filteredLogs.length}{" "}
                                      {props.t("results found")}
                                    </p>
                                  </div>
                                </Col>
                              </Row>

                              <Row className="mt-2">
                                <Col xl="12">
                                  {ongoingSearch == true ? (
                                    <div className="py-5 text-center">
                                      <p className="text-primary">
                                        <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                                        {props.t("Loading")}
                                      </p>
                                    </div>
                                  ) : (
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        id="logs-table"
                                        keyField={"id"}
                                        responsive
                                        bordered={true}
                                        striped={true}
                                        hover={true}
                                        defaultSorted={defaultSorted}
                                        classes={
                                          "table align-middle table-nowrap"
                                        }
                                        headerWrapperClasses={"thead-light"}
                                        headerClasses="header-class"
                                        {...toolkitProps.baseProps}
                                        {...paginationTableProps}
                                      />
                                    </div>
                                  )}
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-3 mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <Col className="pagination justify-content-end inner-custom-pagination">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </Col>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  )}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </>
  )
}

GatewayLogs.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(GatewayLogs)
