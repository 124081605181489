import React, { useEffect, useState } from "react"
import { Row, Col, Button } from "reactstrap"
import PropTypes from "prop-types"
import moment from "moment"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import { withTranslation } from "react-i18next"

// Datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import "../../Reporting/datatables.scss"

const MonitoringAlarmTable = props => {
  const [alarms, setAlarms] = useState([])

  //Setting data state
  useEffect(() => {
    setAlarms(props.data)
  }, [props.data])

  const actionsFormatter = (cell, row) => (
    <Button
      color="primary"
      outline
      size="sm"
      id={row.id}
      onClick={() => {
        props.setShowDrawer(true)
        props.setSelectedRow(row)
      }}
    >
      {props.t("Show")}
    </Button>
  )

  // Sort data in table
  const defaultSorted = [
    {
      dataField: "updated_at",
      order: "desc",
    },
  ]
  // Show selected number of rows
  const pageOptions = {
    custom: true,
    sizePerPageList: [
      { text: "20", value: 20 },
      { text: "50", value: 50 },
      { text: props.t("All"), value: alarms?.length || 0 },
    ],
  }

  // Table structure
  const columns = [
    {
      dataField: "action",
      text: "", //props.t("Actions"),
      sort: false,
      isDummyField: true,
      align: "center",
      headerStyle: (colum, colIndex) => {
        return { width: "150px", textAlign: "center" }
      },
      formatter: actionsFormatter,
    },
    /*{
      dataField: "status",
      text: props.t("Status"),
      sort: true,
      formatter: statusFormatter,
    },*/
    {
      dataField: "description",
      text: props.t("Alarm"),
      sort: true,
      formatter: descriptionFormatter,
    },

    {
      dataField: "client",
      text: props.t("Customer"),
      sort: true,
    },
    {
      dataField: "updated_at",
      text: props.t("Time"),
      sort: true,
      formatter: dateFormatter,
    },
    /*{
      dataField: "acked_by",
      text: props.t("Handler"),
      sort: true,
    },
    {
      dataField: "closed_by",
      text: props.t("Closed by"),
      sort: true,
      // formatter: timeFormatter,
    },
    {
      dataField: "ack_time",
      text: props.t("Handling time"),
      sort: true,
      formatter: timeFormatter,
    },
    {
      dataField: "close_time",
      text: props.t("Closing time"),
      sort: true,
      formatter: timeFormatter,
    },*/
  ]

  function dateFormatter(value) {
    let unix = value / 1000
    var dateString = moment.unix(unix).format("DD.MM.YYYY HH:mm:ss")
    return dateString
  }

  // Can be used to modify grid
  function statusFormatter(cell) {
    return cell ? (
      <span className="badge bg-success font-size-12">
        {props.t("In use")} <i className="bx bx-check"></i>
      </span>
    ) : (
      <span className="badge bg-danger font-size-12"></span> // HOX: Empty span
    )
  }

  // Modified status
  function statusFormatter(cell) {
    if (cell == "CLOSED") {
      return <i className="bx bx-check-circle font-size-20 text-success me-1" />
    } else if (cell == "ASSIGNED") {
      return <i className="bx bxs-calendar-heart font-size-20 text-warning" />
    } else {
      return <i className="bx bxs-calendar-heart font-size-20 text-danger" />
    }
  }

  function descriptionFormatter(cell, row) {
    //return props.t(value)
    if (row.status == "CLOSED") {
      return (
        <>
          <i
            style={{ verticalAlign: "bottom" }}
            className="bx bx-check-circle font-size-20 text-success me-2"
          />
          <span>{props.t(cell)}</span>
        </>
      )
    } else if (row.status == "ASSIGNED") {
      return (
        <>
          <i
            style={{ verticalAlign: "bottom" }}
            className="bx bxs-calendar-heart font-size-20 text-warning me-2"
          />
          <span>{props.t(cell)}</span>
        </>
      )
    } else {
      return (
        <>
          <i
            style={{ verticalAlign: "bottom" }}
            className="bx bxs-calendar-heart font-size-20 text-danger me-2"
          />
          <span>{props.t(cell)}</span>
        </>
      )
    }
  }

  // Converting time from seconds to readable
  function timeFormatter(value) {
    let hours = Math.floor(value / 3600) // get hours
    let minutes = Math.floor((value - hours * 3600) / 60) // get minutes
    let seconds = value - hours * 3600 - minutes * 60 //  get seconds
    if (hours < 10) {
      hours = "0" + hours
    }
    if (minutes < 10) {
      minutes = "0" + minutes
    }
    if (seconds < 10) {
      seconds = "0" + seconds
    }
    return value ? hours + ":" + minutes + ":" + seconds : ""
  }

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          {alarms != undefined && (
            <PaginationProvider pagination={paginationFactory(pageOptions)}>
              {({ paginationProps, paginationTableProps }) => (
                <ToolkitProvider
                  keyField="key"
                  columns={columns}
                  data={alarms}
                  search
                >
                  {toolkitProps => (
                    <React.Fragment>
                      <Row>
                        <Col xl="12">
                          <div className="table-responsive">
                            <BootstrapTable
                              id="alarms-table"
                              keyField={"key"}
                              responsive
                              bordered={false}
                              striped={false}
                              defaultSorted={defaultSorted}
                              classes={"table align-middle table-nowrap"}
                              headerWrapperClasses={"thead-light"}
                              {...toolkitProps.baseProps}
                              {...paginationTableProps}
                            />
                          </div>
                        </Col>
                      </Row>

                      <Row className="align-items-md-center mt-3 mt-30">
                        <Col className="inner-custom-pagination d-flex">
                          <div className="">
                            <SizePerPageDropdownStandalone
                              {...paginationProps}
                            />
                          </div>
                          <Col className="pagination justify-content-end inner-custom-pagination">
                            <PaginationListStandalone {...paginationProps} />
                          </Col>
                        </Col>
                      </Row>
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              )}
            </PaginationProvider>
          )}
        </Col>
      </Row>
    </React.Fragment>
  )
}

MonitoringAlarmTable.propTypes = {
  t: PropTypes.any,
  data: PropTypes.any,
  setShowDrawer: PropTypes.func,
  setSelectedRow: PropTypes.func,
}

export default withTranslation()(MonitoringAlarmTable)
