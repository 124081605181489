import React from "react"
import moment from "moment"
import loadingAnim from "common/animations/loading-dots-blue"
import findAnim from "common/animations/finddata"
import nodataAnim from "common/animations/nodata"
import toastr from "toastr"
import "toastr/build/toastr.min.css"
import "moment/locale/fi"
import "moment/locale/se"
import "moment/locale/en-gb"
import fin from "antd/es/locale/fi_FI"
import swe from "antd/es/locale/sv_SE"
import en from "antd/es/locale/en_GB"
import { Link } from "react-router-dom"
import themes from "common/themes"
import { get, map } from "lodash"
// import themeVar from "common/themes-var"

//Excel export
import XLSX from "xlsx"
//Autotable PDF
import jsPDF from "jspdf"
import "jspdf-autotable"
import { func } from "prop-types"

/**
 * Check if given string is a valid UUID
 * @param {*} str
 * @returns boolean
 */
export function checkIfValidUUID(str) {
  const regexExp =
    /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi
  return regexExp.test(str)
}

/**
 * @param {String} dateString
 * @returns Formatted date as "DD.MM.YYYY HH:mm:ss"
 */
export function formatDateFull(date) {
  return moment(date).format("D.M.YYYY HH:mm:ss")
}
export function formatDateHoursMinutes(date) {
  return moment(date).format("D.M.YYYY HH:mm")
}
export function formatDateStringFull(dateString) {
  return moment(dateString).format("DD.MM.YYYY HH:mm:ss")
}
export function formatDate(date) {
  return moment(date).format("DD.MM.YYYY")
}
export function formatDateSimple(date) {
  return moment(date).format("D.M.YYYY")
}
export function formatDateWithWeekDay(date) {
  return moment(date).format("dddd")
}
export function dateFormatter(value) {
  if (!value) return ""
  var dateString = moment(value).format("DD.MM.YYYY HH:mm:ss")
  return dateString
}
export function formatDateTime(date) {
  return moment(date).format("HH:mm")
}

// Time formatter
export function timeFormatter(value) {
  let hours = Math.floor(value / 3600) // get hours
  let minutes = Math.floor((value - hours * 3600) / 60) // get minutes
  let seconds = value - hours * 3600 - minutes * 60 //  get seconds
  if (hours < 10) {
    hours = "0" + hours
  }
  if (minutes < 10) {
    minutes = "0" + minutes
  }
  if (seconds < 10) {
    seconds = "0" + seconds
  }
  return value ? hours + ":" + minutes + ":" + seconds : ""
}

// Sort strings
export function stringSorter(a, b, param, sortOrder = "ascend") {
  return a[param]
    ? b[param]
      ? a[param].localeCompare(b[param])
      : sortOrder == "ascend"
      ? -1
      : 1
    : sortOrder == "ascend"
    ? 1
    : -1
}

// Sort numbers - nulls always last
export function numberSorter(a, b, sortOrder = "ascend") {
  return a != null
    ? b != null
      ? a - b
      : sortOrder == "ascend"
      ? -1
      : 1
    : sortOrder == "ascend"
    ? 1
    : -1
}

// Sort dates - nulls and zeros always last
export function dateSorter(a, b, sortOrder = "ascend") {
  return a
    ? b
      ? a - b
      : sortOrder == "ascend"
      ? -1
      : 1
    : sortOrder == "ascend"
    ? 1
    : -1
}

/**
 * @returns Options for Lottie
 */
export function getLottieLoadingOptions() {
  return {
    loop: true,
    autoplay: true,
    animationData: loadingAnim,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }
}

export function getLottieSearch() {
  return {
    loop: false,
    autoplay: true,
    animationData: findAnim,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }
}

export function getLottieNothingFound() {
  return {
    loop: false,
    autoplay: true,
    animationData: nodataAnim,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }
}

// Show toast
export function showToast(
  message,
  type = "info",
  timeOut = 8000,
  position = "toast-top-center"
) {
  toastr.options = {
    positionClass: position,
    timeOut: timeOut,
    extendedTimeOut: 1000,
    closeButton: true,
    progressBar: true,
    showEasing: "swing",
    hideEasing: "linear",
    showMethod: "fadeIn",
    hideMethod: "fadeOut",
    showDuration: 300,
    hideDuration: 1000,
  }
  switch (type) {
    case "success":
      toastr.success(message)
      break
    case "error":
      toastr.error(message)
      break
    case "warning":
      toastr.warning(message)
      break
    default:
      toastr.info(message)
      break
  }
}

// Get locale for antd datepicker etc.
export function getLocale() {
  var language = localStorage.getItem("i18nextLng")
  if (language.indexOf("-") > -1) language = language.split("-")[0]
  switch (language) {
    case "en":
      return en
    case "fi":
      return fin
    case "sv":
      return swe
    default:
      return null
  }
}

// Get locale text: en / fi / sv
export function getLocaleText() {
  var language = localStorage.getItem("i18nextLng")
  if (language.indexOf("-") > -1) language = language.split("-")[0]
  switch (language) {
    case "en":
      return "en"
    case "fi":
      return "fi"
    case "sv":
      return "sv"
    default:
      return undefined
  }
}

// Formatting bool for export files
export function isTrue(value) {
  if (value === true) {
    return "X"
  } else {
    return ""
  }
}

// Download excel
export function downloadExcel(title, heading, data) {
  const wb = XLSX.utils.book_new()
  const ws = XLSX.utils.json_to_sheet(data, {
    origin: "A2",
    skipHeader: true,
  })
  XLSX.utils.sheet_add_aoa(ws, heading, { origin: "A1" })
  XLSX.utils.book_append_sheet(wb, ws, title)
  XLSX.write(wb, { bookType: "xlsx", type: "buffer" })
  XLSX.writeFile(wb, title + " (" + moment().format("LLL") + ")" + ".xlsx")
}

// Export PDF table
export function exportPDF(title, heading, data) {
  const unit = "pt"
  const size = "A4" // Use A1, A2, A3 or A4
  const orientation = "landscape" // portrait or landscape
  const theme = "grid" //striped,  plain

  const marginLeft = 40
  const doc = new jsPDF(orientation, unit, size, theme)

  doc.setFontSize(14)

  let content = {
    startY: 50,
    head: [heading],
    body: data,
  }

  doc.text(title, marginLeft, 40)
  doc.autoTable(content)
  doc.save(title + " (" + new Date() + ")" + ".pdf")
}

// Delete duplicates from array
export function deleteDuplicated(array) {
  var newArray = []
  var newArray = array.filter(function (elem, pos) {
    return array.indexOf(elem) == pos
  })
  console.log("new set:" + newArray)
  return newArray
}

// Delete duplicates from array of objects
export function deleteDuplicatedObject(arr) {
  let newArr = arr.filter(
    (v, i, a) => a.findIndex(v2 => v2.value === v.value) === i
  )
  return newArr
}

// Get translatable role name
export function getTranslatedRole(role) {
  switch (role) {
    case "system_admin":
      return "System Admin"
    case "organisation_admin":
      return "Organisation Admin"
    case "organisation_user":
      return "Organisation User"
    case "private_user":
      return "Private User"
    case "salesperson":
      return "Salesperson"
    case "order_manager":
      return "Order Manager"
    default:
      return ""
  }
}

// export function themeVariables(theme) {
//   const filteredTheme = Object.keys(themeVar)
//     .filter(key => theme.includes(key))
//     .reduce((obj, key) => {
//       obj[key] = themeVar[key]
//       console.log()
//       return obj
//     }, {})
//   // console.log(filteredTheme)
//   return filteredTheme
// }
