import React, { useEffect, useState, useContext, useRef } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import {
  Card,
  CardBody,
  Modal,
  CardHeader,
  Col,
  Collapse,
  Row,
  CardTitle,
  Container,
  NavItem,
  NavLink,
  TabContent,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledTooltip,
  Dropdown,
  TabPane,
  Button,
} from "reactstrap"
import SimpleBar from "simplebar-react"
import moment from "moment"
import Lottie from "react-lottie"
import loadingAnim from "common/animations/loading-dots-blue"
import sanitizeHtml from "sanitize-html"

// Entries related imports
import classnames from "classnames"
import img1 from "../../assets/images/small/img-6.jpg"
import iconVideocall from "../../assets/images/icons/videocall.svg"
import iconApp from "../../assets/images/icons/app.svg"
import iconMedicine from "../../assets/images/icons/medicineReminder.svg"
import iconGroupvideo from "../../assets/images/icons/groupvideo.png"
import iconWebpageAlt from "../../assets/images/icons/webIconAlt.svg"

// Welcome card related
import features from "../../assets/images/suvanto_profile_logo.png"
// TEST
import small from "../../assets/images/verification-img.png"

//Translation
import { withTranslation } from "react-i18next"

import "assets/scss/datatables.scss"

import Breadcrumbs from "components/Common/Breadcrumb"
import { getReleaseNotes, getStatusReports } from "helpers/database_helper"
import GlobalState from "contexts/GlobalState"
import ReleaseListItem from "./release-list-item"
import StatusReportItem from "./status-report-item"
import Manual from "components/Manual/Manual"

// TEST
import { useTheme } from "contexts/ThemeProvider"
import { FormGroup } from "@material-ui/core"
import { showToast } from "utils/general"

const LandingPage = props => {
  // TEST
  const { theme, toggleTheme } = useTheme()
  const [cogOpen, setCogOpen] = useState(false)

  const [state, setState] = useContext(GlobalState)

  // Entries related
  const [activeTab, setActiveTab] = useState("1")

  // Selected modal
  const [selectedModal, setSelectedModal] = useState(null)
  const [modal, setModal] = useState(false)
  const [firstName, setFirstName] = useState("")
  const [supportType, setSupportType] = useState(null)
  const [selectedModalContent, setSelectedModalContent] = useState()

  const [releaseNotes, setReleaseNotes] = useState([])
  const [releaseNotesLoading, setReleaseNotesLoading] = useState(true)
  const [releaseNotesNone, setReleaseNotesNone] = useState(false)

  const [statusReports, setStatusReports] = useState([])
  const [statusReportsLoading, setStatusReportsLoading] = useState(true)
  const [statusReportsNone, setStatusReportsNone] = useState(false)
  const [ongoingIssues, setOngoingIssues] = useState(false)
  const [resolvedIssues, setResolvedIssues] = useState(false)

  const [supportEmail, setSupportEmail] = useState("")
  const [supportGsm, setSupportGsm] = useState("040 6577 343")
  const [supportGsm2, setSupportGsm2] = useState()
  const [showFaq, setShowFaq] = useState(false)
  const [secureSupportMail, setSecureSupportMail] = useState(
    "https://secmail.com/send/NDVSnGhL"
  )

  // Check if card is null/"true"/"false" in localStorage
  const [welcomeCardOpen, setWelcomeCardOpen] = useState(
    localStorage.getItem("welcomeCard") != null
      ? localStorage.getItem("welcomeCard") == "false"
        ? false
        : true
      : true
  )

  const abortRef = useRef(null)
  useEffect(() => {
    document.title = props.t("Front page") + " | Suvanto Care"
    abortRef.current = new AbortController()
    return () => abortRef.current.abort()
  }, [])

  // Get support type from user
  useEffect(() => {
    if (state.authUser != null) {
      const user = JSON.parse(state.authUser)
      const name = user.firstName
      setFirstName(name)
      setSupportType(user.supportType)
      if (user.supportType != null) {
        let prefix, domain
        switch (user.supportType) {
          // Elisa
          case 1:
            prefix = "digihoiva.tuki"
            domain = "elisa.fi"
            setSupportEmail(prefix + "@" + domain)
            setSupportGsm("050 3571 727")
            setShowFaq(false)
            setSecureSupportMail(null)
            break
          // 2M-IT
          case 2:
            prefix = "servicedesk"
            domain = "2m-it.fi"
            setSupportEmail(prefix + "@" + domain)
            setSupportGsm(null)
            setShowFaq(false)
            setSecureSupportMail(null)
            break
          // Laajennettu tuki
          case 3:
            prefix = "tuki"
            domain = "suvantocare.fi"
            setSupportEmail(prefix + "@" + domain)
            setSupportGsm("040 6577 343")
            setSupportGsm2("040 6577 378")
            setShowFaq(true)
            setSecureSupportMail("https://secmail.com/send/NDVSnGhL")
            break
          // Elisa laajennettu tuki
          case 4:
            prefix = "digihoiva.tuki"
            domain = "elisa.fi"
            setSupportEmail(prefix + "@" + domain)
            setSupportGsm("050 3571 727")
            setSupportGsm2("040 6577 378")
            setShowFaq(false)
            setSecureSupportMail(null)
            break
          // 2M-IT laajennettu, ei oikeita tietoja vielä
          case 5:
            prefix = "sovellustuki"
            domain = "2m-it.fi"
            setSupportEmail(prefix + "@" + domain)
            setSupportGsm("010 198 015")
            setShowFaq(false)
            setSecureSupportMail(null)
            break
          default:
            prefix = "tuki"
            domain = "suvantocare.fi"
            setSupportEmail(prefix + "@" + domain)
            setSupportGsm("040 6577 343")
            setShowFaq(true)
            setSecureSupportMail("https://secmail.com/send/NDVSnGhL")
            break
        }
      }
    }
  }, [])

  // Release notes
  useEffect(() => {
    getReleaseNotes(abortRef.current, true, 5, null)
      .then(notes => {
        //console.log("Get release notes result: ", notes);
        let array = []
        notes.forEach(note => {
          let niceDate = moment(note.informed_date).format("DD.MM.YYYY HH:mm")
          let temp = new Object()
          temp.modalContent = "releaseNote"
          temp.id = note.id
          temp.date = niceDate
          temp.title = note.target + " v." + note.version

          if (note.release_date) {
            temp.releaseDate = moment(note.release_date).format("DD.MM.YYYY")
          }

          temp.content1_fi = note.what_changes_fi
            ? note.what_changes_fi.replace(/(?:\r\n|\r|\n)/g, "\n")
            : ""
          temp.content2_fi = note.how_use_changes_fi
            ? note.how_use_changes_fi.replace(/(?:\r\n|\r|\n)/g, "\n")
            : ""
          temp.content3_fi = note.how_effects_fi
            ? note.how_effects_fi.replace(/(?:\r\n|\r|\n)/g, "\n")
            : ""
          temp.content1_en = note.what_changes_en
            ? note.what_changes_en.replace(/(?:\r\n|\r|\n)/g, "\n")
            : ""
          temp.content2_en = note.how_use_changes_en
            ? note.how_use_changes_en.replace(/(?:\r\n|\r|\n)/g, "\n")
            : ""
          temp.content3_en = note.how_effects_en
            ? note.how_effects_en.replace(/(?:\r\n|\r|\n)/g, "\n")
            : ""
          temp.content1_sv = note.what_changes_sv
            ? note.what_changes_sv.replace(/(?:\r\n|\r|\n)/g, "\n")
            : ""
          temp.content2_sv = note.how_use_changes_sv
            ? note.how_use_changes_sv.replace(/(?:\r\n|\r|\n)/g, "\n")
            : ""
          temp.content3_sv = note.how_effects_sv
            ? note.how_effects_sv.replace(/(?:\r\n|\r|\n)/g, "\n")
            : ""

          temp.changesProUser = note.changes_pro_ui == 1 ? "Yes" : "No"
          temp.changesEndUser = note.changes_enduser_ui == 1 ? "Yes" : "No"
          temp.changesProcedure = note.changes_user_methods == 1 ? "Yes" : "No"
          temp.type = note.type == 1 ? "Hotfix" : "Update"
          temp.version = note.version

          switch (note.target) {
            case 1:
              temp.target = "ReleaseNoteTypes.1" // Suvanto Care app
              temp.img = iconApp
              break
            case 2:
              temp.target = "ReleaseNoteTypes.2" // Suvanto Health
              temp.img = iconMedicine
              break
            case 3:
              temp.target = "ReleaseNoteTypes.3" // Suvanto Video
              temp.img = iconVideocall
              break
            case 4:
              temp.target = "ReleaseNoteTypes.4" // Suvanto Care webpage
              temp.img = iconWebpageAlt
              break
            case 5:
              temp.target = "ReleaseNoteTypes.5" // Group video call
              temp.img = iconGroupvideo
              break
            /*case 6:
              temp.target = "Medicine reminder"
              temp.img = iconMedicine
              break*/
            default:
              temp.target = "Unknown"
              break
          }

          switch (note.status) {
            case 1:
              temp.status = "Upcoming"
              break
            case 2:
              temp.status = "Waiting for release"
              break
            case 3:
              temp.status = "Postponed"
              break
            case 4:
              temp.status = "Published"
              break
            default:
              temp.status = "Unknown"
              break
          }

          array.push(temp)
        })
        setReleaseNotes(array)
        setReleaseNotesLoading(false)
        if (notes.length == 0) setReleaseNotesNone(true)
      })
      .catch(err => {
        console.log("Release notes error: ", err)
        setReleaseNotesLoading(false)
        setReleaseNotesNone(true)
      })
  }, [])

  // Status reports
  useEffect(() => {
    getStatusReports(abortRef.current, true, 10)
      .then(reports => {
        //console.log("Get status reports result: ", reports);
        const currentLanguage = localStorage.getItem("i18nextLng")
        let issues = false,
          resolved = false
        let array = []
        reports.forEach(rep => {
          let niceDate = moment(rep.start_time).format("DD.MM.YYYY HH:mm")
          if (rep.end_time) {
            var endDate = new Date(rep.end_time)
            if (endDate > 0) {
              let endDateNice = moment(rep.end_time).format("DD.MM.YYYY HH:mm")
              niceDate = niceDate + " - " + endDateNice
            }
          }

          let temp = new Object()
          temp.modalContent = "statusReport"
          temp.id = rep.id
          temp.date = niceDate

          temp.title = rep.header_fi //header_en
          temp.title_fi = rep.header_fi
          temp.title_en = rep.header_en
          temp.title_sv = rep.header_sv

          /*if (currentLanguage) {
            if (currentLanguage.indexOf("fi") == 0) {
              temp.title = rep.header_fi;
            }
            else if (currentLanguage.indexOf("sv") == 0) {
              temp.title = rep.header_sv;
            }
          }*/

          const sanitizeSettings = {
            allowedTags: ["b", "i", "em", "strong", "a"],
            allowedAttributes: {
              a: ["href", "target"],
            },
            nonBooleanAttributes: [],
          }
          rep.description_fi = sanitizeHtml(
            rep.description_fi,
            sanitizeSettings
          )
          rep.description_en = sanitizeHtml(
            rep.description_en,
            sanitizeSettings
          )
          rep.description_sv = sanitizeHtml(
            rep.description_sv,
            sanitizeSettings
          )

          temp.content1_fi = rep.description_fi
            ? rep.description_fi.replace(/(?:\r\n|\r|\n)/g, "\n")
            : ""
          temp.content1_en = rep.description_en
            ? rep.description_en.replace(/(?:\r\n|\r|\n)/g, "\n")
            : ""
          temp.content1_sv = rep.description_sv
            ? rep.description_sv.replace(/(?:\r\n|\r|\n)/g, "\n")
            : ""
          temp.content1 = temp.content1_fi

          // TODO: Proper target IDs
          let targets = []
          let split = rep.target_groups.split(",")
          split.forEach(target => {
            switch (target) {
              case "1":
                targets.push("Suvanto Care app")
                break
              case "2":
                targets.push("Video call")
                break
              case "3":
                targets.push("Group video call")
                break
              case "4":
                targets.push("Carephone")
                break
              case "5":
                targets.push("Medicine reminder")
                break
              case "6":
                targets.push("Suvanto On the Go")
                break
              case "7":
                targets.push("Suvanto Home")
                break
              case "8":
                targets.push("Smartlock")
                break
              case "9":
                targets.push("Suvanto Care webpage")
                break
            }
          })
          temp.targets = targets

          switch (rep.current_status) {
            case 1:
              issues = true
              temp.status = "Service outage"
              temp.img = img1
              temp.color = "#f46a6a"
              break
            case 2:
              issues = true
              temp.status = "Service disruption"
              temp.img = img1
              temp.color = "#f1b44c"
              break
            case 3:
              resolved = true
              temp.status = "Service operational"
              temp.img = img1
              temp.color = "#34c38f"
              break
          }

          array.push(temp)
        })

        setStatusReports(array)
        setOngoingIssues(issues)
        setResolvedIssues(resolved)

        // Show disturbance notices automatically if ongoing issues
        if (issues == true) toggle("2")

        setStatusReportsLoading(false)
        if (reports.length == 0) setStatusReportsNone(true)
      })
      .catch(err => {
        console.log("Status reports error: ", err)
        setStatusReportsLoading(false)
        setStatusReportsNone(true)
      })
  }, [])

  // For modal for news
  const onOpenModal = i => {
    i.content1 = i.content1_fi
    i.content2 = i.content2_fi
    i.content3 = i.content3_fi
    i.title = i.title_fi

    /*i.content1 = i.content1_en;
    i.content2 = i.content2_en;
    i.content3 = i.content3_en;
    i.title = i.title_en;

    const currentLanguage = localStorage.getItem("i18nextLng")
    if (currentLanguage) {
      if (currentLanguage.indexOf("fi") == 0) {
        i.content1 = i.content1_fi;
        i.content2 = i.content2_fi;
        i.content3 = i.content3_fi;
        i.title = i.title_fi;
      }
      else if (currentLanguage.indexOf("sv") == 0) {
        i.content1 = i.content1_sv;
        i.content2 = i.content2_sv;
        i.content3 = i.content3_sv;
        i.title = i.title_sv;
      }
    }*/

    setSelectedModal(i)
    toggleModal()
  }

  // Change card view
  const changeView = i => {
    switch (i) {
      case 1:
        let value = JSON.stringify(!welcomeCardOpen)
        setWelcomeCardOpen(prevState => !prevState)
        localStorage.setItem("welcomeCard", value)
        return
      case 2:
        // TODO: rest of cards modify
        setWelcomeCardOpen(prevState => !prevState)
        return
      case 3:
        // TODO: rest of cards modify
        setWelcomeCardOpen(prevState => !prevState)
        return
    }
  }

  // Modal needs an id of selected link for rendering right data from array
  const toggleModal = () => {
    console.log("toggleModal function")
    setModal(!modal)
    removeBodyCss()
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  const animationLoading = {
    loop: true,
    autoplay: true,
    animationData: loadingAnim,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }

  const onButtonClick = () => {
    fetch("/files/file.pdf").then(response => {
      response.blob().then(blob => {
        const fileURL = window.URL.createObjectURL(blob)
        let alink = document.createElement("a")
        alink.href = fileURL
        alink.download = props.t("Instructions") + ".pdf"
        alink.click()
      })
    })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            className="breadcrumb m-0 justify-content-center"
            title={props.t("Front page")}
            breadcrumbItem={
              <>
                <div className="d-inline-block mb-0">
                  <Dropdown
                    isOpen={cogOpen}
                    toggle={() => setCogOpen(!cogOpen)}
                  >
                    <DropdownToggle
                      id="cog-button"
                      className="btn nav-btn"
                      tag="a"
                      style={{ marginTop: "2px" }}
                    >
                      <i className="bx bx-cog font-size-16"></i>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-end py-2 px-3">
                      <h6 className="pb-2 pt-1">{props.t("Modify view")}</h6>
                      <div className="pb-1 text-muted">
                        <input
                          type="checkbox"
                          id="welcomecard"
                          autoComplete="off"
                          className="form-check-input  me-2"
                          defaultChecked={!!welcomeCardOpen}
                          onChange={() => {
                            changeView(1)
                          }}
                        ></input>
                        <label
                          className="form-check-label"
                          htmlFor="welcomecard"
                        >
                          <p className="mb-2">{props.t("Welcome card")}</p>
                        </label>
                      </div>
                      {/* <div className="pb-1 text-muted">
                        <input
                          disabled={true}
                          type="checkbox"
                          id="welcomecard"
                          className="form-check-input me-2 "
                          defaultChecked={true}
                          // onChange={() => {
                          //   changeView(2)
                          // }}
                        ></input>
                        <label
                          className="form-check-label"
                          htmlFor="welcomecard"
                        >
                          <p className="mb-2">{props.t("Support card")}</p>
                        </label>
                      </div>
                      <div className="pb-1 text-muted">
                        <input
                          disabled={true}
                          type="checkbox"
                          id="welcomecard"
                          className="form-check-input me-2 "
                          defaultChecked={true}
                          // onChange={() => {
                          //   changeView(3)
                          // }}
                        ></input>
                        <label
                          className="form-check-label "
                          htmlFor="welcomecard"
                        >
                          <p className="mb-2">{props.t("Bulletins card")}</p>
                        </label>
                      </div> */}
                    </DropdownMenu>
                  </Dropdown>
                  <UncontrolledTooltip placement="bottom" target="cog-button">
                    {props.t("View settings")}
                  </UncontrolledTooltip>
                </div>

                {/* TODO: ISTEKKI !! Vielä yksi linnki https://suvanto.help/doku.php?id=public:istekki-ohjeet */}
                <div className="d-inline-block">
                  <Manual source="landing-page"></Manual>
                </div>
              </>
            }
          />
          <Modal
            isOpen={modal}
            toggle={() => {
              setModal(!modal)
            }}
            centered
          >
            {selectedModal && selectedModal.modalContent == "releaseNote" && (
              <div>
                <div className="modal-header">
                  <h5 className="modal-title mt-0">
                    {props.t(selectedModal.target) +
                      " v." +
                      selectedModal.version}
                  </h5>
                  <button
                    type="button"
                    onClick={() => {
                      setModal(false)
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <h6>{props.t("Changes")}</h6>
                  <p style={{ whiteSpace: "pre-line" }}>
                    {selectedModal.content1}
                  </p>
                  <h6 className="mt-4">{props.t("Visible in")}</h6>
                  <p style={{ whiteSpace: "pre-line" }}>
                    {selectedModal.content2}
                  </p>
                  <h6 className="mt-4">{props.t("Affects")}</h6>
                  <table className="table">
                    <tbody>
                      <tr>
                        <td>{props.t("User interface for end user")}</td>
                        <td>{props.t(selectedModal.changesEndUser)}</td>
                      </tr>
                      <tr>
                        <td>
                          {props.t("User interface for professional user")}
                        </td>
                        <td>{props.t(selectedModal.changesProUser)}</td>
                      </tr>
                      <tr>
                        <td>{props.t("Procedural changes")}</td>
                        <td>{props.t(selectedModal.changesProcedure)}</td>
                      </tr>
                    </tbody>
                  </table>
                  <p className="mb-0" style={{ whiteSpace: "pre-line" }}>
                    {selectedModal.content3}
                  </p>
                  {selectedModal.releaseDate != null &&
                    selectedModal.releaseDate.length > 0 && (
                      <div>
                        <h6 className="mt-4">
                          {props.t("Release date") +
                            ": " +
                            selectedModal.releaseDate}
                        </h6>
                      </div>
                    )}
                </div>
              </div>
            )}

            {selectedModal && selectedModal.modalContent == "statusReport" && (
              <div>
                <div className="modal-header">
                  <h5 className="modal-title mt-0">{selectedModal.title}</h5>
                  <button
                    type="button"
                    onClick={() => {
                      setModal(false)
                    }}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <p
                    style={{ whiteSpace: "pre-line" }}
                    dangerouslySetInnerHTML={{ __html: selectedModal.content1 }}
                  ></p>
                </div>
              </div>
            )}
          </Modal>
          <Col lg="12">
            <Row>
              <Col lg="6">
                <Collapse isOpen={welcomeCardOpen}>
                  <Card>
                    <Col lg="12">
                      <Row>
                        <Col sm="12">
                          <div className="pt-3 px-3 mt-1 ms-4">
                            <h4 className="text-primary mt-2 mb-4">
                              {props.t("Hi")}, {firstName}!
                            </h4>
                            <p className="mt-4">{props.t("WhatsNew1")}.</p>
                            <p className="mt-3 mb-2">
                              {/* {props.t("WhatsNew3")}. */}
                              {props.t("WhatsNew4")}. {props.t("WhatsNew2")}.
                            </p>
                            <div className="mt-2 mb-4">
                              <a
                                href={"https://suvanto.info"}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <li>{props.t("Instructional videos")}</li>
                              </a>
                              <a
                                href={
                                  "https://suvanto.help/doku.php?id=public:istekki-ohjeet"
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <li>{props.t("Help portal")}</li>
                              </a>
                            </div>
                          </div>
                          <Row>
                            <div className="p-3 ms-4 mb-2 pt-0">
                              <Button
                                type="button"
                                color="primary"
                                // UPDATE: Update new instructions
                                onClick={() => onButtonClick()}
                                className="btn btn-primary p-2 px-3 me-2"
                              >
                                <i className="mdi mdi-file-download-outline me-2" />
                                {props.t("Download manual")}
                              </Button>
                              <Button
                                outline
                                color="primary"
                                className="py-2 px-3"
                                onClick={() => {
                                  changeView(1)
                                  showToast(
                                    props.t(
                                      "You can set the card to be visible from the settings button on the right"
                                    ),
                                    "info"
                                  )
                                }}
                              >
                                <i className="mdi mdi-check-bold me-2" />
                                {props.t("OK, hide this card")}
                              </Button>
                            </div>
                          </Row>
                        </Col>
                        {/* <Col md="4" sm="12">
                          <div className="container-img d-md-none pt-2">
                            <img
                              src={small}
                              alt=""
                              height="20"
                              className="auth-logo-3 mx-auto"
                            />{" "}
                            <img
                              src={features}
                              alt=""
                              height="20"
                              width="auto"
                              className="auth-logo-1 mt-4 mx-auto"
                              // className="mt-4 mx-auto"
                            />
                          </div>
                        </Col> */}
                      </Row>
                    </Col>
                  </Card>
                </Collapse>

                {/* SUPPORT CARD */}
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">{props.t("Support")}</CardTitle>

                    <div>
                      <p className="text-muted">
                        {props.t("Support description 1")}{" "}
                        {(supportType === 3 || supportType === 4) && (
                          <>{props.t("Support description 4")}</>
                        )}
                        {(supportType === 0 || supportType === 1) && (
                          <>{props.t("Support description 3")}</>
                        )}
                      </p>
                      {showFaq && (
                        <p className="text-muted">
                          {props.t("Support description 2")}{" "}
                          <span className="text-primary">
                            <a
                              href={
                                "https://www.suvantocare.fi/kotona-asumisen-tukipalvelut/"
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {props.t("this link")}
                            </a>
                          </span>
                          .
                        </p>
                      )}
                      <Row>
                        <Col sm="8">
                          <div>
                            <p className="mt-3">
                              {supportType === 3 ||
                              supportType === 4 ||
                              supportType === 5 ? (
                                <>
                                  {props.t(
                                    "Extended support contact information"
                                  )}
                                </>
                              ) : (
                                <>{props.t("Support contact information")}</>
                              )}
                            </p>

                            <div className="text-muted">
                              {supportEmail != null && (
                                <p className="mb-1">
                                  <i className="mdi mdi-email align-middle text-primary me-1" />{" "}
                                  {supportEmail}
                                </p>
                              )}
                              {supportGsm != undefined && (
                                <p className="mb-1">
                                  <i className="mdi mdi-phone align-middle text-primary me-1" />{" "}
                                  {supportGsm} {props.t("Support gsm 1")}
                                </p>
                              )}
                              {supportGsm2 != undefined && (
                                <p className="mb-1">
                                  <i className="mdi mdi-phone-plus align-middle text-primary me-1" />{" "}
                                  {supportGsm2} {props.t("Support gsm 2")}
                                </p>
                              )}
                            </div>
                          </div>
                        </Col>
                        {secureSupportMail != null && (
                          <Col sm="4">
                            <div>
                              <p className="mt-3">
                                {props.t("Secure support email")}
                              </p>

                              <div className="text-muted">
                                <a
                                  href={secureSupportMail}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <button
                                    type="button"
                                    className="btn btn-secondary "
                                  >
                                    {props.t("Navigate")}
                                    <i className="bx bxs-send font-size-12 align-middle ms-2 "></i>
                                  </button>
                                </a>
                              </div>
                            </div>
                          </Col>
                        )}
                      </Row>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col lg="6">
                <Card>
                  <CardHeader className="bg-transparent border-bottom">
                    <div className="d-flex flex-wrap">
                      <div className="me-2">
                        <h5 className="card-title mt-1 mb-0">
                          {props.t("Bulletins")}
                        </h5>
                      </div>
                      <ul
                        className="nav nav-tabs nav-tabs-custom card-header-tabs ms-auto"
                        role="tablist"
                      >
                        <NavItem>
                          <NavLink
                            to="#"
                            className={classnames({
                              active: activeTab === "1",
                            })}
                            onClick={() => {
                              toggle("1")
                            }}
                          >
                            {props.t("Releases")}
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTab === "2",
                            })}
                            onClick={() => {
                              toggle("2")
                            }}
                          >
                            {ongoingIssues && (
                              <span className="mdi mdi-alert-circle text-danger me-1" />
                            )}
                            {!ongoingIssues && resolvedIssues && (
                              <span className="mdi mdi-alert-circle text-success me-1" />
                            )}
                            {props.t("Error bulletins")}
                          </NavLink>
                        </NavItem>
                      </ul>
                    </div>
                  </CardHeader>

                  <CardBody>
                    <SimpleBar style={{ maxHeight: "100vh" }}>
                      <div>
                        {/* RELEASE NOTES */}
                        <TabContent activeTab={activeTab}>
                          <TabPane className="show" tabId="1">
                            {releaseNotesLoading && (
                              <Col lg={12} style={{ height: "100%" }}>
                                <Lottie
                                  options={animationLoading}
                                  height={100}
                                  width={100}
                                />
                              </Col>
                            )}
                            {releaseNotesNone && (
                              <Col
                                className="mt-5 mb-5"
                                lg={12}
                                style={{ height: "100%" }}
                              >
                                <div className="text-center">
                                  <h6 className="text-muted">
                                    {props.t("No releases found")}
                                  </h6>
                                </div>
                              </Col>
                            )}
                            <ul className="list-group list-group-flush">
                              {releaseNotes.map(item => (
                                <li
                                  key={item.id}
                                  className="list-group-item py-3"
                                >
                                  <ReleaseListItem
                                    item={item}
                                    onOpenModal={selection => {
                                      onOpenModal(selection)
                                    }}
                                  />
                                </li>
                              ))}
                            </ul>
                          </TabPane>

                          {/* STATUS REPORTS */}
                          <TabPane className="show" tabId="2">
                            {statusReportsLoading && (
                              <Col lg={12} style={{ height: "100%" }}>
                                <Lottie
                                  options={animationLoading}
                                  height={100}
                                  width={100}
                                />
                              </Col>
                            )}
                            {statusReportsNone && (
                              <Col
                                className="mt-5 mb-5"
                                lg={12}
                                style={{ height: "100%" }}
                              >
                                <div className="text-center">
                                  <h6 className="text-muted">
                                    {props.t("No service issues found")}
                                  </h6>
                                </div>
                              </Col>
                            )}
                            <ul className="list-group list-group-flush">
                              {statusReports.map(item => (
                                <li
                                  key={item.id}
                                  className="list-group-item py-3"
                                >
                                  <StatusReportItem
                                    item={item}
                                    onOpenModal={selection => {
                                      onOpenModal(selection)
                                    }}
                                  />
                                </li>
                              ))}
                            </ul>
                          </TabPane>
                        </TabContent>
                      </div>
                    </SimpleBar>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
        </Container>
      </div>
    </React.Fragment>
  )
}

LandingPage.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(LandingPage)
