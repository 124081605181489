import React, { useEffect, useRef, useState } from "react"
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  AccordionBody,
  AccordionItem,
  Form,
  Input,
  Label,
  FormGroup,
  Button,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  CardHeader,
  Accordion,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Table,
  UncontrolledTooltip,
  Alert,
} from "reactstrap"
import PropTypes from "prop-types"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Link, useLocation } from "react-router-dom"
import { withTranslation } from "react-i18next"
import moment from "moment"
import { getCarephones, syncCarephones } from "helpers/backend_helper"

import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import "../datatables.scss"

import CarephoneDrawer from "./carephone-drawer"
import { showToast, downloadExcel } from "utils/general"
import { DatePicker } from "antd"

const CarephoneConfigs = props => {
  const [carephones, setCarephones] = useState([])
  const [filteredCarephones, setFilteredCarephones] = useState([])
  const [activeTab, setActiveTab] = useState("1")
  const [filtersOpen, setFiltersOpen] = useState("")
  const [alertsOpen, setAlertsOpen] = useState("")
  const [ongoingSearch, setOngoingSearch] = useState(false)
  const [ongoingSync, setOngoingSync] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [operationalFilter, setOperationalFilter] = useState("0")
  const [linkedFilter, setLinkedFilter] = useState("0")
  const [selectedRow, setSelectedRow] = useState(null)
  const [showDrawer, setShowDrawer] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [statsDate, setStatsDate] = useState(moment())
  const [statistics, setStatistics] = useState({ total: 0 })
  const [multilinks, setMultilinks] = useState([])
  const [duplicates, setDuplicates] = useState([])
  const [menu, setMenu] = useState(false) //Dropdown button state
  const [title, setTitle] = useState(props.t("Carephones"))

  const search = useLocation().search
  const abortRef = useRef(null)
  useEffect(() => {
    document.title = title + " | Suvanto Care"
    abortRef.current = new AbortController()

    const searchParam = new URLSearchParams(search).get("search")
    if (searchParam && searchParam != "null") setSearchText(searchParam.trim())

    getDevices()
    return () => abortRef.current.abort()
  }, [])

  // Clear selection when closing drawer
  useEffect(() => {
    if (!showDrawer) setSelectedRow(null)
  }, [showDrawer])

  // Calculate stats
  useEffect(() => {
    let temp = new Object()
    temp.total = carephones.length
    temp.linked = carephones.filter(data => data.links.length > 0).length
    temp.inUse = carephones.filter(
      data => data.operationalStatusValue == 0
    ).length
    temp.notUsed = carephones.filter(
      data => data.operationalStatusValue != 0
    ).length

    let at = statsDate.valueOf()
    temp.prod = 0
    carephones.forEach(item => {
      let start =
        item.prodStart != undefined ? new Date(item.prodStart) : undefined
      let end = item.prodEnd != undefined ? new Date(item.prodEnd) : undefined
      if (start != undefined && start.getTime() > 0 && start.getTime() < at) {
        if (end != undefined && start.getTime() < end.getTime()) {
          if (end > at) {
            temp.prod += 1
          }
        } else {
          temp.prod += 1
        }
      }
    })
    setStatistics(temp)
  }, [carephones, statsDate])

  // Check duplicate values
  useEffect(() => {
    if (carephones) {
      // GSM, ICCID, SERIAL
      let dups = []
      for (let i = 0; i < carephones.length - 1; i++) {
        for (let j = i + 1; j < carephones.length; j++) {
          if (
            carephones[i].iccid &&
            carephones[i].iccid === carephones[j].iccid
          ) {
            dups.push({
              key: carephones[i].iccid,
              name: "ICCID:" + carephones[i].iccid,
            })
          }
          if (carephones[i].gsm && carephones[i].gsm === carephones[j].gsm) {
            dups.push({
              key: carephones[i].gsm,
              name: "GSM:" + carephones[i].gsm,
            })
          }
          if (
            carephones[i].serial &&
            carephones[i].serial === carephones[j].serial
          ) {
            dups.push({
              key: carephones[i].serial,
              name: "SN:" + carephones[i].serial,
            })
          }
        }
      }
      setDuplicates(dups)
    }
  }, [carephones])

  // Check multiple links
  useEffect(() => {
    if (carephones) {
      let multis = []
      carephones.forEach(element => {
        if (element.links.length > 1) {
          multis.push({ key: element.key, name: element.serial })
        }
      })
      setMultilinks(multis)
    }
  }, [carephones])

  // Hide alerts if no content
  useEffect(() => {
    if (multilinks.length == 0 && duplicates.length == 0) setAlertsOpen(false)
  }, [multilinks, duplicates])

  // Search or filter changed
  useEffect(() => {
    setCurrentPage(1)
    handleSearch()
  }, [searchText, carephones, operationalFilter, linkedFilter])

  // Handle search function
  const handleSearch = () => {
    let searchTextLowercase = searchText.toLowerCase()
    let result = carephones.filter(
      data =>
        (operationalFilter === "0" ||
          (operationalFilter === "1" && data.operationalStatusValue == 0) ||
          (operationalFilter === "2" && data.operationalStatusValue != 0)) &&
        (linkedFilter === "0" ||
          (linkedFilter === "1" && data.links.length > 0) ||
          (linkedFilter === "2" && data.links.length == 0)) &&
        (data.key?.toLowerCase() === searchTextLowercase ||
          data.gsm?.toLowerCase().includes(searchTextLowercase) ||
          data.serial?.toLowerCase().includes(searchTextLowercase) ||
          data.iccid?.toLowerCase().includes(searchTextLowercase) ||
          data.linkedNames?.toLowerCase().includes(searchTextLowercase) ||
          data.orderNumber?.toLowerCase().includes(searchTextLowercase) ||
          data.accessories?.toLowerCase().includes(searchTextLowercase))
    )
    setFilteredCarephones(result)
  }

  // Get carephones
  const getDevices = () => {
    setOngoingSearch(true)
    getCarephones(abortRef.current)
      .then(result => {
        //console.log("care: ", result.entries)
        if (result.statusCode === 200 && result.entries != null) {
          result.entries.forEach(element => {
            let linkedNames = []
            if (element.links) {
              element.links.forEach(link => {
                linkedNames.push(link.name)
              })
            }
            element.linkedNames = linkedNames.join()
          })
          setCarephones(result.entries)
        } else {
          setCarephones([])
        }
        setOngoingSearch(false)
      })
      .catch(err => {
        console.log(err)
        setCarephones([])
        setOngoingSearch(false)
      })
  }

  // Sync all devices with Neat
  const syncDeviceList = () => {
    setOngoingSync(true)
    syncCarephones(abortRef.current)
      .then(result => {
        setOngoingSync(false)
        if (result.statusCode === 200) {
          showToast(props.t("Success"), "success")
          getDevices()
        } else {
          showToast(props.t("Error"), "error")
        }
      })
      .catch(err => {
        setOngoingSync(false)
        showToast(props.t("Error"), "error")
      })
  }

  const defaultSorted = [
    {
      dataField: "serial",
      order: "asc",
    },
  ]

  // Show selected number of rows
  const pageOptions = {
    custom: true,
    pageStartIndex: 1,
    totalSize: filteredCarephones.length,
    page: currentPage,
    sizePerPageList: [
      { text: "10", value: 10 },
      { text: "50", value: 50 },
      { text: "100", value: 100 },
      { text: props.t("All"), value: filteredCarephones?.length || 0 },
    ],
    onPageChange: (page, sizePerPage) => {
      setCurrentPage(page)
    },
    onSizePerPageChange: (sizePerPage, page) => {
      setCurrentPage(1)
    },
  }

  // Table structure
  const columns = [
    {
      dataField: "actions",
      isDummy: true,
      text: props.t("Actions"),
      sort: true,
      align: "center",
      formatter: editFormatter,
    },
    {
      dataField: "serial",
      text: props.t("Serial"),
      sort: true,
    },
    {
      dataField: "deviceType",
      text: props.t("Type"),
      sort: true,
    },
    {
      dataField: "firmware",
      text: props.t("Firmware"),
      sort: true,
    },
    {
      dataField: "callbackTime",
      text: props.t("Callback time"),
      sort: true,
    },
    {
      dataField: "orderNumber",
      text: props.t("Order number"),
      sort: true,
    },
    {
      dataField: "linkedNames",
      text: props.t("Links"),
      sort: true,
      formatter: linksFormatter,
    },
    {
      dataField: "gsm",
      text: props.t("Gsm"),
      sort: true,
    },
    {
      dataField: "iccid",
      text: props.t("ICCID"),
      sort: true,
    },
    {
      dataField: "syncDate",
      text: props.t("Synced"),
      sort: true,
      formatter: dateFormatter,
    },
    {
      dataField: "operationalStatus",
      text: props.t("Operation status"),
      sort: true,
    },
    {
      dataField: "globalTechStatus",
      text: props.t("Global status"),
      sort: true,
    },
    {
      dataField: "mainsStatus",
      text: props.t("Mains status"),
      sort: true,
    },
    {
      dataField: "accumulatorStatus",
      text: props.t("Battery status"),
      sort: true,
    },
    {
      dataField: "connectionPortalStatus",
      text: props.t("Connection status"),
      sort: true,
    },
    {
      dataField: "accessoriesBatteryStatus",
      text: props.t("Accessory battery status"),
      sort: true,
    },
    {
      dataField: "operator",
      text: props.t("Operator"),
      sort: true,
    },
    {
      dataField: "signalStrength",
      text: props.t("Signal strength"),
      sort: true,
    },
    {
      dataField: "lastReboot",
      text: props.t("Rebooted"),
      sort: true,
      formatter: dateFormatter,
    },
    /*{
      dataField: "lastAlarmId",
      text: props.t("Last alarm ID"),
      sort: true,
    },*/
    {
      dataField: "lastAlarmTime",
      text: props.t("Last alarm time"),
      sort: true,
      formatter: dateFormatter,
    },
    {
      dataField: "prodStart",
      text: props.t("Prod start"),
      sort: true,
      formatter: simpleDateFormatter,
    },
    {
      dataField: "prodEnd",
      text: props.t("Prod end"),
      sort: true,
      formatter: simpleDateFormatter,
    },
    {
      dataField: "accessories",
      text: props.t("Accessories"),
      sort: true,
    },
    {
      dataField: "key",
      text: props.t("GUID"),
      sort: true,
    },
  ]

  function editFormatter(cell, row) {
    return (
      <>
        <Button
          color="primary"
          outline
          size="sm"
          onClick={() => {
            setShowDrawer(true)
            setSelectedRow(row)
          }}
        >
          <i className="mdi mdi-pencil" />
          {/**props.t("Edit")*/}
        </Button>
      </>
    )
  }

  function dateFormatter(value) {
    if (!value) return ""
    var dateString = moment(value).format("DD.MM.YYYY HH:mm:ss")
    return dateString
  }

  function simpleDateFormatter(value) {
    if (!value) return ""
    var dateString = moment(value).format("DD.MM.YYYY")
    return dateString
  }

  function linksFormatter(cell, row) {
    if (row.links && row.links.length > 0) {
      return (
        <div className="d-grid">
          {row.links?.map((entry, key) => (
            <Link key={key} to={"/clients/" + entry.key} target="_blank">
              <span>{entry.name}</span>
            </Link>
          ))}
        </div>
      )
    }
  }

  // Export values
  const generateExcel = () => {
    const heading = [
      [
        props.t("Serial"),
        props.t("Type"),
        props.t("Firmware"),
        props.t("Callback time"),
        props.t("Order number"),
        props.t("Links"),
        props.t("Gsm"),
        props.t("ICCID"),
        props.t("Synced"),
        props.t("Operation status"),
        props.t("Global status"),
        props.t("Mains status"),
        props.t("Battery status"),
        props.t("Connection status"),
        props.t("Accessory battery status"),
        props.t("Operator"),
        props.t("Signal strength"),
        props.t("Rebooted"),
        props.t("Last alarm time"),
        props.t("Prod start"),
        props.t("Prod end"),
        props.t("Accessories"),
        props.t("GUID"),
      ],
    ]
    const data = filteredCarephones.map(elt => [
      elt.serial,
      elt.deviceType,
      elt.firmware,
      elt.callbackTime,
      elt.orderNumber,
      elt.linkedNames,
      elt.gsm,
      elt.iccid,
      dateFormatter(elt.syncDate),
      elt.operationalStatus,
      elt.globalTechStatus,
      elt.mainsStatus,
      elt.accumulatorStatus,
      elt.connectionPortalStatus,
      elt.accessoriesBatteryStatus,
      elt.operator,
      elt.signalStrength,
      dateFormatter(elt.lastReboot),
      dateFormatter(elt.lastAlarmTime),
      simpleDateFormatter(elt.prodStart),
      simpleDateFormatter(elt.prodEnd),
      elt.accessories,
      elt.key,
    ])

    downloadExcel(title, heading, data)
    showToast(props.t("Excel file exported succesfully!"), "success")
  }

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title={props.t("Carephones")} />
          <Card>
            <CardHeader className="bg-transparent border-bottom">
              <div className="d-flex flex-wrap">
                <ul
                  className="nav nav-tabs nav-tabs-custom card-header-tabs"
                  role="tablist"
                >
                  <NavItem>
                    <NavLink
                      to="#"
                      active={activeTab === "1"}
                      onClick={() => {
                        setActiveTab("1")
                      }}
                    >
                      {props.t("Table")}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      active={activeTab === "2"}
                      onClick={() => {
                        setActiveTab("2")
                      }}
                    >
                      {props.t("Statistics")}
                    </NavLink>
                  </NavItem>
                </ul>
              </div>
            </CardHeader>
            <CardBody>
              <TabContent activeTab={activeTab}>
                <TabPane className="show" tabId="1">
                  <Accordion
                    flush
                    open={alertsOpen}
                    toggle={id => {
                      id === alertsOpen
                        ? setAlertsOpen("")
                        : setAlertsOpen(id)
                    }}
                  >
                    <AccordionItem>
                      <AccordionBody
                        accordionId="1"
                        className="accordion-zeropadding"
                      >
                        <Alert color="danger">
                          <div>
                            <h5 className="alert-heading">
                              {props.t("Duplicate values")}
                            </h5>
                            {duplicates.map((entry, key) => (
                              <Button
                                size="sm"
                                color="danger"
                                outline
                                key={key}
                                className="me-2 mb-2"
                                onClick={() => {
                                  setSearchText(entry.key)
                                }}
                              >
                                <span>{entry.name}</span>
                              </Button>
                            ))}
                          </div>
                          <div>
                            <h5 className="alert-heading">
                              {props.t("Multiple links")}
                            </h5>
                            {multilinks.map((entry, key) => (
                              <Button
                                size="sm"
                                color="danger"
                                outline
                                key={key}
                                className="me-2 mb-2"
                                onClick={() => {
                                  setSearchText(entry.key)
                                }}
                              >
                                <span>{entry.name}</span>
                              </Button>
                            ))}
                          </div>
                        </Alert>
                      </AccordionBody>
                    </AccordionItem>
                  </Accordion>
                  <Row>
                    <Col>
                      {carephones != null && (
                        <PaginationProvider
                          pagination={paginationFactory(pageOptions)}
                        >
                          {({ paginationProps, paginationTableProps }) => (
                            <ToolkitProvider
                              keyField="key"
                              columns={columns}
                              data={filteredCarephones}
                              search
                            >
                              {toolkitProps => (
                                <React.Fragment>
                                  <Row className="mb-1">
                                    {" "}
                                    <Col className="col-auto pe-0">
                                      <Form
                                        className="mt-sm-0 d-flex align-items-center"
                                        onSubmit={e => {
                                          e.preventDefault()
                                          return false
                                        }}
                                      >
                                        <div className="search-box me-2 mb-2 d-inline-block float-end">
                                          <div className="position-relative">
                                            <Input
                                              type="text"
                                              value={searchText}
                                              onChange={event =>
                                                setSearchText(
                                                  event.target.value
                                                )
                                              }
                                              placeholder={props.t("Search")}
                                            />
                                            <i className="bx bx-search-alt search-icon" />
                                          </div>
                                        </div>
                                      </Form>
                                    </Col>
                                    <Col className="col-auto">
                                      <Button
                                        className="round-icon-button"
                                        id="filter-button"
                                        color="primary"
                                        outline
                                        onClick={() => {
                                          filtersOpen.length > 0
                                            ? setFiltersOpen("")
                                            : setFiltersOpen("1")
                                        }}
                                      >
                                        <UncontrolledTooltip
                                          placement="bottom"
                                          target="filter-button"
                                        >
                                          {props.t("Filters")}
                                        </UncontrolledTooltip>
                                        {filtersOpen ? (
                                          <i className="mdi mdi-filter-minus"></i>
                                        ) : (
                                          <i className="mdi mdi-filter-menu"></i>
                                        )}
                                      </Button>
                                      <Button
                                        className="ms-3"
                                        style={{ borderRadius: "100px" }}
                                        color="primary"
                                        disabled={ongoingSearch}
                                        onClick={() => {
                                          getDevices()
                                        }}
                                      >
                                        <i className="mdi mdi-refresh me-2" />
                                        {props.t("Refresh data")}
                                      </Button>
                                      <Button
                                        className="ms-3"
                                        style={{ borderRadius: "100px" }}
                                        color="primary"
                                        disabled={ongoingSync}
                                        onClick={() => {
                                          syncDeviceList()
                                        }}
                                      >
                                        <i className="mdi mdi-cloud-sync me-2" />
                                        {props.t("Sync devices")}
                                      </Button>
                                      {(duplicates.length > 0 ||
                                        multilinks.length > 0) && (
                                        <Button
                                          color="danger"
                                          className="round-icon-button ms-3"
                                          id="alerts-button"
                                          onClick={() => {
                                            alertsOpen.length > 0
                                              ? setAlertsOpen("")
                                              : setAlertsOpen("1")
                                          }}
                                        >
                                          <UncontrolledTooltip
                                            placement="bottom"
                                            target="alerts-button"
                                          >
                                            {props.t("Notices")}
                                          </UncontrolledTooltip>
                                          {alertsOpen ? (
                                            <i className="mdi mdi-alert-minus"></i>
                                          ) : (
                                            <i className="mdi mdi-alert-plus"></i>
                                          )}
                                        </Button>
                                      )}
                                    </Col>
                                    <Col>
                                      <span className="float-end ms-2">
                                        <UncontrolledTooltip
                                          placement="bottom"
                                          target="downloadstatstable"
                                        >
                                          {props.t("Download statistics")}
                                        </UncontrolledTooltip>
                                        <Dropdown
                                          id="printButtonServicesStats"
                                          isOpen={menu}
                                          toggle={() => setMenu(!menu)}
                                          className="download-btn text-center"
                                          type="button"
                                        >
                                          <DropdownToggle
                                            id="downloadstatstable"
                                            className="text-muted"
                                            tag="i"
                                          >
                                            <i className="mdi mdi-download" />
                                          </DropdownToggle>
                                          <DropdownMenu>
                                            {/* TODO: Can not choose directory file right now */}
                                            <DropdownItem
                                              onClick={() => generateExcel()}
                                            >
                                              {props.t("Download .xlsx")}
                                            </DropdownItem>
                                          </DropdownMenu>
                                        </Dropdown>
                                      </span>
                                      <div className="mt-2 float-end">
                                        <p>
                                          {filteredCarephones.length}{" "}
                                          {props.t("found results")}
                                        </p>
                                      </div>
                                    </Col>
                                  </Row>

                                  <Accordion
                                    flush
                                    open={filtersOpen}
                                    toggle={id => {
                                      id === filtersOpen
                                        ? setFiltersOpen("")
                                        : setFiltersOpen(id)
                                    }}
                                  >
                                    <AccordionItem>
                                      <AccordionBody
                                        accordionId="1"
                                        className="mb-3"
                                        style={{
                                          backgroundColor: "#e8f0fe",
                                          border: "1px solid #ced4da",
                                        }}
                                      >
                                        <Row xs={1} sm={2} md={3} xl={4}>
                                          <Col>
                                            <FormGroup>
                                              <Label>
                                                {props.t("Operation status")}
                                              </Label>
                                              <Input
                                                type="select"
                                                bsSize={"sm"}
                                                name="operationalFilter"
                                                value={operationalFilter}
                                                onChange={v => {
                                                  setOperationalFilter(
                                                    v.target.value
                                                  )
                                                }}
                                              >
                                                <option value="0">
                                                  {props.t("All")}
                                                </option>
                                                <option value="1">
                                                  {props.t("In use")}
                                                </option>
                                                <option value="2">
                                                  {props.t("Not in use")}
                                                </option>
                                              </Input>
                                            </FormGroup>
                                          </Col>
                                          <Col>
                                            <FormGroup>
                                              <Label>{props.t("Links")}</Label>
                                              <Input
                                                type="select"
                                                bsSize={"sm"}
                                                name="linkedFilter"
                                                value={linkedFilter}
                                                onChange={v => {
                                                  setLinkedFilter(
                                                    v.target.value
                                                  )
                                                }}
                                              >
                                                <option value="0">
                                                  {props.t("All")}
                                                </option>
                                                <option value="1">
                                                  {props.t("Linked")}
                                                </option>
                                                <option value="2">
                                                  {props.t("Not linked")}
                                                </option>
                                              </Input>
                                            </FormGroup>
                                          </Col>
                                        </Row>
                                      </AccordionBody>
                                    </AccordionItem>
                                  </Accordion>

                                  <Row className="mt-2">
                                    <Col xl="12">
                                      {ongoingSearch == true ? (
                                        <div className="py-5 text-center">
                                          <Link to="#" className="text-primary">
                                            <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                                            {props.t("Loading")}
                                          </Link>
                                        </div>
                                      ) : (
                                        <div className="table-responsive table-scroll-window">
                                          {/**<SearchBar { ...toolkitProps.searchProps } />*/}
                                          <BootstrapTable
                                            id="carephones-table"
                                            keyField={"key"}
                                            responsive
                                            bordered={true}
                                            striped={true}
                                            hover={true}
                                            ro
                                            defaultSorted={defaultSorted}
                                            classes={
                                              "table align-middle table-nowrap"
                                            }
                                            headerWrapperClasses={"thead-light"}
                                            headerClasses="header-class"
                                            {...toolkitProps.baseProps}
                                            {...paginationTableProps}
                                          />
                                        </div>
                                      )}
                                    </Col>
                                  </Row>

                                  <Row className="align-items-md-center mt-3 mt-30">
                                    <Col className="inner-custom-pagination d-flex">
                                      <div className="">
                                        <SizePerPageDropdownStandalone
                                          {...paginationProps}
                                        />
                                      </div>
                                      <Col className="pagination justify-content-end inner-custom-pagination">
                                        <PaginationListStandalone
                                          {...paginationProps}
                                        />
                                      </Col>
                                    </Col>
                                  </Row>
                                </React.Fragment>
                              )}
                            </ToolkitProvider>
                          )}
                        </PaginationProvider>
                      )}
                    </Col>
                  </Row>
                </TabPane>

                <TabPane className="show" tabId="2">
                  <Table
                    style={{ tableLayout: "fixed" }}
                    className="table"
                    bordered={false}
                    striped={false}
                    hover={true}
                  >
                    <thead>
                      <tr>
                        <th></th>
                        <th>{props.t("Count")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">{props.t("Total")}</th>
                        <td>{statistics.total}</td>
                      </tr>
                      <tr>
                        <th scope="row">{props.t("Linked")}</th>
                        <td>{statistics.linked}</td>
                      </tr>
                      <tr>
                        <th scope="row">{props.t("Operational")}</th>
                        <td>{statistics.inUse}</td>
                      </tr>
                      <tr>
                        <th scope="row">{props.t("Not operational")}</th>
                        <td>{statistics.notUsed}</td>
                      </tr>
                    </tbody>
                  </Table>

                  <DatePicker
                    className="mt-4 mb-3"
                    defaultValue={moment()}
                    showToday
                    allowClear={false}
                    onChange={v => {
                      setStatsDate(v)
                    }}
                    format="DD.MM.YYYY"
                    style={{
                      width: "100%",
                    }}
                  />

                  <Table
                    style={{ tableLayout: "fixed" }}
                    className="table"
                    bordered={false}
                    striped={false}
                    hover={true}
                  >
                    <tbody>
                      <tr>
                        <th scope="row">{props.t("Production")}</th>
                        <td>{statistics.prod}</td>
                      </tr>
                    </tbody>
                  </Table>
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>

          <CarephoneDrawer
            showDrawer={showDrawer}
            setShowDrawer={setShowDrawer}
            selectedRow={selectedRow}
            getCarephones={getDevices}
          />
        </Container>
      </div>
    </>
  )
}

CarephoneConfigs.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(CarephoneConfigs)
