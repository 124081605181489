import React, { useEffect, useRef, useState } from "react"
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  AccordionBody,
  AccordionItem,
  Form,
  Input,
  Label,
  FormGroup,
  Button,
  NavItem,
  NavLink,
  TabContent,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  TabPane,
  CardHeader,
  Accordion,
  UncontrolledTooltip,
} from "reactstrap"
import PropTypes from "prop-types"
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import { useLocation, Route } from "react-router-dom"
import { withTranslation } from "react-i18next"
import moment from "moment"
import { getSupportSms, querySms } from "helpers/backend_helper"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import "../../datatables.scss"
import CustomRangePicker from "components/DatePickers/RangePicker"
import { downloadExcel, showToast } from "utils/general"

const SmsLogs = props => {
  const [activeTab, setActiveTab] = useState("1")
  const [supportMessages, setSupportMessages] = useState([])
  const [messages, setMessages] = useState([])
  const [filteredMessages, setFilteredMessages] = useState([])
  const [filtersOpen, setFiltersOpen] = useState("1")
  const [ongoingSearch, setOngoingSearch] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [recipient, setRecipient] = useState("")
  const [sender, setSender] = useState("")
  const [statusFilter, setStatusFilter] = useState("")
  const [maxRows, setMaxRows] = useState("100")
  const [dateRange, setDateRange] = useState(null)
  const [first, setFirst] = useState(true)
  const [currentPage, setCurrentPage] = useState(1)
  const [menu, setMenu] = useState(false) //Dropdown button state
  const [title, setTitle] = useState(props.t("SMS logs"))

  const search = useLocation().search
  const abortRef = useRef(null)
  useEffect(() => {
    document.title = title + " | Suvanto Care"
    abortRef.current = new AbortController()
    setDateRange([moment().subtract(1, "days"), moment()])
    const rec = new URLSearchParams(search).get("recipient")
    if (rec) {
      setRecipient(rec.trim())
    }
    return () => abortRef.current.abort()
  }, [])

  // Get messages on page load
  useEffect(() => {
    if (dateRange && first) {
      setFirst(false)
      getMessages(dateRange[0].valueOf(), dateRange[1].valueOf())
    }
  }, [dateRange])

  // Search or filter changed
  useEffect(() => {
    setCurrentPage(1)
    handleSearch()
  }, [searchText, messages])

  // Handle search function
  const handleSearch = () => {
    let result = messages.filter(
      data =>
        data.message?.toLowerCase().includes(searchText) ||
        data.recipient?.toLowerCase().includes(searchText) ||
        data.sender?.toLowerCase().includes(searchText) ||
        data.source?.toLowerCase().includes(searchText) ||
        data.status?.toLowerCase().includes(searchText)
    )
    setFilteredMessages(result)
  }

  // Get messages
  const getMessages = (startMs, endMs) => {
    if (!ongoingSearch) {
      //console.log("GET SMS: ", startMs, endMs, recipient)
      setOngoingSearch(true)
      querySms(
        abortRef.current,
        startMs,
        endMs,
        sender ? sender : undefined,
        recipient ? recipient : undefined,
        maxRows.length > 0 ? parseInt(maxRows, 10) : 100,
        undefined,
        statusFilter.length > 0 ? parseInt(statusFilter, 10) : undefined
      )
        .then(result => {
          //console.log("care: ", result.entries)
          if (result.statusCode === 200 && result.entries != null) {
            setMessages(result.entries)
          } else {
            setMessages([])
          }
          setOngoingSearch(false)
        })
        .catch(err => {
          console.log(err)
          setMessages([])
          setOngoingSearch(false)
        })
    }
  }

  // Get support message
  const getSupportMessages = () => {
    if (!ongoingSearch) {
      setOngoingSearch(true)
      getSupportSms(abortRef.current)
        .then(result => {
          //console.log("care: ", result.entries)
          if (result.statusCode === 200 && result.entries != null) {
            setSupportMessages(result.entries)
          } else {
            setSupportMessages([])
          }
          setOngoingSearch(false)
        })
        .catch(err => {
          console.log(err)
          setSupportMessages([])
          setOngoingSearch(false)
        })
    }
  }

  const defaultSorted = [
    {
      dataField: "statusTime",
      order: "desc",
    },
  ]

  // Show selected number of rows
  const pageOptions = {
    custom: true,
    pageStartIndex: 1,
    totalSize: filteredMessages.length,
    page: currentPage,
    sizePerPageList: [
      { text: "10", value: 10 },
      { text: "50", value: 50 },
      { text: "100", value: 100 },
      { text: props.t("All"), value: filteredMessages?.length || 0 },
    ],
    onPageChange: (page, sizePerPage) => {
      setCurrentPage(page)
    },
    onSizePerPageChange: (sizePerPage, page) => {
      setCurrentPage(1)
    },
  }
  const pageOptions2 = {
    custom: true,
    pageStartIndex: 1,
    totalSize: supportMessages.length,
  }

  // Table structure
  const columns = [
    {
      dataField: "statusTime",
      text: props.t("Updated"),
      sort: true,
      formatter: dateFormatter,
    },
    {
      dataField: "status",
      text: props.t("Status"),
      sort: true,
    },
    {
      dataField: "message",
      text: props.t("Message"),
      sort: true,
    },
    {
      dataField: "sender",
      text: props.t("Sender"),
      sort: true,
    },
    {
      dataField: "recipient",
      text: props.t("Recipient"),
      sort: true,
    },
    {
      dataField: "source",
      text: props.t("Source"),
      sort: true,
    },
  ]
  const columns2 = [
    {
      dataField: "statusTime",
      text: props.t("Status updated"),
      sort: true,
      formatter: dateFormatter,
    },
    {
      dataField: "message",
      text: props.t("Message"),
      sort: true,
    },
    {
      dataField: "sender",
      text: props.t("Sender"),
      sort: true,
    },
  ]

  function dateFormatter(value) {
    if (!value) return ""
    var dateString = moment(value).format("DD.MM.YYYY HH:mm:ss")
    return dateString
  }

  const onDateSelection = value => {
    setDateRange(value)
  }
  // Export values
  const generateExcel = () => {
    const heading = [
      [
        props.t("Updated"),
        props.t("Status"),
        props.t("Message"),
        props.t("Sender"),
        props.t("Recipient"),
        props.t("Source"),
      ],
    ]
    const data = filteredMessages.map(elt => [
      dateFormatter(elt.statusTime),
      elt.status,
      elt.message,
      elt.sender,
      elt.recipient,
      elt.source,
    ])

    downloadExcel(title, heading, data)
    showToast(props.t("Excel file exported succesfully!"), "success")
  }

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title={props.t("SMS logs")} />
          <Card>
            <CardHeader className="bg-transparent border-bottom">
              <div className="d-flex flex-wrap">
                <ul
                  className="nav nav-tabs nav-tabs-custom card-header-tabs"
                  role="tablist"
                >
                  <NavItem>
                    <NavLink
                      to="#"
                      active={activeTab === "1"}
                      onClick={() => {
                        setActiveTab("1")
                      }}
                    >
                      {props.t("Search")}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      active={activeTab === "2"}
                      onClick={() => {
                        setActiveTab("2")
                      }}
                    >
                      {props.t("Security mail")}
                    </NavLink>
                  </NavItem>
                </ul>
              </div>
            </CardHeader>
            <CardBody>
              <TabContent activeTab={activeTab}>
                <TabPane className="show" tabId="1">
                  <Row>
                    <Col>
                      {messages != null && (
                        <PaginationProvider
                          pagination={paginationFactory(pageOptions)}
                        >
                          {({ paginationProps, paginationTableProps }) => (
                            <ToolkitProvider
                              keyField="key"
                              columns={columns}
                              data={filteredMessages}
                              search
                            >
                              {toolkitProps => (
                                <React.Fragment>
                                  <Row className="mb-1">
                                    {" "}
                                    <Col className="col-auto pe-0">
                                      <Form
                                        className="mt-sm-0 d-flex align-items-center"
                                        onSubmit={e => {
                                          e.preventDefault()
                                          return false
                                        }}
                                      >
                                        <div className="search-box me-2 mb-2 d-inline-block float-end">
                                          <div className="position-relative">
                                            <Input
                                              type="text"
                                              onChange={event =>
                                                setSearchText(
                                                  event.target.value.toLowerCase()
                                                )
                                              }
                                              placeholder={props.t("Search")}
                                            />
                                            <i className="bx bx-search-alt search-icon" />
                                          </div>
                                        </div>
                                      </Form>
                                    </Col>
                                    <Col className="col-auto">
                                      <Button
                                        className="round-icon-button-sm"
                                        id="filter-button"
                                        color="primary"
                                        outline
                                        onClick={() => {
                                          filtersOpen.length > 0
                                            ? setFiltersOpen("")
                                            : setFiltersOpen("1")
                                        }}
                                      >
                                        <UncontrolledTooltip
                                          placement="bottom"
                                          target="filter-button"
                                        >
                                          {props.t("Filters")}
                                        </UncontrolledTooltip>
                                        {filtersOpen ? (
                                          <i className="mdi mdi-filter-minus"></i>
                                        ) : (
                                          <i className="mdi mdi-filter-menu"></i>
                                        )}
                                      </Button>
                                    </Col>
                                    <Col>
                                      <CustomRangePicker
                                        value={dateRange}
                                        rangeChanged={onDateSelection}
                                        showTime={true}
                                        allowClear={false}
                                      />
                                    </Col>
                                    <Col className="col-auto">
                                      <Button
                                        //className="ms-3"
                                        style={{ borderRadius: "100px" }}
                                        color="primary"
                                        disabled={ongoingSearch}
                                        onClick={() => {
                                          getMessages(
                                            dateRange[0].valueOf(),
                                            dateRange[1].valueOf()
                                          )
                                        }}
                                      >
                                        <i className="mdi mdi-check-bold me-2" />
                                        {props.t("Apply")}
                                      </Button>
                                      <Button
                                        className="ms-3 round-icon-button"
                                        id="calendar-button"
                                        color="primary"
                                        disabled={ongoingSearch}
                                        onClick={() => {
                                          setDateRange([dateRange[0], moment()])
                                          getMessages(
                                            dateRange[0].valueOf(),
                                            moment().valueOf()
                                          )
                                        }}
                                      >
                                        <UncontrolledTooltip
                                          placement="bottom"
                                          target="calendar-button"
                                        >
                                          {props.t("Refresh time")}
                                        </UncontrolledTooltip>
                                        <i className="mdi mdi-calendar-refresh" />
                                      </Button>
                                    </Col>
                                    <Col>
                                      <span className="float-end ms-2">
                                        <UncontrolledTooltip
                                          placement="bottom"
                                          target="downloadstatstable"
                                        >
                                          {props.t("Download")}
                                        </UncontrolledTooltip>
                                        <Dropdown
                                          id="printButtonServicesStats"
                                          isOpen={menu}
                                          toggle={() => setMenu(!menu)}
                                          className="download-btn text-center"
                                          type="button"
                                        >
                                          <DropdownToggle
                                            id="downloadstatstable"
                                            className="text-muted"
                                            tag="i"
                                          >
                                            <i className="mdi mdi-download" />
                                          </DropdownToggle>
                                          <DropdownMenu>
                                            {/* TODO: Can not choose directory file right now */}
                                            <DropdownItem
                                              onClick={() => generateExcel()}
                                            >
                                              {props.t("Download .xlsx")}
                                            </DropdownItem>
                                          </DropdownMenu>
                                        </Dropdown>
                                      </span>
                                      <div className="mt-2 float-end">
                                        <p>
                                          {filteredMessages.length}{" "}
                                          {props.t("results found")}
                                        </p>
                                      </div>
                                    </Col>
                                  </Row>

                                  <Accordion
                                    flush
                                    open={filtersOpen}
                                    toggle={id => {
                                      id === filtersOpen
                                        ? setFiltersOpen("")
                                        : setFiltersOpen(id)
                                    }}
                                  >
                                    <AccordionItem>
                                      <AccordionBody
                                        accordionId="1"
                                        style={{
                                          backgroundColor: "#e8f0fe",
                                          border: "1px solid #ced4da",
                                        }}
                                      >
                                        <Row>
                                          <Col>
                                            <FormGroup>
                                              <Label>
                                                {props.t("Max rows")}
                                              </Label>
                                              <Input
                                                type="number"
                                                name="maxRows"
                                                value={maxRows}
                                                onChange={v => {
                                                  setMaxRows(v.target.value)
                                                }}
                                              />
                                            </FormGroup>
                                          </Col>
                                          <Col>
                                            <FormGroup>
                                              <Label>
                                                {props.t("SMS status")}
                                              </Label>
                                              <Input
                                                type="select"
                                                name="statusFilter"
                                                value={statusFilter}
                                                onChange={v => {
                                                  setStatusFilter(
                                                    v.target.value
                                                  )
                                                }}
                                              >
                                                <option value="">
                                                  {props.t("All")}
                                                </option>
                                                <option value="-1">
                                                  {props.t("Received")}
                                                </option>
                                                <option value="0">
                                                  {props.t("Sent")}
                                                </option>
                                                <option value="1">
                                                  {props.t("Delivered")}
                                                </option>
                                                <option value="3">
                                                  {props.t("Deleted")}
                                                </option>
                                                <option value="5">
                                                  {props.t("Expired")}
                                                </option>
                                                <option value="6">
                                                  {props.t("Rejected")}
                                                </option>
                                                <option value="7">
                                                  {props.t("Failed")}
                                                </option>
                                                <option value="11">
                                                  {props.t("Unknown")}
                                                </option>
                                              </Input>
                                            </FormGroup>
                                          </Col>
                                          <Col>
                                            <FormGroup>
                                              <Label>
                                                {props.t("Recipient")}
                                              </Label>
                                              <Input
                                                type="text"
                                                name="recipient"
                                                value={recipient}
                                                onChange={v => {
                                                  setRecipient(v.target.value)
                                                }}
                                              />
                                            </FormGroup>
                                          </Col>
                                          <Col>
                                            <FormGroup>
                                              <Label>{props.t("Sender")}</Label>
                                              <Input
                                                type="text"
                                                name="sender"
                                                value={sender}
                                                onChange={v => {
                                                  setSender(v.target.value)
                                                }}
                                              />
                                            </FormGroup>
                                          </Col>
                                        </Row>
                                      </AccordionBody>
                                    </AccordionItem>
                                  </Accordion>

                                  <Row className="mt-2">
                                    <Col xl="12">
                                      {ongoingSearch == true ? (
                                        <div className="py-5 text-center">
                                          <p className="text-primary">
                                            <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                                            {props.t("Loading")}
                                          </p>
                                        </div>
                                      ) : (
                                        <div className="table-responsive">
                                          <BootstrapTable
                                            id="messages-table"
                                            keyField={"key"}
                                            responsive
                                            bordered={true}
                                            striped={true}
                                            hover={true}
                                            defaultSorted={defaultSorted}
                                            classes={
                                              "table align-middle table-nowrap"
                                            }
                                            headerWrapperClasses={"thead-light"}
                                            headerClasses="header-class"
                                            {...toolkitProps.baseProps}
                                            {...paginationTableProps}
                                          />
                                        </div>
                                      )}
                                    </Col>
                                  </Row>

                                  <Row className="align-items-md-center mt-3 mt-30">
                                    <Col className="inner-custom-pagination d-flex">
                                      <div className="">
                                        <SizePerPageDropdownStandalone
                                          {...paginationProps}
                                        />
                                      </div>
                                      <Col className="pagination justify-content-end inner-custom-pagination">
                                        <PaginationListStandalone
                                          {...paginationProps}
                                        />
                                      </Col>
                                    </Col>
                                  </Row>
                                </React.Fragment>
                              )}
                            </ToolkitProvider>
                          )}
                        </PaginationProvider>
                      )}
                    </Col>
                  </Row>
                </TabPane>

                <TabPane className="show" tabId="2">
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions2)}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="key"
                        columns={columns2}
                        data={supportMessages}
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-1">
                              <Col className="col-auto">
                                <Button
                                  style={{ borderRadius: "100px" }}
                                  color="primary"
                                  disabled={ongoingSearch}
                                  onClick={() => {
                                    getSupportMessages()
                                  }}
                                >
                                  <i className="mdi mdi-refresh me-2" />
                                  {props.t("Refresh data")}
                                </Button>
                              </Col>
                              <Col>
                                <div className="mt-2 float-end">
                                  <p>
                                    {supportMessages.length}{" "}
                                    {props.t("results found")}
                                  </p>
                                </div>
                              </Col>
                            </Row>

                            <Row className="mt-2">
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    id="support-table"
                                    keyField={"key"}
                                    responsive
                                    bordered={true}
                                    striped={true}
                                    hover={true}
                                    defaultSorted={defaultSorted}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    headerClasses="header-class"
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-3 mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <Col className="pagination justify-content-end inner-custom-pagination">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </Col>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </Container>
      </div>
    </>
  )
}

SmsLogs.propTypes = {
  t: PropTypes.any,
  match: PropTypes.any,
}

export default withTranslation()(SmsLogs)
