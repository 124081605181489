import React, { useEffect, useRef, useState } from "react"
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  AccordionBody,
  AccordionItem,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Form,
  Input,
  Label,
  FormGroup,
  Button,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  CardHeader,
  Accordion,
  Table,
  UncontrolledTooltip,
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormFeedback,
} from "reactstrap"
import PropTypes from "prop-types"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Link, useLocation } from "react-router-dom"
import { withTranslation } from "react-i18next"
import moment from "moment"
import {
  createGateway,
  getGateways,
  importGateways,
  setGatewayInstallationSerials,
} from "helpers/backend_helper"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import "../datatables.scss"
import GatewayDrawer from "./gateway-drawer"
import { Popconfirm } from "antd"
import { downloadExcel, showToast, isTrue } from "utils/general"
import * as Yup from "yup"
import { useFormik } from "formik"

const GatewayConfigs = props => {
  const [activeTab, setActiveTab] = useState("1")
  const [gateways, setGateways] = useState([])
  const [filteredGateways, setFilteredGateways] = useState([])
  const [createConfirm, setCreateConfirm] = useState(false)
  const [filtersOpen, setFiltersOpen] = useState("")
  const [alertsOpen, setAlertsOpen] = useState("")
  const [ongoingSearch, setOngoingSearch] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [ongoingCreate, setOngoingCreate] = useState(false)
  const [configFilter, setConfigFilter] = useState("0")
  const [connectionFilter, setConnectionFilter] = useState("0")
  const [selectedRow, setSelectedRow] = useState(null)
  const [showDrawer, setShowDrawer] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [selectedGateway, setSelectedGateway] = useState(null)
  const [installationSerials, setInstallationSerials] = useState("")
  const [statistics, setStatistics] = useState({ total: 0 })
  const [importModal, setImportModal] = useState(false)
  const [multilinks, setMultilinks] = useState([])
  const [duplicates, setDuplicates] = useState([])
  const [menu, setMenu] = useState(false) //Dropdown button state
  const [title, setTitle] = useState(props.t("Gateways"))

  const search = useLocation().search
  const abortRef = useRef(null)
  useEffect(() => {
    document.title = title + " | Suvanto Care"

    const searchParam = new URLSearchParams(search).get("search")
    if (searchParam && searchParam != "null") setSearchText(searchParam.trim())

    abortRef.current = new AbortController()
    getDevices()
    return () => abortRef.current.abort()
  }, [])

  // Clear selection when closing drawer
  useEffect(() => {
    if (!showDrawer) setSelectedRow(null)
  }, [showDrawer])

  // Calculate stats
  useEffect(() => {
    let now = moment()
    let temp = new Object()
    temp.total = gateways.length
    temp.linked = gateways.filter(data => data.links.length > 0).length
    temp.configured = gateways.filter(data => data.configured == true).length
    temp.unconfigured = gateways.filter(data => !data.configured).length

    temp.online = gateways.filter(
      data =>
        data.configured == true &&
        data.lastConnected &&
        now.diff(data.lastConnected, "minutes") <= 30
    ).length
    temp.offline = gateways.filter(
      data =>
        data.configured == true &&
        (!data.lastConnected || now.diff(data.lastConnected, "minutes") > 30)
    ).length
    temp.offlineRecent = gateways.filter(
      data =>
        data.configured == true &&
        data.lastConnected &&
        now.diff(data.lastConnected, "minutes") > 30 &&
        now.diff(data.lastConnected, "minutes") < 1440
    ).length

    setStatistics(temp)
  }, [gateways])

  // Check duplicate values
  useEffect(() => {
    if (gateways) {
      // GSM, IMSI, ICCID, IMEI
      let dups = []
      for (let i = 0; i < gateways.length - 1; i++) {
        for (let j = i + 1; j < gateways.length; j++) {
          if (gateways[i].iccid && gateways[i].iccid === gateways[j].iccid) {
            dups.push({
              key: gateways[i].iccid,
              name: "ICCID:" + gateways[i].iccid,
            })
          }
          if (gateways[i].gsm && gateways[i].gsm === gateways[j].gsm) {
            dups.push({ key: gateways[i].gsm, name: "GSM:" + gateways[i].gsm })
          }
          if (gateways[i].imei && gateways[i].imei === gateways[j].imei) {
            dups.push({
              key: gateways[i].imei,
              name: "IMEI:" + gateways[i].imei,
            })
          }
          if (gateways[i].imsi && gateways[i].imsi === gateways[j].imsi) {
            dups.push({
              key: gateways[i].imsi,
              name: "IMSI:" + gateways[i].imsi,
            })
          }
        }
      }
      setDuplicates(dups)
    }
  }, [gateways])

  // Check multiple links
  useEffect(() => {
    if (gateways) {
      let multis = []
      gateways.forEach(element => {
        if (
          element.links.length > 1 &&
          element.key !== "1bc604c9-ca3f-4ffe-9045-93d9eb6454b0"
        ) {
          multis.push({ key: element.key, name: element.description })
        }
      })
      setMultilinks(multis)
    }
  }, [gateways])

  // Hide alerts if no content
  useEffect(() => {
    if (multilinks.length == 0 && duplicates.length == 0) setAlertsOpen(false)
  }, [multilinks, duplicates])

  // Search or filter changed
  useEffect(() => {
    setCurrentPage(1)
    handleSearch()
  }, [searchText, gateways, configFilter, connectionFilter])

  // Handle search function
  const handleSearch = () => {
    let searchTextLowercase = searchText.toLowerCase()
    let result = gateways.filter(
      data =>
        (configFilter === "0" ||
          (configFilter === "1" && data.configured == true) ||
          (configFilter === "2" && !data.configured)) &&
        (connectionFilter === "0" ||
          (connectionFilter === "1" && data.lastConnectedDiff <= 30) ||
          (connectionFilter === "2" && data.lastConnectedDiff > 30) ||
          (connectionFilter === "3" &&
            data.lastConnectedDiff > 30 &&
            data.lastConnectedDiff < 1440)) &&
        (data.key?.toLowerCase() === searchTextLowercase ||
          data.serial?.toLowerCase().includes(searchTextLowercase) ||
          data.gsm?.toLowerCase().includes(searchTextLowercase) ||
          data.imsi?.toLowerCase().includes(searchTextLowercase) ||
          data.imei?.toLowerCase().includes(searchTextLowercase) ||
          data.iccid?.toLowerCase().includes(searchTextLowercase) ||
          data.description?.toLowerCase().includes(searchTextLowercase) ||
          data.orderNumber?.toLowerCase().includes(searchTextLowercase) ||
          data.linkedNames?.toLowerCase().includes(searchTextLowercase))
    )
    setFilteredGateways(result)
  }

  // Get gateways
  const getDevices = () => {
    setOngoingSearch(true)
    getGateways(abortRef.current)
      .then(result => {
        //console.log("care: ", result.entries)
        if (result.statusCode === 200 && result.entries != null) {
          let now = moment()
          result.entries.forEach(element => {
            let linkedNames = []
            if (element.links) {
              element.links.forEach(link => {
                linkedNames.push(link.name)
              })
            }
            element.linkedNames = linkedNames.join()

            if (element.lastConnected) {
              element.lastConnectedDiff = now.diff(
                moment(element.lastConnected),
                "minutes"
              )
            } else {
              element.lastConnectedDiff = 99999
            }
          })
          setGateways(result.entries)

          // Update selected row
          if (selectedRow && selectedRow.key) {
            let foundRow = result.entries.find(
              item => item.key == selectedRow.key
            )
            setSelectedRow(foundRow)
          }

          if (result.installationSerials) {
            setInstallationSerials(result.installationSerials)
          }
        } else {
          setGateways([])
        }
        setOngoingSearch(false)
      })
      .catch(err => {
        console.log(err)
        setGateways([])
        setOngoingSearch(false)
      })
  }

  // Create gateway
  const createGW = () => {
    setOngoingCreate(true)
    createGateway(abortRef.current)
      .then(result => {
        //console.log("care: ", result.entries)
        if (result.statusCode === 200) {
          showToast(props.t("Success"), "success")
          getDevices()
        } else {
          showToast(props.t("Error"), "error")
        }
        setOngoingCreate(false)
      })
      .catch(err => {
        console.log(err)
        showToast(props.t("Error"), "error")
        setOngoingCreate(false)
      })
  }

  // Update serials
  const updateInstallationSerials = () => {
    setGatewayInstallationSerials(abortRef.current, installationSerials)
      .then(result => {
        if (result.statusCode === 200) {
          showToast(props.t("Updated"), "success")
          getDevices()
        } else {
          showToast(props.t("Error"), "error")
        }
      })
      .catch(err => {
        console.log(err)
        showToast(props.t("Error"), "error")
      })
  }

  // Import validation
  const importValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      json: "",
    },
    validationSchema: Yup.object({
      json: Yup.string().required(props.t("Required field")),
    }),
    onSubmit: values => {
      console.log("Submit: ", values)
      if (!ongoingCreate) {
        setOngoingCreate(true)
        importGateways(abortRef.current, values.json)
          .then(result => {
            if (result.statusCode == 200) {
              let msg =
                props.t("Added") +
                ": " +
                result.added +
                ", " +
                props.t("Updated") +
                ": " +
                result.updated
              showToast(msg, "success")
              setImportModal(false)
              importValidation.resetForm()
              getDevices()
            } else {
              showToast(props.t("Error"), "error")
            }
          })
          .catch(err => {
            console.log(err)
            showToast(props.t("Error"), "error")
          })
          .finally(() => {
            setOngoingCreate(false)
          })
      }
    },
  })

  const defaultSorted = [
    {
      dataField: "serial",
      order: "asc",
    },
  ]

  // Show selected number of rows
  const pageOptions = {
    custom: true,
    pageStartIndex: 1,
    totalSize: filteredGateways.length,
    page: currentPage,
    sizePerPageList: [
      { text: "10", value: 10 },
      { text: "50", value: 50 },
      { text: "100", value: 100 },
      { text: props.t("All"), value: filteredGateways?.length || 0 },
    ],
    onPageChange: (page, sizePerPage) => {
      setCurrentPage(page)
    },
    onSizePerPageChange: (sizePerPage, page) => {
      setCurrentPage(1)
    },
  }

  // Table structure
  const columns = [
    {
      dataField: "actions",
      isDummy: true,
      text: props.t("Actions"),
      sort: true,
      align: "center",
      formatter: editFormatter,
    },
    {
      dataField: "lastConnected",
      text: props.t("Last connection"),
      sort: true,
      formatter: dateFormatter,
      style: (cell, row, rowIndex, colIndex) => {
        if (row.lastConnectedDiff == null || row.lastConnectedDiff > 30) {
          return {
            color: "#f46a6a",
          }
        }
      },
    },
    {
      dataField: "online",
      text: props.t("Online"),
      sort: true,
    },
    {
      dataField: "description",
      text: props.t("Description"),
      sort: true,
    },
    {
      dataField: "serial",
      text: props.t("Serial"),
      sort: true,
    },
    {
      dataField: "sshId",
      text: props.t("SSH ID"),
      sort: true,
    },
    {
      dataField: "linkedNames",
      text: props.t("Links"),
      sort: true,
      formatter: linksFormatter,
    },
    {
      dataField: "gsm",
      text: props.t("GSM"),
      sort: true,
    },
    {
      dataField: "imsi",
      text: props.t("IMSI"),
      sort: true,
    },
    {
      dataField: "iccid",
      text: props.t("ICCID"),
      sort: true,
    },
    {
      dataField: "zigbeeEpId",
      text: props.t("Zigbee EPID"),
      sort: true,
    },
    {
      dataField: "sshConnection",
      text: props.t("SSH connection"),
      sort: true,
    },
    {
      dataField: "remoteCommand",
      text: props.t("Remote command"),
      sort: true,
    },
    {
      dataField: "remoteCommandRan",
      text: props.t("Remote command ran"),
      sort: true,
      formatter: dateFormatter,
    },
    {
      dataField: "coordinatorUpdate",
      text: props.t("Coordinator update"),
      sort: true,
    },
    {
      dataField: "coordinatorUpdated",
      text: props.t("Coordinator updated"),
      sort: true,
      formatter: dateFormatter,
    },
    {
      dataField: "batteryOperate",
      text: props.t("Battery operated"),
      sort: true,
    },
    {
      dataField: "imei",
      text: props.t("IMEI"),
      sort: true,
    },
    {
      dataField: "ipAddress",
      text: props.t("IP address"),
      sort: true,
    },
    {
      dataField: "virtual",
      text: props.t("Virtual"),
      sort: true,
    },
    {
      dataField: "hostKey",
      text: props.t("Host"),
      sort: true,
    },
    {
      dataField: "orderNumber",
      text: props.t("Order number"),
      sort: true,
    },
    {
      dataField: "operator",
      text: props.t("Operator"),
      sort: true,
    },
    {
      dataField: "simStatus",
      text: props.t("SIM status"),
      sort: true,
    },
    {
      dataField: "networkType",
      text: props.t("Network type"),
      sort: true,
    },
    {
      dataField: "wifi",
      text: props.t("WiFi"),
      sort: true,
    },
    {
      dataField: "rssi",
      text: props.t("RSSI"),
      sort: true,
    },
    {
      dataField: "configured",
      text: props.t("Configured"),
      sort: true,
    },
    {
      dataField: "key",
      text: props.t("GUID"),
      sort: true,
    },
  ]

  function editFormatter(cell, row) {
    return (
      <>
        <Button
          color="primary"
          outline
          size="sm"
          onClick={() => {
            setShowDrawer(true)
            setSelectedRow(row)
          }}
        >
          <i className="mdi mdi-pencil" />
        </Button>
      </>
    )
  }

  function dateFormatter(value) {
    if (!value) return ""
    var dateString = moment(value).format("DD.MM.YYYY HH:mm:ss")
    return dateString
  }

  function linksFormatter(cell, row) {
    if (row.links && row.links.length > 0) {
      return (
        <div className="d-grid">
          {row.links?.map((entry, key) => (
            <Link key={key} to={"/clients/" + entry.key} target="_blank">
              <span>{entry.name}</span>
            </Link>
          ))}
        </div>
      )
    }
  }

  // Export values
  const generateExcel = () => {
    const heading = [
      [
        props.t("Last connection"),
        props.t("Online"),
        props.t("Description"),
        props.t("Serial"),
        props.t("SSH ID"),
        props.t("Links"),
        props.t("GSM"),
        props.t("IMSI"),
        props.t("ICCID"),
        props.t("Zigbee EPID"),
        props.t("SSH connection"),
        props.t("Remote command"),
        props.t("Remote command ran"),
        props.t("Coordinator update"),
        props.t("Coordinator updated"),
        props.t("Battery operated"),
        props.t("IMEI"),
        props.t("IP address"),
        props.t("Virtual"),
        props.t("Host"),
        props.t("Order number"),
        props.t("Operator"),
        props.t("SIM status"),
        props.t("Network type"),
        props.t("WiFi"),
        props.t("RSSI"),
        props.t("Configured"),
        props.t("GUID"),
      ],
    ]
    const data = filteredGateways.map(elt => [
      dateFormatter(elt.lastConnected),
      isTrue(elt.online),
      elt.description,
      elt.serial,
      elt.sshId,
      elt.linkedNames,
      elt.gsm,
      elt.imsi,
      elt.iccid,
      elt.zigbeeEpId,
      isTrue(elt.sshConnection),
      isTrue(elt.remoteCommand),
      dateFormatter(elt.remoteCommandRan),
      isTrue(elt.coordinatorUpdate),
      dateFormatter(elt.coordinatorUpdated),
      isTrue(elt.batteryOperate),
      elt.imei,
      elt.ipAddress,
      elt.virtual,
      elt.hostKey,
      elt.orderNumber,
      elt.operator,
      elt.simStatus,
      elt.networkType,
      elt.wifi,
      elt.rssi,
      isTrue(elt.configured),
      elt.key,
    ])

    downloadExcel(title, heading, data)
    showToast(props.t("Excel file exported succesfully!"), "success")
  }

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title={props.t("Gateways")} />
          <Card>
            <CardHeader className="bg-transparent border-bottom">
              <div className="d-flex flex-wrap">
                <ul
                  className="nav nav-tabs nav-tabs-custom card-header-tabs"
                  role="tablist"
                >
                  <NavItem>
                    <NavLink
                      to="#"
                      active={activeTab === "1"}
                      onClick={() => {
                        setActiveTab("1")
                      }}
                    >
                      {props.t("Table")}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      active={activeTab === "2"}
                      onClick={() => {
                        setActiveTab("2")
                      }}
                    >
                      {props.t("Statistics")}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      active={activeTab === "3"}
                      onClick={() => {
                        setActiveTab("3")
                      }}
                    >
                      {props.t("Other")}
                    </NavLink>
                  </NavItem>
                </ul>
              </div>
            </CardHeader>
            <CardBody>
              <TabContent activeTab={activeTab}>
                <TabPane className="show" tabId="1">
                  <Accordion
                    flush
                    open={alertsOpen}
                    toggle={id => {
                      id === alertsOpen
                        ? setAlertsOpen("")
                        : setAlertsOpen(id)
                    }}
                  >
                    <AccordionItem>
                      <AccordionBody
                        accordionId="1"
                        className="accordion-zeropadding"
                      >
                        <Alert color="danger">
                          <div>
                            <h5 className="alert-heading">
                              {props.t("Duplicate values")}
                            </h5>
                            {duplicates.map((entry, key) => (
                              <Button
                                size="sm"
                                color="danger"
                                outline
                                key={key}
                                className="me-2 mb-2"
                                onClick={() => {
                                  setSearchText(entry.key)
                                }}
                              >
                                <span>{entry.name}</span>
                              </Button>
                            ))}
                          </div>
                          <div>
                            <h5 className="alert-heading">
                              {props.t("Multiple links")}
                            </h5>
                            {multilinks.map((entry, key) => (
                              <Button
                                size="sm"
                                color="danger"
                                outline
                                key={key}
                                className="me-2 mb-2"
                                onClick={() => {
                                  setSearchText(entry.key)
                                }}
                              >
                                <span>{entry.name}</span>
                              </Button>
                            ))}
                          </div>
                        </Alert>
                      </AccordionBody>
                    </AccordionItem>
                  </Accordion>
                  <Row>
                    <Col>
                      {gateways != null && (
                        <PaginationProvider
                          pagination={paginationFactory(pageOptions)}
                        >
                          {({ paginationProps, paginationTableProps }) => (
                            <ToolkitProvider
                              keyField="key"
                              columns={columns}
                              data={filteredGateways}
                              search
                            >
                              {toolkitProps => (
                                <React.Fragment>
                                  <Row className="mb-1">
                                    {" "}
                                    <Col className="col-auto pe-0">
                                      <Form
                                        className="mt-sm-0 d-flex align-items-center"
                                        onSubmit={e => {
                                          e.preventDefault()
                                          return false
                                        }}
                                      >
                                        <div className="search-box me-2 mb-2 d-inline-block float-end">
                                          <div className="position-relative">
                                            <Input
                                              type="text"
                                              value={searchText}
                                              onChange={event =>
                                                setSearchText(
                                                  event.target.value
                                                )
                                              }
                                              placeholder={props.t("Search")}
                                            />
                                            <i className="bx bx-search-alt search-icon" />
                                          </div>
                                        </div>
                                      </Form>
                                    </Col>
                                    <Col className="col-auto">
                                      <Button
                                        color="primary"
                                        className="round-icon-button"
                                        id="filter-button"
                                        outline
                                        onClick={() => {
                                          filtersOpen.length > 0
                                            ? setFiltersOpen("")
                                            : setFiltersOpen("1")
                                        }}
                                      >
                                        <UncontrolledTooltip
                                          placement="bottom"
                                          target="filter-button"
                                        >
                                          {props.t("Filters")}
                                        </UncontrolledTooltip>
                                        {filtersOpen ? (
                                          <i className="mdi mdi-filter-minus"></i>
                                        ) : (
                                          <i className="mdi mdi-filter-menu"></i>
                                        )}
                                      </Button>
                                      <Button
                                        className="ms-3"
                                        style={{ borderRadius: "100px" }}
                                        color="primary"
                                        disabled={ongoingSearch}
                                        onClick={() => {
                                          getDevices()
                                        }}
                                      >
                                        <i className="mdi mdi-refresh me-2" />
                                        {props.t("Refresh data")}
                                      </Button>

                                      <Popconfirm
                                        title={props.t("Confirm action")}
                                        open={createConfirm}
                                        okType="primary"
                                        okText={props.t("Create")}
                                        cancelText={props.t("Cancel")}
                                        onConfirm={() => {
                                          createGW()
                                          setCreateConfirm(false)
                                        }}
                                        onCancel={() => {
                                          setCreateConfirm(false)
                                        }}
                                      >
                                        <Button
                                          className="ms-3"
                                          disabled={ongoingCreate}
                                          style={{ borderRadius: "100px" }}
                                          color="success"
                                          onClick={() => {
                                            setCreateConfirm(true)
                                          }}
                                        >
                                          <i className="mdi mdi-plus me-2" />
                                          {props.t("Create new")}
                                        </Button>
                                      </Popconfirm>

                                      <Button
                                        className="ms-3"
                                        style={{ borderRadius: "100px" }}
                                        color="success"
                                        onClick={() => {
                                          setImportModal(true)
                                        }}
                                      >
                                        <i className="mdi mdi-import me-2" />
                                        {props.t("Import")}
                                      </Button>

                                      {(duplicates.length > 0 ||
                                        multilinks.length > 0) && (
                                        <Button
                                          color="danger"
                                          className="round-icon-button ms-3"
                                          id="alerts-button"
                                          onClick={() => {
                                            alertsOpen.length > 0
                                              ? setAlertsOpen("")
                                              : setAlertsOpen("1")
                                          }}
                                        >
                                          <UncontrolledTooltip
                                            placement="bottom"
                                            target="alerts-button"
                                          >
                                            {props.t("Notices")}
                                          </UncontrolledTooltip>
                                          {alertsOpen ? (
                                            <i className="mdi mdi-alert-minus"></i>
                                          ) : (
                                            <i className="mdi mdi-alert-plus"></i>
                                          )}
                                        </Button>
                                      )}
                                    </Col>
                                    <Col>
                                      <span className="float-end ms-2">
                                        <UncontrolledTooltip
                                          placement="bottom"
                                          target="downloadstatstable"
                                        >
                                          {props.t("Download statistics")}
                                        </UncontrolledTooltip>
                                        <Dropdown
                                          id="printButtonServicesStats"
                                          isOpen={menu}
                                          toggle={() => setMenu(!menu)}
                                          className="download-btn text-center"
                                          type="button"
                                        >
                                          <DropdownToggle
                                            id="downloadstatstable"
                                            className="text-muted"
                                            tag="i"
                                          >
                                            <i className="mdi mdi-download" />
                                          </DropdownToggle>
                                          <DropdownMenu>
                                            {/* TODO: Can not choose directory file right now */}
                                            <DropdownItem
                                              onClick={() => generateExcel()}
                                            >
                                              {props.t("Download .xlsx")}
                                            </DropdownItem>
                                          </DropdownMenu>
                                        </Dropdown>
                                      </span>
                                      <div className="mt-2 float-end">
                                        <p>
                                          {filteredGateways.length}{" "}
                                          {props.t("found results")}
                                        </p>
                                      </div>
                                    </Col>
                                  </Row>

                                  <Accordion
                                    flush
                                    open={filtersOpen}
                                    toggle={id => {
                                      id === filtersOpen
                                        ? setFiltersOpen("")
                                        : setFiltersOpen(id)
                                    }}
                                  >
                                    <AccordionItem>
                                      <AccordionBody
                                        accordionId="1"
                                        className="mb-3"
                                        style={{
                                          backgroundColor: "#e8f0fe",
                                          border: "1px solid #ced4da",
                                        }}
                                      >
                                        <Row xs={1} sm={2} md={3} xl={4}>
                                          <Col>
                                            <FormGroup>
                                              <Label>{props.t("Status")}</Label>
                                              <Input
                                                type="select"
                                                bsSize={"sm"}
                                                name="configFilter"
                                                value={configFilter}
                                                onChange={v => {
                                                  setConfigFilter(
                                                    v.target.value
                                                  )
                                                }}
                                              >
                                                <option value="0">
                                                  {props.t("All")}
                                                </option>
                                                <option value="1">
                                                  {props.t("Configured")}
                                                </option>
                                                <option value="2">
                                                  {props.t("Unconfigured")}
                                                </option>
                                              </Input>
                                            </FormGroup>
                                          </Col>
                                          <Col>
                                            <FormGroup>
                                              <Label>
                                                {props.t("Connection status")}
                                              </Label>
                                              <Input
                                                type="select"
                                                bsSize={"sm"}
                                                name="connectionFilter"
                                                value={connectionFilter}
                                                onChange={v => {
                                                  setConnectionFilter(
                                                    v.target.value
                                                  )
                                                }}
                                              >
                                                <option value="0">
                                                  {props.t("All")}
                                                </option>
                                                <option value="1">
                                                  {props.t("Online")}
                                                </option>
                                                <option value="2">
                                                  {props.t("Offline")}
                                                </option>
                                                <option value="3">
                                                  {props.t("Offline recently")}
                                                </option>
                                              </Input>
                                            </FormGroup>
                                          </Col>
                                        </Row>
                                      </AccordionBody>
                                    </AccordionItem>
                                  </Accordion>

                                  <Row className="mt-2">
                                    <Col xl="12">
                                      {ongoingSearch == true ? (
                                        <div className="py-5 text-center">
                                          <Link to="#" className="text-primary">
                                            <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                                            {props.t("Loading")}
                                          </Link>
                                        </div>
                                      ) : (
                                        <div className="table-responsive">
                                          <BootstrapTable
                                            id="gateways-table"
                                            keyField={"key"}
                                            responsive
                                            bordered={true}
                                            striped={true}
                                            hover={true}
                                            defaultSorted={defaultSorted}
                                            classes={
                                              "table align-middle table-nowrap"
                                            }
                                            headerWrapperClasses={"thead-light"}
                                            headerClasses="header-class"
                                            {...toolkitProps.baseProps}
                                            {...paginationTableProps}
                                          />
                                        </div>
                                      )}
                                    </Col>
                                  </Row>

                                  <Row className="align-items-md-center mt-3 mt-30">
                                    <Col className="inner-custom-pagination d-flex">
                                      <div className="">
                                        <SizePerPageDropdownStandalone
                                          {...paginationProps}
                                        />
                                      </div>
                                      <Col className="pagination justify-content-end inner-custom-pagination">
                                        <PaginationListStandalone
                                          {...paginationProps}
                                        />
                                      </Col>
                                    </Col>
                                  </Row>
                                </React.Fragment>
                              )}
                            </ToolkitProvider>
                          )}
                        </PaginationProvider>
                      )}
                    </Col>
                  </Row>
                </TabPane>

                <TabPane className="show" tabId="2">
                  <Table
                    style={{ tableLayout: "fixed" }}
                    className="table"
                    bordered={false}
                    striped={false}
                    hover={true}
                  >
                    <thead>
                      <tr>
                        <th></th>
                        <th>{props.t("Count")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">{props.t("Total")}</th>
                        <td>{statistics.total}</td>
                      </tr>
                      <tr>
                        <th scope="row">{props.t("Linked")}</th>
                        <td>{statistics.linked}</td>
                      </tr>
                      <tr>
                        <th scope="row">{props.t("Configured")}</th>
                        <td>{statistics.configured}</td>
                      </tr>
                      <tr>
                        <th scope="row">{props.t("Unconfigured")}</th>
                        <td>{statistics.unconfigured}</td>
                      </tr>
                      <tr>
                        <th scope="row">{props.t("Online")}</th>
                        <td>{statistics.online}</td>
                      </tr>
                      <tr>
                        <th scope="row">{props.t("Offline")}</th>
                        <td>{statistics.offline}</td>
                      </tr>
                      <tr>
                        <th scope="row">
                          {props.t("Offline recently") + " (24h)"}
                        </th>
                        <td>{statistics.offlineRecent}</td>
                      </tr>
                    </tbody>
                  </Table>
                </TabPane>

                <TabPane className="show" tabId="3">
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{props.t("Installation serials")}</Label>
                        <Input
                          bsSize="sm"
                          name="installationSerials"
                          type="text"
                          value={installationSerials}
                          onChange={v => {
                            setInstallationSerials(v.target.value)
                          }}
                        ></Input>
                      </FormGroup>
                    </Col>
                    <Col className="col-auto">
                      <div className="d-flex" style={{ height: "100%" }}>
                        <Button
                          className="d-inline-block mb-3"
                          style={{ minWidth: "80px", alignSelf: "flex-end" }}
                          color="primary"
                          size="sm"
                          disabled={ongoingSearch}
                          onClick={() => {
                            updateInstallationSerials()
                          }}
                        >
                          {props.t("Set")}
                        </Button>
                      </div>
                    </Col>
                    <Col sm={6}></Col>
                  </Row>
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>

          <GatewayDrawer
            showDrawer={showDrawer}
            setShowDrawer={setShowDrawer}
            getGateways={getDevices}
            gatewayKey={selectedRow?.key}
          />

          <Modal
            isOpen={importModal}
            toggle={() => {
              setImportModal(!importModal)
            }}
            centered
          >
            <ModalHeader>{props.t("Import")}</ModalHeader>
            <Form
              className="form-horizontal"
              onSubmit={e => {
                e.preventDefault()
                return false
              }}
            >
              <ModalBody>
                <FormGroup>
                  <Label>{props.t("JSON")}</Label>
                  <Input
                    name="json"
                    type="textarea"
                    rows={8}
                    onChange={importValidation.handleChange}
                    onBlur={importValidation.handleBlur}
                    placeholder="[{'SerialNumber': '123', 'MSISDN': '358401234567', 'ICCID': '456', 'IMSI': '789'}]"
                    value={importValidation.values.json || ""}
                    invalid={
                      importValidation.touched.json &&
                      importValidation.errors.json
                        ? true
                        : false
                    }
                  ></Input>
                  {importValidation.touched.json &&
                  importValidation.errors.json ? (
                    <FormFeedback type="invalid">
                      {importValidation.errors.json}
                    </FormFeedback>
                  ) : null}
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="primary"
                  outline
                  onClick={() => {
                    setImportModal(false)
                  }}
                >
                  {props.t("Cancel")}
                </Button>
                <Button
                  color="success"
                  disabled={ongoingCreate}
                  onClick={() => {
                    importValidation.submitForm()
                  }}
                >
                  {props.t("Import")}
                </Button>
              </ModalFooter>
            </Form>
          </Modal>
        </Container>
      </div>
    </>
  )
}

GatewayConfigs.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(GatewayConfigs)
