/***********************
 * REPORTING (PAGE)
 ************************/

import React, { useEffect, useState, useRef, useContext } from "react"
import PropTypes from "prop-types"
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  TabContent,
  TabPane,
  Button,
} from "reactstrap"
// Lottie animations
import {
  getLottieLoadingOptions,
  getLottieSearch,
  getLottieNothingFound,
} from "utils/general"
import { Select } from "antd"

// Importing animations
import Lottie from "react-lottie"

// Import tab components
import CustomersServicesTabReporting from "./customers-services/index-customers-services"
import AlertsTabReporting from "./alerts/index-alerts"
import CallsTabReporting from "./calls/index-calls"
import GroupVideoCallsTabReporting from "./group-calls/index-group-calls"
import DeviceIdentificationsTabReporting from "./device-identifications/index-device-identifications"
import VisitsTabReporting from "./visits/index-visits"
import RemoteMeasurementsTabReporting from "./remote-measurements/index-remote-measurements"

// Reporting list items
import itemsTogether from "./reporting-list"

// Filter related components
import DropdownTree from "components/DropdownList/SelectDropdownTree"
import RangeCalendar from "./filter-calendar"
import TagsTree from "components/DropdownList/TagsTree"

// Locale
import { withTranslation } from "react-i18next"

// Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

// Back-end
import {
  getServiceStats,
  getAlarmStats,
  getCallStats,
  getVisitStats,
  getMeasurementStats,
  getDeviceStats,
  getGroupCallStats,
} from "helpers/backend_helper"
import withRouter from "components/Common/withRouter"
import GlobalState from "contexts/GlobalState"

const Reporting = props => {
  const [state, setState] = useContext(GlobalState)
  const [userRole, setUserRole] = useState(null)
  // Filter related states
  const [customers, setCustomers] = useState([])
  const [dateRange, setDateRange] = useState([null])
  const [startDate, setStartDate] = useState(dateRange.startUnix)
  const [endDate, setEndDate] = useState(dateRange.endUnix)

  // Setting disabled when fetching data
  const [disabled, setDisabled] = useState(true)

  // States for reporting components
  const [servicesData, setServicesData] = useState([])
  const [alarmsData, setAlarmsData] = useState([])
  const [callsData, setCallsData] = useState([])
  const [groupCallsData, setGroupCallsData] = useState([])
  const [visitsData, setVisitsData] = useState([])
  const [measurementsData, setMeasurementsData] = useState([])
  const [deviceData, setDeviceData] = useState([])

  // Tab related states
  const [calendarVisible, setCalendarVisible] = useState(false)
  const [activeTab, setActiveTab] = useState(1)
  const [activeTabContent, setActiveTabContent] = useState(1)
  const [dropdownSize, setDropdownSize] = useState(3)

  // Call types
  const [callsFiltered, setCallsFiltered] = useState(callsData)
  const [selectedCallTypes, setSelectedCalltypes] = useState([])

  // Animations related states
  const [animLoading, setAnimLoading] = useState(false)
  const [animSearch, setAnimSearch] = useState(true)
  const [animNothing, setAnimNothing] = useState(false)

  // Showing data
  const [showContent, setShowContent] = useState(false)

  // Seleced tags
  const [selectedTags, setSelectedTags] = useState([])
  const [filteredCustomers, setFilteredCustomers] = useState(null)
  const [allClients, setAllClients] = useState([])

  // const [groups, setGroups] = useState([])
  const [temp, setTemp] = useState(null)

  const abortRef = useRef(null)
  useEffect(() => {
    document.title = props.t("Reporting") + " | Suvanto Care"
    abortRef.current = new AbortController()

    if (state.authUser != null) {
      const obj = JSON.parse(state.authUser)
      let role = obj.activeRole ? obj.activeRole : obj.role
      setUserRole(role)
    }
    return () => abortRef.current.abort()
  }, [])

  // Disable tags if no customers
  useEffect(() => {
    if (customers != undefined && customers.length != 0) {
      setDisabled(false)
    }
  }, [customers])

  // Sort new temp arr
  useEffect(() => {
    if (temp !== null) {
      let setNew = Array.from(new Set(temp))
      setFilteredCustomers(setNew)
    }
  }, [temp])

  // Filter fetched customers from SelectDropdownTree by selected tags
  useEffect(() => {
    if (allClients.length > 0 && allClients !== null) {
      if (selectedTags?.length > 0) {
        setTemp([])
        allClients.map(o => {
          selectedTags.map(t => {
            t = t.toString()
            if (o.tags.length > 0) {
              o.tags.map(tag => {
                if (tag.key == t) {
                  setTemp(filtered => [...filtered, o])
                }
              })
            }
          })
        })
      }
      if (selectedTags?.length == 0) {
        setFilteredCustomers(allClients)
      }
    }
  }, [selectedTags, allClients])

  // Set filter for calendar
  useEffect(() => {
    if (activeTab == 1) {
      setCalendarVisible(false)
      setDropdownSize(3)
    }
    if (activeTab == 7) {
      setCalendarVisible(false)
      setDropdownSize(3)
    }
    if (activeTab != 1 && activeTab != 7) {
      setCalendarVisible(true)
      setDropdownSize(3)
    }
    if (activeTab != 1 && activeTab != 7 && activeTab == 3) {
      setCalendarVisible(true)
      setDropdownSize(2)
    }
  }, [activeTab])

  // Fetch data from components && set dataVisible
  const getAlerts = currentTab => {
    //Setting find button disabled
    setDisabled(true)
    setActiveTabContent(currentTab)

    let t = currentTab

    setAnimSearch(false)
    setAnimNothing(false)
    setShowContent(false)
    setAnimLoading(true)

    if (t == 1 || t == 7) {
      if (customers.length == 0) {
        setAnimLoading(false)
        setAnimSearch(true)
        // Setting disabled button false
        setDisabled(false)
      }
      if (customers.length != 0) {
        fetchData(t)
      }
    }
    if (t != 1 && t != 7) {
      if (customers.length == 0 || startDate == null || endDate == null) {
        setAnimLoading(false)
        setAnimSearch(true)
        // Setting disabled button false
        setDisabled(false)
      }
      if (customers.length != 0 && startDate != null && endDate != null) {
        fetchData(t)
      }
    }
  }

  // Fetching data
  const fetchData = tab => {
    console.log(tab, "TAB")
    setActiveTab(tab)
    // Setting disabled button false
    setDisabled(true)
    // Customers and service data
    if (tab == 1) {
      getServiceStats(abortRef.current, customers)
        .then(data => {
          console.log("services data saved")
          setServicesData(data)
          setAnimLoading(false)
          setShowContent(true)
        })
        .catch(e => {
          console.log(e)
        })
        .finally(() => {
          setDisabled(false)
        })
    }
    // Alerts data
    if (tab == 2) {
      getAlarmStats(abortRef.current, customers, startDate, endDate)
        .then(data => {
          setAlarmsData(data)
          if (data.list.length == 0) {
            setAnimLoading(false)
            setAnimNothing(true)
          }
          if (data.list.length != 0) {
            setAnimLoading(false)
            setShowContent(true)
            filterShownAlerts(data.list)
            console.log("alerts data saved")
          }
        })
        .catch(e => {
          console.log(e)
        })
        .finally(() => {
          setDisabled(false)
        })
    }
    // Calls data
    if (tab == 3) {
      setAnimSearch(false)
      getCallStats(abortRef.current, customers, startDate, endDate)
        .then(data => {
          console.log({ data }, "datas reporting")
          setCallsData(data)
          if (data.list.length == 0) {
            setAnimLoading(false)
            setAnimNothing(true)
          }
          if (data.list.length != 0) {
            setAnimLoading(false)
            setShowContent(true)
            console.log("calls data saved", data.list)
          }
        })
        .catch(e => {
          console.log(e)
        })
        .finally(() => {
          setDisabled(false)
        })
    }
    // Group video calls data
    if (tab == 4) {
      setAnimSearch(false)
      getGroupCallStats(abortRef.current, customers, startDate, endDate)
        .then(data => {
          console.log({ data }, "datas reporting")
          setGroupCallsData(data)
          if (data.list.length == 0) {
            setAnimLoading(false)
            setAnimNothing(true)
          }
          if (data.list.length != 0) {
            setAnimLoading(false)
            setShowContent(true)
            console.log("group calls data saved", data.list)
          }
        })
        .catch(e => {
          console.log(e)
        })
        .finally(() => {
          setDisabled(false)
        })
    }
    // Visits data
    if (tab == 5) {
      setAnimSearch(false)
      getVisitStats(abortRef.current, customers, startDate, endDate)
        .then(data => {
          setVisitsData(data)
          if (data.list.length == 0) {
            setAnimLoading(false)
            setAnimNothing(true)
          }
          if (data.list.length != 0) {
            setAnimLoading(false)
            setShowContent(true)
            console.log("visits data saved")
          }
        })
        .catch(e => {
          console.log(e)
        })
        .finally(() => {
          setDisabled(false)
        })
    }
    //Remote measuremets data
    if (tab == 6) {
      setAnimSearch(false)
      getMeasurementStats(abortRef.current, customers, startDate, endDate)
        .then(data => {
          setMeasurementsData(data)
          if (data.list.length == 0) {
            setAnimLoading(false)
            setAnimNothing(true)
          }
          if (data.list.length != 0) {
            setAnimLoading(false)
            setShowContent(true)
            console.log("measurements data saved")
          }
        })
        .catch(e => {
          console.log(e)
        })
        .finally(setDisabled(false))
    }
    // Device identifications data
    if (tab == 7) {
      setAnimSearch(false)
      getDeviceStats(abortRef.current, customers)
        .then(data => {
          setDeviceData(data)
          if (data.length == 0) {
            setAnimLoading(false)
            setAnimNothing(true)
          }
          if (data.length != 0) {
            setAnimLoading(false)
            setShowContent(true)
            console.log("device data saved")
          }
        })
        .catch(e => {
          console.log(e)
        })
        .finally(() => {
          setDisabled(false)
        })
    }
  }

  // Change tab
  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
      console.log("Tab:", tab)
    }
  }

  // Creating tab content
  const reportSelections = []
  itemsTogether.forEach(item => {
    reportSelections.push({ value: item.id, label: props.t(`${item.text}`) })
  })

  // Filter shown alerts
  const filterShownAlerts = f => {
    if (f != undefined) {
      // Sending filter categories to alarms
      setFilterData(f)
    }
  }

  // Filter for calls
  useEffect(() => {
    // console.log(callsData)
    // Set original fetched calls
    if (callsData != undefined) {
      setCallsFiltered(callsData)
    }
  }, [callsData])

  // Filter for calls
  useEffect(() => {
    // console.log(selectedCallTypes)
    if (
      selectedCallTypes == [] ||
      selectedCallTypes == undefined ||
      selectedCallTypes == ""
    ) {
      setSelectedCalltypes("")
    }
  }, [selectedCallTypes])

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Reports")}
            className="breadcrumb m-0 justify-content-center"
          />
          <Col xl={12}>
            <Card>
              <CardBody>
                <CardTitle className="mb-2">
                  {props.t("Find statistics")}
                </CardTitle>
                <Row>
                  {userRole == "system_admin" && (
                    <Col xl={2} sm="12" className="mt-2">
                      <TagsTree
                        disabled={allClients.length == 0}
                        loadData={true}
                        expanded={true}
                        selectionChanged={setSelectedTags}
                      />
                    </Col>
                  )}

                  {/* Choose customer */}
                  <Col
                    xl={userRole == "system_admin" ? "3" : "4"}
                    sm="12"
                    className="mt-2"
                  >
                    <DropdownTree
                      tags={selectedTags}
                      options={filteredCustomers}
                      allCustomers={setAllClients}
                      disabled={disabled}
                      setCustomers={setCustomers}
                      allGroups={true}
                    />
                  </Col>
                  <Col xl={2} sm="12" className="mt-2">
                    <Select
                      disabled={disabled}
                      defaultValue={activeTab}
                      style={{ width: 100 + "%" }}
                      onChange={toggleTab}
                      options={reportSelections}
                    />
                  </Col>
                  {/* Date */}
                  {calendarVisible && (
                    <Col
                      xl={userRole == "system_admin" ? "3" : "4"}
                      className="mt-2"
                    >
                      <RangeCalendar
                        disabled={disabled}
                        setStartDate={setStartDate}
                        setEndDate={setEndDate}
                        setDateRange={setDateRange}
                      />
                    </Col>
                  )}
                  <Col className="mt-1 col-auto">
                    {/* Fetch data with filter  */}
                    <div className="d-flex gap-1 flex-wrap">
                      <div className="align-self-end placeholder-glow">
                        <Button
                          type="button"
                          color="primary"
                          disabled={disabled}
                          style={{ padding: "9px" }}
                          onClick={() => getAlerts(activeTab)}
                        >
                          <span className="me-3 ms-3">{props.t("Search")}</span>
                        </Button>
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Row>
            {/* Blank content  */}
            {animLoading && (
              <Col className="mt-5" lg={12} style={{ height: "100%" }}>
                <Lottie
                  options={getLottieLoadingOptions()}
                  height={100}
                  width={100}
                />
              </Col>
            )}
            {animNothing && (
              <TabContent>
                <Card>
                  <CardBody>
                    <Col lg={12} style={{ height: "100%" }}>
                      <div className="text-center">
                        <Lottie
                          options={getLottieNothingFound()}
                          height={150}
                          width={150}
                        />{" "}
                      </div>
                      <div className="text-center">
                        <h4 className="text-muted mb-4">
                          {props.t("No data found")}
                        </h4>
                        <p className="text-muted mb-5">
                          {props.t("No results with search criteria.")}
                        </p>
                      </div>
                    </Col>
                  </CardBody>
                </Card>
              </TabContent>
            )}
            {animSearch && (
              <TabContent>
                <Card style={{ height: "auto", width: "auto" }}>
                  <CardBody>
                    <Col lg={12}>
                      <div className="text-center">
                        {" "}
                        <Lottie
                          options={getLottieSearch()}
                          height={150}
                          width={180}
                        ></Lottie>
                        <div className="text-center">
                          <h4 className="text-muted mb-4 mt-3">
                            {props.t("Find data")}
                          </h4>
                          <p className="text-muted mb-5">
                            {props.t(
                              "Select a group or customer to find the information to display from the list above."
                            )}
                          </p>
                        </div>
                      </div>
                    </Col>
                  </CardBody>
                </Card>
              </TabContent>
            )}
            {showContent == true && (
              <div className="mb-6">
                <TabContent activeTab={activeTabContent}>
                  <TabPane tabId={1} id="services">
                    <CustomersServicesTabReporting
                      customers={customers}
                      activeTab={activeTab}
                      data={servicesData}
                    />
                  </TabPane>
                  <TabPane tabId={2} id="alarms">
                    <AlertsTabReporting
                      customers={customers}
                      activeTab={activeTab}
                      data={alarmsData}
                      setStartDate={setStartDate}
                      setEndDate={setEndDate}
                      start={startDate}
                      end={endDate}
                    />
                  </TabPane>
                  <TabPane tabId={3} id="Calls">
                    <CallsTabReporting
                      activeTab={activeTab}
                      data={callsFiltered}
                      setStartDate={setStartDate}
                      setEndDate={setEndDate}
                      selectedType={selectedCallTypes}
                      start={startDate}
                      end={endDate}
                    />
                  </TabPane>
                  <TabPane tabId={4} id="group-calls">
                    <GroupVideoCallsTabReporting
                      activeTab={activeTab}
                      data={groupCallsData}
                      setStartDate={setStartDate}
                      setEndDate={setEndDate}
                      start={startDate}
                      end={endDate}
                    />
                  </TabPane>
                  <TabPane tabId={5} id="visits">
                    <VisitsTabReporting
                      customers={customers}
                      activeTab={activeTab}
                      data={visitsData}
                      setStartDate={setStartDate}
                      setEndDate={setEndDate}
                    />
                  </TabPane>
                  <TabPane tabId={6} id="remote-measurements">
                    <RemoteMeasurementsTabReporting
                      customers={customers}
                      activeTab={activeTab}
                      data={measurementsData}
                      setStartDate={setStartDate}
                      setEndDate={setEndDate}
                    />
                  </TabPane>
                  <TabPane tabId={7} id="device-identifications">
                    <DeviceIdentificationsTabReporting
                      customers={customers}
                      activeTab={activeTab}
                      data={deviceData}
                    />
                  </TabPane>
                </TabContent>
              </div>
            )}
          </Row>
        </Container>
      </div>
    </>
  )
}

Reporting.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
  setFilter: PropTypes.any,
  setSearch: PropTypes.any,
  setCalendarStartDate: PropTypes.any,
  setCalendarEndDate: PropTypes.any,
  filterContent: PropTypes.any,
  setDisabled: PropTypes.any,
}

export default withRouter(withTranslation()(Reporting))
