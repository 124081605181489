import React, { useEffect, useRef, useState } from "react"
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Form,
  Input,
  Button,
} from "reactstrap"
import PropTypes from "prop-types"
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import moment from "moment"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import "../../datatables.scss"
import CustomRangePicker from "components/DatePickers/RangePicker"
import { getUsersTickets } from "helpers/database_helper"
import XLSX from "xlsx"

const SupportTicketsReport = props => {
  const [tickets, setTickets] = useState([])
  const [filteredTickets, setFilteredTickets] = useState([])
  const [ongoingSearch, setOngoingSearch] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [dateRange, setDateRange] = useState([
    moment().subtract(7, "days"),
    moment(),
  ])
  const [userId, setUserId] = useState("149")
  const [maxItems, setMaxItems] = useState("500")
  const [currentPage, setCurrentPage] = useState(1)

  const abortRef = useRef(null)
  useEffect(() => {
    abortRef.current = new AbortController()
    document.title = props.t("Support tickets") + " SLA | Suvanto Care"
    return () => abortRef.current.abort()
  }, [])

  // Get tickets
  const getTickets = () => {
    if (!ongoingSearch && userId && dateRange) {
      setOngoingSearch(true)
      //let rows = isNaN(maxItems) == false ? parseInt(maxItems, 10) : 500
      let from = moment(dateRange[0]).startOf("day").valueOf()
      let to = moment(dateRange[1]).add(1, "days").startOf("day").valueOf()

      getUsersTickets(abortRef.current, userId, from, to)
        .then(result => {
          //console.log("Tickets: ", result)
          setTickets(result)
        })
        .catch(e => {
          console.log(e)
        })
        .finally(() => {
          setOngoingSearch(false)
        })
    }
  }

  // Search or filter changed
  useEffect(() => {
    setCurrentPage(1)
    handleSearch()
  }, [searchText, tickets])

  // Handle search function
  const handleSearch = () => {
    let result = tickets.filter(
      data =>
        data.ticket_number?.toLowerCase().includes(searchText) ||
        data.title?.toLowerCase().includes(searchText) ||
        data.sender_email?.toLowerCase().includes(searchText) ||
        data.topic?.toLowerCase().includes(searchText)
    )
    setFilteredTickets(result)
  }

  const defaultSorted = [
    {
      dataField: "created",
      order: "desc",
    },
  ]

  const pageOptions = {
    custom: true,
    pageStartIndex: 1,
    totalSize: filteredTickets.length,
    page: currentPage,
    sizePerPageList: [
      { text: "10", value: 10 },
      { text: "50", value: 50 },
      { text: "100", value: 100 },
      { text: props.t("All"), value: filteredTickets?.length || 0 },
    ],
    onPageChange: (page, sizePerPage) => {
      setCurrentPage(page)
    },
    onSizePerPageChange: (sizePerPage, page) => {
      setCurrentPage(1)
    },
  }

  const columns = [
    {
      dataField: "ticket_number",
      text: props.t("Ticket"),
      sort: true,
    },
    {
      dataField: "created",
      text: props.t("Created"),
      sort: true,
      formatter: dateFormatter,
    },
    {
      dataField: "topic",
      text: props.t("Topic"),
      sort: true,
    },
    {
      dataField: "title",
      text: props.t("Title"),
      sort: true,
    },
    {
      dataField: "sender_email",
      text: props.t("Email"),
      sort: true,
    },
    {
      dataField: "current_status",
      text: props.t("Status"),
      sort: true,
    },
    {
      dataField: "processing_started",
      text: props.t("Processing started"),
      sort: true,
      formatter: dateFormatter,
    },
    {
      dataField: "closed",
      text: props.t("Closed at"),
      sort: true,
      formatter: dateFormatter,
    },
    {
      dataField: "count",
      text: props.t("Attachments"),
      sort: true,
    },
    /*{
      dataField: "source",
      text: props.t("Source"),
      sort: true,
    },*/
  ]

  function dateFormatter(value) {
    if (!value) return ""
    var dateString = moment(value).format("DD.MM.YYYY HH:mm:ss")
    return dateString
  }

  const onDateSelection = value => {
    setDateRange(value)
  }

  // Creating Excel workbook+worksheet+download
  const downloadExcel = () => {
    let title = props.t("Tickets")
    let message = props.t("Excel file exported succesfully!")
    const wb = XLSX.utils.book_new()

    const Heading = [
      [
        props.t("Ticket"),
        props.t("Created"),
        props.t("Topic"),
        props.t("Title"),
        props.t("Email"),
        props.t("Status"),
        props.t("Processing started"),
        props.t("Closed at"),
        props.t("Attachments"),
        //props.t("Source"),
      ],
    ]
    const data = filteredTickets.map(elt => [
      elt.ticket_number,
      elt.created,
      elt.topic,
      elt.title,
      elt.sender_email,
      elt.current_status,
      elt.processing_started,
      elt.closed,
      elt.count,
      //elt.source,
    ])

    // creating sheet and adding data from 2nd row of column A.
    // Leaving first row to add Heading
    const ws = XLSX.utils.json_to_sheet(data, {
      origin: "A2",
      skipHeader: true,
    })
    XLSX.utils.sheet_add_aoa(ws, Heading, { origin: "A1" })
    XLSX.utils.book_append_sheet(wb, ws, title)
    XLSX.write(wb, { bookType: "xlsx", type: "buffer" })
    XLSX.writeFile(wb, title + " (" + new Date() + ")" + ".xlsx")
  }

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title={props.t("Support tickets") + " SLA"} />
          <Card>
            <CardBody>
              <Row>
                <Col>
                  {tickets != null && (
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="ticket_number"
                          columns={columns}
                          data={filteredTickets}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-1">
                                {" "}
                                <Col className="col-auto pe-0">
                                  <Form
                                    className="mt-sm-0 d-flex align-items-center"
                                    onSubmit={e => {
                                      e.preventDefault()
                                      return false
                                    }}
                                  >
                                    <div className="search-box me-2 mb-2 d-inline-block float-end">
                                      <div className="position-relative">
                                        <Input
                                          type="text"
                                          onChange={event =>
                                            setSearchText(
                                              event.target.value.toLowerCase()
                                            )
                                          }
                                          placeholder={props.t("Search")}
                                        />
                                        <i className="bx bx-search-alt search-icon" />
                                      </div>
                                    </div>
                                  </Form>
                                </Col>
                                <Col className="col-auto">
                                  <Input
                                    type="text"
                                    name="userId"
                                    value={userId}
                                    placeholder="User ID"
                                    onChange={v => {
                                      setUserId(v.target.value)
                                    }}
                                  />
                                </Col>
                                <Col className="col-auto">
                                  <Input
                                    style={{ width: "80px" }}
                                    type="number"
                                    name="maxItems"
                                    value={maxItems}
                                    onChange={v => {
                                      setMaxItems(v.target.value)
                                    }}
                                  />
                                </Col>
                                <Col>
                                  <CustomRangePicker
                                    value={dateRange}
                                    rangeChanged={onDateSelection}
                                    showTime={false}
                                    allowClear={false}
                                    format={"DD.MM.YYYY"}
                                  />
                                </Col>
                                <Col className="col-auto">
                                  <Button
                                    //className="square-icon-button-sm"
                                    id="search-button"
                                    color="primary"
                                    disabled={ongoingSearch}
                                    onClick={() => {
                                      //setDateRange([dateRange[0], moment()])
                                      getTickets()
                                    }}
                                  >
                                    <i className="mdi mdi-database-search me-2" />
                                    {props.t("Search")}
                                  </Button>
                                  <Button
                                    id="export-button"
                                    className="ms-3"
                                    color="primary"
                                    outline
                                    disabled={ongoingSearch || tickets.length == 0}
                                    onClick={() => {
                                      downloadExcel()
                                    }}
                                  >
                                    <i className="mdi mdi-download me-2" />
                                    {props.t("Download") + " .xlsx"}
                                  </Button>
                                </Col>
                                <Col>
                                  <div className="mt-2 float-end">
                                    <p>
                                      {filteredTickets.length}{" "}
                                      {props.t("results found")}
                                    </p>
                                  </div>
                                </Col>
                              </Row>

                              <Row className="mt-2">
                                <Col xl="12">
                                  {ongoingSearch == true ? (
                                    <div className="py-5 text-center">
                                      <p className="text-primary">
                                        <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                                        {props.t("Loading")}
                                      </p>
                                    </div>
                                  ) : (
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        id="tickets-table"
                                        keyField={"ticket_number"}
                                        responsive
                                        bordered={true}
                                        striped={true}
                                        hover={true}
                                        defaultSorted={defaultSorted}
                                        classes={
                                          "table align-middle table-nowrap"
                                        }
                                        headerWrapperClasses={"thead-light"}
                                        headerClasses="header-class"
                                        {...toolkitProps.baseProps}
                                        {...paginationTableProps}
                                      />
                                    </div>
                                  )}
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-3 mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <Col className="pagination justify-content-end inner-custom-pagination">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </Col>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  )}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </>
  )
}

SupportTicketsReport.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(SupportTicketsReport)
