import React, { useContext, useEffect, useRef, useState } from "react"
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Button,
  Form,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  FormFeedback,
  Tooltip,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Label,
  Alert,
  UncontrolledTooltip,
  Collapse,
  CardTitle,
  CardHeader,
  NavItem,
  NavLink,
  TabPane,
  TabContent,
} from "reactstrap"
import PropTypes from "prop-types"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import moment from "moment"
import Lottie from "react-lottie"
import loadingAnim from "common/animations/loading-dots-blue"
import searchAnim from "common/animations/finddata"
import { showToast } from "utils/general"
import SelectGroup from "components/DropdownList/SelectGroupFilters"
import TagsTree from "components/DropdownList/TagsTree"
import {
  addAdminRequest,
  approveAdminRequest,
  getAdminRequests,
  getAdminUsers,
  getAdminUsersTest,
} from "helpers/backend_helper"
import AdminUserDrawer from "./usermanagement-drawer"
import * as Yup from "yup"
import { useFormik } from "formik"
import "jspdf-autotable"
import { Table as AntdTable } from "antd"
import { downloadExcel, exportPDF } from "utils/general"
import AppUsersSelected from "pages/Services/app-users/app-users-selected"
import { Popconfirm, Switch } from "antd"
import GlobalState from "contexts/GlobalState"

const UserManagement = props => {
  const [state, setState] = useContext(GlobalState)
  const [userRole, setUserRole] = useState(null)
  const [group, setGroup] = useState(null)
  const [users, setUsers] = useState([])
  const [filteredUsers, setFilteredUsers] = useState([])
  const [requests, setRequests] = useState([])
  const [addModal, setAddModal] = useState(false)
  const [selectedRow, setSelectedRow] = useState(null)
  const [loading, setLoading] = useState(false)
  const [showDrawer, setShowDrawer] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [ongoingAdd, setOngoingAdd] = useState(false)
  const [showLinked, setShowLinked] = useState(true)
  const [updateCounter, setUpdateCounter] = useState(0)
  const [selectedUsers, setSelectedUsers] = useState([])
  const [selectedUsersObject, setSelectedUsersObject] = useState([])
  const [TTdownloadstatstable, setTTdownloadstatstable] = useState(false) // Tooltip state for download button
  const [menu, setMenu] = useState(false) //Dropdown button state
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [selectedTags, setSelectedTags] = useState([])
  const [title, setTitle] = useState(props.t("Admin user management"))
  const [selectedRowInfo, setSelectedRowInfo] = useState([])
  const [showOnSide, setShowOnSide] = useState(false)
  const [colSize, setColSize] = useState(12)
  const [activeTab, setActiveTab] = useState("1")
  const [paginationSizes, setPaginationSizes] = useState([
    10, 30, 50, 100, 200, 500,
  ])

  const abortRef = useRef(null)
  useEffect(() => {
    document.title = title + " | Suvanto Care"
    abortRef.current = new AbortController()

    if (state.authUser != null) {
      const obj = JSON.parse(state.authUser)
      let role = obj.activeRole ? obj.activeRole : obj.role
      setUserRole(role)
      /*if (
        role != "system_admin" &&
        (obj.access_permissions == null ||
          obj.access_permissions.alarm_monitoring != true)
      ) {
        navigate("/main", { replace: true })
      }*/
    }

    //getRequests()
    return () => abortRef.current.abort()
  }, [])

  useEffect(() => {
    getAdmins()
  }, [group, showLinked])

  // Search or filter changed
  useEffect(() => {
    handleSearch()
  }, [searchText, users])

  // Name tags state update from app-users-selected.js
  useEffect(() => {
    if (selectedUsers.length == 0) {
      setSelectedUsersObject([])
      start()
    }
  }, [selectedUsers])

  // Reset form when modal changed
  useEffect(() => {
    if (showOnSide == true) {
      setColSize(9)
    } else {
      setColSize(12)
    }
  }, [showOnSide])

  // Handle search function
  const handleSearch = () => {
    let result = users.filter(
      data =>
        data.name?.toLowerCase().includes(searchText) ||
        data.description?.toLowerCase().includes(searchText) ||
        data.groups?.toLowerCase().includes(searchText) ||
        data.gsm?.toLowerCase().includes(searchText) ||
        data.email?.toLowerCase().includes(searchText) ||
        data.fullName?.toLowerCase().includes(searchText)
    )
    setFilteredUsers(result)
  }

  // Get admin users for table
  const getAdmins = () => {
    setLoading(true)
    getAdminUsersTest(abortRef.current, group)
      .then(result => {
        //console.log("Admins: ", result.entries)
        if (result.statusCode == 200 && result.entries) {
          result.entries.sort((a, b) => (a.name > b.name ? 1 : -1))
          // Adding keys to for table
          const addKeys = result.entries.map(obj => {
            if (obj.key == undefined) {
              return { ...obj, key: obj.name }
            }
            return obj
          })
          setUsers(addKeys)
        }
      })
      .catch(err => {
        console.log(err)
        setUsers([])
      })
      .finally(() => {
        setLoading(false)
      })
  }

  // Get support request
  const getRequests = () => {
    //setLoading(true)
    getAdminRequests(abortRef.current)
      .then(result => {
        if (result.statusCode == 200 && result.entries) {
          result.entries.forEach(element => {
            switch (element.requestType) {
              case "CreateAdmin":
                element.requestDescription = "New user"
                break
              case "DeleteAdmin":
                element.requestDescription = "User deletion"
                break
            }
          })
          setRequests(result.entries)
        }
      })
      .catch(err => {
        console.log(err)
        setRequests([])
      })
      .finally(() => {
        //setLoading(false)
      })
  }

  // Admin approve request
  const approveRequest = key => {
    //setLoading(true)
    approveAdminRequest(abortRef.current, key)
      .then(result => {
        if (result.statusCode == 200) {
          getRequests()
          showToast(props.t("Success"), "success", 2000)
        } else {
          showToast(props.t("Error"), "error", 2000)
        }
      })
      .catch(err => {
        console.log(err)
        showToast(props.t("Error"), "error", 2000)
      })
      .finally(() => {
        //setLoading(false)
      })
  }

  // Create user validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      username: "",
    },
    validationSchema: Yup.object({
      username: Yup.string()
        .matches(
          /^[a-z0-9]+[a-z0-9-]*[a-z0-9]+[.]{1}[a-z0-9]+[a-z0-9-]*[a-z0-9]$/,
          props.t("Invalid format")
        )
        .min(7, props.t("Too short"))
        .required(props.t("Required field")),
    }),
    onSubmit: values => {
      console.log("Submit: ", values)
      addAdminRequest(abortRef.current, "CreateAdmin", values.username)
        .then(result => {
          if (result.statusCode == 200) {
            showToast(props.t("Request sent"), "success")
            setAddModal(false)
            getRequests()
          } else {
            showToast(props.t("Error"), "error")
          }
        })
        .catch(err => {
          console.log(err)
          showToast(props.t("Error"), "error")
        })
        .finally(() => {
          //setOngoingRequest(false)
        })
    },
  })

  // Empty selected users
  const start = () => {
    setLoading(true)
    setTimeout(() => {
      setSelectedRowKeys([])
      setLoading(false)
    }, 200)
  }

  // Export values
  const generateExcel = () => {
    const heading = [
      [
        props.t("Username"),
        props.t("Description"),
        props.t("Basic user"),
        props.t("Groups"),
        props.t("Linked"),
        props.t("Email"),
        props.t("GSM"),
        props.t("Connected"),
      ],
    ]
    const data = filteredUsers.map(elt => [
      elt.name,
      elt.description,
      elt.basicUser,
      elt.groups,
      elt.linked,
      elt.email,
      elt.gsm,
      dateFormatter(elt.lastConnection),
    ])

    downloadExcel(title, heading, data)
    showToast(props.t("Excel file exported succesfully!"), "success")
  }

  const generatePdf = () => {
    const heading = [
      props.t("Username"),
      props.t("Description"),
      props.t("Basic user"),
      props.t("Groups"),
      props.t("Linked"),
      props.t("Email"),
      props.t("GSM"),
      props.t("Connected"),
    ]
    const data = filteredUsers.map(elt => [
      elt.name,
      elt.description,
      elt.basicUser,
      elt.groups,
      elt.linked,
      elt.email,
      elt.gsm,
      dateFormatter(elt.lastConnection),
    ])

    exportPDF(title, heading, data)
    showToast(props.t("PDF file exported succesfully!"), "success")
  }

  // Table structure
  const columns = [
    {
      title: props.t("Actions"),
      dataIndex: "actions",
      align: "center",
      render: editFormatter,
      fixed: "left",
      width: "100px",
      ellipsis: true,
    },
    {
      title: props.t("Username"),
      dataIndex: "name",
      render: usernameFormatter,
      sorter: (a, b) => {
        if (a.name < b.name) {
          return -1
        }
        if (a.name > b.name) {
          return 1
        }
        return 0
      },
      width: "200px",
      ellipsis: true,
    },
    {
      title: props.t("Name"),
      dataIndex: "fullName",
      sorter: (a, b) => {
        if (a.fullName < b.fullName) {
          return -1
        }
        if (a.fullName > b.fullName) {
          return 1
        }
        return 0
      },
      width: "200px",
      ellipsis: true,
    },
    {
      title: props.t("Description"),
      dataIndex: "description",
      width: "350px",
      ellipsis: true,
    },
    {
      title: props.t("Basic user"),
      dataIndex: "basicUser",
      sorter: (a, b) => a.basicUser - b.basicUser,
      render: basicFormatter,
      align: "center",
      width: "150px",
      ellipsis: true,
    },
    {
      title: props.t("Groups"),
      dataIndex: "groups",
      sorter: (a, b) => {
        if (a.groups < b.groups) {
          return -1
        }
        if (a.groups > b.groups) {
          return 1
        }
        return 0
      },
      width: "150px",
      ellipsis: true,
    },
    {
      title: props.t("Email"),
      dataIndex: "email",
      sorter: (a, b) => {
        if (a.email < b.email) {
          return -1
        }
        if (a.email > b.email) {
          return 1
        }
        return 0
      },
      width: "250px",
      ellipsis: true,
    },
    {
      title: props.t("GSM"),
      dataIndex: "gsm",
      sorter: (a, b) => a.gsm - b.gsm,
      width: "150px",
      ellipsis: true,
    },
    {
      title: props.t("Connected"),
      dataIndex: "lastConnection",
      sorter: (a, b) => a.lastConnection - b.lastConnection,
      render: dateFormatter,
      width: "160px",
      ellipsis: true,
    },
  ]

  const columns2 = [
    {
      dataIndex: "status",
      title: props.t("Status"),
      width: "160px",
      ellipsis: true,
      render: (cell, row) => {
        switch (cell) {
          case "open":
            if (userRole == "system_admin")
              return (
                <>
                  <i
                    style={{ verticalAlign: "bottom" }}
                    className="bx bxs-calendar-heart font-size-20 text-warning me-2"
                  />
                  <span>{props.t("Pending")}</span>
                  <Button
                    size="sm"
                    color="primary"
                    className="ms-2"
                    onClick={() => {
                      approveRequest(row.key)
                    }}
                  >
                    {props.t("Approve")}
                  </Button>
                </>
              )
            else
              return (
                <>
                  <i
                    style={{ verticalAlign: "bottom" }}
                    className="bx bxs-calendar-heart font-size-20 text-warning me-2"
                  />
                  <span>{props.t("Pending")}</span>
                </>
              )
          case "resolved":
            return (
              <>
                <i
                  style={{ verticalAlign: "bottom" }}
                  className="bx bx-check-circle font-size-20 text-success me-2"
                />
                <span>{props.t("Resolved")}</span>
              </>
            )
          default:
            return cell
        }
      },
    },
    {
      dataIndex: "requestDescription",
      title: props.t("Type"),
      width: "160px",
      ellipsis: true,
      render: cell => {
        return props.t(cell)
      },
    },
    {
      dataIndex: "targetUser",
      title: props.t("Username"),
      width: "160px",
      ellipsis: true,
    },
    {
      dataIndex: "timestamp",
      title: props.t("Updated"),
      width: "160px",
      ellipsis: true,
      render: dateFormatter,
    },
  ]

  // Table formatting functions start
  function editFormatter(cell, row) {
    return (
      <>
        <Button
          color="primary"
          outline
          size="sm"
          onClick={() => {
            setShowDrawer(true)
            setSelectedRow(row)
          }}
        >
          <i className="mdi mdi-pencil" />
        </Button>
      </>
    )
  }

  function usernameFormatter(cell, row) {
    return (
      <>
        <span>{row.name}</span>
        {row.videoRoomId && (
          <i className="ms-2 mdi mdi-account-voice text-primary" />
        )}
      </>
    )
  }

  function linkedFormatter(cell, row) {
    if (row.linked == true) {
      return <Input type="checkbox" disabled defaultChecked={true}></Input>
    } else return <></>
  }

  function basicFormatter(cell, row) {
    if (row.basicUser == true) {
      return <i className="mdi mdi-check-bold text-primary"></i>
    } else return <>{/* <i className="mdi mdi-close text-light"></i> */}</>
  }

  function dateFormatter(value) {
    if (!value) return ""
    var dateString = moment(value).format("DD.MM.YYYY HH:mm:ss")
    return dateString
  }

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title={title} />
          <Row className="mb-4">
            <Col xs="4">
              <div className="d-inline-block" style={{ width: "100%" }}>
                <TagsTree
                  loadData={true}
                  expanded={true}
                  selectionChanged={setSelectedTags}
                />
              </div>
            </Col>
            <Col xs="4">
              <SelectGroup
                onChange={setGroup}
                selectedTags={selectedTags}
                updateCounter={updateCounter}
              />
            </Col>
          </Row>

          <div>
            <Row>
              <Col sm={colSize}>
                <Card>
                  <CardHeader className="bg-transparent border-bottom">
                    <div className="d-flex flex-wrap">
                      <ul
                        className="nav nav-tabs nav-tabs-custom card-header-tabs"
                        role="tablist"
                      >
                        <NavItem>
                          <NavLink
                            to="#"
                            active={activeTab === "1"}
                            onClick={() => {
                              setActiveTab("1")
                            }}
                          >
                            {props.t("Users")}
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            active={activeTab === "2"}
                            onClick={() => {
                              setActiveTab("2")
                            }}
                          >
                            {props.t("Muutospyynnöt")}
                          </NavLink>
                        </NavItem>
                      </ul>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <TabContent activeTab={activeTab}>
                      <TabPane tabId="1">
                        {(loading || !users || users.length == 0) && (
                          <div className="text-center my-5">
                            <Lottie
                              options={{
                                loop: false,
                                autoplay: true,
                                animationData: searchAnim,
                                rendererSettings: {
                                  preserveAspectRatio: "xMidYMid slice",
                                },
                              }}
                              height={150}
                              width={180}
                            ></Lottie>
                          </div>
                        )}

                        {!loading && users != null && users.length > 0 && (
                          <React.Fragment>
                            <Row>
                              <Col className="col-auto pe-0">
                                <Form
                                  className="mt-sm-0 d-flex align-items-center"
                                  onSubmit={e => {
                                    e.preventDefault()
                                    return false
                                  }}
                                >
                                  <div className="search-box me-2 mb-2 d-inline-block float-end">
                                    <div className="position-relative">
                                      <Input
                                        type="text"
                                        onChange={event =>
                                          setSearchText(
                                            event.target.value.toLowerCase()
                                          )
                                        }
                                        placeholder={props.t("Search")}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Form>
                              </Col>
                              <Col className="col-auto">
                                  <Button
                                    style={{ borderRadius: "100px" }}
                                    color="success"
                                    onClick={() => {
                                      setAddModal(true)
                                    }}
                                  >
                                    <i className="fas fa-user-plus"></i>
                                    <span className="d-none d-lg-inline-block ms-2">
                                      {props.t("Create a new user")}
                                    </span>
                                  </Button>
                                </Col>
                              <Col>
                                <span className="float-end ms-2">
                                  <Tooltip
                                    placement="bottom"
                                    isOpen={TTdownloadstatstable}
                                    target="downloadstatstable"
                                    toggle={() => {
                                      setTTdownloadstatstable(
                                        !TTdownloadstatstable
                                      )
                                    }}
                                  >
                                    {props.t("Download statistics")}
                                  </Tooltip>
                                  <Dropdown
                                    id="printButtonServicesStats"
                                    isOpen={menu}
                                    toggle={() => setMenu(!menu)}
                                    className="download-btn text-center"
                                    type="button"
                                  >
                                    <DropdownToggle
                                      id="downloadstatstable"
                                      className="text-muted"
                                      tag="i"
                                    >
                                      <i className="mdi mdi-download" />
                                    </DropdownToggle>
                                    <DropdownMenu>
                                      {/* TODO: Can not choose directory file right now */}
                                      <DropdownItem
                                        onClick={() => generateExcel()}
                                      >
                                        {props.t("Download .xlsx")}
                                      </DropdownItem>
                                      <DropdownItem
                                        onClick={() => generatePdf()}
                                      >
                                        {props.t("Download .pdf")}
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </Dropdown>
                                </span>
                                <span className="mt-2 float-end">
                                  <p>
                                    {filteredUsers.length} {props.t("users")}
                                  </p>
                                </span>
                              </Col>
                            </Row>
                            <Collapse
                              isOpen={
                                selectedUsers.length > 0 && showOnSide == false
                              }
                            >
                              <Row className="py-2">
                                <AppUsersSelected
                                  setSelectedUsers={setSelectedUsers}
                                  setSelectedUsersObject={
                                    setSelectedUsersObject
                                  }
                                  selectedUsersObject={selectedUsersObject}
                                  showOnSide={showOnSide}
                                ></AppUsersSelected>
                                <Row>
                                  <Col className="col-auto">
                                    <Button
                                      color="success"
                                      disabled={
                                        selectedUsers.length == 0 || loading
                                      }
                                      onClick={() => {
                                        linkAdmins()
                                      }}
                                    >
                                      <i className="mdi mdi-link-variant-plus me-2" />
                                      {props.t("Link selected users")}
                                    </Button>
                                    <Button
                                      color="danger"
                                      className="ms-3"
                                      disabled={
                                        selectedUsers.length == 0 || loading
                                      }
                                      onClick={() => {
                                        unlinkAdmins()
                                      }}
                                    >
                                      <i className="mdi mdi-link-variant-off me-2" />
                                      {props.t("Unlink selected users")}
                                    </Button>
                                  </Col>
                                </Row>
                              </Row>
                            </Collapse>

                            {!loading && (
                              <>
                                <div className="mt-2">
                                  <AntdTable
                                    className="striped"
                                    size="middle"
                                    sticky
                                    rowKey={"name"}
                                    columns={columns}
                                    dataSource={filteredUsers}
                                    pagination={{
                                      showSizeChanger: true,
                                      defaultPageSize: 100,
                                      pageSizeOptions: paginationSizes,
                                    }}
                                    tableLayout="auto"
                                    scroll={{
                                      x: "max-content",
                                      y: "65vh",
                                    }}
                                  />
                                </div>
                              </>
                            )}
                            {loading && (
                              <Lottie
                                options={{
                                  loop: false,
                                  autoplay: true,
                                  animationData: loadingAnim,
                                  rendererSettings: {
                                    preserveAspectRatio: "xMidYMid slice",
                                  },
                                }}
                                height={100}
                                width={100}
                              ></Lottie>
                            )}
                          </React.Fragment>
                        )}
                      </TabPane>
                      <TabPane tabId="2">
                        <AntdTable
                          className="clear-header"
                          size="small"
                          sticky
                          rowKey={"key"}
                          columns={columns2}
                          dataSource={requests}
                          pagination={{
                            showSizeChanger: true,
                            defaultPageSize: 500,
                            pageSizeOptions: paginationSizes,
                          }}
                          tableLayout="auto"
                          scroll={{
                            x: "max-content",
                            y: "65vh",
                          }}
                        />
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>{" "}
            </Row>
          </div>

          {/**  User add modal */}
          <Modal
            isOpen={addModal}
            toggle={() => {
              setAddModal(!addModal)
            }}
            centered
          >
            <ModalHeader>{props.t("Create a new user")}</ModalHeader>
            <Form
              className="form-horizontal"
              onSubmit={e => {
                e.preventDefault()
                return false
              }}
            >
              <ModalBody>
                <Alert color={"primary"}>
                  <span>
                    {props.t("User will be linked to currently selected group")}
                  </span>
                </Alert>
                <Alert color={"primary"}>
                  <span>{props.t("UserAllowedChars")}</span>
                </Alert>
                <FormGroup>
                  <Label>{props.t("Username")}</Label>
                  <Input
                    name="username"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    placeholder={"E.g. firstname.lastname"}
                    value={validation.values.username || ""}
                    invalid={
                      validation.touched.username && validation.errors.username
                        ? true
                        : false
                    }
                  ></Input>
                  {validation.touched.username && validation.errors.username ? (
                    <FormFeedback type="invalid">
                      {validation.errors.username}
                    </FormFeedback>
                  ) : null}
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="primary"
                  outline
                  onClick={() => {
                    setAddModal(false)
                  }}
                >
                  {props.t("Cancel")}
                </Button>
                <Button
                  color="success"
                  disabled={ongoingAdd}
                  onClick={() => {
                    validation.submitForm()
                  }}
                >
                  {props.t("Add")}
                </Button>{" "}
              </ModalFooter>
            </Form>
          </Modal>

          <AdminUserDrawer
            showDrawer={showDrawer}
            setShowDrawer={setShowDrawer}
            selectedRow={selectedRow}
            getAdmins={getAdmins}
          />
        </Container>
      </div>
    </>
  )
}

UserManagement.propTypes = {
  t: PropTypes.any,
  selectionChanged: PropTypes.func,
}

export default withTranslation()(UserManagement)
