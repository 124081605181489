import React, { useEffect, useRef, useState } from "react"
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Form,
  Input,
  Button,
  UncontrolledTooltip,
  Tooltip,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ModalBody,
  ModalFooter,
  Modal,
} from "reactstrap"
import PropTypes from "prop-types"
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import { Route } from "react-router-dom"
import { withTranslation } from "react-i18next"
import moment from "moment"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import "../../datatables.scss"
import { getSystemLogs } from "helpers/backend_helper"
import CustomRangePicker from "components/DatePickers/RangePicker"
import withRouter from "components/Common/withRouter"
import { downloadExcel, exportPDF } from "utils/general"
import { showToast } from "utils/general"

const SystemLogs = props => {
  const [logs, setLogs] = useState([])
  const [filteredLogs, setFilteredLogs] = useState([])
  const [ongoingSearch, setOngoingSearch] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [dateRange, setDateRange] = useState([
    moment().subtract(1, "days"),
    moment(),
  ])
  const [logType, setLogType] = useState("script")
  const [maxItems, setMaxItems] = useState("500")
  const [currentPage, setCurrentPage] = useState(1)
  const [showModal, setShowModal] = useState(false)
  const [selectedMessage, setSelectedMessage] = useState(null)
  const [title, setTitle] = useState(props.t("System logs"))
  const [menu, setMenu] = useState(false) //Dropdown button state

  const abortRef = useRef(null)
  useEffect(() => {
    document.title = title + " | Suvanto Care"
    abortRef.current = new AbortController()
    return () => abortRef.current.abort()
  }, [])

  useEffect(() => {
    if (logType && dateRange) getLogs()
  }, [logType, dateRange, maxItems])

  // Get logs
  const getLogs = () => {
    if (!ongoingSearch) {
      setOngoingSearch(true)
      let rows = isNaN(maxItems) == false ? parseInt(maxItems, 10) : 500
      getSystemLogs(
        abortRef.current,
        logType,
        dateRange[0].valueOf(),
        dateRange[1].valueOf(),
        rows
      )
        .then(data => {
          let counter = 1
          if (data.logs != null) {
            data.logs.forEach(log => {
              log.id = counter++
            })
          }
          setLogs(data.logs)
          setOngoingSearch(false)
        })
        .catch(e => {
          console.log(e)
          setOngoingSearch(false)
        })
    }
  }

  // Search or filter changed
  useEffect(() => {
    setCurrentPage(1)
    handleSearch()
  }, [searchText, logs])

  // Handle search function
  const handleSearch = () => {
    let result = logs.filter(
      data =>
        data.content?.toLowerCase().includes(searchText) ||
        data.user?.toLowerCase().includes(searchText)
    )
    setFilteredLogs(result)
  }

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  const pageOptions = {
    custom: true,
    pageStartIndex: 1,
    totalSize: filteredLogs.length,
    page: currentPage,
    sizePerPageList: [
      { text: "10", value: 10 },
      { text: "50", value: 50 },
      { text: "100", value: 100 },
      { text: props.t("All"), value: filteredLogs?.length || 0 },
    ],
    onPageChange: (page, sizePerPage) => {
      setCurrentPage(page)
    },
    onSizePerPageChange: (sizePerPage, page) => {
      setCurrentPage(1)
    },
  }

  const columns = [
    {
      dataField: "timestamp",
      text: props.t("Time"),
      sort: true,
      formatter: dateFormatter,
    },
    {
      dataField: "level",
      text: props.t("Level"),
      sort: true,
      formatter: levelFormatter,
    },
    {
      dataField: "content",
      text: props.t("Content"),
      sort: true,
      formatter: contentFormatter,
    },
    {
      dataField: "user",
      text: props.t("User"),
      sort: true,
    },
    {
      dataField: "origin",
      text: props.t("Origin"),
      sort: true,
    },
    {
      dataField: "thread",
      text: props.t("Thread"),
      sort: true,
    },
  ]

  function dateFormatter(value) {
    if (!value) return ""
    var dateString = moment(value).format("DD.MM.YYYY HH:mm:ss")
    return dateString
  }

  function contentFormatter(cell, row) {
    return (
      <>
        <Button
          color="clear"
          className="me-2"
          outline
          size="sm"
          onClick={() => {
            setSelectedMessage(row.content)
            setShowModal(true)
          }}
        >
          <i className="mdi mdi-arrow-expand text-primary" />
        </Button>
        <span>{row.content}</span>
      </>
    )
    if (!value) return ""
    var dateString = moment(value).format("DD.MM.YYYY HH:mm:ss")
    return dateString
  }

  function levelFormatter(cell, row) {
    if (row.level == "DEBUG") {
      return (
        <span className="text-success">
          <i className="mdi mdi-bug me-1" />
          {row.level}
        </span>
      )
    } else if (row.level == "INFO") {
      return (
        <span className="text-primary">
          <i className="mdi mdi-information me-1" />
          {row.level}
        </span>
      )
    } else if (row.level == "WARN") {
      return (
        <span className="text-warning">
          <i className="mdi mdi-alert me-1" />
          {row.level}
        </span>
      )
    } else if (row.level == "ERROR") {
      return (
        <span className="text-danger">
          <i className="mdi mdi-alert me-1" />
          {row.level}
        </span>
      )
    }
  }

  const onDateSelection = value => {
    setDateRange(value)
  }

  // Export values
  const generateExcel = () => {
    const heading = [
      [
        props.t("Time"),
        props.t("Level"),
        props.t("Content"),
        props.t("User"),
        props.t("Origin"),
        props.t("Thread"),
      ],
    ]
    const data = filteredLogs.map(elt => [
      dateFormatter(elt.timestamp),
      elt.level,
      elt.content,
      elt.user,
      elt.origin,
      elt.thread,
    ])

    downloadExcel(title, heading, data)
    showToast(props.t("Excel file exported succesfully!"), "success")
  }

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title={props.t("System logs")} />

          <Card>
            <CardBody>
              <Row>
                <Col>
                  {logs != null && (
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          columns={columns}
                          data={filteredLogs}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-1">
                                {" "}
                                <Col className="col-auto pe-0">
                                  <Form
                                    className="mt-sm-0 d-flex align-items-center"
                                    onSubmit={e => {
                                      e.preventDefault()
                                      return false
                                    }}
                                  >
                                    <div className="search-box me-2 mb-2 d-inline-block float-end">
                                      <div className="position-relative">
                                        <Input
                                          type="text"
                                          onChange={event =>
                                            setSearchText(
                                              event.target.value.toLowerCase()
                                            )
                                          }
                                          placeholder={props.t("Search")}
                                        />
                                        <i className="bx bx-search-alt search-icon" />
                                      </div>
                                    </div>
                                  </Form>
                                </Col>
                                <Col className="col-auto">
                                  <Input
                                    type="select"
                                    name="logType"
                                    value={logType}
                                    onChange={v => {
                                      setLogType(v.target.value)
                                    }}
                                  >
                                    <option value="script">
                                      {props.t("Script")}
                                    </option>
                                    <option value="application">
                                      {props.t("Application")}
                                    </option>
                                    <option value="security">
                                      {props.t("Security")}
                                    </option>
                                  </Input>
                                </Col>
                                <Col className="col-auto">
                                  <Input
                                    style={{ width: "80px" }}
                                    type="number"
                                    name="maxItems"
                                    value={maxItems}
                                    onChange={v => {
                                      setMaxItems(v.target.value)
                                    }}
                                  />
                                </Col>
                                <Col>
                                  <CustomRangePicker
                                    value={dateRange}
                                    rangeChanged={onDateSelection}
                                    showTime={true}
                                    allowClear={false}
                                  />
                                </Col>
                                <Col className="col-auto">
                                  <Button
                                    className="square-icon-button-sm"
                                    id="calendar-button"
                                    color="primary"
                                    disabled={ongoingSearch}
                                    onClick={() => {
                                      setDateRange([dateRange[0], moment()])
                                    }}
                                  >
                                    <UncontrolledTooltip
                                      placement="bottom"
                                      target="calendar-button"
                                    >
                                      {props.t("Refresh time")}
                                    </UncontrolledTooltip>
                                    <i className="mdi mdi-calendar-refresh" />
                                  </Button>
                                </Col>
                                <Col>
                                  <span className="float-end ms-2">
                                    <UncontrolledTooltip
                                      placement="bottom"
                                      target="downloadstatstable"
                                    >
                                      {props.t("Download")}
                                    </UncontrolledTooltip>
                                    <Dropdown
                                      id="printButtonServicesStats"
                                      isOpen={menu}
                                      toggle={() => setMenu(!menu)}
                                      className="download-btn text-center"
                                      type="button"
                                    >
                                      <DropdownToggle
                                        id="downloadstatstable"
                                        className="text-muted"
                                        tag="i"
                                      >
                                        <i className="mdi mdi-download" />
                                      </DropdownToggle>
                                      <DropdownMenu>
                                        {/* TODO: Can not choose directory file right now */}
                                        <DropdownItem
                                          onClick={() => generateExcel()}
                                        >
                                          {props.t("Download .xlsx")}
                                        </DropdownItem>
                                      </DropdownMenu>
                                    </Dropdown>
                                  </span>
                                  <div className="mt-2 float-end">
                                    <p>
                                      {filteredLogs.length}{" "}
                                      {props.t("results found")}
                                    </p>
                                  </div>
                                </Col>
                              </Row>

                              <Row className="mt-2">
                                <Col xl="12">
                                  {ongoingSearch == true ? (
                                    <div className="py-5 text-center">
                                      <p className="text-primary">
                                        <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                                        {props.t("Loading")}
                                      </p>
                                    </div>
                                  ) : (
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        id="logs-table"
                                        keyField={"id"}
                                        responsive
                                        bordered={true}
                                        striped={true}
                                        hover={true}
                                        defaultSorted={defaultSorted}
                                        classes={
                                          "table align-middle table-nowrap"
                                        }
                                        headerWrapperClasses={"thead-light"}
                                        headerClasses="header-class"
                                        {...toolkitProps.baseProps}
                                        {...paginationTableProps}
                                      />
                                    </div>
                                  )}
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-3 mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <Col className="pagination justify-content-end inner-custom-pagination">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </Col>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  )}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>

      <Modal
        isOpen={showModal}
        toggle={() => {
          setShowModal(!showModal)
        }}
        centered
      >
        <ModalBody>
          <Input
            type="textarea"
            rows={20}
            disabled
            value={selectedMessage}
          ></Input>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              setShowModal(false)
            }}
          >
            {props.t("Close")}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

SystemLogs.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(SystemLogs)
