import React, { useEffect, useRef, useState } from "react"
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Label,
  ListGroup,
  ListGroupItem,
  Row,
} from "reactstrap"
import PropTypes from "prop-types"
import { Route } from "react-router-dom"
import { withTranslation } from "react-i18next"
import { Drawer } from "antd"
import { resetDose, syncDose, updateDose } from "helpers/backend_helper"
import * as Yup from "yup"
import { useFormik } from "formik"
import SelectOrder from "components/DropdownList/SelectOrder"
import { showToast } from "utils/general"
import withRouter from "components/Common/withRouter"

const DoseDrawer = props => {
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const [update, setUpdate] = useState(false)
  const [ongoingSync, setOngoingSync] = useState(false)
  const [ongoingReset, setOngoingReset] = useState(false)
  const [ongoingDelete, setOngoingDelete] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)

  const abortRef = useRef(null)
  useEffect(() => {
    abortRef.current = new AbortController()
    return () => abortRef.current.abort()
  }, [])

  // Update client validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      orderNumber: props.selectedRow?.orderNumber || "",
    },
    validationSchema: Yup.object({}),
    onSubmit: values => {
      console.log("Submit values: ", values)
      setSuccess("")
      setError("")
      setUpdate(true)

      values.orderNumber = values.orderNumber ? values.orderNumber : ""

      updateDose(abortRef.current, props.selectedRow?.deviceId, values)
        .then(result => {
          setUpdate(false)
          if (result.statusCode == 200) {
            setSuccess("Updated")
            props.getDevices()
          } else {
            setError("Error")
          }
        })
        .catch(err => {
          console.log(err)
          setUpdate(false)
          setError("Error")
        })
    },
  })

  // Sync configuration
  const syncConfiguration = () => {
    setOngoingSync(true)
    syncDose(abortRef.current, props.selectedRow?.deviceId)
      .then(result => {
        setOngoingSync(false)
        if (result.statusCode == 200) {
          props.getDevices()
          showToast(props.t("Success"), "success")
        } else {
          showToast(props.t("Error"), "error")
        }
      })
      .catch(err => {
        setOngoingSync(false)
        showToast(props.t("Error"), "error")
      })
  }

  // Reset Dose
  const resetDevice = () => {
    setOngoingReset(true)
    resetDose(abortRef.current, props.selectedRow?.deviceId)
      .then(result => {
        setOngoingReset(false)
        if (result.statusCode == 200) {
          props.getDevices()
          showToast(props.t("Success"), "success")
        } else {
          showToast(props.t("Error"), "error")
        }
      })
      .catch(err => {
        setOngoingReset(false)
        showToast(props.t("Error"), "error")
      })
  }

  // Selected order changed
  const orderSelectionChanged = value => {
    validation.setFieldValue("orderNumber", value)
  }

  return (
    <>
      <Drawer
        title={props.t("Medicine reminder") + " " + props.selectedRow?.deviceId}
        width={500}
        placement="right"
        onClose={() => {
          props.setShowDrawer(false)
          setError("")
          setSuccess("")
        }}
        open={props.showDrawer}
        zIndex={1003}
        bodyStyle={{ backgroundColor: "#f8f8f8" }}
      >
        <Card>
          <CardBody>
            <CardTitle className="mb-4">{props.t("Settings")}</CardTitle>
            <Form
              className="form-horizontal"
              onSubmit={e => {
                e.preventDefault()
                return false
              }}
            >
              <Row>
                <Col>
                  <FormGroup>
                    <Label>{props.t("Order number")}</Label>
                    <SelectOrder
                      selection={validation.values.orderNumber}
                      setSelectedOrder={orderSelectionChanged}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <div className="mt-2 mb-3 d-flex justify-content-center">
                <Button
                  color="primary"
                  disabled={update}
                  style={{ minWidth: "150px" }}
                  onClick={() => {validation.submitForm()}}
                >
                  {props.t("Save")}
                </Button>
              </div>
              {error ? <Alert color="danger">{props.t(error)}</Alert> : null}
              {success ? (
                <Alert color="success">{props.t(success)}</Alert>
              ) : null}
            </Form>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <CardTitle className="mb-4">{props.t("Actions")}</CardTitle>
            <ListGroup className="list-group-flush">
              <ListGroupItem className="pb-4">
                <Row>
                  <Col>
                    <div>
                      <h6>{props.t("Sync configuration")}</h6>
                      <span className="text-muted">
                        {props.t("description")}
                      </span>
                    </div>
                  </Col>
                  <Col className="col-auto">
                    <Button
                      color="primary"
                      style={{ minWidth: "100px" }}
                      disabled={ongoingSync}
                      onClick={() => {
                        syncConfiguration()
                      }}
                    >
                      {props.t("Send")}
                    </Button>
                  </Col>
                </Row>
              </ListGroupItem>
              <ListGroupItem className="pb-4">
                <Row>
                  <Col>
                    <div>
                      <h6>{props.t("Reset device")}</h6>
                      <span className="text-muted">{props.t("")}</span>
                    </div>
                  </Col>
                  <Col className="col-auto">
                    <Button
                      color="danger"
                      style={{ minWidth: "100px" }}
                      disabled={ongoingReset}
                      onClick={() => {
                        resetDevice()
                      }}
                    >
                      {props.t("Send")}
                    </Button>
                  </Col>
                </Row>
              </ListGroupItem>
            </ListGroup>
          </CardBody>
        </Card>
      </Drawer>
    </>
  )
}

DoseDrawer.propTypes = {
  t: PropTypes.any,
  showDrawer: PropTypes.bool,
  setShowDrawer: PropTypes.any,
  selectedRow: PropTypes.any,
  getDevices: PropTypes.func,
}

export default withRouter(withTranslation()(DoseDrawer))
