import React, { useContext, useEffect, useRef, useState } from "react"
import {
  Card,
  CardBody,
  Row,
  Col,
  Button,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Dropdown,
  DropdownToggle,
  FormGroup,
  FormFeedback,
  DropdownMenu,
  DropdownItem,
  Label,
  Collapse,
  CardTitle,
  UncontrolledTooltip,
} from "reactstrap"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import moment from "moment"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import Lottie from "react-lottie"
import loadingAnim from "common/animations/loading-dots-blue"
import { showToast } from "utils/general"
import {
  createApplicationUser,
  deleteApplicationUsers,
  getApplicationUsers,
  linkAppUsersToClient,
  unlinkAppUsersFromClient,
  getAvailableTags,
} from "helpers/backend_helper"
import { Popconfirm, Switch, Input } from "antd"
import * as Yup from "yup"
import { useFormik } from "formik"
import AppUserDrawer from "./app-user-drawer"
import PubSub from "pubsub-js"
import SelectGroup from "components/DropdownList/SelectGroupFilters"
import GlobalState from "contexts/GlobalState"
import XLSX from "xlsx"
import TagsTree from "components/DropdownList/TagsTree"
import AppUsersSelected from "./app-users-selected"

const AppUsersConfig = props => {
  const [state, setState] = useContext(GlobalState)
  const [userRole, setUserRole] = useState(null)
  const [group, setGroup] = useState(null)
  const [users, setUsers] = useState([])
  const [filteredUsers, setFilteredUsers] = useState([])
  const [addModal, setAddModal] = useState(false)
  const [selectedRow, setSelectedRow] = useState(null)
  const [loading, setLoading] = useState(false)
  const [showDrawer, setShowDrawer] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [ongoingAction, setOngoingAction] = useState(false)
  const [selectedUsers, setSelectedUsers] = useState([])
  const [selectedUsersObject, setSelectedUsersObject] = useState([])
  const [deleteConfirm, setDeleteConfirm] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [menu, setMenu] = useState(false) //Dropdown button state
  const [selectedTags, setSelectedTags] = useState([])
  const [showOnSide, setShowOnSide] = useState(false)
  const [colSize, setColSize] = useState(12)

  const abortRef = useRef(null)
  useEffect(() => {
    document.title = props.t("Customer management") + " | Suvanto Care"
    abortRef.current = new AbortController()
    if (state.authUser != null) {
      const obj = JSON.parse(state.authUser)
      let role = obj.activeRole ? obj.activeRole : obj.role
      setUserRole(role)
    }
    const token = PubSub.subscribe("page-refresh", (event, data) => {
      console.log("refresh", event, data)
      setGroup(props.group)
    })
    return () => {
      abortRef.current.abort()
      PubSub.unsubscribe(token)
    }
  }, [])

  useEffect(() => {
    if (props.group) setGroup(props.group)
  }, [props.group])

  useEffect(() => {
    if (group != null) getAppUsers()
  }, [group])

  // Search or filter changed
  useEffect(() => {
    setCurrentPage(1)
    handleSearch()
  }, [searchText, users])

  // Reset form when modal changed
  useEffect(() => {
    addValidation.resetForm()
  }, [addModal])

  // Name tags state update from app-users-selected.js
  useEffect(() => {
    console.log("Selected users updated", selectedUsersObject, selectedUsers)
    if (selectedUsers.length == 0) {
      setSelectedUsersObject([])
    }
  }, [selectedUsers])
  // Showing selected users on own card
  // Reset form when modal changed
  useEffect(() => {
    if (showOnSide == true) {
      setColSize(9)
    } else {
      setColSize(12)
    }
  }, [showOnSide])

  // Get app users for table
  const getAppUsers = () => {
    if (props.suvantoKey) {
      /*console.log(
        "Get users",
        group,
        "test",
        abortRef.current,
        props.suvantoKey,
      )*/
      setLoading(true)
      getApplicationUsers(abortRef.current, props.suvantoKey, group, true)
        .then(result => {
          console.log("Entries: ", result)
          if (result.statusCode == 200 && result.entries) {
            result.entries.sort((a, b) => (a.name < b.name ? 1 : -1)) // Name sort
            result.entries.sort((a, b) => (a.relative > b.relative ? 1 : -1)) // Relatives at the end

            result.entries.forEach(element => {
              element.role = props.t(element.role)
            })
            setUsers(result.entries)
          }
        })
        .catch(err => {
          console.log(err)
          setUsers([])
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  // Link selected users to client
  const linkUsers = () => {
    if (props.suvantoKey && !ongoingAction && selectedUsers.length > 0) {
      setOngoingAction(true)
      linkAppUsersToClient(abortRef.current, props.suvantoKey, selectedUsers)
        .then(result => {
          if (result.statusCode == 200) {
            showToast(props.t("Success"), "success")
            setSelectedUsers([])
            getAppUsers()
          } else {
            showToast(props.t("Error"), "error")
          }
        })
        .catch(err => {
          console.log(err)
          showToast(props.t("Error"), "error")
        })
        .finally(() => {
          setOngoingAction(false)
        })
    }
  }

  // Unlink selected users from client
  const unlinkUsers = () => {
    if (props.suvantoKey && !ongoingAction && selectedUsers.length > 0) {
      setOngoingAction(true)
      unlinkAppUsersFromClient(
        abortRef.current,
        props.suvantoKey,
        selectedUsers
      )
        .then(result => {
          if (result.statusCode == 200) {
            showToast(props.t("Success"), "success")
            setSelectedUsers([])
            getAppUsers()
          } else {
            showToast(props.t("Error"), "error")
          }
        })
        .catch(err => {
          console.log(err)
          showToast(props.t("Error"), "error")
        })
        .finally(() => {
          setOngoingAction(false)
        })
    }
  }

  // Delete selected users
  const deleteUsers = () => {
    if (!ongoingAction && selectedUsers.length > 0) {
      setOngoingAction(true)
      deleteApplicationUsers(abortRef.current, selectedUsers)
        .then(result => {
          if (result.statusCode == 200) {
            if (result.failed && result.failed.length > 0) {
              showToast(
                props.t(
                  "Unable to delete user, check and remove any existing links"
                ),
                "error",
                15000
              )
            } else {
              showToast(props.t("Deleted"), "success")
              setSelectedUsers([])
              getAppUsers()
            }
          } else {
            showToast(props.t("Error"), "error")
          }
        })
        .catch(err => {
          console.log(err)
          showToast(props.t("Error"), "error")
        })
        .finally(() => {
          setOngoingAction(false)
        })
    }
  }

  // Create user validation
  const addValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      username: "",
      name: "",
      group: props.group,
      linkToCurrent: true,
    },
    validationSchema: Yup.object({
      name: Yup.string().required(props.t("Required field")),
      username: Yup.string()
        .matches(
          /^[a-z0-9]+[a-z0-9-]*[a-z0-9]+[.]{1}[a-z0-9]+[a-z0-9-]*[a-z0-9]$/,
          props.t("UserAllowedChars")
        )
        .min(7, props.t("Too short"))
        .required(props.t("Required field")),
    }),
    onSubmit: values => {
      console.log("Submit: ", values)
      if (!ongoingAction) {
        let clientKey = null
        if (values.linkToCurrent == true) clientKey = props.suvantoKey

        setOngoingAction(true)
        createApplicationUser(
          abortRef.current,
          values.username,
          values.group,
          values.name,
          clientKey
        )
          .then(result => {
            if (result.statusCode == 200) {
              showToast(props.t("User created"), "success")
              setAddModal(false)
              addValidation.resetForm()
              getAppUsers()
            }
            else if (result.statusMessage) {
              showToast(props.t(result.statusMessage), "error")
            } 
            else {
              showToast(props.t("Error"), "error")
            }
          })
          .catch(err => {
            console.log(err)
            showToast(props.t("Error"), "error")
          })
          .finally(() => {
            setOngoingAction(false)
          })
      }
    },
  })

  // Handle search function
  const handleSearch = () => {
    let result = users.filter(
      data =>
        data.name?.toLowerCase().includes(searchText) ||
        data.username?.toLowerCase().includes(searchText) ||
        data.gsm?.toLowerCase().includes(searchText) ||
        data.email?.toLowerCase().includes(searchText)
    )
    setFilteredUsers(result)
  }

  const defaultSorted = [
    {
      dataField: "linked",
      order: "desc",
    },
  ]

  // Show selected number of rows
  const pageOptions = {
    custom: true,
    pageStartIndex: 1,
    totalSize: filteredUsers.length,
    page: currentPage,
    onPageChange: (page, sizePerPage) => {
      setCurrentPage(page)
    },
    onSizePerPageChange: (sizePerPage, page) => {
      setCurrentPage(1)
    },
  }

  // Table structure
  const columns = [
    {
      dataField: "edit",
      isDummy: true,
      text: props.t("Edit"),
      sort: true,
      align: "center",
      formatter: editFormatter,
    },
    {
      dataField: "linked",
      text: props.t("Linked"),
      sort: false,
      align: "center",
      formatter: linkedFormatter,
      /*style: (cell, row, rowIndex, colIndex) => {
        if (row.linked == true) {
          return {
            //backgroundColor: "#34C38F",
            color: '#34C38F'
          };
        }
      }*/
    },
    {
      dataField: "role",
      text: props.t("Role"),
      sort: true,
      style: (cell, row, rowIndex, colIndex) => {
        if (row.relative == true) {
          return {
            color: "#00A0D6",
          }
        } else if (row.securityPerson == true) {
          return {
            color: "#CF5A5A",
          }
        }
      },
    },
    {
      dataField: "name",
      text: props.t("Name"),
      sort: true,
    },
    {
      dataField: "username",
      text: props.t("Username"),
      sort: true,
    },
    {
      dataField: "group",
      text: props.t("Group"),
      sort: true,
    },
    {
      dataField: "unit",
      text: props.t("Unit info"),
      sort: true,
    },
    {
      dataField: "gsm",
      text: props.t("GSM"),
      sort: true,
    },
    {
      dataField: "email",
      text: props.t("Email"),
      sort: true,
    },
    {
      dataField: "linkCount",
      text: props.t("Links"),
      sort: true,
      hidden: !group
    },
    {
      dataField: "appVersion",
      text: props.t("App version"),
      sort: true,
      hidden: userRole !== "system_admin",
    },
    {
      dataField: "appOperatingSystem",
      text: props.t("Platform"),
      sort: true,
      hidden: userRole !== "system_admin",
    },
    {
      dataField: "lastConnected",
      text: props.t("Connected"),
      sort: true,
      formatter: dateFormatter,
      hidden: userRole !== "system_admin",
    },
    {
      dataField: "key",
      text: "GUID",
      sort: true,
      hidden: userRole !== "system_admin",
    },
  ]

  function editFormatter(cell, row) {
    return (
      <>
        <Button
          color="primary"
          outline
          size="sm"
          onClick={() => {
            setShowDrawer(true)
            setSelectedRow(row)
          }}
        >
          <i className="mdi mdi-pencil" />
        </Button>
      </>
    )
  }

  function linkedFormatter(cell, row) {
    if (row.linked == true) {
      return <Input type="checkbox" disabled defaultChecked={true}></Input>
    } else return <></>
  }
  function linkedFormatterXlsx(cell) {
    if (cell == true) {
      return "x"
    } else return <></>
  }

  function dateFormatter(value) {
    if (!value) return ""
    var dateString = moment(value).format("DD.MM.YYYY HH:mm:ss")
    return dateString
  }

  // Group selection changed
  const groupChanged = newGroup => {
    if (newGroup) {
      setGroup(newGroup)
    } else {
      setGroup("")
    }
  }

  // Group selection changed
  const groupChanged2 = newGroup => {
    addValidation.setFieldValue("group", newGroup)
  }

  const selectRow = {
    mode: "checkbox",
    selected: selectedUsers,
    clickToSelect: false,
    onSelect: (row, isSelect, rowIndex, e) => {
      let rowObject = [{ key: row.key, name: row.username, group: row.group }]
      if (isSelect) {
        setSelectedUsers(prevState => [...prevState, row.key])
        // Rendering name tags
        tagFunc(rowObject, isSelect)
      } else {
        let res = selectedUsers.filter(o => {
          if (o !== row.key) {
            return o
          } else {
            console.log("remove", o)
          }
        })
        setSelectedUsers(res)
        // Rendering name tags
        tagFunc(rowObject, isSelect)
      }
    },
    onSelectAll: (isSelect, rows) => {
      if (rows) {
        let keys = rows.map(row => row.key)
        let objects = []
        rows.forEach(row => {
          let object = new Object()
          object.key = row.key
          object.name = row.username
          object.group = row.group
          objects.push(object)
        })
        if (isSelect) {
          let selected = selectedUsers
          let arr = [...selected, ...keys]
          // Delete possible duplicates
          var newArray = []
          var newArray = arr.filter(function (elem, pos) {
            return arr.indexOf(elem) == pos
          })
          setSelectedUsers(newArray)
          // Rendering name tags
          tagFunc(objects, isSelect)
        } else {
          //Unselect current rows
          let tempSelected = mapRows(selectedUsers, keys)
          setSelectedUsers(tempSelected)
          // Rendering name tags
          tagFunc(objects, isSelect)
        }
      }
    },
  }

  // Used only in relese 1.0.3
  const selectRow2 = {
    mode: "checkbox",
    selected: selectedUsers,
    clickToSelect: false,
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        setSelectedUsers(prevState => [...prevState, row.key])
      } else {
        setSelectedUsers(prevState =>
          prevState.filter(prevItem => prevItem !== row.key)
        )
      }
    },
    onSelectAll: (isSelect, rows) => {
      if (isSelect) {
        rows.map(row => setSelectedUsers(prevState => [...prevState, row.key]))
      } else {
        setSelectedUsers([])
      }
    },
  }

  // All functions for name tags
  const tagFunc = (obj, add) => {
    let selectedObjects = selectedUsersObject
    let keysTemp = obj.map(o => o.key)
    let res = []

    if (add === true) {
      res = [...selectedObjects, ...obj]
    }
    if (add === false) {
      res = selectedObjects.filter(o => !keysTemp.includes(o.key))
    }

    // Delete possible duplicates
    let result = res.filter(
      (ele, ind) =>
        ind ===
        res.findIndex(elem => elem.name === ele.name && elem.key === ele.key)
    )
    setSelectedUsersObject(result)
  }

  // Mapping rows and keys for "Unselect current rows"
  const mapRows = (selected, keys) => {
    let result = selected
    for (let i = 0; i < keys.length; i++) {
      result = result.filter(items => items !== keys[i])
    }
    return result
  }

  // Creating Excel workbook+worksheet+download
  const downloadExcel = () => {
    let title = props.t("Application users")
    let message = props.t("Excel file exported succesfully!")
    const wb = XLSX.utils.book_new()

    const Heading = [
      [
        props.t("Linked"),
        props.t("Role"),
        props.t("Name"),
        props.t("Username"),
        props.t("Group"),
        props.t("GSM"),
        props.t("App version"),
        props.t("Platform"),
        props.t("Connected"),
        props.t("GUID"),
      ],
    ]
    const data = filteredUsers.map(elt => [
      linkedFormatterXlsx(elt.linked),
      elt.role,
      elt.name,
      elt.username,
      elt.group,
      elt.gsm,
      elt.email,
      elt.appVersion,
      elt.appOperatingSystem,
      elt.lastConnected,
      elt.key,
    ])

    // creating sheet and adding data from 2nd row of column A.
    // Leaving first row to add Heading
    const ws = XLSX.utils.json_to_sheet(data, {
      origin: "A2",
      skipHeader: true,
    })
    XLSX.utils.sheet_add_aoa(ws, Heading, { origin: "A1" })
    XLSX.utils.book_append_sheet(wb, ws, title)
    XLSX.write(wb, { bookType: "xlsx", type: "buffer" })
    XLSX.writeFile(wb, title + " (" + new Date() + ")" + ".xlsx")

    //Show toast
    showToast(message, "success")
  }

  return (
    <>
      <Row>
        {/* Side card of chosen users */}
        {showOnSide == true && (
          <Col sm="3">
            <Collapse isOpen={showOnSide == true}>
              <div className="wrapper">
                {/*  <div className="parent">
                  <div className="sticky"> */}
                <Card>
                  <CardBody>
                    <Row>
                      <Col className="col-auto">
                        <CardTitle className="mb-0 mt-0">
                          {props.t("Chosen users")}
                        </CardTitle>
                      </Col>
                      {selectedUsers.length !== 0 && (
                        <Col className="pb-2">
                          {selectedUsers.length == 1 && (
                            <div className="float-end ms-1">
                              <Popconfirm
                                title={props.t("Confirm action")}
                                open={deleteConfirm}
                                okType="danger"
                                okText={props.t("Delete")}
                                cancelText={props.t("Cancel")}
                                onConfirm={() => {
                                  deleteUsers()
                                  setDeleteConfirm(false)
                                }}
                                onCancel={() => {
                                  setDeleteConfirm(false)
                                }}
                              >
                                <Button
                                  id="deleteUser"
                                  color="danger"
                                  disabled={
                                    selectedUsers.length != 1 ||
                                    loading ||
                                    ongoingAction
                                  }
                                  onClick={() => {
                                    setDeleteConfirm(true)
                                  }}
                                >
                                  <i className="mdi mdi-trash-can" />
                                </Button>
                              </Popconfirm>

                              <UncontrolledTooltip
                                target="deleteUser"
                                placement="bottom"
                              >
                                {props.t("Delete selected user")}
                              </UncontrolledTooltip>
                            </div>
                          )}

                          <div className="float-end ms-1">
                            <Button
                              id="unlinkUsers"
                              color="danger"
                              className="ms-0"
                              disabled={
                                selectedUsers.length == 0 ||
                                loading ||
                                ongoingAction
                              }
                              onClick={() => {
                                unlinkUsers()
                              }}
                            >
                              <i className="mdi mdi-link-variant-off" />
                            </Button>
                            <UncontrolledTooltip
                              target="unlinkUsers"
                              placement="bottom"
                            >
                              {props.t("Unlink selected users")}
                            </UncontrolledTooltip>
                          </div>
                          <div className="float-end">
                            <Button
                              color="success"
                              id="linkUsers"
                              disabled={
                                selectedUsers.length == 0 ||
                                loading ||
                                ongoingAction
                              }
                              onClick={() => {
                                linkUsers()
                              }}
                            >
                              <i className="mdi mdi-link-variant-plus" />
                            </Button>
                            <UncontrolledTooltip
                              target="linkUsers"
                              placement="bottom"
                            >
                              {props.t("Link selected users")}
                            </UncontrolledTooltip>
                          </div>
                        </Col>
                      )}
                    </Row>
                    <AppUsersSelected
                      setSelectedUsers={setSelectedUsers}
                      setSelectedUsersObject={setSelectedUsersObject}
                      selectedUsersObject={selectedUsersObject}
                      showOnSide={showOnSide}
                    ></AppUsersSelected>
                  </CardBody>
                </Card>
              </div>
            </Collapse>
          </Col>
        )}
        {/* Not in use in v1.0.3*/}
        <Col sm={colSize}>
          <Card>
            <CardBody>
              <Row>
                <Col>
                  <CardTitle className="mb-3">
                    {props.t("Application users")}{" "}
                  </CardTitle>
                </Col>
                <Col>
                  <div className="mt-0 float-end">
                    <Dropdown
                      id="download-users-dropdown"
                      style={{ marginTop: "-5px" }}
                      isOpen={menu}
                      toggle={() => setMenu(!menu)}
                      className="download-btn text-center"
                      type="button"
                    >
                      <DropdownToggle
                        id="download-users"
                        className="text-muted"
                        tag="i"
                      >
                        <i className="mdi mdi-download" />
                      </DropdownToggle>
                      <DropdownMenu>
                        {/* TODO: Can not choose directory file right now */}
                        <DropdownItem onClick={() => downloadExcel()}>
                          {props.t("Download .xlsx")}
                        </DropdownItem>
                        {/* <DropdownItem onClick={() => exportPDF()}>
                                      {props.t("Download .pdf")}
                                    </DropdownItem> */}
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                  <div className="me-3 float-end">
                    <p>
                      {filteredUsers.length} {props.t("users")}
                    </p>
                  </div>
                </Col>
              </Row>
              {users != null && (
                <PaginationProvider pagination={paginationFactory(pageOptions)}>
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                      keyField="key"
                      columns={columns}
                      data={filteredUsers}
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          <Row>
                            {/* TAGS */}
                            {userRole === "system_admin" && (
                              <Col sm="3">
                                <TagsTree
                                  loadData={true}
                                  expanded={true}
                                  selectionChanged={setSelectedTags}
                                />
                              </Col>
                            )}
                            <Col sm="3">
                              <SelectGroup
                                onChange={groupChanged}
                                multiple={false}
                                selection={group}
                                selectedTags={selectedTags}
                              />
                            </Col>
                            <Col sm="3">
                              <Form
                                className="mt-sm-0 d-flex align-items-center"
                                onSubmit={e => {
                                  e.preventDefault()
                                  return false
                                }}
                              >
                                <Input
                                  type="text"
                                  style={{
                                    width: "100%",
                                    borderRadius: "4px",
                                    height: "32px",
                                  }}
                                  onChange={event =>
                                    setSearchText(
                                      event.target.value.toLowerCase()
                                    )
                                  }
                                  placeholder={props.t("Search") + "..."}
                                  prefix={<i className="bx bx-search-alt" />}
                                />
                              </Form>
                            </Col>
                            <Col sm="3">
                              <div className="float-end">
                                <Button
                                  // style={{ borderRadius: "100px" }}
                                  color="success"
                                  onClick={() => {
                                    setAddModal(true)
                                  }}
                                >
                                  <i className="fas fa-user-plus"></i>
                                  {showOnSide == false && (
                                    <span className="d-none d-lg-inline-block ms-2">
                                      {props.t("Create a new app user")}
                                    </span>
                                  )}
                                </Button>
                              </div>
                              {/* TODO: Finish side card */}
                              <Switch
                                id="switchScene"
                                checkedChildren={
                                  <i className="bx bx-window"></i>
                                }
                                unCheckedChildren={
                                  <i className="bx bx-list-minus"></i>
                                }
                                className="mt-1"
                                checked={showOnSide}
                                onChange={v => setShowOnSide(v)}
                              ></Switch>

                              <UncontrolledTooltip
                                target="switchScene"
                                placement="bottom"
                              >
                                {props.t("Switch view of selected users")}
                              </UncontrolledTooltip>
                            </Col>
                          </Row>

                          <Collapse
                            isOpen={
                              selectedUsers.length > 0 && showOnSide == false
                            }
                          >
                            <Row className="py-2">
                              <AppUsersSelected
                                setSelectedUsers={setSelectedUsers}
                                setSelectedUsersObject={setSelectedUsersObject}
                                selectedUsersObject={selectedUsersObject}
                                showOnSide={showOnSide}
                              ></AppUsersSelected>
                              <Col className="col-auto">
                                <Button
                                  color="success"
                                  disabled={
                                    selectedUsers.length == 0 ||
                                    loading ||
                                    ongoingAction
                                  }
                                  onClick={() => {
                                    linkUsers()
                                  }}
                                >
                                  <i className="mdi mdi-link-variant-plus me-2" />
                                  {props.t("Link selected users")}
                                </Button>
                                <Button
                                  color="danger"
                                  className="ms-3"
                                  disabled={
                                    selectedUsers.length == 0 ||
                                    loading ||
                                    ongoingAction
                                  }
                                  onClick={() => {
                                    unlinkUsers()
                                  }}
                                >
                                  <i className="mdi mdi-link-variant-off me-2" />
                                  {props.t("Unlink selected users")}
                                </Button>
                              </Col>
                              <Col className="mt-2">
                                {/* <p>
                                {selectedUsers.length +
                                  " " +
                                  props.t("selected users")}
                              </p> */}
                              </Col>
                              <Col className="col-auto">
                                <Popconfirm
                                  title={props.t("Confirm action")}
                                  open={deleteConfirm}
                                  okType="danger"
                                  okText={props.t("Delete")}
                                  cancelText={props.t("Cancel")}
                                  onConfirm={() => {
                                    deleteUsers()
                                    setDeleteConfirm(false)
                                  }}
                                  onCancel={() => {
                                    setDeleteConfirm(false)
                                  }}
                                >
                                  <Button
                                    color="danger"
                                    disabled={
                                      selectedUsers.length != 1 ||
                                      loading ||
                                      ongoingAction
                                    }
                                    onClick={() => {
                                      setDeleteConfirm(true)
                                    }}
                                  >
                                    <i className="mdi mdi-trash-can me-2" />
                                    {props.t("Delete selected user")}
                                  </Button>
                                </Popconfirm>
                              </Col>
                            </Row>
                          </Collapse>

                          {!loading && (
                            <>
                              <div className="table-responsive mt-1 mb-2">
                                <BootstrapTable
                                  id="users-table"
                                  keyField={"key"}
                                  //condensed
                                  responsive
                                  bordered={true}
                                  striped={true}
                                  hover={true}
                                  //rowStyle={ rowStyle }
                                  selectRow={selectRow}
                                  defaultSorted={defaultSorted}
                                  classes={"table align-middle table-nowrap"}
                                  headerWrapperClasses={"thead-light"}
                                  headerClasses="header-class"
                                  {...toolkitProps.baseProps}
                                  {...paginationTableProps}
                                />
                              </div>
                              <SizePerPageDropdownStandalone
                                {...paginationProps}
                              />
                              <PaginationListStandalone {...paginationProps} />
                            </>
                          )}
                          {loading && (
                            <Lottie
                              options={{
                                loop: false,
                                autoplay: true,
                                animationData: loadingAnim,
                                rendererSettings: {
                                  preserveAspectRatio: "xMidYMid slice",
                                },
                              }}
                              height={100}
                              width={100}
                            ></Lottie>
                          )}
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
              )}
            </CardBody>
          </Card>
        </Col>
        {/* Used in release v1.0.3 */}
        {/* <Card>
          <CardBody>
            {users != null && (
              <PaginationProvider pagination={paginationFactory(pageOptions)}>
                {({ paginationProps, paginationTableProps }) => (
                  <ToolkitProvider
                    keyField="key"
                    columns={columns}
                    data={filteredUsers}
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        <Row>
                          <Col>
                            {userRole === "system_admin" && (
                              <div
                                className="d-inline-block"
                                style={{ minWidth: "300px" }}
                              >
                                <TagsTree
                                  loadData={true}
                                  expanded={true}
                                  selectionChanged={setSelectedTags}
                                />
                              </div>
                            )}
                          </Col>
                          <Col>
                            <FormGroup
                            // style={{ marginTop: "2px" }}
                            >
                              <SelectGroup
                                onChange={groupChanged}
                                multiple={false}
                                clearable={true}
                                selection={group}
                                selectedTags={selectedTags}
                              />
                            </FormGroup>
                          </Col>
                          <Col className="col-auto pe-0">
                            <Form
                              className="mt-sm-0 d-flex align-items-center"
                              onSubmit={e => {
                                e.preventDefault()
                                return false
                              }}
                            >
                              <div className="search-box me-2 mb-2 d-inline-block float-end">
                                <div className="position-relative">
                                  <Input
                                    type="text"
                                    onChange={event =>
                                      setSearchText(
                                        event.target.value.toLowerCase()
                                      )
                                    }
                                    placeholder={props.t("Search")}
                                  />
                                  {/* TODO: <i className="bx bx-search-alt search-icon" /> 
                                </div>
                              </div>
                            </Form>
                          </Col>
                          <Col className="col-auto">
                            <Button
                              style={{ borderRadius: "100px" }}
                              color="success"
                              onClick={() => {
                                setAddModal(true)
                              }}
                            >
                              <i className="fas fa-user-plus"></i>
                              <span className="d-none d-lg-inline-block ms-2">
                                {props.t("Create a new app user")}
                              </span>
                            </Button>
                          </Col>
                          <Col>
                            <div className="mt-0 float-end">
                              <Dropdown
                                id="download-users-dropdown"
                                isOpen={menu}
                                toggle={() => setMenu(!menu)}
                                className="download-btn text-center"
                                type="button"
                              >
                                <DropdownToggle
                                  id="download-users"
                                  className="text-muted"
                                  tag="i"
                                >
                                  <i className="mdi mdi-download" />
                                </DropdownToggle>
                                <DropdownMenu>
                                  {/* TODO: Can not choose directory file right now 
                                  <DropdownItem onClick={() => downloadExcel()}>
                                    {props.t("Download .xlsx")}
                                  </DropdownItem>
                                  {/* <DropdownItem onClick={() => exportPDF()}>
                                      {props.t("Download .pdf")}
                                    </DropdownItem> 
                                </DropdownMenu>
                              </Dropdown>
                            </div>
                            <div className="mt-2 me-3 float-end">
                              <p>
                                {filteredUsers.length} {props.t("users")}
                              </p>
                            </div>
                          </Col>
                        </Row>

                        <Collapse isOpen={selectedUsers.length > 0}>
                          <Row className="py-2">
                            <Col className="col-auto">
                              <Button
                                color="success"
                                disabled={
                                  selectedUsers.length == 0 ||
                                  loading ||
                                  ongoingAction
                                }
                                onClick={() => {
                                  linkUsers()
                                }}
                              >
                                <i className="mdi mdi-link-variant-plus me-2" />
                                {props.t("Link selected users")}
                              </Button>
                              <Button
                                color="danger"
                                className="ms-3"
                                disabled={
                                  selectedUsers.length == 0 ||
                                  loading ||
                                  ongoingAction
                                }
                                onClick={() => {
                                  unlinkUsers()
                                }}
                              >
                                <i className="mdi mdi-link-variant-off me-2" />
                                {props.t("Unlink selected users")}
                              </Button>
                            </Col>
                            <Col className="mt-2">
                              <p>
                                {selectedUsers.length +
                                  " " +
                                  props.t("selected users")}
                              </p>
                            </Col>
                            <Col className="col-auto">
                              <Popconfirm
                                title={props.t("Confirm action")}
                                open={deleteConfirm}
                                okType="danger"
                                okText={props.t("Delete")}
                                cancelText={props.t("Cancel")}
                                onConfirm={() => {
                                  deleteUsers()
                                  setDeleteConfirm(false)
                                }}
                                onCancel={() => {
                                  setDeleteConfirm(false)
                                }}
                              >
                                <Button
                                  color="danger"
                                  disabled={
                                    selectedUsers.length != 1 ||
                                    loading ||
                                    ongoingAction
                                  }
                                  onClick={() => {
                                    setDeleteConfirm(true)
                                  }}
                                >
                                  <i className="mdi mdi-trash-can me-2" />
                                  {props.t("Delete selected user")}
                                </Button>
                              </Popconfirm>
                            </Col>
                          </Row>
                        </Collapse>

                        {!loading && (
                          <>
                            <div className="table-responsive mt-1 mb-2">
                              <BootstrapTable
                                id="users-table"
                                keyField={"key"}
                                //condensed
                                responsive
                                bordered={true}
                                striped={true}
                                hover={true}
                                //rowStyle={ rowStyle }
                                selectRow={selectRow2}
                                defaultSorted={defaultSorted}
                                classes={"table align-middle table-nowrap"}
                                headerWrapperClasses={"thead-light"}
                                headerClasses="header-class"
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                              />
                            </div>
                            <SizePerPageDropdownStandalone
                              {...paginationProps}
                            />
                            <PaginationListStandalone {...paginationProps} />
                          </>
                        )}
                        {loading && (
                          <Lottie
                            options={{
                              loop: false,
                              autoplay: true,
                              animationData: loadingAnim,
                              rendererSettings: {
                                preserveAspectRatio: "xMidYMid slice",
                              },
                            }}
                            height={100}
                            width={100}
                          ></Lottie>
                        )}
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                )}
              </PaginationProvider>
            )}
          </CardBody>
        </Card> */}
        <Modal
          isOpen={addModal}
          toggle={() => {
            setAddModal(!addModal)
          }}
          centered
        >
          <ModalHeader>{props.t("Create a new app user")}</ModalHeader>
          <Form
            className="form-horizontal"
            onSubmit={e => {
              e.preventDefault()
              return false
            }}
          >
            <ModalBody>
              <FormGroup>
                <Label>{props.t("Username")}</Label>
                <Input
                  name="username"
                  type="text"
                  onChange={addValidation.handleChange}
                  onBlur={addValidation.handleBlur}
                  placeholder="etunimi.sukunimi"
                  value={addValidation.values.username || ""}
                  invalid={
                    addValidation.touched.username &&
                    addValidation.errors.username
                      ? true
                      : false
                  }
                ></Input>
                {addValidation.touched.username &&
                addValidation.errors.username ? (
                  <FormFeedback type="invalid">
                    {addValidation.errors.username}
                  </FormFeedback>
                ) : null}
              </FormGroup>
              <FormGroup>
                <Label>{props.t("Name")}</Label>
                <Input
                  name="name"
                  type="text"
                  onChange={addValidation.handleChange}
                  onBlur={addValidation.handleBlur}
                  value={addValidation.values.name || ""}
                  invalid={
                    addValidation.touched.name && addValidation.errors.name
                      ? true
                      : false
                  }
                ></Input>
                {addValidation.touched.name && addValidation.errors.name ? (
                  <FormFeedback type="invalid">
                    {addValidation.errors.name}
                  </FormFeedback>
                ) : null}
              </FormGroup>

              <FormGroup>
                <Label>{props.t("Group")}</Label>
                <SelectGroup
                  onChange={groupChanged2}
                  multiple={false}
                  clearable={false}
                  selection={addValidation.values.group}
                />
              </FormGroup>

              <FormGroup className="mt-4">
                <Switch
                  className="me-2"
                  name="linkToCurrent"
                  checked={addValidation.values.linkToCurrent}
                  onChange={v => {
                    addValidation.setFieldValue("linkToCurrent", v)
                  }}
                ></Switch>
                <Label>{props.t("Link to current client")}</Label>
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                outline
                onClick={() => {
                  setAddModal(false)
                }}
              >
                {props.t("Cancel")}
              </Button>
              <Button
                color="success"
                disabled={ongoingAction}
                onClick={() => {
                  addValidation.submitForm()
                }}
              >
                {props.t("Add")}
              </Button>{" "}
            </ModalFooter>
          </Form>
        </Modal>
        <AppUserDrawer
          showDrawer={showDrawer}
          setShowDrawer={setShowDrawer}
          selectedRow={selectedRow}
          getUsers={getAppUsers}
        />
      </Row>
    </>
  )
}

AppUsersConfig.propTypes = {
  t: PropTypes.any,
  suvantoKey: PropTypes.string,
  group: PropTypes.string,
  selectionChanged: PropTypes.func,
}

export default withTranslation()(AppUsersConfig)
