import {
  getGroupCallClients,
  getGroupClientConfig,
  addVideoMessage,
} from "helpers/backend_helper"
import React, { useContext, useEffect, useRef, useState, useMemo } from "react"
import PropTypes from "prop-types"
// import { CheckCircleTwoTone } from "@ant-design/icons"
import {
  Alert,
  Button,
  Card,
  CardBody,
  FormFeedback,
  Input,
  CardImg,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Form,
  UncontrolledTooltip,
} from "reactstrap"
import { withTranslation } from "react-i18next"
//Import Scrollbar
import SimpleBar from "simplebar-react"

import Breadcrumbs from "components/Common/Breadcrumb"
import searchAnim from "common/animations/finddata"
import loadingAnim from "common/animations/loading-dots-blue"
import nodataAnim from "common/animations/nodata"
import Lottie from "react-lottie"
// import groupcallImage from "../../../assets/images/devices/test-group-call.jpg"
// import groupCallIcon from "../../../assets/images/devices/groupcall.svg"
// import groupcallImage from "../../../assets/images/devices/groupcall.jpg"
import GlobalState from "contexts/GlobalState"
import { Switch, Radio, ConfigProvider, Tree, DatePicker } from "antd"
const { RangePicker } = DatePicker
import {
  formatDateFull,
  formatDateHoursMinutes,
  formatDateSimple,
  formatDateTime,
} from "utils/general"
// MUI
import Box from "@mui/material/Box"
import Slider from "@mui/material/Slider"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"
import { getGroupCalendarEvents } from "helpers/backend_helper"
import moment from "moment"
import { differenceWith, isEqual } from "lodash"
import Manual from "components/Manual/Manual"

const GroupCallServices = props => {
  const [state, setState] = useContext(GlobalState)
  const [origResult, setOrigResult] = useState([])
  const [resultClients, setResultClients] = useState([])
  const [callLoading, setCallLoading] = useState(false)
  const [guestsLoading, setGuestsLoading] = useState(false)
  const [groupGuests, setGroupGuests] = useState([])
  const [startError, setStartError] = useState(null)
  const [useDemoPlatform, setUseDemoPlatform] = useState(false)
  const [hasDemoRoom, setHasDemoRoom] = useState(false)
  const [calendarDate, setCalendarDate] = useState(moment())
  const [calendarDateStart, setCalendarDateStart] = useState(moment())
  const [calendarDateEnd, setCalendarDateEnd] = useState(moment())
  const [calendarEvents, setCalendarEvents] = useState([])
  const [calendarLoading, setCalendarLoading] = useState(true)
  const [groups, setGroups] = useState([])
  // categories
  const [offline, setOffline] = useState([])
  const [online, setOnline] = useState([])
  const [groupCall, setGroupCall] = useState([])
  const [call, setCall] = useState([])
  // Group invitation
  const [groupInvitation, setGroupInvitation] = useState(false)
  const [invitationValue, setInvitationValue] = useState("All")
  const [groupInviteChosen, setGroupInviteChosen] = useState([])
  const [range, setRange] = useState(true)
  const [searchText, setSearchText] = useState("")
  const [treeOptions, setTreeOptions] = useState([])
  const [filteredGuests, setFilteredGuests] = useState([])
  const [eventsByDay, setEventsByDay] = useState({})
  const [eventsByDayFiltered, setEventsByDayFiltered] = useState({})
  const [firstFetch, setFirstFetch] = useState(true)
  // Search field
  const [searchInput, setSearchInput] = useState("")

  const abortRef = useRef(null)
  const scrollViewRef = useRef(null)

  useEffect(() => {
    document.title = props.t("Group video call") + " | Suvanto Care"
    abortRef.current = new AbortController()
    if (state.authUser != null) {
      const obj = JSON.parse(state.authUser)
      if (
        obj.groupVideoId &&
        obj.groupVideoId.length > 0 &&
        obj.groupVideoDemoId &&
        obj.groupVideoDemoId.length > 0
      ) {
        setHasDemoRoom(true)
      }
    }
    return () => abortRef.current.abort()
  }, [])

  useEffect(() => {
    getGroupClients()
  }, [useDemoPlatform])

  // set by date
  const groupEvents = events => {
    console.log("group")
    if (events.length > 0) {
      const groupedEvents = events.reduce((acc, event) => {
        const day = moment(event.start).format("YYYY-MM-DD")
        acc[day] = [...(acc[day] || []), event]
        return acc
      }, {})
      // console.log(groupedEvents, calendarEvents, "grouped")
      setEventsByDay(groupedEvents)
      filterObjects(groupedEvents, range)
    } else {
      setEventsByDayFiltered({})
    }
  }

  useEffect(() => {
    // console.log(calendarDate, calendarDateStart, calendarDateEnd)
    if (
      (calendarDate !== undefined &&
        (moment(calendarDate).isBefore(calendarDateStart) ||
          moment(calendarDate).isAfter(calendarDateEnd))) ||
      (moment().isSame(calendarDate, "day") && firstFetch)
    ) {
      setCalendarLoading(true)
      setCalendarEvents([])
      let start = moment(calendarDate).startOf("week")
      let end = moment(calendarDate).endOf("week")
      setCalendarDateStart(start)
      setCalendarDateEnd(end)

      getGroupCalendarEvents(abortRef.current, start, end, !useDemoPlatform)
        .then(result => {
          if (result.statusCode == 200 && result.events != null) {
            setCalendarEvents(result.events)
            groupEvents(result.events)
            console.log(result.events, "result")
            if (useDemoPlatform) {
              setFirstFetch(false)
            }
          }
        })
        .catch(e => {
          console.log(e)
        })
        .finally(() => {
          setCalendarLoading(false)
          setCalendarLoading(false)
        })
    }
    if (
      calendarDate.isBetween(calendarDateStart, calendarDateEnd) &&
      range == false
    ) {
      filterObjects(eventsByDay, false)
    }
  }, [calendarDate, useDemoPlatform])

  // Scroll view start in SimpleBar
  useEffect(() => {
    if (
      eventsByDay &&
      Object.keys(eventsByDay).length != 0 &&
      scrollViewRef.current
    ) {
      const today = moment()
      const currentDayDiv = Object.keys(eventsByDay).filter(day => {
        if (moment(day).isSame(today, "day")) {
          return day
        }
      })

      if (currentDayDiv) {
        const scrollElement = scrollViewRef.current.getScrollElement()
        let firstDayElement = scrollElement.querySelector(
          `[data-event-id="${currentDayDiv}"]`
        )

        if (firstDayElement) {
          firstDayElement.scrollIntoView({ behavior: "smooth" })
        }
      }
    }
  }, [eventsByDay])

  // Istekki TODO: set socket
  useEffect(() => {
    const interval = setInterval(() => {
      getGroupCallClients(abortRef.current, useDemoPlatform)
        .then(result => {
          if (result.clients) {
            // console.log(result.clients, "clients")
            const differences = findDifferences(result.clients)
            if (differences.length > 0) {
              console.log("Result changed:")
              differences.forEach((difference, index) => {
                console.log(`Difference ${index + 1}:`, difference)
              })
              setOrigResult(result.clients)
            } else {
              // console.log("Arrays are the same.")
            }
          }
        })
        .catch(err => {
          console.log(err)
        })
    }, 10000)
    return () => clearInterval(interval)
  }, [])

  const findDifferences = obj1 => {
    const customizer = (origResult, obj1) => {
      return isEqual(obj1, origResult)
    }

    const differences = differenceWith(origResult, obj1, (a, b) => {
      return !customizer(a, b)
    })
    return differences
  }

  // Get list of group guests
  const getGroupClients = () => {
    setGuestsLoading(true)
    getGroupCallClients(abortRef.current, useDemoPlatform)
      .then(result => {
        if (result.clients) {
          console.log(result, "result")
          setOrigResult(result.clients)

          let categorized = categorizingResult(result.clients)
          setGroupGuests(categorized)
          setResultClients(categorized)
        }
        setGuestsLoading(false)
      })
      .catch(err => {
        console.log(err)
        setGuestsLoading(false)
      })
  }

  const categorizingResult = result => {
    result.forEach(client => {
      client.statusDescription = "Unknown"
      switch (client.status) {
        case "offline":
          client.statusDescription = "Unavailable"
          break
        case "online":
          client.statusDescription = "Available"
          break
        case "group":
          client.statusDescription = "Ongoing group call"
          break
        case "call":
          client.statusDescription = "Ongoing video call"
          break
      }
    })

    // Getting group names
    let groups = []
    result.map(g => groups.push(g.group))
    const withoutDuplicates = [...new Set(groups)]
    setGroups(withoutDuplicates)

    let online = []
    let offline = []
    let groupcall = []
    let call = []
    online = result.filter(c => c.status === "online")
    offline = result.filter(c => c.status === "offline")
    groupcall = result.filter(c => c.status === "group")
    call = result.filter(c => c.status === "call")
    setOnline(online)
    setOffline(offline)
    setCall(call)
    setGroupCall(groupcall)

    result.sort((a, b) => (a.name < b.name ? 1 : -1))

    result.sort((a, b) => (a.statusDescription > b.statusDescription ? 1 : -1))

    return result
  }

  // Start call, get config from server
  const startCall = () => {
    setStartError(null)
    setCallLoading(true)
    console.log(
      abortRef.current,
      useDemoPlatform,
      groupInviteChosen,
      "group invite"
    )
    getGroupClientConfig(abortRef.current, useDemoPlatform, groupInviteChosen)
      .then(result => {
        if (result.statusCode == 200 && result.link != null) {
          window.open(result.link, "_blank", "noreferrer")
        } else if (result.status == "missing_id") {
          setStartError("No group video permissions")
        } else {
          setStartError("Something went wrong")
        }
      })
      .catch(e => {
        console.log(e)
        setStartError("Something went wrong")
      })
      .finally(() => {
        setCallLoading(false)
      })
  }

  // Join event, get config from server
  const joinEvent = roomName => {
    setStartError(null)
    setCallLoading(true)
    getGroupClientConfig(abortRef.current, useDemoPlatform, [], roomName)
      .then(result => {
        if (result.statusCode == 200 && result.link != null) {
          window.open(result.link, "_blank", "noreferrer")
        } else if (result.status == "missing_id") {
          setStartError("No group video permissions")
        } else {
          setStartError("Something went wrong")
        }
      })
      .catch(e => {
        console.log(e)
        setStartError("Something went wrong")
      })
      .finally(() => {
        setCallLoading(false)
      })
  }
  // For testing
  const onSelect = (selectedKeys, info) => {
    // console.log("selected", selectedKeys, info)
  }

  // Lottie options
  const lottieLoadingOpts = {
    loop: true,
    autoplay: true,
    animationData: loadingAnim,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }

  const lottieNoneOpts = {
    loop: false,
    autoplay: true,
    animationData: nodataAnim,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }

  // Get styling for status badge
  const getStatusStyling = status => {
    switch (status) {
      case "offline":
        return "badge bg-gray font-size-12 p-2"
      case "online":
        return "badge bg-success font-size-12 p-2"
      default:
        return "badge bg-primary font-size-12 p-2"
    }
  }

  const getStatusStylingVar = status => {
    switch (status) {
      case "offline":
        return "badge badge-soft-gray font-size-12 p-2"
      case "online":
        return "badge badge-soft-success font-size-10 p-2"
      default:
        return "badge badge-soft-primary font-size-12 p-2"
    }
  }

  // Radio buttons onchange
  const onChangeInvitation = ({ target: { value } }) => {
    console.log("checked value", value)
    setInvitationValue(value)
  }

  // TEST

  // Search or filter changed
  useEffect(() => {
    handleSearch()
  }, [searchText])

  const getParentKey = (key, tree) => {
    // console.log(key, tree, "getparent function")
    let parentKey
    for (let i = 0; i < tree.length; i++) {
      const node = tree[i]
      if (node.children) {
        if (node.children.some(item => item.key === key)) {
          parentKey = node.key
        } else if (getParentKey(key, node.children)) {
          parentKey = getParentKey(key, node.children)
        }
      }
    }
    return parentKey
  }

  // TODO: set working
  const handleSearch = () => {
    const { value } = searchText.toLowerCase()
    const newExpandedKeys = treeOptions
      .map(e => {
        // console.log(e, "test")
        e.children.map(item => {
          if (item.title.toLowerCase().indexOf(value) > -1) {
            return getParentKey(item.key, defaultData)
          } else {
            return null
          }
        })
      })
      .filter((item, i, self) => !!(item && self.indexOf(item) === i))

    // console.log(value, newExpandedKeys, "test")
    // setExpandedKeys(newExpandedKeys);
    // setSearchValue(value);
    // setAutoExpandParent(true);
  }

  useEffect(() => {
    if (groupGuests.length > 0 && groups.length > 0) {
      let all = []
      let opts = []
      // Groups
      groups.forEach(g => {
        let guests = groupGuests.filter(
          guest => guest.group === g && guest.status === "online"
        ) // Online guests only
        // console.log(guests, "guests")
        let groupChildrenKey = []
        guests.map(guest => {
          groupChildrenKey.push(guest.key)
        })
        let groupValue = new Object()
        groupValue.title = g
        // groupValue.key = groupChildrenKey
        // TODO: no null
        groupValue.key = null
        groupValue.children = []

        // Guests
        guests.forEach(client => {
          let clientObject = new Object()
          clientObject.title = client.name
          clientObject.key = client.key
          clientObject.room = client.room
          // TODO set icon to implicate if online
          // clientObject.icon = <CheckCircleTwoTone />
          groupValue.children.push(clientObject)
        })

        opts.push(groupValue)
      })
      let allKeys = []
      groups.map(g => {
        allKeys.push(g.key)
      })
      all = [
        {
          // key: allKeys,
          // TODO: no null
          key: null,
          title: props.t("All"),
          children: opts,
        },
      ]
      setTreeOptions(all)
      setFilteredGuests(all)
    }
  }, [groups])

  const onCheck = (checkedKeys, info) => {
    // console.log("onCheck", checkedKeys, info)
    let keys = info.checkedNodes?.filter(n => n.key !== null)
    console.log(keys)
    let realKeys = []
    keys.map(k => realKeys.push(k.room))
    console.log(realKeys, "real")
    setGroupInviteChosen(realKeys)
  }

  // Check if event is now
  const checkIfPresent = (start, end) => {
    const currentTime = moment()
    return currentTime.isBetween(moment(start).add(-10, "minutes"), end)
    // return currentTime.isBetween(start, end)
  }

  // Filtering clients groups
  useEffect(() => {
    console.log(searchInput, "treeoptions")
    if (searchInput.length == 0) {
      console.log("settingOrig", origResult)
      setResultClients(origResult)
      setGroupGuests(origResult)
    }
    if (searchInput !== "") {
      console.log("searchInput")
      let result = []
      result = origResult.filter(
        data =>
          data.name?.toLowerCase().includes(searchInput.toLowerCase()) ||
          data.group?.toLowerCase().includes(searchInput.toLowerCase())
      )
      console.log(result)
      setResultClients(result)
      setGroupGuests(result)
    }
  }, [searchInput, origResult])

  // Update clientslist
  useEffect(() => {
    if (origResult.length > 0) {
      categorizingResult(resultClients)
    }
  }, [resultClients])

  // Update clientslist
  const filterObjects = (events, bool) => {
    console.log(events, bool, "range, t")
    if (bool == false) {
      const formattedDay = new Date(calendarDate).toISOString().split("T")[0]
      const filteredData = Object.keys(events)
        .filter(key => key == formattedDay)
        .reduce((obj, key) => {
          obj[key] = events[key]
          return obj
        }, {})
      console.log(filteredData, "norange")
      setEventsByDayFiltered(filteredData)
    }
    if (bool) {
      console.log(events, "range")
      setEventsByDayFiltered(events)
    }
  }

  // Handle search field function
  const handleSearchField = event => {
    if (treeOptions != undefined) {
      setSearchInput(event.target.value)
    }
  }

  // Change time range
  const onChange = e => {
    if (e.target.value == 1) {
      setRange(true)
      filterObjects(eventsByDay, true)
      console.log(calendarDate, "range")
    }
    if (e.target.value == 2) {
      console.log(calendarDate, "norange")
      setRange(false)
      filterObjects(eventsByDay, false)
    }
  }

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Group video call")}
            className="breadcrumb m-0 justify-content-center"
            breadcrumbItem={
              <>
                {hasDemoRoom && (
                  <div className="d-inline-block mb-0">
                    <div className="d-flex me-1">
                      <Label>{props.t("Demo platform")}</Label>
                      <Switch
                        className="ms-2"
                        name="useDemoPlatform"
                        checked={useDemoPlatform}
                        onChange={v => setUseDemoPlatform(v)}
                      />
                    </div>
                  </div>
                )}
                <div className="d-inline-block">
                  <Manual
                    source={"group-video-call"}
                    // tab={activeTab}
                  ></Manual>
                </div>
                {/* <Col>
                    <Button
                      color="primary"
                      style={{
                        borderRadius: "100px",
                        paddingTop: "4px",
                        paddingBottom: "4px",
                      }}
                      disabled={guestsLoading}
                      onClick={() => {
                        getGroupClients()
                      }}
                    >
                      <i className="mdi mdi-refresh me-1" />
                      {props.t("Refresh")}
                    </Button>
                  </Col> */}
              </>
            }
          />
          {/** 
          <Row>
            <Col className="col-auto">
              <Button
                color="clear"
                className="p-0 text-primary"
                disabled={guestsLoading}
                onClick={() => {
                  getGroupClients()
                }}
              >
                {props.t("Refresh")}
                <i className="mdi mdi-refresh ms-2" />
              </Button>
            </Col>
            {hasDemoRoom && (
              <Col>
                <FormGroup>
                  <Switch
                    className="me-2"
                    name="useDemoPlatform"
                    checked={useDemoPlatform}
                    onChange={v => setUseDemoPlatform(v)}
                  />
                  <Label>{props.t("Demo platform")}</Label>
                </FormGroup>
              </Col>
            )}
          </Row>
*/}
          {/* <Row xs={1} xl={2}>
            <Col className="order-2 order-xl-1">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">
                    {props.t("Group call clients")}
                  </CardTitle>
                  {guestsLoading && (
                    <Lottie
                      options={lottieLoadingOpts}
                      height={100}
                      width={100}
                    />
                  )}
                  {!guestsLoading && groupGuests.length > 0 && (
                    <ul className="list-group list-group-flush">
                      {groupGuests.map((guest, index) => (
                        <li key={guest.key} className="list-group-item py-3">
                          <Row>
                            <Col>
                              <div>
                                <h6>{guest.name}</h6>
                                <span className="text-muted">
                                  {guest.group}
                                </span>
                              </div>
                            </Col>
                            <Col
                              className="col-auto d-flex"
                              style={{ alignItems: "center" }}
                            >
                              <span
                                style={{ borderRadius: "20px" }}
                                className={"" + getStatusStyling(guest.status)}
                              >
                                {props.t(guest.statusDescription)}
                              </span>
                            </Col>
                            <Col
                              className="col-auto d-flex"
                              style={{ alignItems: "center" }}
                            >
                              <TooltipItemVar
                                t={props.t}
                                data={guest}
                                index={index}
                              />
                            </Col>
                          </Row>
                        </li>
                      ))}
                    </ul>
                  )}
                  {!guestsLoading &&
                    (groupGuests == null || groupGuests.length == 0) && (
                      <div className="text-center mb-4">
                        <Lottie
                          options={lottieNoneOpts}
                          height={150}
                          width={150}
                        />
                        <h5 className="text-muted mt-2">
                          {props.t("No clients found")}
                        </h5>
                      </div>
                    )}
                </CardBody>
              </Card>
            </Col>
            <Col className="order-1 order-xl-2">
              <Card>
                <CardBody className="p-0">
                  <CardImg
                    top
                    width="100%"
                    height={"200px"}
                    style={{ objectFit: "cover", opacity: "0.5" }}
                    src={groupcallImage}
                  ></CardImg>
                  <div className="text-center mb-3" style={{ padding: "20px" }}>
                    <h5 className="text-muted mt-3">
                      {props.t("Group call will open in a new window")}
                    </h5>
                    {!callLoading && (
                      <Button
                        className="mt-3"
                        type="button"
                        style={{ minWidth: "180px" }}
                        onClick={() => startCall()}
                      >
                        {props.t("Start group call")}
                      </Button>
                    )}
                    {startError && (
                      <Alert className="mt-4" color="danger">
                        {props.t(startError)}
                      </Alert>
                    )}
                    {callLoading && (
                      <Lottie
                        options={lottieLoadingOpts}
                        height={100}
                        width={100}
                      />
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row> */}
          <Row xs={1} xl={2}>
            {/* <Col className="order-2 order-xl-1"> */}
            <Col className="order-2 order-xl-1" xl="5">
              <Card>
                <CardBody>
                  <div>
                    <CardTitle className="mb-3">
                      {props.t("Group call clients")}
                      {groupInvitation ? (
                        <Button
                          className="float-end ms-1"
                          color="primary"
                          size="sm"
                          onClick={() => {
                            setGroupInvitation(false)
                          }}
                        >
                          {props.t("Cancel")}
                        </Button>
                      ) : (
                        <>
                          <Button
                            id="invitebutton"
                            className="float-end ms-1 mb-2"
                            color="primary"
                            size="sm"
                            disabled={groupInvitation}
                            onClick={() => {
                              setGroupInvitation(true)
                            }}
                          >
                            <i className="mdi mdi-account-group me-1"></i>
                            {props.t("Invite")}
                          </Button>
                          <UncontrolledTooltip
                            placement="bottom"
                            target="invitebutton"
                          >
                            {props.t("Invite to a group video call")}
                          </UncontrolledTooltip>
                        </>
                      )}
                      <div className="search-field px-1 float-end mt-0 pt-0">
                        <div className="box mb-2 d-inline-block">
                          <div className="position-relative">
                            <Input
                              style={{
                                height: "28px",
                                width: "100%",
                                fontSize: "12px",
                              }}
                              // size={"sm"}
                              type="text"
                              onChange={event => handleSearchField(event)}
                              placeholder={props.t("Search")}
                            />
                            {/* <i className="bx bx-search-alt search-icon mt-0 p-0" /> */}
                          </div>
                        </div>
                      </div>
                    </CardTitle>
                  </div>
                  {guestsLoading && (
                    <Lottie
                      options={lottieLoadingOpts}
                      height={100}
                      width={100}
                    />
                  )}
                  {!guestsLoading && groupGuests.length > 0 && (
                    <Col lg="12">
                      {groupInvitation ? (
                        <Row>
                          <ConfigProvider
                            theme={{
                              components: {
                                Radio: {
                                  size: "small",
                                },
                              },
                            }}
                          >
                            <div className="py-3 font-size-10">
                              <Row>
                                <Col sm="12">
                                  <h6>
                                    <Button
                                      className="float-end"
                                      size="sm"
                                      disabled={groupInviteChosen.length == 0}
                                      onClick={() => startCall()}
                                    >
                                      <i className="fas fa-video me-2"></i>
                                      {props.t("Start group call")} (
                                      {groupInviteChosen.length})
                                    </Button>
                                    <span className="mt-2">
                                      {props.t("Invite to a group video call")}
                                    </span>
                                  </h6>
                                  {/* <Radio.Group
                                    className="mt-2 font-size-10"
                                    size="small"
                                    options={invitationOptions}
                                    onChange={onChangeInvitation}
                                    value={invitationValue}
                                  /> */}
                                </Col>
                                <Col sm="4"></Col>
                              </Row>
                            </div>
                          </ConfigProvider>
                          <Row>
                            <Tree
                              checkable
                              defaultExpandAll
                              onSelect={onSelect}
                              onCheck={onCheck}
                              treeData={filteredGuests}
                            />
                          </Row>
                        </Row>
                      ) : (
                        <Row>
                          {online.length > 0 && (
                            <Col xs="12">
                              <Customers
                                t={props.t}
                                data={online}
                                title="Available"
                                groups={groups}
                                groupInvitation={groupInvitation}
                                setGroupInviteChosen={setGroupInviteChosen}
                                chosen={groupInviteChosen}
                              />
                            </Col>
                          )}
                          {call.length > 0 && (
                            <Col xs="12">
                              <Customers
                                t={props.t}
                                data={call}
                                title="In call"
                                groups={groups}
                                groupInvitation={groupInvitation}
                                setGroupInviteChosen={setGroupInviteChosen}
                                chosen={groupInviteChosen}
                              />
                            </Col>
                          )}
                          {groupCall.length > 0 && (
                            <Col xs="12">
                              <Customers
                                t={props.t}
                                data={groupCall}
                                title="In group call"
                                groups={groups}
                                groupInvitation={groupInvitation}
                                setGroupInviteChosen={setGroupInviteChosen}
                                chosen={groupInviteChosen}
                              />
                            </Col>
                          )}
                          {offline.length > 0 && (
                            <Col xs="12">
                              <Customers
                                t={props.t}
                                data={offline}
                                title="Offline"
                                groups={groups}
                                groupInvitation={groupInvitation}
                                setGroupInviteChosen={setGroupInviteChosen}
                                chosen={groupInviteChosen}
                              />
                            </Col>
                          )}
                        </Row>
                      )}
                    </Col>
                  )}
                  {!guestsLoading &&
                    (groupGuests == null || groupGuests.length == 0) && (
                      <div className="text-center mb-4">
                        <Lottie
                          options={lottieNoneOpts}
                          height={150}
                          width={150}
                        />
                        <h5 className="text-muted mt-2">
                          {props.t("No clients found")}
                        </h5>
                      </div>
                    )}
                </CardBody>
              </Card>
            </Col>
            <Col className=" order-1 order-xl-2" xl="7">
              <Card>
                <CardBody className="pt-3 pb-0 mb-1">
                  <Row>
                    {/* <Col md="2">
                      <div className="icon-group display-4 px-2 text-primary">
                        <i className="mdi mdi-account-group"></i>
                      </div>
                    </Col> */}
                    <Col xs="12">
                      <span className="float-end">
                        {!callLoading ? (
                          <Button type="button" onClick={() => startCall()}>
                            <i className="fas fa-video font-size-12 me-2"></i>
                            {props.t("Start group call")}
                          </Button>
                        ) : (
                          <Lottie
                            options={lottieLoadingOpts}
                            height="auto"
                            width={100}
                          />
                        )}
                      </span>
                      <h5 className="py-1">{props.t("Start a group call")}</h5>{" "}
                    </Col>
                    <Col md="12" className="">
                      <div className="text-muted mb-2">
                        <div className="py-2">
                          {props.t("Group call will open in a new window")}.{" "}
                          {props.t(
                            "You can see the available people from the Group call customers card"
                          )}
                          .{/* </div> */} {/* <div> */}
                          {props.t(
                            "You can also invite group call clients separately to a call"
                          )}
                          .
                        </div>
                      </div>
                    </Col>
                  </Row>

                  {startError && (
                    <Alert className="mt-4" color="danger">
                      {props.t(startError)}
                    </Alert>
                  )}
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  {range ? (
                    <CardTitle>
                      <div className="container-event-title">
                        <div className="flex-1 ">{props.t("Events")}</div>

                        <div className="flex-2">
                          <Button
                            className="datepicker-events-btn-1 p-0"
                            color="transparent"
                            onClick={() => {
                              setCalendarDate(
                                moment(calendarDate.add(-1, "weeks"))
                              )
                            }}
                          >
                            <i className="mdi mdi-chevron-left" />
                          </Button>

                          <DatePicker
                            className="datepicker-events-range"
                            value={calendarDate}
                            suffixIcon={false}
                            defaultValue={calendarDate}
                            bordered={false}
                            onChange={date => {
                              setCalendarDate(date)
                            }}
                            picker="week"
                            allowClear={false}
                            format={value =>
                              `${moment(value)
                                .startOf("isoWeek")
                                .format("D.M.YYYY")} - ${moment(value)
                                .endOf("isoWeek")
                                .format("D.M.YYYY")}`
                            }
                          />
                          <Button
                            className="datepicker-events-btn-2 p-0"
                            color="transparent"
                            onClick={() => {
                              setCalendarDate(
                                moment(calendarDate.add(1, "weeks"))
                              )
                            }}
                          >
                            <i className="mdi mdi-chevron-right ms-0" />
                          </Button>
                        </div>
                        <div className="flex-1">
                          <span className="float-end ">
                            <Radio.Group
                              buttonStyle=""
                              className="group-call-range"
                              onChange={onChange}
                              defaultValue={1}
                            >
                              <Radio.Button value={1}>
                                {props.t("Week")}
                              </Radio.Button>
                              <Radio.Button value={2}>
                                {props.t("Day")}
                              </Radio.Button>
                            </Radio.Group>
                          </span>
                        </div>
                      </div>
                    </CardTitle>
                  ) : (
                    <CardTitle>
                      <div className="container-event-title">
                        <div className="flex-1 ">{props.t("Events")}</div>

                        <div className="flex-2">
                          <Button
                            className="datepicker-events-btn-1 p-0"
                            color="transparent"
                            onClick={() => {
                              setCalendarDate(
                                moment(calendarDate.add(-1, "days"))
                              ),
                                groupEvents(calendarEvents)
                            }}
                          >
                            <i className="mdi mdi-chevron-left" />
                          </Button>
                          <DatePicker
                            className="datepicker-events"
                            value={calendarDate}
                            suffixIcon={false}
                            defaultValue={calendarDate}
                            bordered={false}
                            onChange={date => {
                              setCalendarDate(date)
                            }}
                            format={"D.M.YYYY"}
                            allowClear={false}
                          />
                          <Button
                            className="datepicker-events-btn-2 p-0"
                            color="transparent"
                            onClick={() => {
                              setCalendarDate(
                                moment(calendarDate.add(1, "days")),
                                groupEvents(calendarEvents)
                              )
                            }}
                          >
                            <i className="mdi mdi-chevron-right ms-0" />
                          </Button>
                        </div>
                        <div className="flex-1">
                          <span className="float-end ">
                            <Radio.Group
                              buttonStyle=""
                              className="group-call-range"
                              onChange={onChange}
                              defaultValue={1}
                            >
                              <Radio.Button value={1}>
                                {props.t("Week")}
                              </Radio.Button>
                              <Radio.Button value={2}>
                                {props.t("Day")}
                              </Radio.Button>
                            </Radio.Group>
                          </span>
                        </div>
                      </div>
                    </CardTitle>
                  )}
                  <Col xs="12"></Col>
                  {calendarLoading ? (
                    <>
                      <Col xs="12">
                        <div className="text-center my-3">
                          <div to="#" className="text-primary p-4">
                            <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                          </div>
                        </div>
                      </Col>
                    </>
                  ) : (
                    <>
                      {Object.keys(eventsByDayFiltered).length > 0 &&
                      eventsByDayFiltered !== undefined ? (
                        <div className="mt-3">
                          <SimpleBar
                            ref={scrollViewRef}
                            className="p-2 px-3"
                            style={{ maxHeight: "400px" }}
                          >
                            {Object.keys(eventsByDayFiltered).map(
                              (day, key) => (
                                <div key={key} className="">
                                  <Row>
                                    <Col xs="2" className="font-size-20 py-1">
                                      <h6 className="mb-0 mt-1 text-muted">
                                        {moment(day).format("dddd")}
                                      </h6>
                                      <div className="">
                                        {moment(day).format("D.M.")}
                                      </div>{" "}
                                    </Col>
                                    {eventsByDay[day].map(
                                      (event, eventIndex) => (
                                        <>
                                          {eventIndex !== 0 && (
                                            <Col xs="2"></Col>
                                          )}
                                          <Col xs="10">
                                            <Card
                                              data-event-id={day}
                                              outline
                                              className="shadow-none border"
                                              color={
                                                moment().isBetween(
                                                  moment(event.start).add(
                                                    -10,
                                                    "minutes"
                                                  ),
                                                  moment(event.start).add(
                                                    event.duration,
                                                    "minutes"
                                                  )
                                                )
                                                  ? "secondary"
                                                  : ""
                                              }
                                            >
                                              <CardBody>
                                                <Row>
                                                  <Col
                                                    xs="3"
                                                    className="text-truncate"
                                                  >
                                                    <h5 className="text-muted">
                                                      {moment(
                                                        event.start
                                                      ).format("HH:mm")}{" "}
                                                      -{" "}
                                                      {moment(event.start)
                                                        .add(
                                                          event.duration,
                                                          "minutes"
                                                        )
                                                        .format("HH:mm")}
                                                    </h5>
                                                  </Col>
                                                  <Col xs="9">
                                                    {event.canceled ? (
                                                      <>
                                                        <span className="float-end">
                                                          <span className="badge badge-soft-danger p-2">
                                                            <i className="mdi mdi-cancel me-1" />
                                                            {props.t(
                                                              "Event canceled"
                                                            )}
                                                          </span>
                                                        </span>
                                                        <s className="h6 text-muted">
                                                          {event.title}
                                                        </s>
                                                      </>
                                                    ) : (
                                                      <>
                                                        <h6 className="mt-0">
                                                          <span className="float-end">
                                                            {event.duration}min
                                                          </span>
                                                          {event.title}
                                                        </h6>
                                                        {event.roomName !=
                                                          null &&
                                                          checkIfPresent(
                                                            event.start,
                                                            moment(
                                                              event.start
                                                            ).add(
                                                              event.duration,
                                                              "minutes"
                                                            )
                                                          ) && (
                                                            <Button
                                                              id="joinEventButton"
                                                              className="float-end ms-1 mb-2"
                                                              color="secondary"
                                                              size="sm"
                                                              onClick={() => {
                                                                joinEvent(
                                                                  event.roomName
                                                                )
                                                              }}
                                                            >
                                                              <i className="fas fa-video me-1"></i>
                                                              {props.t("Join")}
                                                            </Button>
                                                          )}
                                                      </>
                                                    )}
                                                    {event.users.map(
                                                      (user, key) => (
                                                        <div
                                                          className="text-muted"
                                                          key={key + "_user"}
                                                        >
                                                          {user.name}
                                                        </div>
                                                      )
                                                    )}
                                                  </Col>
                                                </Row>
                                              </CardBody>
                                            </Card>
                                          </Col>
                                        </>
                                      )
                                    )}
                                  </Row>
                                </div>
                              )
                            )}
                          </SimpleBar>
                        </div>
                      ) : (
                        <div className="p-3 text-center">
                          {props.t("No group call events")}
                        </div>
                      )}
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

const Customers = props => {
  const [chosen, setChosen] = useState([])
  useEffect(() => {
    // console.log(props.chosen, "customers item")
    setChosen(props.chosen)
  }, [props.chosen])

  // Change chosen from Customers
  const handleChangeChosen = key => {
    console.log(key, chosen, "choose / unchoose")

    if (chosen.includes(key)) {
      let c = chosen.filter(x => x !== key)
      setChosen(c)
      props.setGroupInviteChosen(c)
      // console.log("includes")
    } else {
      let c = chosen.push(key)
      setChosen(c)
      props.setGroupInviteChosen(c)
      // console.log("does not include")
    }
  }

  const getStatusStylingVar = status => {
    switch (status) {
      case "offline":
        return "badge badge-soft-gray font-size-12 p-2"
      case "online":
        return "badge badge-soft-success font-size-10 p-2"
      default:
        return "badge badge-soft-primary font-size-12 p-2"
    }
  }
  return (
    <>
      {props.groups?.map((g, index) => (
        <ul key={index + g} className="list-group list-group-flush">
          {props.groups.length > 1 && (
            <div className="text-muted font-size-12">
              <i className="mdi mdi-account-group me-1" />
              {g}
            </div>
          )}
          {props.data?.map((guest, index) => (
            <div key={guest.key}>
              {g === guest.group && (
                <li className="list-group p-2">
                  <Col lg="12">
                    <Row>
                      {props.groupInvitation ? (
                        <Col>
                          <div className="form-check  ">
                            <input
                              checked={chosen.includes(guest.key)}
                              type="checkbox"
                              className="form-check-input"
                              id={guest.key}
                              value={guest.key}
                              onChange={() => handleChangeChosen(guest.key)}
                            />
                            <label
                              className="form-check-label ms-1 me-2"
                              htmlFor={guest.key}
                            >
                              <div>
                                <span className="h6">{guest.name}</span>
                                <span className="text-muted font-size-11">
                                  {guest.statusDescription === "Available" ? (
                                    <div className="text-success">
                                      {props.t(guest.statusDescription)}
                                    </div>
                                  ) : (
                                    <>
                                      {guest.statusDescription ===
                                      "Unavailable" ? (
                                        <div className="text-muted">
                                          {props.t("Last active")}{" "}
                                          {guest.last_online
                                            ? formatDateSimple(
                                                guest.last_online
                                              )
                                            : "-"}
                                        </div>
                                      ) : (
                                        <div className="text-primary">
                                          {props.t(guest.statusDescription)}
                                        </div>
                                      )}
                                    </>
                                  )}
                                </span>
                              </div>
                            </label>
                          </div>
                        </Col>
                      ) : (
                        <>
                          <Col>
                            <span>
                              {guest.statusDescription === "Available" ? (
                                <i className="bx bxs-circle text-success me-2 font-size-10"></i>
                              ) : (
                                <>
                                  {guest.statusDescription === "Unavailable" ? (
                                    <i className="bx bx-circle text-gray me-2 font-size-10"></i>
                                  ) : (
                                    <i className="bx bx-circle text-primary me-2 font-size-10"></i>
                                  )}
                                </>
                              )}

                              <span className="h6">{guest.name}</span>
                              <div
                                style={{
                                  marginInlineStart: "18px",
                                }}
                                className="text-muted font-size-11"
                              >
                                {guest.statusDescription === "Available" ? (
                                  <div className="text-success">
                                    {props.t(guest.statusDescription)}
                                  </div>
                                ) : (
                                  <>
                                    {guest.statusDescription ===
                                    "Unavailable" ? (
                                      <div className="text-muted">
                                        {props.t("Last active")}{" "}
                                        {guest.last_online
                                          ? formatDateSimple(guest.last_online)
                                          : "-"}
                                      </div>
                                    ) : (
                                      <div className="text-primary">
                                        {props.t(guest.statusDescription)}
                                      </div>
                                    )}
                                  </>
                                )}
                              </div>
                            </span>
                          </Col>
                          <Col
                            className="col-auto d-flex"
                            style={{ alignItems: "center" }}
                          >
                            {guest.status !== "online" ? (
                              <div className="text-muted font-size-10"></div>
                            ) : (
                              <></>
                            )}
                          </Col>
                          <Col
                            className="col-auto d-flex"
                            style={{ alignItems: "center" }}
                          >
                            <ModalSend
                              t={props.t}
                              data={guest}
                              index={index}
                            ></ModalSend>

                            <TooltipItemVar
                              t={props.t}
                              data={guest}
                              keyId={guest.key}
                              index={index}
                            />
                          </Col>
                        </>
                      )}
                    </Row>
                  </Col>
                </li>
              )}
            </div>
          ))}
        </ul>
      ))}
    </>
  )
}
const TooltipItemVar = props => {
  return (
    <>
      <i
        id={"info_" + props.index + props.keyId}
        className="text-muted bx bx-info-circle ms-1"
      ></i>
      <UncontrolledTooltip
        placement="right"
        target={"info_" + props.index + props.keyId}
      >
        <div className="d-grid">
          <span>{props.t("Group") + ": " + props.data.group}</span>
          {props.data.statusDescription !== "Available" &&
            props.data.last_online && (
              <span>
                {props.t("Last active") +
                  ": " +
                  formatDateFull(props.data.last_online)}
              </span>
            )}
          {props.data.device_battery != undefined && (
            <span>
              {props.t("Device battery level") +
                ": " +
                props.data.device_battery +
                "%"}
            </span>
          )}
        </div>
      </UncontrolledTooltip>
    </>
  )
}
const ModalSend = props => {
  const abortRef = useRef(null)
  // Disable button when saving
  const [saveDisable, setSaveDisable] = useState(false)
  // Video message entry
  const [newMessageVideo, setNewMessageVideo] = useState("")
  const [newResponseOption, setNewResponseOption] = useState(1)
  const [newExpirationTime, setNewExpirationTime] = useState(15)
  const [newAlarmExpiration, setNewAlarmExpiration] = useState(false)
  const [audioAlert, setAudioAlert] = useState(true)
  // Modal
  const [modal, setModal] = useState(false)
  // Validation
  const [errorMessage, setErrorMessage] = useState(null)
  const [successMessage, setSuccessMessage] = useState(null)
  //
  useEffect(() => {
    abortRef.current = new AbortController()
    return () => abortRef.current.abort()
  }, [])

  const handleResponseOptions = e => {
    console.log(e, "response")
    setNewResponseOption(e)
  }
  // Setting alarm
  const settingExAlarm = () => {
    setNewAlarmExpiration(!newAlarmExpiration)
  }
  // Setting audio alarm
  const settingAudioAlarm = () => {
    setAudioAlert(!audioAlert)
  }
  // Slider expiration time
  const marks = [
    {
      value: 0,
      label: "0",
    },
    {
      value: 15,
      label: "15",
    },
    {
      value: 30,
      label: "30",
    },
    {
      value: 45,
      label: "45",
    },
    {
      value: 60,
      label: "60",
    },
  ]
  // format slider
  const handleSlider = (event, newValue) => {
    if (typeof newValue === "number") {
      setNewExpirationTime(newValue)
    }
  }
  // Formatting minutes
  const formatMinutes = value => {
    let Hours = Math.floor(value / 60)
    let minutes = value % 60

    let resultH = ""
    let resultM = ""

    if (Hours > 0) {
      resultH = Hours + "h"
    }
    if (Hours == 0) {
      resultH = ""
    }
    if (minutes > 0) {
      resultM = minutes + "min"
    }
    if (minutes == 0) {
      resultM = ""
    }
    if (minutes == 0 && Hours == 0) {
      resultM = minutes + "min"
    }

    return resultH + " " + resultM
  }
  //  ** START OF MODAL FUNCTIONS ** //
  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }
  const openMessageModal = () => {
    removeBodyCss()
    setNewMessageVideo("")
    setModal(!modal)
  }

  // New video message
  const validationVideoMessage = useFormik({
    enableReinitialize: true,
    initialValues: {
      newMessageVideo: newMessageVideo,
    },
    validationSchema: Yup.object({
      newMessageVideo: Yup.string()
        .required(props.t("Please enter your message"))
        .min(5, props.t("Message is too short"))
        .max(50, props.t("Message is too long")),
    }),
    onSubmit: values => {
      setErrorMessage(null)
      setSuccessMessage(null)
      setSaveDisable(true)
      addVideoMessage(
        abortRef.current,
        props.data.key,
        values.newMessageVideo,
        newResponseOption,
        newExpirationTime,
        newAlarmExpiration,
        audioAlert
      )
        .then(result => {
          console.log(result)
          if (result.statusCode != null && result.statusCode == 200) {
            setSuccessMessage("Success")
            openMessageModal()

            // Set to formik
            setNewMessageVideo("")
            setNewResponseOption(1)
            setNewExpirationTime(15)
            setNewAlarmExpiration(false)
            setAudioAlert(true)
          } else {
            setErrorMessage("Error")
          }
        })
        .catch(err => {
          console.log(err)
          setErrorMessage("Error")
        })
        .finally(() => {
          setSaveDisable(false)
        })
    },
  })

  return (
    <>
      <Button
        id={"modal_" + props.index + "_" + props.data.key}
        color="transparent"
        onClick={() => {
          setModal(true)
        }}
      >
        <i className="text-muted mdi mdi-message-text-clock "></i>
      </Button>
      <UncontrolledTooltip
        placement="right"
        target={"modal_" + props.index + "_" + props.data.key}
      >
        <div className="d-grid">
          <span>{props.t("Send a message")}</span>
        </div>
      </UncontrolledTooltip>
      <Modal
        isOpen={modal}
        toggle={() => {
          setModal(!modal)
        }}
        centered
      >
        <ModalHeader>
          {props.t("Send a message to")} {props.data.name}
        </ModalHeader>

        <Form
          onSubmit={e => {
            e.preventDefault()
            return false
          }}
        >
          <ModalBody>
            <div className="mt-1">
              <Label>{props.t("Message")}</Label>
              {errorMessage ? (
                <Alert color="danger">{props.t(errorMessage)}</Alert>
              ) : null}
              {successMessage ? (
                <Alert color="success">{props.t(successMessage)}</Alert>
              ) : null}
              <Input
                type="text"
                maxLength="50"
                name="newMessageVideo"
                onChange={validationVideoMessage.handleChange}
                onBlur={validationVideoMessage.handleBlur}
                value={validationVideoMessage.values.newMessageVideo || ""}
                invalid={
                  validationVideoMessage.touched.newMessageVideo &&
                  validationVideoMessage.errors.newMessageVideo
                    ? true
                    : false
                }
                className="form-control"
                placeholder={props.t("max. 50 characters")}
              />
              {validationVideoMessage.touched.newMessageVideo &&
              validationVideoMessage.errors.newMessageVideo ? (
                <FormFeedback type="invalid">
                  {validationVideoMessage.errors.newMessageVideo}
                </FormFeedback>
              ) : null}
            </div>
            <div className="mt-4">
              <Row>
                <Col xs={12}>
                  <FormGroup>
                    <Label>{props.t("Response options")}</Label>
                    <Input
                      name="stage"
                      type="select"
                      onChange={e => handleResponseOptions(e.target.value)}
                    >
                      <option value={1}>{props.t("Ok")}</option>
                      <option value={2}>{props.t("Yes / No")}</option>
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
            </div>
            <div className="mt-2">
              <Label>
                {props.t("Message expiration time")}{" "}
                {formatMinutes(newExpirationTime)}
              </Label>
              <div className="p-2">
                <Box sx={{ width: "100%" }}>
                  <Slider
                    size="small"
                    valueLabelDisplay="auto"
                    aria-labelledby="non-linear-slider"
                    defaultValue={30}
                    getAriaValueText={formatMinutes}
                    valueLabelFormat={formatMinutes}
                    step={1}
                    onChange={handleSlider}
                    marks={marks}
                    min={0}
                    value={newExpirationTime}
                    max={60}
                  />
                </Box>
              </div>
            </div>
            <Row>
              <Col xs={6}>
                <div className="mt-4 mb-0">
                  <FormGroup>
                    <Label>{props.t("Expiration alarm")}</Label>
                    <Switch
                      className="ms-2"
                      name="arrange_time"
                      checked={newAlarmExpiration}
                      onChange={settingExAlarm}
                      checkedChildren={
                        <i className="mdi mdi-bell-check-outline align-middle font-size-14" />
                      }
                      unCheckedChildren={
                        <i className="mdi mdi-bell-off-outline align-middle font-size-14" />
                      }
                    ></Switch>
                  </FormGroup>
                </div>
              </Col>
              <Col xs={6}>
                <div className="mt-4 mb-0">
                  <FormGroup>
                    <Label>{props.t("Message tone")}</Label>
                    <Switch
                      className="ms-2"
                      name="arrange_time"
                      checked={audioAlert}
                      onChange={settingAudioAlarm}
                      checkedChildren={
                        <i className="mdi mdi-bell-check-outline align-middle font-size-14" />
                      }
                      unCheckedChildren={
                        <i className="mdi mdi-bell-off-outline align-middle font-size-14" />
                      }
                    ></Switch>
                    <div id="info-circle" className=" d-md-inline-block">
                      <Button
                        className="pz-2 py-0"
                        size="sm"
                        style={{ cursor: "default" }}
                        color="tranparent"
                        onClick={() => {}}
                      >
                        <i className="bx bx-info-circle font-size-10 " />
                      </Button>
                    </div>
                    <UncontrolledTooltip placement="right" target="info-circle">
                      {props.t("Video device message tone on/off")}
                    </UncontrolledTooltip>
                  </FormGroup>
                </div>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter className="">
            <div className="float-end">
              <button
                type="button"
                onClick={() => {
                  openMessageModal()
                }}
                className="btn btn-outline-primary "
                data-dismiss="modal"
              >
                {props.t("Cancel")}
              </button>

              <button
                disabled={saveDisable}
                className="btn btn-primary ms-2"
                onClick={() => {
                  validationVideoMessage.submitForm()
                }}
              >
                {props.t("Save entry")}
              </button>
            </div>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  )
}

GroupCallServices.propTypes = {
  t: PropTypes.any,
  handleChangeChosen: PropTypes.func,
}

Customers.propTypes = {
  t: PropTypes.any,
  data: PropTypes.any,
  key: PropTypes.any,
  index: PropTypes.number,
  title: PropTypes.any,
  groups: PropTypes.any,
  invitationValue: PropTypes.any,
  groupInvitation: PropTypes.bool,
  chosen: PropTypes.any,
}

TooltipItemVar.propTypes = {
  t: PropTypes.any,
  data: PropTypes.any,
  index: PropTypes.number,
  keyId: PropTypes.string,
}
ModalSend.propTypes = {
  t: PropTypes.any,
  data: PropTypes.any,
  index: PropTypes.number,
}
export default withTranslation()(GroupCallServices)
