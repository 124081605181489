import React, { useEffect, useRef, useState } from "react"
import {
  Card,
  CardBody,
  Row,
  Col,
  Form,
  Input,
  Label,
  FormGroup,
  Button,
  Alert,
  CardTitle,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"
import PropTypes from "prop-types"
import { Route } from "react-router-dom"
import { withTranslation } from "react-i18next"
import { DatePicker, Drawer, Switch } from "antd"
import {
  deleteVideoClient,
  updateVideoClient,
  updateVideoRoom,
  videoForceRefresh,
  videoGetClientStatus,
} from "helpers/backend_helper"
import * as Yup from "yup"
import { useFormik } from "formik"
import { showToast } from "utils/general"
import withRouter from "components/Common/withRouter"

const VideoRoomDrawer = props => {
  const [clientError, setClientError] = useState("")
  const [clientSuccess, setClientSuccess] = useState("")
  const [clientUpdate, setClientUpdate] = useState(false)

  const [updateError, setUpdateError] = useState("")
  const [updateSuccess, setUpdateSuccess] = useState("")
  const [update, setUpdate] = useState(false)
  const [refreshOngoing, setRefreshOngoing] = useState(false)
  const [statusOngoing, setStatusOngoing] = useState(false)

  const [deleteModal, setDeleteModal] = useState(false)
  const [ongoingDelete, setOngoingDelete] = useState(false)

  const abortRef = useRef(null)
  useEffect(() => {
    abortRef.current = new AbortController()
    return () => abortRef.current.abort()
  }, [])

  // Update client validation
  const clientValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      roomName: props.selectedRow?.roomName || "",
      newClientName: props.selectedRow?.clientName || "",
      newClientPassword: "",
      callRequestTimeout: props.selectedRow?.callRequestTimeout || "",
      idleDisplayMode: props.selectedRow?.idleDisplayMode || "",
      groupCallHost: props.selectedRow?.groupCallHost || false,
      groupCallGuest: props.selectedRow?.groupCallGuest || false,
      callbackEnabled: props.selectedRow?.callbackEnabled || false,
      feedbackEnabled: props.selectedRow?.feedbackEnabled || false,
    },
    validationSchema: Yup.object({}),
    onSubmit: values => {
      console.log("Submit values: ", values)
      setClientSuccess("")
      setClientError("")
      setClientUpdate(true)
      updateVideoClient(
        abortRef.current,
        values.roomName,
        values.newClientPassword,
        values.newClientName,
        values.callbackEnabled,
        values.feedbackEnabled,
        values.groupCallHost,
        values.groupCallGuest,
        parseInt(values.idleDisplayMode, 10),
        parseInt(values.callRequestTimeout, 10)
      )
        .then(result => {
          console.log(result)
          if (result.statusCode == 200) {
            setClientSuccess("Updated")
            props.getRooms()
          }
          else {
            setClientError("Error")
            if (result.statusMessage) {
              setClientError(""+result.statusMessage)
            }
          }
        })
        .catch(err => {
          console.log(err)
          setClientError("Error")
        })
        .finally(() => {
          setClientUpdate(false)
        })
    },
  })

  const roomValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      password: "",
      stage: props.selectedRow?.stage,
      videoStartingDate: props.selectedRow?.videoStartingDate,
      videoEndingDate: props.selectedRow?.videoEndingDate,
      groupStartingDate: props.selectedRow?.groupStartingDate,
      groupEndingDate: props.selectedRow?.groupEndingDate,
      healthStartingDate: props.selectedRow?.healthStartingDate,
      healthEndingDate: props.selectedRow?.healthEndingDate,
    },
    validationSchema: Yup.object({}),
    onSubmit: values => {
      let temp = JSON.parse(JSON.stringify(values))
      if (values.password.length > 0) temp.password = values.password
      temp.videoStartingDate = values.videoStartingDate
        ? values.videoStartingDate.valueOf()
        : 0
      temp.videoEndingDate = values.videoEndingDate
        ? values.videoEndingDate.valueOf()
        : 0
      temp.groupStartingDate = values.groupStartingDate
        ? values.groupStartingDate.valueOf()
        : 0
      temp.groupEndingDate = values.groupEndingDate
        ? values.groupEndingDate.valueOf()
        : 0
      temp.healthStartingDate = values.healthStartingDate
        ? values.healthStartingDate.valueOf()
        : 0
      temp.healthEndingDate = values.healthEndingDate
        ? values.healthEndingDate.valueOf()
        : 0

      console.log("Submit values: ", temp)

      setUpdateSuccess("")
      setUpdateError("")
      setUpdate(true)
      updateVideoRoom(abortRef.current, props.selectedRow.roomName, temp)
        .then(result => {
          console.log(result)
          setUpdate(false)
          setUpdateSuccess("Updated")
          props.getRooms()
        })
        .catch(err => {
          console.log(err)
          setUpdate(false)
          setUpdateError("Error")
        })
    },
  })

  // Force client refresh
  const forceRefreshClient = () => {
    setRefreshOngoing(true)
    videoForceRefresh(abortRef.current, props.selectedRow.roomName)
      .then(result => {
        console.log(result)
        showToast(props.t("Sent"), "success")
        setRefreshOngoing(false)
        //props.getRooms()
      })
      .catch(err => {
        console.log(err)
        showToast(props.t("Error"), "error")
        setRefreshOngoing(false)
      })
  }

  // Force client refresh
  const getClientStatus = () => {
    setStatusOngoing(true)
    videoGetClientStatus(abortRef.current, props.selectedRow.roomName)
      .then(result => {
        console.log(result)
        showToast(props.t("Sent"), "success")
        setStatusOngoing(false)
        props.getRooms()
      })
      .catch(err => {
        console.log(err)
        showToast(props.t("Error"), "error")
        setStatusOngoing(false)
      })
  }

  // Delete room
  const deleteRoom = () => {
    if (
      props.selectedRow.roomName != null &&
      props.selectedRow.roomName.length > 0
    ) {
      setOngoingDelete(true)
      deleteVideoClient(abortRef.current, props.selectedRow.roomName)
        .then(result => {
          if (result.statusCode === 200) {
            setDeleteModal(false)
            showToast(props.t("Deleted"), "success")
            props.getRooms()
            props.setShowDrawer(false)
          } else {
            showToast(props.t("Error"), "error")
          }
          setOngoingDelete(false)
        })
        .catch(err => {
          showToast(props.t("Error"), "error")
          setOngoingDelete(false)
        })
    }
  }

  return (
    <>
      <Drawer
        className="custom"
        title={props.t("Video number") + " " + props.selectedRow?.roomName}
        //width={1200}
        //width={"80%"}
        placement="right"
        onClose={() => {
          props.setShowDrawer(false)
          setClientError("")
          setClientSuccess("")
          setUpdateError("")
          setUpdateSuccess("")
        }}
        open={props.showDrawer}
        zIndex={1003}
        bodyStyle={{ backgroundColor: "#f8f8f8" }}
      >
        <Row xs={1}>
          <Col>
            <Card>
              <CardBody>
                <CardTitle className="mb-4">
                  {props.t("Video number settings")}
                </CardTitle>
                <Form
                  className="form-horizontal"
                  onSubmit={e => {
                    e.preventDefault()
                    return false
                  }}
                >
                  <Row xs={2}>
                    <Col>
                      <FormGroup>
                        <Label>{props.t("Name")}</Label>
                        <Input
                          bsSize="sm"
                          name="newClientName"
                          type="text"
                          value={clientValidation.values.newClientName}
                          onChange={clientValidation.handleChange}
                        ></Input>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>{props.t("New password")}</Label>
                        <Input
                          bsSize="sm"
                          name="newClientPassword"
                          type="text"
                          value={clientValidation.values.newClientPassword}
                          onChange={clientValidation.handleChange}
                        ></Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row xs={2}>
                    <Col>
                      <FormGroup>
                        <Label>{props.t("Call request timeout")}</Label>
                        <Input
                          bsSize="sm"
                          name="callRequestTimeout"
                          type="number"
                          value={clientValidation.values.callRequestTimeout}
                          onChange={clientValidation.handleChange}
                        ></Input>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>{props.t("Idle display")}</Label>
                        <Input
                          bsSize="sm"
                          name="idleDisplayMode"
                          type="select"
                          value={clientValidation.values.idleDisplayMode}
                          onChange={clientValidation.handleChange}
                        >
                          <option value="1">{props.t("Default")}</option>
                          <option value="2">
                            {props.t("Show navigation")}
                          </option>
                          <option value="3">{props.t("Hide all")}</option>
                          <option value="4">{props.t("Hide buttons")}</option>
                          <option value="5">
                            {props.t("Hide local video")}
                          </option>
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row xs={2}>
                    <Col>
                      <FormGroup className="mt-2">
                        <Switch
                          className="me-2"
                          name="groupCallHost"
                          checked={clientValidation.values.groupCallHost}
                          onChange={v =>
                            clientValidation.setFieldValue("groupCallHost", v)
                          }
                        ></Switch>
                        <Label>{props.t("Group host")}</Label>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup className="mt-2">
                        <Switch
                          className="me-2"
                          name="groupCallGuest"
                          checked={clientValidation.values.groupCallGuest}
                          onChange={v =>
                            clientValidation.setFieldValue("groupCallGuest", v)
                          }
                        ></Switch>
                        <Label>{props.t("Group guest")}</Label>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row xs={2}>
                    <Col>
                      <FormGroup className="mt-2">
                        <Switch
                          className="me-2"
                          name="callbackEnabled"
                          checked={clientValidation.values.callbackEnabled}
                          onChange={v =>
                            clientValidation.setFieldValue("callbackEnabled", v)
                          }
                        ></Switch>
                        <Label>{props.t("Callback")}</Label>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup className="mt-2">
                        <Switch
                          className="me-2"
                          name="feedbackEnabled"
                          checked={clientValidation.values.feedbackEnabled}
                          onChange={v =>
                            clientValidation.setFieldValue("feedbackEnabled", v)
                          }
                        ></Switch>
                        <Label>{props.t("Feedback")}</Label>
                      </FormGroup>
                    </Col>
                  </Row>

                  <div className="mt-2 mb-3 d-flex justify-content-center">
                    <Button
                      color="primary"
                      disabled={
                        !props.selectedRow?.clientPassword || clientUpdate
                      }
                      style={{ minWidth: "150px" }}
                      onClick={() => {clientValidation.submitForm()}}
                    >
                      {props.t("Save")}
                    </Button>
                  </div>
                  {!props.selectedRow?.clientPassword && (
                    <Alert color="danger">{props.t("Unknown password")}</Alert>
                  )}
                  {clientError ? (
                    <Alert color="danger">{props.t(clientError)}</Alert>
                  ) : null}
                  {clientSuccess ? (
                    <Alert color="success">{props.t(clientSuccess)}</Alert>
                  ) : null}
                </Form>
              </CardBody>
            </Card>
          </Col>

          {/** OTHER CARD */}
          <Col>
            <Card>
              <CardBody>
                <CardTitle className="mb-4">
                  {props.t("Other information")}
                </CardTitle>
                <Form
                  className="form-horizontal"
                  onSubmit={e => {
                    e.preventDefault()
                    return false
                  }}
                >
                  <Row xs={2}>
                    <Col>
                      <FormGroup>
                        <Label>{props.t("Stage")}</Label>
                        <Input
                          bsSize="sm"
                          name="stage"
                          type="select"
                          value={roomValidation.values.stage}
                          onChange={roomValidation.handleChange}
                        >
                          <option value="idle">{props.t("Idle")}</option>
                          <option value="test">{props.t("Test")}</option>
                          <option value="prod">{props.t("Production")}</option>
                          <option value="">{props.t("Undefined")}</option>
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>{props.t("Password")}</Label>
                        <Input
                          bsSize="sm"
                          name="password"
                          type="text"
                          placeholder={props.t(
                            "Must match password set in AC portal"
                          )}
                          value={roomValidation.values.password}
                          onChange={roomValidation.handleChange}
                        ></Input>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row xs={2}>
                    <Col>
                      <FormGroup>
                        <Label>{props.t("Video start")}</Label>
                        <DatePicker
                          size={"small"}
                          style={{ width: "100%" }}
                          format="DD.MM.YYYY"
                          value={roomValidation.values.videoStartingDate}
                          onChange={v =>
                            roomValidation.setFieldValue("videoStartingDate", v)
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>{props.t("Video end")}</Label>
                        <DatePicker
                          size={"small"}
                          style={{ width: "100%" }}
                          format="DD.MM.YYYY"
                          value={roomValidation.values.videoEndingDate}
                          onChange={v =>
                            roomValidation.setFieldValue("videoEndingDate", v)
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row xs={2}>
                    <Col>
                      <FormGroup>
                        <Label>{props.t("Group start")}</Label>
                        <DatePicker
                          size={"small"}
                          style={{ width: "100%" }}
                          format="DD.MM.YYYY"
                          value={roomValidation.values.groupStartingDate}
                          onChange={v =>
                            roomValidation.setFieldValue("groupStartingDate", v)
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>{props.t("Group end")}</Label>
                        <DatePicker
                          size={"small"}
                          style={{ width: "100%" }}
                          format="DD.MM.YYYY"
                          value={roomValidation.values.groupEndingDate}
                          onChange={v =>
                            roomValidation.setFieldValue("groupEndingDate", v)
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row xs={2}>
                    <Col>
                      <FormGroup>
                        <Label>{props.t("Health start")}</Label>
                        <DatePicker
                          size={"small"}
                          style={{ width: "100%" }}
                          format="DD.MM.YYYY"
                          value={roomValidation.values.healthStartingDate}
                          onChange={v =>
                            roomValidation.setFieldValue(
                              "healthStartingDate",
                              v
                            )
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup>
                        <Label>{props.t("Health end")}</Label>
                        <DatePicker
                          size={"small"}
                          style={{ width: "100%" }}
                          format="DD.MM.YYYY"
                          value={roomValidation.values.healthEndingDate}
                          onChange={v =>
                            roomValidation.setFieldValue("healthEndingDate", v)
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <div className="mt-2 mb-3 d-flex justify-content-center">
                    <Button
                      color="primary"
                      disabled={update}
                      style={{ minWidth: "150px" }}
                      onClick={() => {roomValidation.submitForm()}}
                    >
                      {props.t("Save")}
                    </Button>
                  </div>
                  {updateError ? (
                    <Alert color="danger">{props.t(updateError)}</Alert>
                  ) : null}
                  {updateSuccess ? (
                    <Alert color="success">{props.t(updateSuccess)}</Alert>
                  ) : null}
                </Form>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <CardTitle className="mb-4">{props.t("Actions")}</CardTitle>
                <ListGroup className="list-group-flush">
                  <ListGroupItem className="py-4">
                    <Row>
                      <Col>
                        <div>
                          <h6>{props.t("Refresh video status")}</h6>
                          <span className="text-muted">
                            {props.t("Query current video status from API")}
                          </span>
                        </div>
                      </Col>
                      <Col className="col-auto">
                        <Button
                          color="primary"
                          style={{ minWidth: "100px" }}
                          onClick={() => {
                            getClientStatus()
                          }}
                          disabled={
                            !props.selectedRow?.clientPassword || statusOngoing
                          }
                        >
                          {props.t("Send")}
                        </Button>
                      </Col>
                    </Row>
                  </ListGroupItem>
                  <ListGroupItem className="py-4">
                    <Row>
                      <Col>
                        <div>
                          <h6>{props.t("Page refresh")}</h6>
                          <span className="text-muted">
                            {props.t(
                              "Force the video client to refresh the webpage"
                            )}
                          </span>
                        </div>
                      </Col>
                      <Col className="col-auto">
                        <Button
                          color="primary"
                          style={{ minWidth: "100px" }}
                          onClick={() => {
                            forceRefreshClient()
                          }}
                          disabled={
                            !props.selectedRow?.clientPassword || refreshOngoing
                          }
                        >
                          {props.t("Refresh")}
                        </Button>
                      </Col>
                    </Row>
                  </ListGroupItem>
                  <ListGroupItem className="pb-4">
                    <Row>
                      <Col>
                        <div>
                          <h6>{props.t("Deletion")}</h6>
                          <span className="text-muted">
                            {props.t(
                              "The device and all related data will be deleted permanently"
                            )}
                          </span>
                        </div>
                      </Col>
                      <Col className="col-auto">
                        <Button
                          color="danger"
                          style={{ minWidth: "100px" }}
                          onClick={() => {
                            setDeleteModal(true)
                          }}
                        >
                          {props.t("Delete")}
                        </Button>
                      </Col>
                    </Row>
                  </ListGroupItem>
                </ListGroup>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Drawer>

      <Modal
        isOpen={deleteModal}
        toggle={() => {
          setDeleteModal(!deleteModal)
        }}
        centered
      >
        <ModalHeader>{props.t("Delete confirmation")}</ModalHeader>
        <ModalBody>
          <p>
            {props.t("Are you sure you want to delete") +
              " " +
              props.selectedRow?.roomName +
              "?"}
          </p>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            outline
            onClick={() => {
              setDeleteModal(false)
            }}
          >
            {props.t("Cancel")}
          </Button>
          <Button
            disabled={ongoingDelete}
            color="danger"
            onClick={() => {
              deleteRoom()
            }}
          >
            {props.t("Delete")}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

VideoRoomDrawer.propTypes = {
  t: PropTypes.any,
  showDrawer: PropTypes.bool,
  setShowDrawer: PropTypes.any,
  selectedRow: PropTypes.any,
  getRooms: PropTypes.func,
}

export default withRouter(withTranslation()(VideoRoomDrawer))
