import React, { useEffect, useRef, useState } from "react"
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Button,
  Form,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  FormFeedback,
  Tooltip,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Label,
  Alert,
  UncontrolledTooltip,
  Collapse,
  CardTitle,
} from "reactstrap"
import PropTypes from "prop-types"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import moment from "moment"
import Lottie from "react-lottie"
import loadingAnim from "common/animations/loading-dots-blue"
import searchAnim from "common/animations/finddata"
import { showToast } from "utils/general"
import SelectGroup from "components/DropdownList/SelectGroupFilters"
import TagsTree from "components/DropdownList/TagsTree"
import {
  addAdminsToGroup,
  createAdminUser,
  createGroup,
  deleteGroup,
  getAdminUsers,
  removeAdminsFromGroup,
} from "helpers/backend_helper"
import AdminDrawer2 from "./admin-drawer"
import * as Yup from "yup"
import { useFormik } from "formik"
import "jspdf-autotable"
import { Table } from "antd"
import { downloadExcel, exportPDF } from "utils/general"
import AppUsersSelected from "pages/Services/app-users/app-users-selected"
import { Popconfirm, Switch } from "antd"

const AdminUsersManagement2 = props => {
  const [group, setGroup] = useState(null)
  const [users, setUsers] = useState([])
  const [filteredUsers, setFilteredUsers] = useState([])
  const [addModal, setAddModal] = useState(false)
  const [groupAddModal, setGroupAddModal] = useState(false)
  const [groupDeleteModal, setGroupDeleteModal] = useState(false)
  const [selectedRow, setSelectedRow] = useState(null)
  const [loading, setLoading] = useState(false)
  const [showDrawer, setShowDrawer] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [ongoingAdd, setOngoingAdd] = useState(false)
  const [showLinked, setShowLinked] = useState(true)
  const [updateCounter, setUpdateCounter] = useState(0)
  const [selectedUsers, setSelectedUsers] = useState([])
  const [selectedUsersObject, setSelectedUsersObject] = useState([])
  const [TTdownloadstatstable, setTTdownloadstatstable] = useState(false) // Tooltip state for download button
  const [menu, setMenu] = useState(false) //Dropdown button state
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [paginationL, setPaginationL] = useState([10, 30, 50])
  const [ellipsis, setEllipsis] = useState(true)
  const [selectedTags, setSelectedTags] = useState([])
  const [title, setTitle] = useState(props.t("Admin users"))
  const [selectedRowInfo, setSelectedRowInfo] = useState([])
  const [showOnSide, setShowOnSide] = useState(false)
  const [colSize, setColSize] = useState(12)
  const [deleteConfirm, setDeleteConfirm] = useState(false)

  const abortRef = useRef(null)
  useEffect(() => {
    document.title = title + " | Suvanto Care"
    abortRef.current = new AbortController()
    return () => abortRef.current.abort()
  }, [])

  useEffect(() => {
    //console.log("Group changed -> get users")
    if (group) getAdmins()
  }, [group, showLinked])

  // Not in use
  // useEffect(() => {
  //   if (showLinked == true) setSelectedUsers([])
  // }, [group])

  // Search or filter changed
  useEffect(() => {
    handleSearch()
  }, [searchText, users])

  // Name tags state update from app-users-selected.js
  useEffect(() => {
    // console.log("Selected users updated", selectedUsersObject, selectedUsers)
    if (selectedUsers.length == 0) {
      setSelectedUsersObject([])
      start()
    }
  }, [selectedUsers])
  // Reset form when modal changed
  useEffect(() => {
    if (showOnSide == true) {
      setColSize(9)
    } else {
      setColSize(12)
    }
  }, [showOnSide])

  // Handle search function
  const handleSearch = () => {
    let result = users.filter(
      data =>
        data.name?.toLowerCase().includes(searchText) ||
        data.description?.toLowerCase().includes(searchText) ||
        data.groups?.toLowerCase().includes(searchText) ||
        data.gsm?.toLowerCase().includes(searchText) ||
        data.email?.toLowerCase().includes(searchText) ||
        data.fullName?.toLowerCase().includes(searchText)
    )
    setFilteredUsers(result)
    settingPagination(result.length)
  }

  // Get admin users for table
  const getAdmins = () => {
    setLoading(true)
    getAdminUsers(abortRef.current, group, showLinked)
      .then(result => {
        //console.log("Admins: ", result.entries)
        if (result.statusCode == 200 && result.entries) {
          result.entries.sort((a, b) => (a.name > b.name ? 1 : -1))
          // Adding keys to for table
          const addKeys = result.entries.map(obj => {
            if (obj.key == undefined) {
              return { ...obj, key: obj.name }
            }
            return obj
          })
          setUsers(addKeys)
        }
      })
      .catch(err => {
        console.log(err)
        setUsers([])
      })
      .finally(() => {
        setLoading(false)
      })
  }

  // Link admins to a group
  const linkAdmins = () => {
    setLoading(true)
    addAdminsToGroup(abortRef.current, group, selectedUsers)
      .then(result => {
        //console.log("Link: ", result)
        if (result.statusCode == 200) {
          getAdmins()
          showToast(props.t("Success"), "success", 3000)
        } else {
          showToast(props.t("Error"), "error")
        }
      })
      .catch(err => {
        console.log(err)
        showToast(props.t("Error"), "error")
      })
      .finally(() => {
        setLoading(false)
      })
  }

  // Unlink admins from a group
  const unlinkAdmins = () => {
    setLoading(true)
    removeAdminsFromGroup(abortRef.current, group, selectedUsers)
      .then(result => {
        //console.log("Unlink: ", result)
        if (result.statusCode == 200) {
          getAdmins()
          showToast(props.t("Success"), "success", 3000)
        } else {
          showToast(props.t("Error"), "error")
        }
      })
      .catch(err => {
        console.log(err)
        showToast(props.t("Error"), "error")
      })
      .finally(() => {
        setLoading(false)
      })
  }

  // Create user validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      username: "",
    },
    validationSchema: Yup.object({
      username: Yup.string()
        .matches(
          /^[a-z0-9]+[a-z0-9-]*[a-z0-9]+[.]{1}[a-z0-9]+[a-z0-9-]*[a-z0-9]$/,
          props.t("Invalid format")
        )
        .min(7, props.t("Too short"))
        .required(props.t("Required field")),
    }),
    onSubmit: values => {
      console.log("Submit: ", values)
      if (values.username && group) {
        setOngoingAdd(true)
        createAdminUser(abortRef.current, group, values.username.toLowerCase())
          .then(result => {
            if (result.statusCode == 200) {
              showToast(props.t("Created"), "success")
              setAddModal(false)
              validation.resetForm()
              getAdmins()
            } else {
              showToast(props.t("Error"), "error")
            }
          })
          .catch(err => {
            console.log(err)
            showToast(props.t("Error"), "error")
          })
          .finally(() => {
            setOngoingAdd(false)
          })
      }
    },
  })

  // Create group validation
  const groupValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      group: "",
    },
    validationSchema: Yup.object({
      group: Yup.string()
        .matches(
          /^[A-ZÄÖÅ0-9]+[a-zäöåA-ZÄÖÅ0-9- ]*[a-zäöåA-ZÄÖÅ0-9]$/,
          props.t("Invalid format")
        )
        .min(4, props.t("Too short"))
        .required(props.t("Required field")),
    }),
    onSubmit: values => {
      console.log("Submit: ", values)
      setOngoingAdd(true)
      createGroup(abortRef.current, values.group)
        .then(result => {
          if (result.statusCode == 200) {
            showToast(props.t("Created"), "success")
            setGroupAddModal(false)
            groupValidation.resetForm()
            setUpdateCounter(prevCount => prevCount + 1)
          } else {
            showToast(props.t("Error"), "error")
          }
        })
        .catch(err => {
          console.log(err)
          showToast(props.t("Error"), "error")
        })
        .finally(() => {
          setOngoingAdd(false)
        })
    },
  })

  // Delete selected group
  const groupDelete = () => {
    if (group) {
      setOngoingAdd(true)
      deleteGroup(abortRef.current, group)
        .then(result => {
          if (result.statusCode == 200) {
            showToast(props.t("Deleted"), "success")
            setGroupDeleteModal(false)
            setGroup(null)
            setUpdateCounter(prevCount => prevCount + 1)
          } else if (result.statusCode == 403) {
            showToast(
              props.t("Operation failed") +
                ", " +
                props.t("reason") +
                ": " +
                result.reason,
              "error"
            )
          } else {
            showToast(props.t("Error"), "error")
          }
        })
        .catch(err => {
          console.log(err)
          showToast(props.t("Error"), "error")
        })
        .finally(() => {
          setOngoingAdd(false)
        })
    }
  }
  // Empty selected users
  const start = () => {
    setLoading(true)
    setTimeout(() => {
      setSelectedRowKeys([])
      setLoading(false)
    }, 200)
  }

  // Excel export
  const generateExcel = () => {
    const heading = [
      [
        props.t("Username"),
        props.t("Description"),
        props.t("Basic user"),
        props.t("Groups"),
        props.t("Linked"),
        props.t("Email"),
        props.t("GSM"),
        props.t("Connected"),
      ],
    ]
    const data = filteredUsers.map(elt => [
      elt.name,
      elt.description,
      elt.basicUser,
      elt.groups,
      elt.linked,
      elt.email,
      elt.gsm,
      dateFormatter(elt.lastConnection),
    ])

    downloadExcel(group, heading, data)
    showToast(props.t("Excel file exported succesfully!"), "success")
  }

  // PDF export
  const generatePdf = () => {
    const heading = [
      props.t("Username"),
      props.t("Description"),
      props.t("Basic user"),
      props.t("Groups"),
      props.t("Linked"),
      props.t("Email"),
      props.t("GSM"),
      props.t("Connected"),
    ]
    const data = filteredUsers.map(elt => [
      elt.name,
      elt.description,
      elt.basicUser,
      elt.groups,
      elt.linked,
      elt.email,
      elt.gsm,
      dateFormatter(elt.lastConnection),
    ])

    exportPDF(group, heading, data)
    showToast(props.t("PDF file exported succesfully!"), "success")
  }
  //

  // Table structure
  const columns2 = [
    {
      title: props.t("Actions"),
      dataIndex: "actions",
      align: "center",
      render: editFormatter,
      fixed: "left",
      width: "5%",
    },
    {
      title: props.t("Linked"),
      dataIndex: "linked",
      sorter: (a, b) => a.linked - b.linked,
      align: "center",
      render: linkedFormatter,
      width: "5%",
    },
    {
      title: props.t("Username"),
      dataIndex: "name",
      render: usernameFormatter,
      sorter: (a, b) => {
        if (a.name < b.name) {
          return -1
        }
        if (a.name > b.name) {
          return 1
        }
        return 0
      },
      width: "15%",
    },
    {
      title: props.t("Name"),
      dataIndex: "fullName",
      width: "10%",
      sorter: (a, b) => {
        if (a.fullName < b.fullName) {
          return -1
        }
        if (a.fullName > b.fullName) {
          return 1
        }
        return 0
      },
    },
    {
      title: props.t("Description"),
      dataIndex: "description",
      width: "15%",
      ellipsis: ellipsis,
    },
    {
      title: props.t("Basic user"),
      dataIndex: "basicUser",
      sorter: (a, b) => a.basicUser - b.basicUser,
      render: basicFormatter,
      align: "center",
      width: "6%",
    },
    {
      title: props.t("Groups"),
      dataIndex: "groups",
      width: "15%",
      sorter: (a, b) => {
        if (a.groups < b.groups) {
          return -1
        }
        if (a.groups > b.groups) {
          return 1
        }
        return 0
      },
      ellipsis: ellipsis,
    },
    {
      title: props.t("Email"),
      dataIndex: "email",
      sorter: (a, b) => {
        if (a.email < b.email) {
          return -1
        }
        if (a.email > b.email) {
          return 1
        }
        return 0
      },
      width: "15%",
    },
    {
      title: props.t("GSM"),
      dataIndex: "gsm",
      width: "10%",
      sorter: (a, b) => a.gsm - b.gsm,
    },
    {
      title: props.t("Connected"),
      dataIndex: "lastConnection",
      sorter: (a, b) => a.lastConnection - b.lastConnection,
      render: dateFormatter,
      width: "10%",
    },
  ]

  // Table formatting functions start
  function editFormatter(cell, row) {
    return (
      <>
        <Button
          color="primary"
          outline
          size="sm"
          onClick={() => {
            setShowDrawer(true)
            setSelectedRow(row)
          }}
        >
          <i className="mdi mdi-pencil" />
        </Button>
      </>
    )
  }

  function usernameFormatter(cell, row) {
    return (
      <>
        <span>{row.name}</span>
        {row.videoRoomId && (
          <i className="ms-2 mdi mdi-account-voice text-primary" />
        )}
      </>
    )
  }

  function linkedFormatter(cell, row) {
    if (row.linked == true) {
      return <Input type="checkbox" disabled defaultChecked={true}></Input>
    } else return <></>
  }

  function basicFormatter(cell, row) {
    if (row.basicUser == true) {
      return <i className="mdi mdi-check-bold text-primary"></i>
    } else return <>{/* <i className="mdi mdi-close text-light"></i> */}</>
  }

  function dateFormatter(value) {
    if (!value) return ""
    var dateString = moment(value).format("DD.MM.YYYY HH:mm:ss")
    return dateString
  }
  // Table formatting functions end

  // Table features + functions start when selecting one row
  const onSelectChange = (row, isSelect, rowArray) => {
    let rowObject = [{ key: row.key, name: row.name, group: row.groups }]
    if (isSelect) {
      setSelectedUsers(prevState => [...prevState, row.key])
      // Rendering name tags
      tagFunc(rowObject, isSelect)
    } else {
      let res = selectedUsers.filter(o => {
        if (o !== row.key) {
          return o
        } else {
          console.log("remove", o)
        }
      })
      setSelectedUsers(res)
      // Rendering name tags
      tagFunc(rowObject, isSelect)
    }
  }
  // Table features + functions start when selecting all
  const onSelectChangeAll = (isSelect, object, rowArray) => {
    if (rowArray) {
      let keys = rowArray.map(row => row.key)
      let objects = []
      rowArray.forEach(row => {
        let object = new Object()
        object.key = row.key
        object.name = row.name
        object.group = row.groups
        objects.push(object)
      })

      if (isSelect) {
        let selected = selectedUsers
        let arr = [...selected, ...keys]
        // Delete possible duplicates
        var newArray = []
        var newArray = arr.filter(function (elem, pos) {
          return arr.indexOf(elem) == pos
        })
        setSelectedUsers(newArray)
        // Rendering name tags
        tagFunc(objects, isSelect)
      } else {
        //Unselect current rows
        let tempSelected = mapRows(selectedUsers, keys)
        setSelectedUsers(tempSelected)
        // Rendering name tags
        tagFunc(objects, isSelect)
      }
    }
  }

  const rowSelection = {
    selectedUsers,
    onSelect: onSelectChange,
    onSelectAll: onSelectChangeAll,
  }

  // All functions for name tags
  const tagFunc = (obj, add) => {
    let selectedObjects = selectedUsersObject
    let keysTemp = obj.map(o => o.name)
    let res = []
    if (add === true) {
      res = [...selectedObjects, ...obj]
    }
    if (add === false) {
      res = selectedObjects.filter(o => !keysTemp.includes(o.name))
    }

    // Delete possible duplicates
    let result = res.filter(
      (ele, ind) =>
        ind ===
        res.findIndex(elem => elem.name === ele.name && elem.key === ele.key)
    )
    setSelectedUsersObject(result)
  }

  // Mapping rows and keys for "Unselect current rows"
  const mapRows = (selected, keys) => {
    let result = selected
    for (let i = 0; i < keys.length; i++) {
      result = result.filter(items => items !== keys[i])
    }
    return result
  }

  // Pagination dynamic length
  const settingPagination = length => {
    let start = 10
    let itemsArr = []
    if (length > start) {
      itemsArr.push(start)
    }
    for (let items = 10; items < length; ) {
      items = items + 20
      if (length > items) {
        itemsArr.push(items)
      }
    }
    itemsArr.push(length)
    setPaginationL(itemsArr)
  }
  // Table features + functions end

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title={title} />
          <Row className="mb-4">
            <Col xs="4">
              <div className="d-inline-block" style={{ width: "100%" }}>
                <TagsTree
                  loadData={true}
                  expanded={true}
                  selectionChanged={setSelectedTags}
                />
              </div>
            </Col>
            <Col xs="4">
              <SelectGroup
                onChange={setGroup}
                selectedTags={selectedTags}
                updateCounter={updateCounter}
              />
            </Col>
            <Col xs="4">
              <Button
                id="add-button"
                color="success"
                className="square-icon-button-sm"
                disabled={loading}
                onClick={() => {
                  setGroupAddModal(true)
                }}
              >
                <UncontrolledTooltip placement="bottom" target="add-button">
                  {props.t("Create a new group")}
                </UncontrolledTooltip>
                <i className="mdi mdi-account-multiple-plus font-size-18"></i>
              </Button>
              <Button
                id="delete-button"
                color="danger"
                className="square-icon-button-sm ms-3"
                disabled={!group || loading}
                onClick={() => {
                  setGroupDeleteModal(true)
                }}
              >
                <UncontrolledTooltip placement="bottom" target="delete-button">
                  {props.t("Delete group")}
                </UncontrolledTooltip>
                <i className="mdi mdi-trash-can font-size-18"></i>
              </Button>
            </Col>
          </Row>

          {!group && !loading && (
            <Card>
              <CardBody>
                <div className="text-center my-5">
                  <Lottie
                    options={{
                      loop: false,
                      autoplay: true,
                      animationData: searchAnim,
                      rendererSettings: {
                        preserveAspectRatio: "xMidYMid slice",
                      },
                    }}
                    height={150}
                    width={180}
                  ></Lottie>
                </div>
              </CardBody>
            </Card>
          )}

          {group && (
            <div>
              <Row>
                {/* Side card of chosen users */}
                {showOnSide == true && (
                  <Col sm="3">
                    <Collapse isOpen={showOnSide == true}>
                      <div className="wrapper">
                        {/*  <div className="parent">
                  <div className="sticky"> */}
                        <Card>
                          <CardBody>
                            <Row>
                              <Col className="col-auto">
                                <CardTitle className="mb-0 mt-0">
                                  {/* TODO: Translate */}
                                  {props.t("Chosen users")}
                                </CardTitle>
                              </Col>
                              {selectedUsers.length !== 0 && (
                                <Col className="pb-2">
                                  {selectedUsers.length == 1 && (
                                    <div className="float-end ms-1">
                                      <Popconfirm
                                        title={props.t("Confirm action")}
                                        open={deleteConfirm}
                                        okType="danger"
                                        okText={props.t("Delete")}
                                        cancelText={props.t("Cancel")}
                                        // onConfirm={() => {
                                        //   deleteUsers()
                                        //   setDeleteConfirm(false)
                                        // }}
                                        onCancel={() => {
                                          setDeleteConfirm(false)
                                        }}
                                      >
                                        <Button
                                          id="deleteUser"
                                          color="danger"
                                          // disabled={
                                          //   selectedUsers.length != 1 ||
                                          //   loading ||
                                          //   ongoingAction
                                          // }
                                          onClick={() => {
                                            setDeleteConfirm(true)
                                          }}
                                        >
                                          <i className="mdi mdi-trash-can" />
                                        </Button>
                                      </Popconfirm>

                                      <UncontrolledTooltip
                                        target="deleteUser"
                                        placement="bottom"
                                      >
                                        {props.t("Delete selected user")}
                                      </UncontrolledTooltip>
                                    </div>
                                  )}

                                  <div className="float-end ms-1">
                                    <Button
                                      id="unlinkUsers"
                                      color="danger"
                                      className="ms-0"
                                      disabled={selectedUsers.length == 0}
                                      onClick={() => {
                                        unlinkAdmins()
                                      }}
                                    >
                                      <i className="mdi mdi-link-variant-off" />
                                    </Button>
                                    <UncontrolledTooltip
                                      target="unlinkUsers"
                                      placement="bottom"
                                    >
                                      {props.t("Unlink selected users")}
                                    </UncontrolledTooltip>
                                  </div>
                                  <div className="float-end">
                                    <Button
                                      color="success"
                                      id="linkUsers"
                                      disabled={selectedUsers.length == 0}
                                      onClick={() => {
                                        linkAdmins()
                                      }}
                                    >
                                      <i className="mdi mdi-link-variant-plus" />
                                    </Button>
                                    <UncontrolledTooltip
                                      target="linkUsers"
                                      placement="bottom"
                                    >
                                      {props.t("Link selected users")}
                                    </UncontrolledTooltip>
                                  </div>
                                </Col>
                              )}
                            </Row>
                            <AppUsersSelected
                              setSelectedUsers={setSelectedUsers}
                              setSelectedUsersObject={setSelectedUsersObject}
                              selectedUsersObject={selectedUsersObject}
                              showOnSide={showOnSide}
                            ></AppUsersSelected>
                          </CardBody>
                        </Card>
                      </div>
                    </Collapse>
                  </Col>
                )}
                <Col sm={colSize}>
                  <Card>
                    <CardBody>
                      {users != null && (
                        <React.Fragment>
                          <Row>
                            <Col className="col-auto pe-0">
                              <Form
                                className="mt-sm-0 d-flex align-items-center"
                                onSubmit={e => {
                                  e.preventDefault()
                                  return false
                                }}
                              >
                                <div className="search-box me-2 mb-2 d-inline-block float-end">
                                  <div className="position-relative">
                                    <Input
                                      type="text"
                                      onChange={event =>
                                        setSearchText(
                                          event.target.value.toLowerCase()
                                        )
                                      }
                                      placeholder={props.t("Search")}
                                    />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Form>
                            </Col>

                            <Col className="col-auto">
                              <Button
                                style={{ borderRadius: "100px" }}
                                color="success"
                                onClick={() => {
                                  setAddModal(true)
                                }}
                              >
                                <i className="fas fa-user-plus"></i>
                                <span className="d-none d-lg-inline-block ms-2">
                                  {props.t("Create a new user")}
                                </span>
                              </Button>
                            </Col>
                            <Col className="mt-2">
                              <FormGroup>
                                <Switch
                                  className="me-2"
                                  name="showLinked"
                                  checked={showLinked}
                                  onChange={v => {
                                    setShowLinked(v)
                                  }}
                                ></Switch>
                                <Label>
                                  {props.t("Show only linked users")}
                                </Label>
                              </FormGroup>
                            </Col>
                            <Col>
                              {" "}
                              <Switch
                                id="switchScene"
                                checkedChildren={
                                  <i className="bx bx-window"></i>
                                }
                                unCheckedChildren={
                                  <i className="bx bx-list-minus"></i>
                                }
                                className="mt-1"
                                checked={showOnSide}
                                onChange={v => setShowOnSide(v)}
                              ></Switch>
                              <UncontrolledTooltip
                                target="switchScene"
                                placement="bottom"
                              >
                                {props.t("Switch view of selected users")}
                              </UncontrolledTooltip>
                            </Col>
                            <Col>
                              <span className="float-end ms-2">
                                <Tooltip
                                  placement="bottom"
                                  isOpen={TTdownloadstatstable}
                                  target="downloadstatstable"
                                  toggle={() => {
                                    setTTdownloadstatstable(
                                      !TTdownloadstatstable
                                    )
                                  }}
                                >
                                  {props.t("Download statistics")}
                                </Tooltip>
                                <Dropdown
                                  id="printButtonServicesStats"
                                  isOpen={menu}
                                  toggle={() => setMenu(!menu)}
                                  className="download-btn text-center"
                                  type="button"
                                >
                                  <DropdownToggle
                                    id="downloadstatstable"
                                    className="text-muted"
                                    tag="i"
                                  >
                                    <i className="mdi mdi-download" />
                                  </DropdownToggle>
                                  <DropdownMenu>
                                    {/* TODO: Can not choose directory file right now */}
                                    <DropdownItem
                                      onClick={() => generateExcel()}
                                    >
                                      {props.t("Download .xlsx")}
                                    </DropdownItem>
                                    <DropdownItem onClick={() => generatePdf()}>
                                      {props.t("Download .pdf")}
                                    </DropdownItem>
                                  </DropdownMenu>
                                </Dropdown>
                              </span>
                              <span className="mt-2 float-end">
                                <p>
                                  {filteredUsers.length} {props.t("users")}
                                </p>
                              </span>
                            </Col>
                          </Row>
                          <Collapse
                            isOpen={
                              selectedUsers.length > 0 && showOnSide == false
                            }
                          >
                            <Row className="py-2">
                              <AppUsersSelected
                                setSelectedUsers={setSelectedUsers}
                                setSelectedUsersObject={setSelectedUsersObject}
                                selectedUsersObject={selectedUsersObject}
                                showOnSide={showOnSide}
                              ></AppUsersSelected>
                              <Row>
                                <Col className="col-auto">
                                  <Button
                                    color="success"
                                    disabled={
                                      selectedUsers.length == 0 || loading
                                    }
                                    onClick={() => {
                                      linkAdmins()
                                    }}
                                  >
                                    <i className="mdi mdi-link-variant-plus me-2" />
                                    {props.t("Link selected users")}
                                  </Button>
                                  <Button
                                    color="danger"
                                    className="ms-3"
                                    disabled={
                                      selectedUsers.length == 0 || loading
                                    }
                                    onClick={() => {
                                      unlinkAdmins()
                                    }}
                                  >
                                    <i className="mdi mdi-link-variant-off me-2" />
                                    {props.t("Unlink selected users")}
                                  </Button>
                                  {/* <Button
                                color="primary"
                                className="ms-3"
                                onClick={start}
                                disabled={!hasSelected}
                                loading={loading}
                              >
                                <i className="mdi mdi-select-off me-2" />
                                {props.t("Remove selections")}
                              </Button> */}
                                </Col>
                              </Row>
                            </Row>
                          </Collapse>

                          {!loading && (
                            <>
                              <div className="mt-2">
                                <Table
                                  bordered
                                  size="small"
                                  rowSelection={rowSelection}
                                  columns={columns2}
                                  dataSource={filteredUsers}
                                  onRow={(record, rowIndex) => {
                                    return {
                                      onClick: event => {
                                        setEllipsis(!ellipsis)
                                      },
                                    }
                                  }}
                                  pagination={{
                                    showSizeChanger: true,
                                    pageSizeOptions: paginationL,
                                  }}
                                  // tableLayout="fixed"
                                  height="100%"
                                  scroll={{
                                    x: "2300px",
                                    y: "70vh",
                                  }}
                                  fixed
                                />
                              </div>
                            </>
                          )}
                          {loading && (
                            <Lottie
                              options={{
                                loop: false,
                                autoplay: true,
                                animationData: loadingAnim,
                                rendererSettings: {
                                  preserveAspectRatio: "xMidYMid slice",
                                },
                              }}
                              height={100}
                              width={100}
                            ></Lottie>
                          )}
                        </React.Fragment>
                      )}
                    </CardBody>
                  </Card>
                </Col>{" "}
              </Row>
            </div>
          )}

          <Modal
            isOpen={addModal}
            toggle={() => {
              setAddModal(!addModal)
            }}
            centered
          >
            <ModalHeader>{props.t("Create a new user")}</ModalHeader>
            <Form
              className="form-horizontal"
              onSubmit={e => {
                e.preventDefault()
                return false
              }}
            >
              <ModalBody>
                <Alert color={"primary"}>
                  <span>
                    {props.t("User will be linked to currently selected group")}
                  </span>
                </Alert>
                <Alert color={"primary"}>
                  <span>{props.t("UserAllowedChars")}</span>
                </Alert>
                <FormGroup>
                  <Label>{props.t("Username")}</Label>
                  <Input
                    name="username"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    placeholder={"E.g. firstname.lastname"}
                    value={validation.values.username || ""}
                    invalid={
                      validation.touched.username && validation.errors.username
                        ? true
                        : false
                    }
                  ></Input>
                  {validation.touched.username && validation.errors.username ? (
                    <FormFeedback type="invalid">
                      {validation.errors.username}
                    </FormFeedback>
                  ) : null}
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="primary"
                  outline
                  onClick={() => {
                    setAddModal(false)
                  }}
                >
                  {props.t("Cancel")}
                </Button>
                <Button
                  color="success"
                  disabled={ongoingAdd}
                  onClick={() => {
                    validation.submitForm()
                  }}
                >
                  {props.t("Add")}
                </Button>{" "}
              </ModalFooter>
            </Form>
          </Modal>

          <Modal
            isOpen={groupAddModal}
            toggle={() => {
              setGroupAddModal(!groupAddModal)
            }}
            centered
          >
            <ModalHeader>{props.t("Create a new group")}</ModalHeader>
            <Form
              className="form-horizontal"
              onSubmit={e => {
                e.preventDefault()
                return false
              }}
            >
              <ModalBody>
                <Alert color={"primary"}>
                  <span>{props.t("GroupAllowedChars")}</span>
                </Alert>
                <FormGroup>
                  <Label>{props.t("Name")}</Label>
                  <Input
                    name="group"
                    type="text"
                    onChange={groupValidation.handleChange}
                    onBlur={groupValidation.handleBlur}
                    value={groupValidation.values.group || ""}
                    invalid={
                      groupValidation.touched.group &&
                      groupValidation.errors.group
                        ? true
                        : false
                    }
                  ></Input>
                  {groupValidation.touched.group &&
                  groupValidation.errors.group ? (
                    <FormFeedback type="invalid">
                      {groupValidation.errors.group}
                    </FormFeedback>
                  ) : null}
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="primary"
                  outline
                  onClick={() => {
                    setGroupAddModal(false)
                  }}
                >
                  {props.t("Cancel")}
                </Button>
                <Button
                  color="success"
                  disabled={ongoingAdd}
                  onClick={() => {
                    groupValidation.submitForm()
                  }}
                >
                  {props.t("Add")}
                </Button>{" "}
              </ModalFooter>
            </Form>
          </Modal>

          <Modal
            isOpen={groupDeleteModal}
            toggle={() => {
              setGroupDeleteModal(!groupDeleteModal)
            }}
            centered
          >
            <ModalHeader>{props.t("Delete confirmation")}</ModalHeader>
            <ModalBody>
              <p>
                {props.t("Are you sure you want to delete") +
                  ": " +
                  group +
                  "?"}
              </p>
              <Alert color={"primary"}>
                <span>
                  {props.t(
                    "Deletion will fail if the group has any users or devices attached to it!"
                  )}
                </span>
              </Alert>
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                outline
                onClick={() => {
                  setGroupDeleteModal(false)
                }}
              >
                {props.t("Cancel")}
              </Button>
              <Button
                disabled={ongoingAdd}
                color="danger"
                onClick={() => {
                  groupDelete()
                }}
              >
                {props.t("Delete")}
              </Button>
            </ModalFooter>
          </Modal>

          <AdminDrawer2
            showDrawer={showDrawer}
            setShowDrawer={setShowDrawer}
            selectedRow={selectedRow}
            getAdmins={getAdmins}
          />
        </Container>
      </div>
    </>
  )
}

AdminUsersManagement2.propTypes = {
  t: PropTypes.any,
  selectionChanged: PropTypes.func,
}

export default withTranslation()(AdminUsersManagement2)
