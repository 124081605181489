import PropTypes from "prop-types"
import React, { useState, useEffect, useContext } from "react"
import { Collapse } from "reactstrap"
import { Link, Route } from "react-router-dom"
import classname from "classnames"

//i18n
import { withTranslation } from "react-i18next"

import GlobalState from "../../contexts/GlobalState"
import withRouter from "components/Common/withRouter"

const Navbar = props => {
  const [state, setState] = useContext(GlobalState)
  const [showDevMenu, setShowDevMenu] = useState(false)
  const [showSalesMenu, setShowSalesMenu] = useState(false)
  const [showVideoMenu, setShowVideoMenu] = useState(false)
  const [showGroupCall, setShowGroupCall] = useState(false)
  const [showVideoSystem, setShowVideoSystem] = useState(false)
  const [showMonitoringMenu, setShowMonitoringMenu] = useState(false)
  const [showAlarmMonitoring, setShowAlarmMonitoring] = useState(false)
  const [showOfflineVideos, setShowOfflineVideos] = useState(false)
  const [showReporting, setShowReporting] = useState(false)
  const [showCustomers, setShowCustomers] = useState(true)
  const [showAppointments, setShowAppointments] = useState(false)
  const [role, setRole] = useState("")
  const [devtools, setdevtools] = useState(false)
  const [videomenu, setvideomenu] = useState(false)
  const [salesmenu, setsalesmenu] = useState(false)
  const [devices, setdevices] = useState(false)
  const [logs, setlogs] = useState(false)
  const [messaging, setmessaging] = useState(false)
  const [exports, setexports] = useState(false)
  const [monitoringmenu, setmonitoringmenu] = useState(false)
  const [tests, settests] = useState(false)

  useEffect(() => {
    setMenuStates()
  }, [])

  useEffect(() => {
    setMenuStates()
  }, [state.authUser])

  useEffect(() => {
    var matchingMenuItem = null
    var ul = document.getElementById("navigation")
    var items = ul.getElementsByTagName("a")
    removeActivation(items)
    for (var i = 0; i < items.length; ++i) {
      // console.log(window.location.pathname, items[i].pathname)
      // Original
      if (window.location.pathname === items[i].pathname) {
        // if (window.location.pathname.includes(items[i].pathname)) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem)
    }
  })

  // Set menu visibility states
  const setMenuStates = () => {
    if (state.authUser != null) {
      const obj = JSON.parse(state.authUser)
      let role = obj.activeRole ? obj.activeRole : obj.role
      setRole(role)

      // Reset states first
      setShowDevMenu(false)
      setShowSalesMenu(false)
      setShowMonitoringMenu(false)
      setShowAlarmMonitoring(false)
      setShowOfflineVideos(false)
      setShowReporting(false)
      setShowGroupCall(false)
      setShowVideoMenu(false)
      setShowVideoSystem(false)
      setShowAppointments(false)

      // Allow by role
      if (role == "system_admin") {
        setShowDevMenu(true)
        setShowSalesMenu(true)
        setShowAlarmMonitoring(true)
        setShowReporting(true)

        setShowOfflineVideos(true)
        setShowMonitoringMenu(true)
        setShowVideoMenu(true)
        setShowVideoSystem(true)
        setShowAppointments(true)
      }
      if (role == "salesperson") {
        setShowSalesMenu(true)
        setShowReporting(true)
      }
      if (role == "order_manager") {
        setShowSalesMenu(true)
        setShowReporting(true)
      }
      if (role == "organisation_admin") {
        setShowReporting(true)
      }
      /*if (role != "private_user") {
        setShowCustomers(true)
      }*/
      if (obj.groupVideoId != null && obj.groupVideoId.length > 0) {
        setShowGroupCall(true)
        setShowVideoMenu(true)
      }
      if (
        obj.access_permissions != null &&
        obj.access_permissions.alarm_monitoring == true
      ) {
        setShowAlarmMonitoring(true)
        setShowMonitoringMenu(true)
      }

      if (
        obj.access_permissions != null &&
        obj.access_permissions.offline_video_monitoring == true
      ) {
        setShowOfflineVideos(true)
        setShowMonitoringMenu(true)
      }

      if (
        obj.access_permissions != null &&
        obj.access_permissions.video_system == true
      ) {
        setShowVideoSystem(true)
        setShowVideoMenu(true)
      }

      if (
        obj.access_permissions != null &&
        obj.access_permissions.appointments == true
      ) {
        setShowAppointments(true)
      }
    }
  }

  const removeActivation = items => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i]
      const parent = items[i].parentElement
      if (item && item.classList.contains("active")) {
        item.classList.remove("active")
      }
      if (parent) {
        if (parent.classList.contains("active")) {
          parent.classList.remove("active")
        }
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    if (parent) {
      parent.classList.add("active") // li
      const parent2 = parent.parentElement
      parent2.classList.add("active") // li
      const parent3 = parent2.parentElement
      if (parent3) {
        parent3.classList.add("active") // li
        const parent4 = parent3.parentElement
        if (parent4) {
          parent4.classList.add("active") // li
          const parent5 = parent4.parentElement
          if (parent5) {
            parent5.classList.add("active") // li
            const parent6 = parent5.parentElement
            if (parent6) {
              parent6.classList.add("active") // li
            }
          }
        }
      }
    }
    return false
  }

  return (
    <React.Fragment>
      <div className="topnav">
        <div className="container-fluid">
          <nav
            className="navbar navbar-light navbar-expand-lg topnav-menu"
            id="navigation"
          >
            <Collapse
              isOpen={props.leftMenu}
              className="navbar-collapse"
              id="topnav-menu-content"
            >
              <ul className="navbar-nav ">
                <li className="nav-item text-truncate">
                  <Link className="nav-link d-flex" style={{ alignItems: "center" }} to="/main">
                    <i className="bx bx-home-circle me-2"></i>
                    {props.t("Front page")}
                  </Link>
                </li>
                {/*<li className="nav-item">
                  <Link className="nav-link" to="/dashboard">
                    <i className="bx bxs-dashboard me-2"></i>
                    {props.t("Dashboard")}
                  </Link>
                </li>*/}

                {showCustomers && (
                  <li className="nav-item text-truncate">
                    <Link className="nav-link d-flex" style={{ alignItems: "center" }} to="/clients">
                      <i className="bx bxs-user me-2"></i>
                      {props.t("Customer management")}
                    </Link>
                  </li>
                )}

                {/**showGroupCall && (
                  <li className="nav-item text-truncate">
                    <Link className="nav-link d-flex" style={{ alignItems: "center" }} to="/group-call">
                      <i className="bx bxs-group me-2"></i>
                      {props.t("Group video call")}
                    </Link>
                  </li>
                )*/}

                {showVideoMenu && (
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle arrow-none d-inline-flex text-truncate"
                      onClick={e => {
                        e.preventDefault()
                        setvideomenu(!videomenu)
                      }}
                      to="/#"
                    >
                      <div className="d-flex" style={{ alignItems: "center" }}>
                        <i
                          className="bx bxs-video me-2"
                        ></i>
                      </div>
                      {props.t("Video calls")}
                      <div className="arrow-down"></div>
                    </Link>
                    <div
                      className={classname("dropdown-menu", {
                        show: videomenu,
                      })}
                    >
                      {showGroupCall && (
                        <li className="nav-item text-truncate">
                          <Link to="/group-call" className="dropdown-item">
                            {props.t("Group video call")}
                          </Link>
                        </li>
                      )}
                      {showVideoSystem && (
                        <li className="nav-item text-truncate">
                          <Link to="/config/video-system" className="dropdown-item">
                            {props.t("Video system")}
                          </Link>
                        </li>
                      )}
                    </div>
                  </li>
                )}

                {showMonitoringMenu && (
                  <li className="nav-item dropdown ">
                    <Link
                      className="nav-link dropdown-toggle arrow-none d-inline-flex text-truncate"
                      onClick={e => {
                        e.preventDefault()
                        setmonitoringmenu(!monitoringmenu)
                      }}
                      to="/#"
                    >
                      <div className="d-flex" style={{ alignItems: "center" }}>
                        <i
                          className="mdi mdi-monitor-eye me-2"
                          //style={{ paddingBottom: "2px" }}
                        ></i>
                      </div>
                      {props.t("Monitoring")}
                      <div className="arrow-down"></div>
                    </Link>
                    <div
                      className={classname("dropdown-menu", {
                        show: monitoringmenu,
                      })}
                    >
                      {showAlarmMonitoring && (
                        <Link className="dropdown-item" to="/monitoring/alarms">
                          {props.t("Alarm monitoring")}
                        </Link>
                      )}
                      {showOfflineVideos && (
                        <Link
                          className="dropdown-item"
                          to="/monitoring/offline-videos"
                        >
                          {props.t("Offline videos")}
                        </Link>
                      )}
                    </div>
                  </li>
                )}

                {showReporting && (
                  <li className="nav-item text-truncate">
                    <Link className="nav-link d-flex" style={{ alignItems: "center" }} to="/reporting">
                      <i className="bx bxs-report me-2"></i>
                      {props.t("Reports")}
                    </Link>
                  </li>
                )}

                {showSalesMenu && (
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle arrow-none d-inline-flex text-truncate"
                      onClick={e => {
                        e.preventDefault()
                        setsalesmenu(!salesmenu)
                      }}
                      to="/#"
                    >
                      <div className="d-flex" style={{ alignItems: "center" }}>
                        <i
                          className="bx bxs-dollar-circle me-2"
                          //style={{ paddingBottom: "2px" }}
                        ></i>
                      </div>
                      {props.t("Sales")}
                      <div className="arrow-down"></div>
                    </Link>
                    <div
                      className={classname("dropdown-menu", {
                        show: salesmenu,
                      })}
                    >
                      <Link
                        to="/sales/service-monitoring"
                        className="dropdown-item"
                      >
                        {props.t("Customers and services")}
                      </Link>
                      <Link to="/sales/orders" className="dropdown-item">
                        {props.t("Orders")}
                      </Link>
                    </div>
                  </li>
                )}
                {/*
                TODO: ISTEK Testing Calendar */}
                {showAppointments && (
                <li className="nav-item text-truncate">
                  <Link className="nav-link d-flex" style={{ alignItems: "center" }} to="/appointments">
                    <i className="bx bxs-calendar me-2"></i>
                    {props.t("Appointments")}
                  </Link>
                </li>
                )}

                {/** Istekki admin user management testing */}
               {/** 
                <li className="nav-item text-truncate">
                  <Link className="nav-link d-flex" style={{ alignItems: "center" }} to="/admin/user-management">
                    <i className="bx bxs-group me-2"></i>
                    {props.t("Admins")}
                  </Link>
                </li>*/}

                {showDevMenu && (
                  <li className="nav-item dropdown">
                    <Link
                      className="nav-link dropdown-toggle arrow-none text-truncate d-flex"
                      style={{ alignItems: "center" }}
                      onClick={e => {
                        e.preventDefault()
                        setdevtools(!devtools)
                      }}
                      to="/#"
                    >
                      <i className="bx bxl-dev-to me-2"></i>
                      {props.t("Admin tools")}
                      <div className="arrow-down"></div>
                    </Link>
                    <div
                      className={classname("dropdown-menu", { show: devtools })}
                    >
                      <div className="nav-item dropdown">
                        <Link
                          className="dropdown-item"
                          onClick={e => {
                            e.preventDefault()
                            setdevices(!devices)
                          }}
                          to="/#"
                        >
                          {props.t("Devices")}
                          <div className="arrow-down"></div>
                        </Link>
                        <div
                          className={classname("dropdown-menu", {
                            show: devices,
                          })}
                        >
                          <Link to="/config/gateways" className="dropdown-item">
                            {props.t("Gateways")}
                          </Link>
                          <Link to="/config/trackers" className="dropdown-item">
                            {props.t("DevMenu.Trackers")}
                          </Link>
                          <Link
                            to="/config/video-devices"
                            className="dropdown-item"
                          >
                            {props.t("Video devices")}
                          </Link>
                          <Link
                            to="/config/video-numbers"
                            className="dropdown-item"
                          >
                            {props.t("Video numbers")}
                          </Link>
                          <Link
                            to="/config/carephones"
                            className="dropdown-item"
                          >
                            {props.t("Carephones")}
                          </Link>
                          <Link to="/config/pindoras" className="dropdown-item">
                            {props.t("Smartlocks")}
                          </Link>
                          <Link to="/config/doses" className="dropdown-item">
                            {props.t("Doses")}
                          </Link>
                          <Link
                            to="/config/measuringdevices"
                            className="dropdown-item"
                          >
                            {props.t("Measuring devices")}
                          </Link>
                        </div>
                      </div>

                      <div className="nav-item dropdown">
                        <Link
                          className="dropdown-item"
                          onClick={e => {
                            e.preventDefault()
                            setlogs(!logs)
                          }}
                          to="/#"
                        >
                          {props.t("Logs")}
                          <div className="arrow-down"></div>
                        </Link>
                        <div
                          className={classname("dropdown-menu", { show: logs })}
                        >
                          <Link to="/logs/system" className="dropdown-item">
                            {props.t("System logs")}
                          </Link>
                          <Link to="/logs/gateway" className="dropdown-item">
                            {props.t("Gateway logs")}
                          </Link>
                          <Link to="/logs/sms" className="dropdown-item">
                            {props.t("SMS logs")}
                          </Link>
                          <Link to="/logs/feedback" className="dropdown-item">
                            {props.t("Feedback")}
                          </Link>
                          <Link to="/logs/audit-logs" className="dropdown-item">
                            {props.t("Audit logs")}
                          </Link>
                        </div>
                      </div>

                      <div className="nav-item dropdown">
                        <Link
                          className="dropdown-item"
                          onClick={e => {
                            e.preventDefault()
                            setmessaging(!messaging)
                          }}
                          to="/#"
                        >
                          {props.t("Messaging")}
                          <div className="arrow-down"></div>
                        </Link>
                        <div
                          className={classname("dropdown-menu", {
                            show: messaging,
                          })}
                        >
                          <Link
                            to="/messaging/releases"
                            className="dropdown-item"
                          >
                            {props.t("Release notes")}
                          </Link>
                          <Link
                            to="/messaging/errorbulletins"
                            className="dropdown-item"
                          >
                            {props.t("Error bulletins")}
                          </Link>
                          <span className="dropdown-item disabled">
                            <i className="bx bxs-lock me-2"></i>
                            {props.t("Direct messaging")}
                          </span>
                        </div>
                      </div>
                      <div className="nav-item dropdown">
                        <Link
                          className="dropdown-item"
                          onClick={e => {
                            e.preventDefault()
                            setexports(!exports)
                          }}
                          to="/#"
                        >
                          {props.t("Reporting")}
                          <div className="arrow-down"></div>
                        </Link>
                        <div
                          className={classname("dropdown-menu", {
                            show: exports,
                          })}
                        >
                          <Link
                            to="/reporting/support-tickets"
                            className="dropdown-item"
                          >
                            {props.t("Support tickets") + " SLA"}
                          </Link>
                        </div>
                      </div>
                      <Link to="/config/admins" className="dropdown-item">
                        {props.t("Admin users")}
                      </Link>
                      {/* <Link to="/config/admins2" className="dropdown-item">
                        {props.t("Admin users DEMO")}
                      </Link> */}
                      <Link to="/config/groups" className="dropdown-item">
                        {props.t("Groups")}
                      </Link>
                      <Link to="/config/clients" className="dropdown-item">
                        {props.t("Clients")}
                      </Link>
                     
                        <div className="nav-item dropdown">
                          <Link
                            className="dropdown-item"
                            onClick={e => {
                              e.preventDefault()
                              settests(!tests)
                            }}
                            to="/#"
                          >
                            {props.t("Demo")}
                            <div className="arrow-down"></div>
                          </Link>
                          <div
                            className={classname("dropdown-menu", {
                              show: tests,
                            })}
                          >
                          <Link
                            to="/admin/user-management"
                            className="dropdown-item"
                          >
                            {props.t("Admin user management")}
                          </Link>
                          </div>
                        </div>
                   
                    </div>
                  </li>
                )}
              </ul>
            </Collapse>
          </nav>
        </div>
      </div>
    </React.Fragment>
  )
}

Navbar.propTypes = {
  leftMenu: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
  location: PropTypes.any,
  t: PropTypes.any,
  deactivate: PropTypes.any,
}

export default withRouter(withTranslation()(Navbar))
