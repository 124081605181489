import React, { useContext, useEffect, useRef, useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Dropdown,
  DropdownToggle,
  Progress,
  DropdownMenu,
  DropdownItem,
  NavItem,
  NavLink,
  Modal,
  ModalHeader,
  UncontrolledTooltip,
  Tooltip,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Form,
  Label,
  Input,
  FormFeedback,
  CardTitle,
  CardFooter,
  Alert,
} from "reactstrap"
import moment from "moment"
import { isEqual, differenceWith, isObject } from "lodash"
import TagsTree from "components/DropdownList/TagsTree"
import PropTypes from "prop-types"
import classnames from "classnames"
import GlobalState from "contexts/GlobalState"
import { withTranslation } from "react-i18next"
import Breadcrumbs from "components/Common/Breadcrumb"
import SelectClientDropdown from "components/DropdownList/SelectClientManagement"
import Chip from "@material-ui/core/Chip"
import { Popconfirm } from "antd"
//Simple bar
import SimpleBar from "simplebar-react"
import {
  createClient,
  deleteClient,
  getAvailableTags,
  deleteHomeActivityHistory,
  getClientData,
  deleteClientData,
} from "helpers/backend_helper" // Data fetching/ backend helper
import {
  checkIfValidUUID,
  showToast,
  getLottieLoadingOptions,
  deleteDuplicated,
} from "utils/general" // Validation
import * as Yup from "yup"
import { useFormik } from "formik"
import PubSub from "pubsub-js"
import { Link, useParams, useLocation, useNavigate } from "react-router-dom"
import { Switch } from "antd"

// Animations
import Lottie from "react-lottie"
// SVG
import iconVideo from "assets/images/icons/videocall.svg"
import iconGo from "assets/images/icons/mukana.svg"
import iconMed from "assets/images/icons/medicineReminder.svg"
import iconLock from "assets/images/icons/alylukko-web.png"
import iconMeasure from "assets/images/icons/etamittaus-ikoni.svg"
import iconSafety from "assets/images/icons/turvapuhelin.svg"

// Subpages
import ClientDashboard from "pages/CustomerProfile/customer-dashboard" // Default landing page, Dashboard
import ClientInfo from "pages/CustomerProfile/customer-info"
import AtHomeServices from "pages/Services/at-home/at-home"
import OnTheGoServices from "pages/Services/on-the-go/on-the-go"
import JournalServices from "pages/Services/journal/journal"
import CarephoneServices from "pages/Services/carephone/carephone"
import SmartlockServices from "pages/Services/smartlock/smartlock"
import AlarmServices from "pages/Services/alarms/alarms"
import VideoCallServices from "pages/Services/video-call/video-call"
import DoseServices from "pages/Services/dose/dose"
import MeasurementServices from "pages/Services/measurements/measurements"
import AppUsersConfig from "pages/Services/app-users/app-users"
import AlarmSettingsConfig from "pages/Services/alarm-settings/alarm-settings"
import AlarmCenterConfig from "pages/Services/alarm-center/alarm-center"
import ScheduledMessagesConfig from "pages/Services/scheduled-messages/scheduled-messages"
import ClientIntegrations from "pages/Services/integrations/integrations"
import SelectGroup from "components/DropdownList/SelectGroup"
import Manual from "components/Manual/Manual"

const CustomerProfile = props => {
  const [state, setState] = useContext(GlobalState)
  const [userRole, setUserRole] = useState(null)
  const [activeTab, setActiveTab] = useState(null)
  const [clientId, setClientId] = useState(null)
  const [clientData, setClientData] = useState(null)
  const [services, setServices] = useState([])
  const [tools, setTools] = useState([])
  const [showLoading, setShowLoading] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [resetModal, setResetModal] = useState(false)
  const [createModal, setCreateModal] = useState(false)
  const [ongoingDelete, setOngoingDelete] = useState(false)
  const [ongoingCreate, setOngoingCreate] = useState(false)
  const [refreshing, setRefreshing] = useState(false)
  const [updateCounter, setUpdateCounter] = useState(0)
  const [menu, setMenu] = useState(false)
  const [chosenInfo, setChosenInfo] = useState("")
  const [clientArrayOrig, setClientArrayOrig] = useState([])
  const [clientArray, setClientArray] = useState(clientArrayOrig)
  //const [match, setMatch] = useState(props.match)
  const [params, setParams] = useState(useParams())
  const [totalServices, setTotalServices] = useState(0)
  const [isTooltip, setIsTooltip] = useState({})
  const [nameClient, setNameClient] = useState("")
  // Group modal
  const [modal_small, setmodal_small] = useState(false)
  const [modalInfo, setModalInfo] = useState()
  // AT Home delete data modal
  const [openDeleteModal, setOpenDeleteModal] = useState(0)
  // Tags
  const [tags, setTags] = useState([])
  const [selectedTags, setSelectedTags] = useState([])
  const [filteredDataTemp, setFilteredDataTemp] = useState([])
  // Custom menu toggle
  const [activeDropdown, setActiveDropdown] = useState(false)
  // Search field
  const [searchInput, setSearchInput] = useState("")
  const [selectedTagsNames, setSelectedTagNames] = useState([])
  // Refresh client list if info changed
  const [refreshName, setRefreshName] = useState(false)
  const [status, setStatus] = useState([])
  const [previousStatus, setPreviousStatus] = useState([])

  const abortRef = useRef(null)
  const { search } = useLocation()
  const navigate = useNavigate()
  var type = 0
  useEffect(() => {
    const matching = search.match(/type=(.*)/)
    type = matching?.[1]
    document.title = props.t("Customer management") + " | Suvanto Care"
  }, [])

  useEffect(() => {
    let num = typeof Number
    abortRef.current = new AbortController()
    let role
    if (state.authUser != null) {
      const obj = JSON.parse(state.authUser)
      role = obj.activeRole ? obj.activeRole : obj.role
      setUserRole(role)
    }

    // Url params validation
    if (params && params.clientId != undefined) {
      if (!checkIfValidUUID(params.clientId)) {
        // if (checkIfValidUUID(props.match.params.clientId) + num == false) {
        updatePath("/clients/select")
        //setMatch(props.match)
        setActiveTab(null)
        setClientId(null)
        // }
      }
    }

    // Get available tags
    if (role !== "private_user") {
      getAvailableTags(abortRef.current)
        .then(result => {
          if (result.statusCode == 200) {
            console.log("Tags: ", result)
            setTags(result.tags)
          }
        })
        .catch(err => {
          console.log(err)
        })
    }

    return () => abortRef.current.abort()
  }, [])

  // Language changed
  useEffect(() => {
    updateNavigation(clientData)
  }, [state.language])

  // Get all groups when clientid null
  useEffect(() => {
    if (clientId == null || clientId == undefined) {
      setClientArray(clientArrayOrig)
    }
  }, [clientId])

  // Not in use, does not change card order render for some reason // Sort filtered  asc
  // useEffect(() => {
  //   if (clientArray !== []) {
  //     let asc = clientArray.sort((a, b) => (a.name > b.name ? 1 : -1))
  //     setClientArray(asc)
  //     // console.log(asc)
  //   }
  // }, [clientArray])

  // Calculating customer stats
  useEffect(() => {
    if (clientArrayOrig) {
      let count = 0
      clientArrayOrig.map(c => {
        let add = 0
        add = c.services.total
        count = count + add
      })
      setTotalServices(count)
      setClientArray(clientArrayOrig)
    }
  }, [clientArrayOrig])

  // Filtering clients groups
  useEffect(() => {
    let tags = selectedTags
    if (tags.length === 0 && searchInput === "") {
      // console.log("neither")
      setClientArray(clientArrayOrig)
    }
    if (tags.length === 0 && searchInput !== "") {
      // console.log("searchInput")
      let result = []
      result = clientArrayOrig.filter(data =>
        data.name?.toLowerCase().includes(searchInput.toLowerCase())
      )
      setClientArray(result)
    }
    if (tags.length !== 0 && searchInput === "") {
      // console.log("tags")
      setClientArray(mapGroups(tags))
    }
    if (tags.length !== 0 && searchInput !== "") {
      // console.log("tags, searchInput")
      let groups = []
      groups = mapGroups(tags)
      groups = groups.filter(data =>
        data.name?.toLowerCase().includes(searchInput.toLowerCase())
      )
      setClientArray(groups)
    }
  }, [selectedTags, searchInput])

  // Set no name to dropdown
  useEffect(() => {
    document.title = props.t("Customer management") + " | Suvanto Care"
    if (clientId == null) {
      setNameClient("")
    }
    if (clientData !== null) {
      setNameClient(clientData.name)
    }
  }, [clientData])

  // Delete validation
  const deleteValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      reason: "",
    },
    validationSchema: Yup.object({}),
    onSubmit: values => {
      if (clientData?.key && !ongoingDelete) {
        setOngoingDelete(true)
        deleteClient(abortRef.current, clientData.key, values.reason)
          .then(result => {
            if (result.statusCode == 200) {
              showToast(props.t("Deleted"), "success")
              setDeleteModal(false)
              handleClientChange(null)
              setUpdateCounter(prevCount => prevCount + 1)
            } else {
              showToast(props.t("Error"), "error")
            }
          })
          .catch(err => {
            console.log(err)
            showToast(props.t("Error"), "error")
          })
          .finally(() => {
            setOngoingDelete(false)
          })
      }
    },
  })

  // Data reset validation
  const dataResetValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      confirmationText: "",
      clientInfo: userRole == "system_admin",
      alarms: userRole == "system_admin",
      journal: userRole == "system_admin",
      userLinks: userRole == "system_admin",
      health: userRole == "system_admin",
      scheduledMessages: userRole == "system_admin",
      alarmRules: userRole == "system_admin",
      tracker: userRole == "system_admin",
      home: userRole == "system_admin",
    },
    validationSchema: Yup.object({}),
    onSubmit: values => {
      console.log("Reset client data: ", values)
      if (clientData?.key && !ongoingDelete) {
        setOngoingDelete(true)
        deleteClientData(
          abortRef.current,
          clientData.key,
          values.clientInfo,
          values.alarms,
          values.journal,
          values.alarmRules,
          values.scheduledMessages,
          values.userLinks,
          values.health,
          values.home,
          values.tracker
        )
          .then(result => {
            console.log("Reset result: ", result)
            if (result.statusCode == 200) {
              showToast(props.t("Deleted"), "success")
              setResetModal(false)
              dataResetValidation.resetForm()

              // Refresh client and page
              refreshClient()
              PubSub.publish("page-refresh", "Update page content")
            } else {
              showToast(props.t("Error"), "error")
            }
          })
          .catch(err => {
            console.log(err)
            showToast(props.t("Error"), "error")
          })
          .finally(() => {
            setOngoingDelete(false)
          })
      }
    },
  })

  // Map filtered groups
  const mapGroups = value => {
    let arrayGroups = []
    let arrayGroupsName = []
    for (let i = 0; i <= value.length; i++) {
      clientArrayOrig.filter(data => {
        data.tags?.map(t => {
          if (t.key.includes(value[i]) && arrayGroups.includes(data) == false) {
            arrayGroups.push(data)
          }
        })
      })
    }
    return arrayGroups
  }

  // Show tooltip
  const toggleTooltip = e => {
    setIsTooltip(!isTooltip)

    if (!isTooltip[e]) {
      setIsTooltip({ [e]: true })
    } else {
      setIsTooltip({})
    }
  }

  // Create validation
  const createValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      clientName: "",
      group: "",
    },
    validationSchema: Yup.object({
      clientName: Yup.string()
        .min(4, props.t("Too short"))
        .required(props.t("Required field")),
      group: Yup.string().required(props.t("Required field")),
    }),
    onSubmit: values => {
      if (!ongoingCreate) {
        setOngoingCreate(true)
        createClient(abortRef.current, values.group, values.clientName)
          .then(result => {
            if (result.statusCode == 200) {
              showToast(props.t("Created"), "success")
              setCreateModal(false)
              updatePath("/clients/" + result.clientKey)
              setActiveTab(0)
              setUpdateCounter(prevCount => prevCount + 1)
              createValidation.setFieldValue("clientName", "")
            } else {
              showToast(props.t("Error"), "error")
            }
          })
          .catch(err => {
            console.log(err)
            showToast(props.t("Error"), "error")
          })
          .finally(() => {
            setOngoingCreate(false)
          })
      }
    },
  })

  const updatePath = path => {
    navigate(path, { replace: true })
    document.title = props.t("Customer management") + " | Suvanto Care"
  }

  const toggleTab = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab)
    }
  }

  // Refresh client data without changing tab
  const refreshClient = () => {
    handleClientChange(clientId, false)
  }

  // Setting active title
  useEffect(() => {
    setChosenInfo(setTitle(activeTab))
  }, [activeTab])

  const setTitle = t => {
    switch (t) {
      case 1:
        return "Navigation" + ".Suvanto Home"
      case 2:
        return "Navigation" + ".Suvanto OnTheGo"
      case 3:
        return "Navigation" + ".Carephone"
      case 4:
        return "Navigation" + ".Video"
      case 5:
        return "Navigation" + ".Dose"
      case 6:
        return "Navigation" + ".Journal"
      case 7:
        return "Navigation" + ".Health"
      case 8:
        return "Navigation" + ".Smartlock"
      case 9:
        return "Navigation" + ".Client Information"
      case 21:
        return "Navigation" + ".Alarms"
      case 22:
        return "Navigation" + ".Application Users"
      case 23:
        return "Navigation" + ".Alarm rules"
      case 24:
        return "Navigation" + ".Alarm Center"
      case 25:
        return "Navigation" + ".Scheduled Messages"
      case 26:
        return "Integrations"
      default:
        return ""
    }
  }

  // Group modal
  const tog_small = info => {
    setModalInfo(info)
    setmodal_small(!modal_small)
    removeBodyCss()
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  const modalValueChoose = id => {
    tog_small()
    handleClientChange(id)
  }

  // Filter unchanged values
  const filterUnchangedValues = (originalValues, newValues) => {
    console.log(originalValues, newValues, "two")
    const changedValues = {}

    for (const key in newValues) {
      if (!isEqual(originalValues[key], newValues[key])) {
        changedValues[key] = newValues[key]
      }
    }

    console.log(changedValues, "changed")
    return changedValues
  }

  // TEST Istekki TODO: set socket
  // useEffect(() => {
  //  console.log(clientId, "clientid change")
  // }, [clientId])

  // TEST Istekki TODO: set socket
  useEffect(() => {
    const interval = setInterval(() => {
      if (activeTab == 0 && (clientId !== null || clientId !== undefined)) {
        fetchData()
      }
    }, 30000)
    return () => clearInterval(interval)
  }, [clientId, activeTab, clientData])

  const findObjectDifferences = (obj1, obj2) => {
    const differences = {}

    const compareObjects = (o1, o2, currentPath = "") => {
      for (const key in o1) {
        const path = currentPath ? `${currentPath}.${key}` : key

        if (isObject(o1[key]) && isObject(o2[key])) {
          compareObjects(o1[key], o2[key], path)
        } else if (!isEqual(o1[key], o2[key])) {
          differences[path] = {
            oldValue: o1[key],
            newValue: o2[key],
          }
        }
      }
    }
    compareObjects(obj1, obj2)

    const isEmptyObject = obj => {
      return Object.keys(obj).length !== 0
    }
    console.log(differences, "differences")
    return isEmptyObject(differences)
  }

  const compare = newData => {
    console.log(clientData, newData)
    let differences = findObjectDifferences(clientData, newData)

    // let old = clientData
    let news = newData
    // const deepComparison = isEqual(news, old)

    if (differences) {
      // console.log(
      //   "Objects are not equal. Updating clientData. Differences:",
      //   differences
      // )
      setClientData(news)
    } else {
      // console.log("Objects are equal. No update needed.")
    }
  }

  const fetchData = () => {
    if (activeTab == 0 && (clientId !== null || clientId !== undefined)) {
      getClientData(abortRef.current, clientId, true)
        .then(data => {
          if (data) {
            if (data.journal && data.journal.latest) {
              data.journal.latest.forEach(element => {
                let date = new Date(element.date)
                element.dateNice = moment(date).format("DD.MM. HH:mm:ss")
                switch (element.type) {
                  case "message":
                    element.typeDescription = "Message"
                    break
                  case "message_client":
                    element.typeDescription = "Message to client"
                    break
                  case "call_video":
                    element.typeDescription = "Video call"
                    break
                  case "call_phone":
                    element.typeDescription = "Call to phone"
                    break
                  case "call_tracker":
                    element.typeDescription = "Call to tracker"
                    break
                  case "call_carephone":
                    element.typeDescription = "Call to carephone"
                    break
                  default:
                    element.typeDescription = ""
                    break
                }
              })
            }

            compare(data)
          }
        })
        .catch(e => {
          console.log(e)
        })
        .finally(() => {})
    }
  }
  // TEST END

  // Selected client changed event handler
  const handleClientChange = (value, resetTab = true) => {
    if (value !== "polling") {
      setClientId(value)
      if (value != null) {
        handleVisibility()
        setShowLoading(true)

        if (resetTab) {
          setActiveTab(0)
          setChosenInfo(setTitle(0))
        }

        // Get client data from server
        getClientData(abortRef.current, value, true)
          .then(data => {
            console.log("CLIENT DATA: ", data)
            if (data && data.hasOwnProperty("key")) {
              if (data.journal && data.journal.latest) {
                data.journal.latest.forEach(element => {
                  switch (element.type) {
                    case "message":
                      element.typeDescription = "Message"
                      break
                    case "message_client":
                      element.typeDescription = "Message to client"
                      break
                    case "call_video":
                      element.typeDescription = "Video call"
                      break
                    case "call_phone":
                      element.typeDescription = "Call to phone"
                      break
                    case "call_tracker":
                      element.typeDescription = "Call to tracker"
                      break
                    case "call_carephone":
                      element.typeDescription = "Call to carephone"
                      break
                    default:
                      element.typeDescription = ""
                      break
                  }
                })
              }
              updatePath("/clients/" + data.key)
              setClientData(data)
              updateNavigation(data)
            } else {
              updatePath("/clients/select")
              setClientData(null)
              setClientId(null)
              updateNavigation(null)
            }
          })
          .catch(e => {
            console.log(e)
            updatePath("/clients/select")
            setClientData(null)
            setClientId(null)
            updateNavigation(null)
          })
          .finally(() => {
            setShowLoading(false)
          })
      } else {
        updatePath("/clients/select")
        setClientData(null)
        updateNavigation(null)
      }
    }
    if (value === "polling") {
      console.log("polling")
    }
  }

  // Update navigation menu items based on client data
  const updateNavigation = data => {
    let tempServices = []
    let tempTools = []
    let tabs = []
    if (data != null) {
      // User role is null when refreshing page
      let userRoleTemp
      let showAlarmCenter = false
      if (state.authUser != null) {
        const obj = JSON.parse(state.authUser)
        let role = obj.activeRole ? obj.activeRole : obj.role
        setUserRole(role)
        userRoleTemp = role
        if (
          obj.access_permissions != null &&
          obj.access_permissions.alarm_monitoring == true &&
          (userRoleTemp == "system_admin" ||
            userRoleTemp == "organisation_admin" ||
            userRoleTemp == "salesperson")
        ) {
          showAlarmCenter = true
        }
      }

      if (!data.lockedTabs?.info) {
        tempTools.push({
          id: 9,
          text: "Navigation.Client Information",
          disabled: false,
        })
      }

      if (
        !data.lockedTabs?.app_users &&
        (userRoleTemp == "system_admin" ||
          userRoleTemp == "organisation_admin" ||
          userRoleTemp == "salesperson")
      ) {
        tempTools.push({
          id: 22,
          text: "Navigation.Application Users",
          disabled: false,
        })
      }

      if (data.gatewayKey != null && data.gatewayKey.length > 0) {
        tempServices.push({
          id: 1,
          text: "Navigation.Suvanto Home",
          disabled: false,
        })
      }
      if (data.trackerKey != null && data.trackerKey.length > 0) {
        tempServices.push({
          id: 2,
          text: "Navigation.Suvanto OnTheGo",
          disabled: false,
        })
      }
      if (data.carephoneKey != null && data.carephoneKey.length > 0) {
        tempServices.push({
          id: 3,
          text: "Navigation.Carephone",
          disabled: false,
        })
      }
      if (data.videoCallRoomName != null && data.videoCallRoomName.length > 0) {
        tempServices.push({
          id: 4,
          text: "Navigation.Video",
          disabled: false,
        })
        if (!data.lockedTabs?.scheduled_messages) {
          tempTools.push({
            id: 25,
            text: "Navigation.Scheduled Messages",
            disabled: false,
          })
        }
      }
      if (
        data.doseConfigurationId != null &&
        data.doseConfigurationId.length > 0
      ) {
        tempServices.push({
          id: 5,
          text: "Navigation.Dose",
          disabled: false,
        })
      }
      if (data.pindoraId != null && data.pindoraId.length > 0) {
        tempServices.push({
          id: 8,
          text: "Navigation.Smartlock",
          disabled: false,
        })
      }

      if (data.measurements == true) {
        tempServices.push({
          id: 7,
          text: "Navigation.Health",
          disabled: false,
        })
      }

      if (!data.lockedTabs?.journal)
        tempServices.push({
          id: 6,
          text: "Navigation.Journal",
          disabled: false,
        })
      if (!data.lockedTabs?.alarms)
        tempServices.push({
          id: 21,
          text: "Navigation.Alarms",
          disabled: false,
        })

      if (
        !data.lockedTabs?.alarm_rules &&
        (data.gatewayKey ||
          (data.measurements == true && data.videoCallRoomName))
      ) {
        tempTools.push({
          id: 23,
          text: "Navigation.Alarm rules",
          disabled: false,
        })
      }

      if (showAlarmCenter == true) {
        tempTools.push({
          id: 24,
          text: "Navigation.Alarm Center",
          disabled: false,
        })
      }

      if (userRoleTemp === "system_admin") {
        tempTools.push({
          id: 26,
          text: "Integrations",
          disabled: false,
        })
      }
    }

    let tabObject = new Object()
    tabObject.label = props.t("Overview")
    tabObject.value = 0
    tabs.push(tabObject)

    tabObject = new Object()
    tabObject.label = props.t("Navigation.Client Information")
    tabObject.value = 9
    tabs.push(tabObject)

    // Services for select
    tabObject = new Object()
    tabObject.label = props.t("Services")
    tabObject.value = "Services"
    tabObject.options = []
    tempServices.forEach(srvc => {
      if (srvc.disabled != true) {
        let tab = new Object()
        tab.label = props.t(srvc.text)
        tab.value = srvc.id

        tabObject.options.push(tab)
      }
    })

    tabs.push(tabObject)

    // Tools for select
    tabObject = new Object()
    tabObject.label = props.t("Maintenance tools")
    tabObject.value = "Tools"
    tabObject.options = []
    tempTools.forEach(tool => {
      if (tool.disabled != true) {
        let tab = new Object()
        tab.label = props.t(tool.text)
        tab.value = tool.id
        tabObject.options.push(tab)
      }
    })
    tabs.push(tabObject)

    setServices(tempServices)
    setTools(tempTools)
  }

  const itemsTogetherJSX = []
  services.forEach(item => {
    itemsTogetherJSX.push(
      <Link
        key={item.id}
        to="#"
        className={
          classnames({
            active: activeTab == item.id,
            disabled: item.disabled === true,
          }) + " dropdown-item"
        }
        onClick={() => {
          toggleTab(item.id)
        }}
      >
        {props.t(`${item.text}`)}
        {item.disabled == true && <i className="bx bx-lock ms-2"></i>}
      </Link>
    )
  })

  const itemsTogetherJSX2 = []
  tools.forEach(item => {
    itemsTogetherJSX2.push(
      <Link
        key={item.id}
        to="#"
        className={
          classnames({
            active: activeTab == item.id,
            disabled: item.disabled === true,
          }) + " dropdown-item"
        }
        onClick={() => {
          toggleTab(item.id)
        }}
      >
        {props.t(`${item.text}`)}
        {item.disabled == true && <i className="bx bx-lock ms-2"></i>}
      </Link>
    )
  })

  // Group selection changed in create client modal
  const groupChanged = newGroup => {
    createValidation.setFieldValue("group", newGroup)
  }

  // Customer chosen card visibility
  const [visibility, setVisibility] = useState("hidden")
  const handleVisibility = () => {
    if (visibility === "hidden") {
      setTimeout(() => {
        setVisibility("visible")
      }, 400)
    }
  }

  const [menuClient, setMenuClient] = useState(false)
  // Example Show only 12
  const [wantedLength, setWantedLength] = useState(12)

  const addLength = l => {
    setWantedLength(wantedLength + l)
  }

  const setBreadcrumbClient = () => {
    if (clientId !== null) {
      if (/*activeTab !== 1*/ true) {
        return (
          <>
            {activeTab === 1 &&
              (userRole !== "organisation_user" ||
                "private_user" ||
                "order_manager") && (
                <DeleteAtHomeHistory
                  t={props.t}
                  gatewayKey={clientData.gatewayKey}
                  showLoading={showLoading}
                  refreshing={refreshing}
                  refreshClient={refreshClient}
                  // deleteData={deleteAtHomeData}
                />
              )}

            {/* <Button
              key="refresh-button"
              className="ms-2"
              color="primary"
              style={{
                borderRadius: "100px",
                paddingTop: "4px",
                paddingBottom: "4px",
              }}
              disabled={showLoading || refreshing}
              onClick={() => {
                refreshClient()
                PubSub.publish("page-refresh", "Update page content")
                setRefreshing(true)
                setTimeout(() => {
                  setRefreshing(false)
                }, 5000)
              }}
            >
              <i className="mdi mdi-refresh me-2" />
              {props.t("Refresh")}
            </Button> */}
          </>
        )
      }
    }
  }

  // Handle search field function
  const handleSearch = event => {
    if (clientArrayOrig != undefined) {
      setSearchInput(event.target.value)
    }
  }

  // Filter by selected tags
  useEffect(() => {
    let setNew = Array.from(new Set(filteredDataTemp))
    setClientArray(setNew)
  }, [filteredDataTemp])

  // Toggle custom menu
  const menuToggle = (t, c) => {
    if (t === "click") {
      setActiveDropdown(true)
      setMenu(!menu)
    }
    if (t === "hover") {
      if (activeDropdown == false) {
        setMenu(c)
      }
    }
  }

  // SelectClientManagement refresh client search TODO: Delete
  // const refreshSearchClients = () => {
  //   // console.log("refresh clients")
  //   setRefreshName(true)
  //   setTimeout(() => {
  //     setRefreshName(false)
  //   }, 500)
  // }

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Customer management")}
            className="breadcrumb m-0 justify-content-center"
            breadcrumbItem={
              <div className="d-inline-block">
                {userRole === "system_admin" && (
                  <Button
                    id="add-button"
                    // style={{ marginTop: "2px" }}
                    // outline
                    size="sm"
                    className="py-1 px-2 me-1"
                    color="success"
                    disabled={ongoingCreate || ongoingDelete}
                    onClick={() => {
                      setCreateModal(true)
                    }}
                  >
                    <UncontrolledTooltip placement="bottom" target="add-button">
                      {props.t("Create a new client")}
                    </UncontrolledTooltip>
                    <i className="fas fa-user-plus"></i>
                    {/* <span className="ms-2 d-none d-md-inline-block ">
                      {props.t("Add a new client")}
                    </span> */}
                  </Button>
                )}
                {/* {userRole === "system_admin" && (
                  <Button
                    id="add-button"
                    style={{ marginTop: "2px" }}
                    color="success"
                    disabled={ongoingCreate || ongoingDelete}
                    onClick={() => {
                      setCreateModal(true)
                    }}
                  >
                    <UncontrolledTooltip placement="bottom" target="add-button">
                      {props.t("Create a new client")}
                    </UncontrolledTooltip>

                    <i className="fas fa-user-plus font-size-12"></i>
                    {/* <span className="d-none d-md-inline-block ms-2">
                        {props.t("Add a new client")}
                      </span> 
                  </Button>
                )} */}
                <div className="d-inline-block">
                  <Manual
                    source={"customer-management"}
                    tab={activeTab}
                  ></Manual>
                </div>
              </div>
            }
          />

          <Row>
            {/* Customer pick card */}
            <Col lg="12">
              <Card className="mb-2">
                <CardBody className="py-1">
                  <Row>
                    <Col md="8">
                      <div className="container-select-customer">
                        <div className="flex-1">
                          <div className="d-inline-block me-2">
                            <div className="avatar-xs mx-auto ">
                              <div className="avatar-title bg-light  rounded-circle text-primary h3">
                                <i className="bx bxs-user"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex-2">
                          <UncontrolledTooltip
                            placement="bottom"
                            target="dropdownClient"
                          >
                            {props.t("Select a client")}
                          </UncontrolledTooltip>
                          <div className="input-field" id="dropdownClient">
                            <SelectClientDropdown
                              clientArray={setClientArrayOrig}
                              selectionName={nameClient}
                              onChange={handleClientChange}
                              customerId={clientId}
                              params={params}
                              updateCounter={updateCounter}
                              // TODO: Delete
                              // refresh={refreshName}
                            />
                          </div>
                        </div>{" "}
                        {clientData != null && userRole != "private_user" && (
                          <div className="flex-3 d-none d-lg-inline-block ms-2">
                            <UncontrolledTooltip
                              target="groupNameBadge"
                              placement="bottom"
                            >
                              {props.t("Group")}
                            </UncontrolledTooltip>
                            <span
                              id="groupNameBadge"
                              className="badge badge-soft-primary me-1 font-size-12 p-2"
                            >
                              <i className="bx bx-group"></i> {clientData.group}
                            </span>
                          </div>
                        )}
                      </div>
                    </Col>
                    {clientId == null && (
                      <Col md="4" className="">
                        <>
                          {clientArrayOrig != undefined && (
                            <>
                              {clientArrayOrig.length == 0 &&
                              totalServices == 0 ? (
                                <>
                                  <div className="text-center py-2 mt-2 text-primary font-size-14">
                                    {props.t("Loading")}
                                    <i className="bx bx-loader bx-spin ms-2"></i>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div id="window-card" className="p-0">
                                    <div className="window-child">
                                      <div className="mb-0 d-inline-block">
                                        <i className="bx bx-group text-primary display-6 p-0 me-3" />
                                      </div>
                                      <div className="d-inline-block">
                                        <h4 className="mb-0 mt-0">
                                          {clientArrayOrig.length}
                                        </h4>
                                        <p className="mb-0">
                                          {props.t("Groups.s")}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="window-child ">
                                      <div className="mb-0 mt-0 d-inline-block">
                                        <i className="bx bx-devices text-primary display-6 p-0 me-3" />
                                      </div>
                                      <div className="d-inline-block">
                                        <h4 className="mb-0 mt-0">
                                          {totalServices}
                                        </h4>
                                        <p className="mb-0 mt-0">
                                          {props.t("Services.s")}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}
                            </>
                          )}
                        </>
                      </Col>
                    )}
                    {clientId !== null && (
                      <>
                        {clientData && (
                          <Col md="4">
                            <span className="float-end">
                              <Dropdown
                                isOpen={menuClient}
                                toggle={() => setMenuClient(!menuClient)}
                                className="d-inline-block"
                              >
                                <div
                                  style={{ marginTop: "6px" }}
                                  className=" mb-0"
                                >
                                  <DropdownToggle
                                    tag="i"
                                    className="text-muted text-center"
                                  >
                                    <Button color="primary">
                                      <i className="bx bxs-cog text-white" />
                                      <span className="d-none d-lg-inline-block ms-2 text-white font-size-14">
                                        {props.t("Client management")}
                                      </span>

                                      <i className="mdi mdi-chevron-down text-white ms-1" />
                                    </Button>
                                  </DropdownToggle>
                                </div>
                                <DropdownMenu className="dropdown-menu-end">
                                  <DropdownItem
                                    onClick={() => toggleTab(9)}
                                    className={
                                      classnames({
                                        disabled:
                                          clientData?.lockedTabs?.info == true,
                                      }) + " "
                                    }
                                  >
                                    <NavLink className="p-0 me-4">
                                      {/* <i className="mdi mdi-information-outline me-2" />*/}
                                      <i className="bx bx-user align-middle me-2" />
                                      <span className="mt-1">
                                        {props.t("Show information")}
                                      </span>
                                      {clientData?.lockedTabs?.info == true && (
                                        <i className="bx bx-lock ms-2"></i>
                                      )}
                                    </NavLink>
                                  </DropdownItem>

                                  {(userRole === "system_admin" ||
                                    userRole === "organisation_admin" ||
                                    userRole === "salesperson") && (
                                    <>
                                      <div className="dropdown-divider" />
                                      <DropdownItem
                                        onClick={() => {
                                          setResetModal(true)
                                        }}
                                        className={
                                          classnames({
                                            disabled:
                                              userRole !== "system_admin" &&
                                              userRole !==
                                                "organisation_admin" &&
                                              userRole !== "salesperson",
                                          }) + " "
                                        }
                                      >
                                        <NavLink className=" p-0">
                                          <i className="mdi mdi-file-remove text-danger me-2" />
                                          <span>{props.t("Delete data")}</span>
                                          {userRole !== "system_admin" &&
                                            userRole !== "organisation_admin" &&
                                            userRole !== "salesperson" && (
                                              <i className="bx bx-lock ms-2"></i>
                                            )}
                                        </NavLink>
                                      </DropdownItem>
                                    </>
                                  )}

                                  {userRole === "system_admin" && (
                                    <>
                                      <DropdownItem
                                        onClick={() => {
                                          setDeleteModal(true)
                                        }}
                                        className={
                                          classnames({
                                            disabled:
                                              userRole !== "system_admin",
                                          }) + " "
                                        }
                                      >
                                        <NavLink className="p-0">
                                          <i className="mdi mdi-account-remove me-2 text-danger" />
                                          <span>
                                            {props.t("Delete client")}
                                          </span>
                                          {userRole !== "system_admin" && (
                                            <i className="bx bx-lock ms-2"></i>
                                          )}
                                        </NavLink>
                                      </DropdownItem>
                                    </>
                                  )}
                                </DropdownMenu>
                              </Dropdown>
                            </span>
                          </Col>
                        )}
                      </>
                    )}
                  </Row>
                </CardBody>
              </Card>
            </Col>

            <Col md="12">
              {clientId !== null && (
                <Row>
                  <Col lg="12">
                    {clientData && (
                      <div className="pt-2 mt-1">
                        <span className="float-end">
                          {setBreadcrumbClient()}
                        </span>
                        {clientData && (
                          <div className="">
                            <div id="navigation-management">
                              <ul className="navbar-nav ">
                                <li className="nav-item ">
                                  <Link
                                    className={
                                      "nav-link " +
                                      classnames({
                                        active: activeTab === 0,
                                      })
                                    }
                                    to="#"
                                    onClick={() => toggleTab(0)}
                                  >
                                    <i className="bx bxs-dashboard text-primary"></i>
                                    <span className="ms-2 d-none d-md-inline-block">
                                      {props.t("Overview")}
                                    </span>
                                  </Link>
                                </li>

                                <li
                                  className="nav-item dropdown"
                                  onMouseEnter={() => menuToggle("hover", true)}
                                  onMouseLeave={() =>
                                    menuToggle("hover", false)
                                  }
                                >
                                  {chosenInfo !== "" ? (
                                    <Link
                                      className="nav-link active dropdown-toggle arrow-none d-inline-flex "
                                      onClick={e => {
                                        e.preventDefault()
                                        setMenu(!menu)
                                      }}
                                      to="/#"
                                    >
                                      <i className="mdi mdi-application-cog text-primary font-size-14 "></i>
                                      <span className="ms-2 d-none d-md-inline-block">
                                        {props.t("Services and maintenance")} -{" "}
                                      </span>
                                      <span className="ms-2">
                                        {props.t(chosenInfo)}
                                      </span>
                                      <i className="mdi mdi-chevron-down ms-1" />
                                    </Link>
                                  ) : (
                                    <Link
                                      className="nav-link  dropdown-toggle arrow-none d-inline-flex"
                                      onClick={e => {
                                        e.preventDefault()
                                        setMenu(!menu)
                                      }}
                                      to="/#"
                                    >
                                      <i className="mdi mdi-application-cog text-primary font-size-14 "></i>
                                      <span className="ms-2 d-none d-md-inline-block">
                                        {props.t("Services and maintenance")}
                                      </span>
                                      <i className="mdi mdi-chevron-down ms-1" />
                                    </Link>
                                  )}

                                  <div
                                    className={classnames("dropdown-menu ", {
                                      show: menu,
                                    })}
                                  >
                                    <Row>
                                      <Col md={6} className="py-2">
                                        <h5 className="font-size-14 ms-3 mb-2">
                                          {props.t("Services")}
                                        </h5>
                                        {itemsTogetherJSX}
                                      </Col>

                                      <Col md={6} className="py-2">
                                        <h5 className="font-size-14 ms-3 mb-2">
                                          {props.t("Maintenance")}
                                        </h5>
                                        {itemsTogetherJSX2}
                                      </Col>
                                    </Row>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </Col>
                </Row>
              )}

              <Row xs={1} lg={2}>
                {clientId != null && clientData != null ? (
                  <Col lg="12">
                    <div>
                      {{
                        0: (
                          <>
                            <ClientDashboard
                              setTab={setActiveTab}
                              clientData={clientData}
                              services={services}
                              clientDataLoading={showLoading}
                              refreshClient={refreshClient}
                            />
                          </>
                        ),
                        1: (
                          <>
                            <AtHomeServices
                              gatewayKey={clientData.gatewayKey}
                              refreshClient={refreshClient}
                              openDeleteModal={openDeleteModal}
                              clientId={clientId}
                            />
                          </>
                        ),
                        2: (
                          <OnTheGoServices trackerKey={clientData.trackerKey} />
                        ),
                        3: (
                          <CarephoneServices
                            carephoneKey={clientData.carephoneKey}
                            suvantoKey={clientData.key}
                          />
                        ),
                        4: (
                          <VideoCallServices
                            suvantoKey={clientData.key}
                            clientId={clientId}
                          />
                        ),
                        5: (
                          <DoseServices
                            suvantoKey={clientData.key}
                            configId={clientData.doseConfigurationId}
                          />
                        ),
                        6: <JournalServices suvantoKey={clientData.key} />,
                        7: <MeasurementServices suvantoKey={clientData.key} />,
                        8: (
                          <SmartlockServices
                            smartlockKey={clientData.pindoraId}
                            suvantoKey={clientData.key}
                          />
                        ),
                        9: (
                          <ClientInfo
                            suvantoKey={clientData.key}
                            setClientName={setNameClient}
                            // refreshClients={refreshSearchClients}
                          />
                        ),
                        21: <AlarmServices suvantoKey={clientData.key} />,
                        22: (
                          <AppUsersConfig
                            suvantoKey={clientData.key}
                            group={clientData.group}
                          />
                        ),
                        23: (
                          <AlarmSettingsConfig
                            suvantoKey={clientData.key}
                            gatewayKey={clientData.gatewayKey}
                          />
                        ),
                        24: <AlarmCenterConfig suvantoKey={clientData.key} />,
                        25: (
                          <ScheduledMessagesConfig
                            suvantoKey={clientData.key}
                          />
                        ),
                        26: (
                          <ClientIntegrations
                            suvantoKey={clientData.key}
                            refreshClient={refreshClient}
                          />
                        ),
                      }[activeTab] || <div></div>}
                    </div>
                  </Col>
                ) : (
                  <>
                    {params.clientId !== "select" &&
                    typeof clientId === "string" ? (
                      <Col lg="12">
                        <div className="p-5 text-center">
                          <Lottie
                            options={getLottieLoadingOptions()}
                            height={100}
                            width={100}
                          ></Lottie>
                        </div>
                      </Col>
                    ) : (
                      <Col lg={12}>
                        <Row>
                          {clientArrayOrig.length !== 0 && (
                            <>
                              <Col lg="12">
                                <Row>
                                  <Col md="2">
                                    <div className="mt-2 d-inline-block">
                                      <span className="h5">
                                        {props.t("Groups")}
                                      </span>
                                      <span className="ms-2">
                                        {clientArray.length}
                                      </span>
                                    </div>
                                  </Col>

                                  {/* TODO: Different window if only one group showing for user
                                   {clientArrayOrig.length > 1 ? ( */}
                                  <Col md="10">
                                    <div className="search-field float-end mt-1 ms-2">
                                      <div className="box search-box square mb-2 d-inline-block">
                                        <div className="position-relative">
                                          <Input
                                            type="text"
                                            onChange={event =>
                                              handleSearch(event)
                                            }
                                            placeholder={props.t("Search")}
                                          />
                                          <i className="bx bx-search-alt search-icon " />
                                        </div>
                                      </div>
                                    </div>
                                    {/* TAGS */}
                                    {userRole == "system_admin" ? (
                                      <div
                                        id="tags-tree-management"
                                        className="py-1 d-inline-block float-end mb-2"
                                      >
                                        <TagsTree
                                          data={tags}
                                          selected={selectedTags}
                                          selectionChanged={setSelectedTags}
                                          selectedTagTitles={
                                            setSelectedTagNames
                                          }
                                          expanded={true}
                                        />
                                      </div>
                                    ) : (
                                      <div></div>
                                    )}
                                  </Col>
                                </Row>
                              </Col>

                              {/* Group info modal */}
                              <Modal
                                size="lg"
                                centered
                                isOpen={modal_small}
                                toggle={() => {
                                  tog_small()
                                }}
                              >
                                {modalInfo !== undefined && (
                                  <>
                                    <div className="modal-header">
                                      <h5 className="modal-title mt-0">
                                        {modalInfo.name}
                                      </h5>
                                      <button
                                        onClick={() => {
                                          setmodal_small(false)
                                        }}
                                        type="button"
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                      >
                                        <span aria-hidden="true">&times;</span>
                                      </button>
                                    </div>
                                    <div className="modal-body font-size-14">
                                      <Row>
                                        <Col xs="6" className="px-3 py-2">
                                          {/* Group client names */}
                                          <h5 className="font-size-15 mb-4">
                                            {props.t("Customers")}{" "}
                                            {modalInfo.clients.length}
                                          </h5>
                                          {modalInfo.clients.length !== 0 ? (
                                            <SimpleBar
                                              className="mb-2"
                                              style={{ maxHeight: "45vh" }}
                                            >
                                              {modalInfo.clients.map(
                                                (info, index) => (
                                                  <span
                                                    key={"pkey_modal" + index}
                                                    className="group-modal-info mb-2"
                                                    onClick={() => {
                                                      modalValueChoose(info.id)
                                                    }}
                                                  >
                                                    <small className="text-primary float-end me-3 mt-1">
                                                      {props.t("Select")}
                                                    </small>
                                                    <p className="mb-2">
                                                      {info.name}
                                                    </p>
                                                  </span>
                                                )
                                              )}
                                            </SimpleBar>
                                          ) : (
                                            <p>{props.t("no clients")}</p>
                                          )}
                                        </Col>
                                        <Col xs="6" className="px-3 py-2">
                                          {/* Group Services Modal */}
                                          <h5 className="font-size-15 mb-4">
                                            {props.t("Services")}{" "}
                                            {modalInfo.services.total}
                                          </h5>
                                          {modalInfo.services.total !== 0 ? (
                                            <div className="modalInfo">
                                              {modalInfo.services.home !==
                                                0 && (
                                                <div className="mb-2">
                                                  <i
                                                    style={{
                                                      height: "18px",
                                                      display: "inline",
                                                    }}
                                                    className="fas fa-house-user font-size-18 text-suvanto-blue me-2  float-end"
                                                  ></i>
                                                  {props.t("Home")}{" "}
                                                  <span className="">
                                                    {modalInfo.services.home}
                                                  </span>
                                                </div>
                                              )}
                                              {modalInfo.services.tracker !==
                                                0 && (
                                                <div className="mb-2">
                                                  <img
                                                    style={{
                                                      height: "18px",
                                                      display: "inline",
                                                    }}
                                                    src={iconGo}
                                                    alt=""
                                                    className="img-fluid me-2 float-end"
                                                  />
                                                  {props.t("On the Go")}{" "}
                                                  <span className="">
                                                    {modalInfo.services.tracker}
                                                  </span>
                                                </div>
                                              )}
                                              {modalInfo.services.video !==
                                                0 && (
                                                <div className="mb-2">
                                                  <img
                                                    style={{
                                                      height: "18px",
                                                      display: "inline",
                                                    }}
                                                    src={iconVideo}
                                                    alt=""
                                                    className="img-fluid me-2 float-end"
                                                  />
                                                  {props.t("Video call")}{" "}
                                                  <span className="">
                                                    {modalInfo.services.video}
                                                  </span>
                                                </div>
                                              )}
                                              {modalInfo.services.health !==
                                                0 && (
                                                <div className="mb-2">
                                                  <img
                                                    style={{
                                                      height: "18px",
                                                      display: "inline",
                                                    }}
                                                    src={iconMeasure}
                                                    alt=""
                                                    className="img-fluid me-2 float-end"
                                                  />
                                                  {props.t(
                                                    "Measurement devices"
                                                  )}{" "}
                                                  <span className="">
                                                    {modalInfo.services.health}
                                                  </span>
                                                </div>
                                              )}
                                              {modalInfo.services.smartlock !==
                                                0 && (
                                                <div className="mb-2">
                                                  <img
                                                    style={{
                                                      height: "18px",
                                                      display: "inline",
                                                    }}
                                                    src={iconLock}
                                                    alt=""
                                                    className="img-fluid me-2 float-end"
                                                  />
                                                  {props.t("Smartlock")}{" "}
                                                  <span className="">
                                                    {
                                                      modalInfo.services
                                                        .smartlock
                                                    }
                                                  </span>
                                                </div>
                                              )}
                                              {modalInfo.services.dose !==
                                                0 && (
                                                <div className="mb-2">
                                                  <img
                                                    style={{
                                                      height: "18px",
                                                      display: "inline",
                                                    }}
                                                    src={iconMed}
                                                    alt=""
                                                    className="img-fluid me-2 float-end"
                                                  />
                                                  {props.t(
                                                    "Medication reminder"
                                                  )}{" "}
                                                  <span className="">
                                                    {modalInfo.services.dose}
                                                  </span>
                                                </div>
                                              )}
                                              {modalInfo.services.carephone !==
                                                0 && (
                                                <div className="mb-2">
                                                  <img
                                                    style={{
                                                      height: "18px",
                                                      display: "inline",
                                                    }}
                                                    src={iconSafety}
                                                    alt=""
                                                    className="img-fluid me-2 float-end"
                                                  />
                                                  {props.t("Carephone")}{" "}
                                                  <span className="">
                                                    {
                                                      modalInfo.services
                                                        .carephone
                                                    }
                                                  </span>
                                                </div>
                                              )}
                                            </div>
                                          ) : (
                                            <p>{props.t("no services")}</p>
                                          )}
                                        </Col>
                                      </Row>
                                      {modalInfo.tags.length !== 0 && (
                                        <>
                                          <h5 className="font-size-15 mt-3  mb-3">
                                            {props.t("Tags")}
                                          </h5>
                                          <div className="mb-4">
                                            {modalInfo.tags.map(
                                              (tag, index) => (
                                                <Chip
                                                  key={"tagspan_" + index}
                                                  className="font-size-12 me-2 my-2"
                                                  label={tag.name}
                                                />
                                              )
                                            )}
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  </>
                                )}
                              </Modal>
                              {clientArrayOrig.length > 0 ? (
                                <Col lg="12">
                                  <Row>
                                    {clientArray.length > 0 ? (
                                      <>
                                        {clientArray
                                          .slice(0, wantedLength)
                                          .map((item, index) => (
                                            <Col
                                              xs={12}
                                              sm={6}
                                              lg={3}
                                              key={item.name + "_card"}
                                            >
                                              <Card
                                                id={index + item.name}
                                                className="group-cards"
                                                onClick={() => {
                                                  tog_small(item)
                                                }}
                                              >
                                                <CardBody className="group-card-pick">
                                                  <CardTitle>
                                                    {item.name}
                                                  </CardTitle>
                                                  <Row>
                                                    <Col xs="6">
                                                      <div className="d-flex flex-wrap my-2">
                                                        <div className="avatar-sm me-3 d-none">
                                                          <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                                                            <i className="bx bx-user"></i>
                                                          </div>
                                                        </div>
                                                        <div className="">
                                                          <p className="text-muted mb-0">
                                                            {props.t(
                                                              "Customers"
                                                            )}
                                                          </p>
                                                          <h3 className="mb-0">
                                                            {
                                                              item.clients
                                                                .length
                                                            }
                                                          </h3>
                                                        </div>
                                                      </div>
                                                    </Col>
                                                    <Col xs="6">
                                                      <div className="d-flex flex-wrap my-2">
                                                        <div className="avatar-sm me-3  d-none">
                                                          <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                                                            <i className="mdi mdi-devices"></i>
                                                          </div>
                                                        </div>
                                                        <div className="">
                                                          <p className="text-muted mb-0">
                                                            {props.t(
                                                              "Services"
                                                            )}
                                                          </p>
                                                          <h3 className="mb-0">
                                                            {
                                                              item.services
                                                                .total
                                                            }
                                                          </h3>
                                                        </div>
                                                      </div>
                                                    </Col>
                                                  </Row>
                                                </CardBody>
                                                <CardFooter
                                                  className="bg-transparent border-top"
                                                  style={{ minHeight: "40px" }}
                                                >
                                                  {item.services.home !== 0 && (
                                                    <>
                                                      <i
                                                        id={"servHome" + index}
                                                        style={{
                                                          height: "18px",
                                                          display: "inline",
                                                          margin: "0px",
                                                        }}
                                                        className="fas fa-house-user font-size-18 text-suvanto-blue me-3"
                                                      ></i>
                                                      <Tooltip
                                                        placement="bottom"
                                                        target={
                                                          "servHome" + index
                                                        }
                                                        isOpen={
                                                          isTooltip[
                                                            item.name + "_h"
                                                          ]
                                                        }
                                                        toggle={() =>
                                                          toggleTooltip(
                                                            item.name + "_h"
                                                          )
                                                        }
                                                      >
                                                        {props.t(
                                                          "At home services"
                                                        )}
                                                        : {item.services.home}
                                                      </Tooltip>
                                                    </>
                                                  )}
                                                  {item.services.video !==
                                                    0 && (
                                                    <>
                                                      <img
                                                        id={"servVideo" + index}
                                                        style={{
                                                          height: "18px",
                                                          display: "inline",
                                                          marginTop: "-4px",
                                                        }}
                                                        src={iconVideo}
                                                        alt=""
                                                        className="img-fluid me-3"
                                                      />
                                                      <Tooltip
                                                        placement="bottom"
                                                        target={
                                                          "servVideo" + index
                                                        }
                                                        isOpen={
                                                          isTooltip[
                                                            item.name + "_v"
                                                          ]
                                                        }
                                                        toggle={() =>
                                                          toggleTooltip(
                                                            item.name + "_v"
                                                          )
                                                        }
                                                      >
                                                        {props.t(
                                                          "Video call services"
                                                        )}
                                                        : {item.services.video}
                                                      </Tooltip>
                                                    </>
                                                  )}{" "}
                                                  {item.services.tracker !==
                                                    0 && (
                                                    <>
                                                      <div className="test">
                                                        <img
                                                          id={
                                                            "servTracker" +
                                                            index
                                                          }
                                                          style={{
                                                            height: "18px",
                                                            display: "inline",
                                                            marginTop: "-4px",
                                                          }}
                                                          src={iconGo}
                                                          alt=""
                                                          className="img-fluid me-3 "
                                                        />
                                                      </div>
                                                      <Tooltip
                                                        placement="bottom"
                                                        target={
                                                          "servTracker" + index
                                                        }
                                                        isOpen={
                                                          isTooltip[
                                                            item.name + "_t"
                                                          ]
                                                        }
                                                        toggle={() =>
                                                          toggleTooltip(
                                                            item.name + "_t"
                                                          )
                                                        }
                                                      >
                                                        {props.t(
                                                          "On the go services"
                                                        )}
                                                        :{" "}
                                                        {item.services.tracker}
                                                      </Tooltip>
                                                    </>
                                                  )}
                                                  {item.services.carephone !==
                                                    0 && (
                                                    <>
                                                      <img
                                                        id={
                                                          "servSafety" + index
                                                        }
                                                        style={{
                                                          height: "18px",
                                                          display: "inline",
                                                          marginTop: "-4px",
                                                        }}
                                                        src={iconSafety}
                                                        alt=""
                                                        className="img-fluid me-3"
                                                      />
                                                      <Tooltip
                                                        placement="bottom"
                                                        target={
                                                          "servSafety" + index
                                                        }
                                                        isOpen={
                                                          isTooltip[
                                                            item.name + "_s"
                                                          ]
                                                        }
                                                        toggle={() =>
                                                          toggleTooltip(
                                                            item.name + "_s"
                                                          )
                                                        }
                                                      >
                                                        {props.t(
                                                          "Safety services"
                                                        )}
                                                        : {item.services.safety}
                                                      </Tooltip>
                                                    </>
                                                  )}{" "}
                                                  {item.services.dose !== 0 && (
                                                    <>
                                                      <img
                                                        id={"servDose" + index}
                                                        style={{
                                                          height: "18px",
                                                          display: "inline",
                                                          marginTop: "-4px",
                                                        }}
                                                        src={iconMed}
                                                        alt=""
                                                        className="img-fluid me-3"
                                                      />
                                                      <Tooltip
                                                        placement="bottom"
                                                        target={
                                                          "servDose" + index
                                                        }
                                                        isOpen={
                                                          isTooltip[
                                                            item.name + "_d"
                                                          ]
                                                        }
                                                        toggle={() =>
                                                          toggleTooltip(
                                                            item.name + "_d"
                                                          )
                                                        }
                                                      >
                                                        {props.t(
                                                          "Medicine alarm services"
                                                        )}
                                                        : {item.services.dose}
                                                      </Tooltip>
                                                    </>
                                                  )}{" "}
                                                  {item.services.health !==
                                                    0 && (
                                                    <>
                                                      <img
                                                        id={
                                                          "servMeasure" + index
                                                        }
                                                        style={{
                                                          height: "18px",
                                                          display: "inline",
                                                          marginTop: "-4px",
                                                        }}
                                                        src={iconMeasure}
                                                        alt=""
                                                        className="img-fluid me-3"
                                                      />
                                                      <Tooltip
                                                        placement="bottom"
                                                        target={
                                                          "servMeasure" + index
                                                        }
                                                        isOpen={
                                                          isTooltip[
                                                            item.name + "_m"
                                                          ]
                                                        }
                                                        toggle={() =>
                                                          toggleTooltip(
                                                            item.name + "_m"
                                                          )
                                                        }
                                                      >
                                                        {props.t(
                                                          "Measurement devices"
                                                        )}
                                                        : {item.services.health}
                                                      </Tooltip>
                                                    </>
                                                  )}
                                                  {item.services.smartlock !==
                                                    0 && (
                                                    <>
                                                      <img
                                                        id={"servLock" + index}
                                                        style={{
                                                          height: "18px",
                                                          display: "inline",
                                                          marginTop: "-4px",
                                                        }}
                                                        src={iconLock}
                                                        alt=""
                                                        className="img-fluid me-3"
                                                      />
                                                      <Tooltip
                                                        placement="bottom"
                                                        target={
                                                          "servLock" + index
                                                        }
                                                        isOpen={
                                                          isTooltip[
                                                            item.name + "_l"
                                                          ]
                                                        }
                                                        toggle={() =>
                                                          toggleTooltip(
                                                            item.name + "_l"
                                                          )
                                                        }
                                                      >
                                                        {props.t("Smartlocks")}:{" "}
                                                        {
                                                          item.services
                                                            .smartlock
                                                        }
                                                      </Tooltip>
                                                    </>
                                                  )}
                                                </CardFooter>
                                              </Card>
                                            </Col>
                                          ))}
                                      </>
                                    ) : (
                                      <Row>
                                        <Col>
                                          <p className="py-4">
                                            {props.t("No results found with")}{" "}
                                            {selectedTagsNames.length > 0 && (
                                              <>
                                                <strong>
                                                  {selectedTagsNames.join(", ")}{" "}
                                                </strong>
                                              </>
                                            )}
                                            {selectedTagsNames.length > 0 &&
                                              searchInput !== "" && (
                                                <>{props.t("and")}</>
                                              )}{" "}
                                            <strong>{searchInput}</strong>
                                          </p>
                                        </Col>
                                      </Row>
                                    )}
                                  </Row>
                                </Col>
                              ) : (
                                <></>
                              )}
                              {clientArrayOrig.length !== 0 && (
                                <Col lg="12" className="text-center ">
                                  <div className="progress-div mt-4">
                                    <div className="mb-3 text-muted progress-box">
                                      {clientArray.length >= wantedLength ? (
                                        <>
                                          <p className="mb-1">
                                            {props.t("Showing")} {wantedLength}{" "}
                                            <span className="font-size-10">
                                              /
                                            </span>{" "}
                                            {clientArray.length}
                                          </p>
                                          <Progress
                                            color="primary"
                                            className="progress-sm"
                                            value={wantedLength}
                                            max={clientArray.length}
                                          ></Progress>
                                        </>
                                      ) : (
                                        <>
                                          {/* {props.t("Showing all results")}! */}
                                        </>
                                      )}
                                    </div>
                                    {clientArray.length >= wantedLength ? (
                                      <div className="progress-box">
                                        <Button
                                          color="primary"
                                          style={{
                                            width: "70%",
                                            borderRadius: "25px",
                                          }}
                                          className="transition-color-link mt-4 py-1"
                                          onClick={() => {
                                            addLength(12)
                                          }}
                                        >
                                          <div className="mb-0 mt-0">
                                            {props.t("Show more")}
                                          </div>
                                        </Button>
                                      </div>
                                    ) : null}
                                  </div>
                                </Col>
                              )}
                            </>
                          )}
                        </Row>
                      </Col>
                    )}
                  </>
                )}
              </Row>
            </Col>
          </Row>

          {/** DELETE CLIENT MODAL */}
          <Modal
            isOpen={deleteModal}
            toggle={() => {
              setDeleteModal(!deleteModal)
            }}
            centered
          >
            <ModalHeader>{props.t("Delete confirmation")}</ModalHeader>
            <ModalBody>
              <p>
                {props.t("Are you sure you want to delete") +
                  " " +
                  clientData?.group +
                  " - " +
                  clientData?.name +
                  "?"}
              </p>
              <Form
                className="form-horizontal"
                onSubmit={e => {
                  e.preventDefault()
                  return false
                }}
              >
                <FormGroup>
                  <Label>{props.t("Reason")}</Label>
                  <Input
                    name="reason"
                    type="textarea"
                    rows={5}
                    value={deleteValidation.reason}
                    onChange={deleteValidation.handleChange}
                  ></Input>
                </FormGroup>
              </Form>
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                outline
                onClick={() => {
                  setDeleteModal(false)
                }}
              >
                {props.t("Cancel")}
              </Button>
              <Button
                disabled={ongoingDelete}
                color="danger"
                onClick={() => {
                  deleteValidation.submitForm()
                }}
              >
                {props.t("Delete")}
              </Button>
            </ModalFooter>
          </Modal>

          {/** DELETE CLIENT DATA MODAL */}
          <Modal
            isOpen={resetModal}
            toggle={() => {
              setResetModal(!resetModal)
            }}
            centered
          >
            <ModalHeader>{props.t("Delete data")}</ModalHeader>
            <ModalBody>
              <Alert color="info">{props.t("ClientDataDeleteInfo")}</Alert>
              <p>{props.t("Select the data to delete") + ":"}</p>

              <Form
                className="form-horizontal mt-4"
                onSubmit={e => {
                  e.preventDefault()
                  return false
                }}
              >
                <Row xs={2}>
                  <Col>
                    <FormGroup>
                      <Switch
                        className="me-2"
                        name="clientInfo"
                        style={{
                          backgroundColor: dataResetValidation.values.clientInfo
                            ? "#f46a6a"
                            : "",
                        }}
                        checkedChildren={<i className="mdi mdi-trash-can" />}
                        checked={dataResetValidation.values.clientInfo}
                        onChange={v =>
                          dataResetValidation.setFieldValue("clientInfo", v)
                        }
                      />
                      <Label>{props.t("Client information")}</Label>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Switch
                        className="me-2"
                        name="home"
                        style={{
                          backgroundColor: dataResetValidation.values.home
                            ? "#f46a6a"
                            : "",
                        }}
                        checkedChildren={<i className="mdi mdi-trash-can" />}
                        checked={dataResetValidation.values.home}
                        onChange={v =>
                          dataResetValidation.setFieldValue("home", v)
                        }
                      />
                      <Label>{props.t("Home sensor data")}</Label>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Switch
                        className="me-2"
                        name="tracker"
                        style={{
                          backgroundColor: dataResetValidation.values.tracker
                            ? "#f46a6a"
                            : "",
                        }}
                        checkedChildren={<i className="mdi mdi-trash-can" />}
                        checked={dataResetValidation.values.tracker}
                        onChange={v =>
                          dataResetValidation.setFieldValue("tracker", v)
                        }
                      />
                      <Label>{props.t("Tracker history")}</Label>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Switch
                        className="me-2"
                        name="alarms"
                        style={{
                          backgroundColor: dataResetValidation.values.alarms
                            ? "#f46a6a"
                            : "",
                        }}
                        checkedChildren={<i className="mdi mdi-trash-can" />}
                        checked={dataResetValidation.values.alarms}
                        onChange={v =>
                          dataResetValidation.setFieldValue("alarms", v)
                        }
                      />
                      <Label>{props.t("Alarms")}</Label>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Switch
                        className="me-2"
                        name="journal"
                        style={{
                          backgroundColor: dataResetValidation.values.journal
                            ? "#f46a6a"
                            : "",
                        }}
                        checkedChildren={<i className="mdi mdi-trash-can" />}
                        checked={dataResetValidation.values.journal}
                        onChange={v =>
                          dataResetValidation.setFieldValue("journal", v)
                        }
                      />
                      <Label>{props.t("Journal entries")}</Label>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Switch
                        className="me-2"
                        name="alarmRules"
                        style={{
                          backgroundColor: dataResetValidation.values.alarmRules
                            ? "#f46a6a"
                            : "",
                        }}
                        checkedChildren={<i className="mdi mdi-trash-can" />}
                        checked={dataResetValidation.values.alarmRules}
                        onChange={v =>
                          dataResetValidation.setFieldValue("alarmRules", v)
                        }
                      />
                      <Label>{props.t("Alarm rules")}</Label>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Switch
                        className="me-2"
                        name="scheduledMessages"
                        style={{
                          backgroundColor: dataResetValidation.values
                            .scheduledMessages
                            ? "#f46a6a"
                            : "",
                        }}
                        checkedChildren={<i className="mdi mdi-trash-can" />}
                        checked={dataResetValidation.values.scheduledMessages}
                        onChange={v =>
                          dataResetValidation.setFieldValue(
                            "scheduledMessages",
                            v
                          )
                        }
                      />
                      <Label>{props.t("Scheduled messages")}</Label>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Switch
                        className="me-2"
                        name="health"
                        style={{
                          backgroundColor: dataResetValidation.values.health
                            ? "#f46a6a"
                            : "",
                        }}
                        checkedChildren={<i className="mdi mdi-trash-can" />}
                        checked={dataResetValidation.values.health}
                        onChange={v =>
                          dataResetValidation.setFieldValue("health", v)
                        }
                      />
                      <Label>{props.t("Measurements")}</Label>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Switch
                        className="me-2"
                        name="userLinks"
                        style={{
                          backgroundColor: dataResetValidation.values.userLinks
                            ? "#f46a6a"
                            : "",
                        }}
                        checkedChildren={<i className="mdi mdi-trash-can" />}
                        checked={dataResetValidation.values.userLinks}
                        onChange={v =>
                          dataResetValidation.setFieldValue("userLinks", v)
                        }
                      />
                      <Label>{props.t("User links")}</Label>
                    </FormGroup>
                  </Col>
                </Row>

                <hr />
                <Row>
                  <Col>
                    <FormGroup>
                      <Label>
                        {props.t("Write the text below to confirm the action")}
                      </Label>
                      <Input
                        name="confirmationText"
                        placeholder="POISTO"
                        type="text"
                        value={dataResetValidation.values.confirmationText}
                        onChange={dataResetValidation.handleChange}
                      ></Input>
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                outline
                onClick={() => {
                  setResetModal(false)
                  dataResetValidation.resetForm()
                }}
              >
                {props.t("Cancel")}
              </Button>
              <Button
                disabled={
                  ongoingDelete ||
                  dataResetValidation.values.confirmationText !== "POISTO"
                }
                color="danger"
                onClick={() => {
                  dataResetValidation.submitForm()
                }}
              >
                {props.t("Delete")}
              </Button>
            </ModalFooter>
          </Modal>

          {/** CREATE CLIENT MODAL */}
          <Modal
            isOpen={createModal}
            toggle={() => {
              setCreateModal(!createModal)
            }}
            centered
          >
            <ModalHeader>{props.t("Create a new client")}</ModalHeader>
            <ModalBody>
              <Form
                className="form-horizontal"
                onSubmit={e => {
                  e.preventDefault()
                  return false
                }}
              >
                <FormGroup>
                  <Label>{props.t("Name")}</Label>
                  <Input
                    name="clientName"
                    type="text"
                    value={createValidation.clientName}
                    placeholder={props.t("Name")}
                    onChange={createValidation.handleChange}
                    onBlur={createValidation.handleBlur}
                    invalid={
                      createValidation.touched.clientName &&
                      createValidation.errors.clientName
                        ? true
                        : false
                    }
                  ></Input>
                  {createValidation.touched.clientName &&
                  createValidation.errors.clientName ? (
                    <FormFeedback type="invalid">
                      {createValidation.errors.clientName}
                    </FormFeedback>
                  ) : null}
                </FormGroup>
                <FormGroup>
                  <Label>{props.t("Group")}</Label>
                  <SelectGroup onChange={groupChanged} multiple={false} />
                </FormGroup>
              </Form>
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                outline
                onClick={() => {
                  setCreateModal(false)
                }}
              >
                {props.t("Cancel")}
              </Button>
              <Button
                disabled={
                  ongoingCreate ||
                  !createValidation.values.group ||
                  !createValidation.values.clientName
                }
                color="success"
                onClick={() => {
                  createValidation.submitForm()
                }}
              >
                {props.t("Create")}
              </Button>
            </ModalFooter>
          </Modal>
        </Container>
      </div>
    </>
  )
}

const DeleteAtHomeHistory = props => {
  const abortRef = useRef(null)
  const [deleteConfirm, setDeleteConfirm] = useState(false)
  // Delete modal
  const [deleteModal, setDeleteModal] = useState(false)
  const [ongoingDelete, setOngoingDelete] = useState(false)
  const [gatewayKey, setGatewayKey] = useState(props.gatewayKey)

  useEffect(() => {
    abortRef.current = new AbortController()
    return () => abortRef.current.abort()
  }, [])

  // DELETE ALL DATA
  const deleteAllData = () => {
    if (gatewayKey && !ongoingDelete) {
      setOngoingDelete(true)
      deleteHomeActivityHistory(abortRef.current, gatewayKey)
        .then(result => {
          if (result.statusCode == 200) {
            showToast(props.t("Deleted"), "success")
            setDeleteModal(false)
          } else {
            showToast(props.t("Error"), "error")
          }
        })
        .catch(err => {
          console.log(err)
          showToast(props.t("Error"), "error")
        })
        .finally(() => {
          setOngoingDelete(false)
          props.refreshClient()
        })
    }
  }

  const confirm = value => {
    if (value === true) {
      setDeleteModal(!deleteModal)
    }
  }
  return (
    <>
      {/** DELETE ACTIVITY DATA MODAL */}
      <Modal
        isOpen={deleteModal}
        toggle={() => {
          setDeleteModal(!deleteModal)
        }}
        centered
      >
        <ModalHeader>{props.t("Delete confirmation")}</ModalHeader>
        <ModalBody className="py-4">
          <p>{props.t("Are you sure you want to delete all activity data")}?</p>
          <h5 className="font-size-14 text-danger ">
            {props.t("The action is final and cannot be revoked")}.
          </h5>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            outline
            onClick={() => {
              setDeleteModal(false)
            }}
          >
            {props.t("Cancel")}
          </Button>
          <Button
            disabled={ongoingDelete}
            color="danger"
            onClick={() => {
              deleteAllData()
            }}
          >
            {props.t("Delete")}
          </Button>
        </ModalFooter>
      </Modal>
      <Popconfirm
        title={props.t("Do you want to delete all activity data?")}
        open={deleteConfirm}
        okType="danger"
        okText={props.t("Confirm")}
        cancelText={props.t("Cancel")}
        onConfirm={() => {
          confirm(true)
          setDeleteConfirm(false)
        }}
        onCancel={() => {
          setDeleteConfirm(false)
        }}
      >
        <Button
          className="ms-2"
          color="danger"
          style={{
            borderRadius: "100px",
            paddingTop: "4px",
            paddingBottom: "4px",
          }}
          disabled={props.showLoading || props.refreshing}
          onClick={() => {
            setDeleteConfirm(true)
          }}
        >
          <i className="bx bx-trash me-2" />
          {props.t("Delete all data")}
        </Button>
      </Popconfirm>
    </>
  )
}

DeleteAtHomeHistory.propTypes = {
  t: PropTypes.any,
  showLoading: PropTypes.any,
  refreshing: PropTypes.bool,
  deleteData: PropTypes.any,
  refreshClient: PropTypes.any,
  gatewayKey: PropTypes.any,
}

CustomerProfile.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
  type: PropTypes.string,
  history: PropTypes.any,
  settingOptions: PropTypes.any,
  deleteData: PropTypes.any,
}
export default withTranslation()(CustomerProfile)
