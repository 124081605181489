const action = [
  {
    label: "Devices",
    types: [
      { type: "Devices", label: "AlarmTitles." + "1" },
      { type: "Devices", label: "AlarmTitles." + "2" },
      { type: "Devices", label: "AlarmTitles." + "10" },
      { type: "Devices", label: "AlarmTitles." + "11" },
      { type: "Devices", label: "AlarmTitles." + "26" },
      { type: "Devices", label: "AlarmTitles." + "29" },
      { type: "Devices", label: "AlarmTitles." + "32" },
      { type: "Devices", label: "AlarmTitles." + "33" },
      { type: "Devices", label: "AlarmTitles." + "44" },
      { type: "Devices", label: "AlarmTitles." + "45" },
      { type: "Devices", label: "AlarmTitles." + "46" },
      { type: "Devices", label: "AlarmTitles." + "47" },
      { type: "Devices", label: "AlarmTitles." + "48" },
      { type: "Devices", label: "AlarmTitles." + "51" },
      { type: "Devices", label: "AlarmTitles." + "52" },
      { type: "Devices", label: "AlarmTitles." + "53" },
    ],
  },
  {
    label: "Apartment",
    types: [
      { type: "Apartment", label: "AlarmTitles." + "20" },
      { type: "Apartment", label: "AlarmTitles." + "21" },
      { type: "Apartment", label: "AlarmTitles." + "22" },
      { type: "Apartment", label: "AlarmTitles." + "23" },
      { type: "Apartment", label: "AlarmTitles." + "37" },
      { type: "Apartment", label: "AlarmTitles." + "38" },
      { type: "Apartment", label: "AlarmTitles." + "18" },
      { type: "Apartment", label: "AlarmTitles." + "19" },
    ],
  },
  {
    label: "Health",
    types: [
      {
        type: "Health",
        label: "AlarmTitles." + "30",
      },
      { type: "Health", label: "AlarmTitles." + "27" },
      { type: "Health", label: "AlarmTitles." + "28" },
      { type: "Health", label: "AlarmTitles." + "31" },
    ],
  },
  {
    label: "Safety",
    types: [
      {
        type: "Safety",
        label: "AlarmTitles." + "3",
      },
      {
        type: "Safety",
        label: "AlarmTitles." + "4",
      },
      {
        type: "Safety",
        label: "AlarmTitles." + "35",
      },
      {
        type: "Safety",
        label: "AlarmTitles." + "36",
      },
      {
        type: "Safety",
        label: "AlarmTitles." + "42",
      },
      { type: "Safety", label: "AlarmTitles." + "5" },
    ],
  },
  {
    label: "Activity",
    types: [
      {
        type: "Activity",
        label: "AlarmTitles." + "6",
      },
      {
        type: "Activity",
        label: "AlarmTitles." + "12",
      },
      {
        type: "Activity",
        label: "AlarmTitles." + "14",
      },
      {
        type: "Activity",
        label: "AlarmTitles." + "16",
      },
      {
        type: "Activity",
        label: "AlarmTitles." + "24",
      },
      {
        type: "Activity",
        label: "AlarmTitles." + "25",
      },
    ],
  },
  {
    label: "Abnormal activity",
    types: [
      {
        type: "Abnormal activity",
        label: "AlarmTitles." + "17",
      },
      {
        type: "Abnormal activity",
        label: "AlarmTitles." + "15",
      },
      {
        type: "Abnormal activity",
        label: "AlarmTitles." + "13",
      },
    ],
  },
  {
    label: "Other",
    types: [
      {
        type: "Other",
        label: "AlarmTitles." + "34",
      },
      {
        type: "Other",
        label: "AlarmTitles." + "39",
      },
      {
        type: "Other",
        label: "AlarmTitles." + "43",
      },
    ],
  },
]

export { action }
