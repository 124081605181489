import React, { useContext, useEffect, useRef, useState } from "react"
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Collapse,
  Form,
  FormGroup,
  Input,
  Label,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import { Drawer, Switch } from "antd"
import * as Yup from "yup"
import { useFormik } from "formik"
import { showToast } from "utils/general"
import { isEqual } from "lodash"
import {
  getApplicationUser,
  getAppUsersLinks,
  linkClientsToAppUser,
  sendAppUserCredentials,
  unlinkClientsFromAppUser,
  updateApplicationUser,
} from "helpers/backend_helper"
import Lottie from "react-lottie"
import loadingAnim from "common/animations/loading-dots-blue"
import classnames from "classnames"
import SelectGroup from "components/DropdownList/SelectGroupFilters"
import TagsTree from "components/DropdownList/TagsTree"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import GlobalState from "contexts/GlobalState"
import withRouter from "components/Common/withRouter"

const AppUserDrawer = props => {
  const [state, setState] = useContext(GlobalState)
  const [userRole, setUserRole] = useState(null)
  const [activeTab, setActiveTab] = useState("1")
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const [update, setUpdate] = useState(false)
  const [loading, setLoading] = useState(false)
  const [userData, setUserData] = useState(null)
  const [newPassword, setNewPassword] = useState("")
  const [sms, setSms] = useState(
    "Lataa Suvanto Care -sovellus osoitteesta suvantocare.fi/download.html Käyttäjätunnus on muotoa etu.sukunimi ja salasana"
  )
  const [selectedClients, setSelectedClients] = useState([])
  const [clients, setClients] = useState([])
  const [filteredClients, setFilteredClients] = useState([])
  const [linksGroup, setLinksGroup] = useState([])
  const [searchText, setSearchText] = useState("")
  const [currentPage, setCurrentPage] = useState(1)
  const [selectedTags, setSelectedTags] = useState([])

  const abortRef = useRef(null)
  useEffect(() => {
    abortRef.current = new AbortController()
    if (state.authUser != null) {
      const obj = JSON.parse(state.authUser)
      let role = obj.activeRole ? obj.activeRole : obj.role
      setUserRole(role)
    }
    return () => abortRef.current.abort()
  }, [])

  useEffect(() => {
    setSearchText("")
    if (props.selectedRow) {
      setNewPassword("")
      getData()
      setClients([])
    }
  }, [props.selectedRow])

  useEffect(() => {
    if (activeTab == "2") {
      getLinkableClients()
    }
  }, [linksGroup, userData, activeTab])

  // Search or filter changed
  useEffect(() => {
    setCurrentPage(1)
    handleSearch()
  }, [searchText, clients])

  // Get admins data
  const getData = () => {
    if (props.selectedRow?.key) {
      setLoading(true)
      setUserData(null)
      getApplicationUser(abortRef.current, props.selectedRow.key)
        .then(result => {
          if (result.statusCode == 200 && result.data) {
            setUserData(result.data)
            //setLinksGroup([result.data.group])
            //setLinksGroup([])
          }
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  // Get linkable clients for link management
  const getLinkableClients = () => {
    if (userData?.key && !update) {
      setUpdate(true)
      setClients([])
      getAppUsersLinks(abortRef.current, userData.key, linksGroup)
        .then(result => {
          if (result.statusCode == 200 && result.entries) {
            result.entries.sort((a, b) =>
              a.clientName > b.clientName ? 1 : -1
            )
            setClients(result.entries)
          } else {
            showToast(props.t("Error"), "Error")
          }
        })
        .catch(err => {
          console.log(err)
          showToast(props.t("Error"), "Error")
        })
        .finally(() => {
          setUpdate(false)
        })
    }
  }

  // Filter unchanged values
  const filterUnchangedValues = (originalValues, newValues) => {
    const changedValues = {}

    for (const key in newValues) {
      if (!isEqual(originalValues[key], newValues[key])) {
        changedValues[key] = newValues[key]
      }
    }
    return changedValues
  }

  // Update client validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: userData?.name || "",
      group: userData?.group || "",
      gsm: userData?.gsm || "",
      email: userData?.email || "",
      language: userData?.language || "",
      unit: userData?.unit || "",
      relative: userData?.relative || false,
      notificationsDisabled: userData?.notificationsDisabled || false,
      alarmsDisabled: userData?.alarmsDisabled || false,
      personalJournalOnly: userData?.personalJournalOnly || false,
    },
    validationSchema: Yup.object({}),
    onSubmit: values => {
      console.log("Submit values: ", values)
      // Filter out unchanged values
      const initialFormValues = JSON.parse(
        JSON.stringify(validation.initialValues)
      )
      const changedValues = filterUnchangedValues(initialFormValues, values)
      console.log("Filtered changed: ", changedValues)
      let temp = { ...changedValues }
      console.log("Filtered submit values: ", temp)

      setSuccess("")
      setError("")
      if (userData?.key && !update) {
        setUpdate(true)
        updateApplicationUser(abortRef.current, userData?.key, temp)
          .then(result => {
            if (result.statusCode == 200) {
              setSuccess("Updated")
              getData()
              props.getUsers()
            } else {
              setError("Error")
            }
          })
          .catch(err => {
            console.log(err)
            setError("Error")
          })
          .finally(() => {
            setUpdate(false)
          })
      }
    },
  })

  // Send credentials
  const sendCredentials = () => {
    if (userData?.key && !update) {
      //console.log("Send creds: ", userData.key, sms)
      setUpdate(true)
      sendAppUserCredentials(abortRef.current, userData.key, sms)
        .then(result => {
          if (result.statusCode == 200) {
            showToast(props.t("Sent"), "success")
          } else {
            showToast(props.t("Error"), "error")
          }
        })
        .catch(err => {
          console.log(err)
          showToast(props.t("Error"), "error")
        })
        .finally(() => {
          setUpdate(false)
        })
    }
  }

  // Link selected clients to user
  const linkClients = () => {
    if (userData?.key && !update && selectedClients.length > 0) {
      setUpdate(true)
      linkClientsToAppUser(abortRef.current, userData.key, selectedClients)
        .then(result => {
          if (result.statusCode == 200) {
            showToast(props.t("Success"), "success")
            setSelectedClients([])
            getLinkableClients()
          } else {
            showToast(props.t("Error"), "error")
          }
        })
        .catch(err => {
          console.log(err)
          showToast(props.t("Error"), "error")
        })
        .finally(() => {
          setUpdate(false)
        })
    }
  }

  // Unlink selected clients from user
  const unlinkClients = () => {
    if (userData?.key && !update && selectedClients.length > 0) {
      setUpdate(true)
      unlinkClientsFromAppUser(abortRef.current, userData.key, selectedClients)
        .then(result => {
          if (result.statusCode == 200) {
            showToast(props.t("Success"), "success")
            setSelectedClients([])
            getLinkableClients()
          } else {
            showToast(props.t("Error"), "error")
          }
        })
        .catch(err => {
          console.log(err)
          showToast(props.t("Error"), "error")
        })
        .finally(() => {
          setUpdate(false)
        })
    }
  }

  // Group selection changed in users info
  const groupChanged = newGroup => {
    validation.setFieldValue("group", newGroup)
  }

  // Group selection changed in links management
  const groupChanged2 = newGroup => {
    setLinksGroup(newGroup)
  }

  // Handle search function
  const handleSearch = () => {
    let result = clients.filter(data =>
      data.clientName?.toLowerCase().includes(searchText)
    )
    setFilteredClients(result)
  }

  const defaultSorted = [
    {
      dataField: "linked",
      order: "desc",
    },
  ]

  // Show selected number of rows
  const pageOptions = {
    custom: true,
    pageStartIndex: 1,
    totalSize: filteredClients.length,
    page: currentPage,
    onPageChange: (page, sizePerPage) => {
      setCurrentPage(page)
    },
    onSizePerPageChange: (sizePerPage, page) => {
      setCurrentPage(1)
    },
  }

  // Table structure
  const columns = [
    {
      dataField: "linked",
      text: props.t("Linked"),
      sort: false,
      align: "center",
      formatter: linkedFormatter,
    },
    {
      dataField: "clientName",
      text: props.t("Client"),
      sort: true,
    },
    {
      dataField: "group",
      text: props.t("Group"),
      sort: true,
    },
  ]

  function linkedFormatter(cell, row) {
    if (row.linked == true) {
      return <Input type="checkbox" disabled defaultChecked={true}></Input>
    } else return <></>
  }

  const selectRow = {
    mode: "checkbox",
    selected: selectedClients,
    clickToSelect: false,
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        setSelectedClients(prevState => [...prevState, row.clientKey])
      } else {
        setSelectedClients(prevState =>
          prevState.filter(prevItem => prevItem !== row.clientKey)
        )
      }
    },
    onSelectAll: (isSelect, rows) => {
      if (isSelect) {
        rows.map(row =>
          setSelectedClients(prevState => [...prevState, row.clientKey])
        )
      } else {
        setSelectedClients([])
      }
    },
  }
  return (
    <>
      <Drawer
        title={props.selectedRow?.username}
        width={"80%"}
        placement="right"
        onClose={() => {
          props.setShowDrawer(false)
          setActiveTab("1")
          setClients([])
          setLinksGroup([])
          setSelectedClients([])
          setError("")
          setSuccess("")
          setSms(
            "Lataa Suvanto Care -sovellus osoitteesta suvantocare.fi/download.html Käyttäjätunnus on muotoa etu.sukunimi ja salasana"
          )
        }}
        open={props.showDrawer}
        zIndex={1003}
        bodyStyle={{ backgroundColor: "#f8f8f8" }}
      >
        {loading && (
          <Lottie
            options={{
              loop: false,
              autoplay: true,
              animationData: loadingAnim,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
              },
            }}
            height={100}
            width={100}
          ></Lottie>
        )}

        {!loading && userData && (
          <>
            <div className="d-flex flex-wrap mb-3">
              <ul className="nav icon-tab" role="tablist">
                <NavItem>
                  <NavLink
                    to="#"
                    className={classnames({ active: activeTab === "1" })}
                    onClick={() => {
                      setActiveTab("1")
                    }}
                  >
                    {props.t("Information and settings")}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "2" })}
                    onClick={() => {
                      setActiveTab("2")
                    }}
                  >
                    {props.t("Manage users links")}
                  </NavLink>
                </NavItem>
              </ul>
            </div>

            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <Row xs={1} xl={2}>
                  <Col xs={12} xl={7}>
                    <Card>
                      <CardBody>
                        <CardTitle className="mb-4">
                          {props.t("User information")}
                        </CardTitle>
                        {loading && (
                          <Lottie
                            options={{
                              loop: false,
                              autoplay: true,
                              animationData: loadingAnim,
                              rendererSettings: {
                                preserveAspectRatio: "xMidYMid slice",
                              },
                            }}
                            height={100}
                            width={100}
                          ></Lottie>
                        )}
                        {!loading && (
                          <Form
                            autoComplete="nope"
                            className="form-horizontal"
                            onSubmit={e => {
                              e.preventDefault()
                              return false
                            }}
                          >
                            {error ? (
                              <Alert color="danger">{props.t(error)}</Alert>
                            ) : null}
                            {success ? (
                              <Alert color="success">{props.t(success)}</Alert>
                            ) : null}
                            <Row xs={1} md={2}>
                              <Col>
                                <FormGroup>
                                  <Label>{props.t("Name")}</Label>
                                  <Input
                                    name="name"
                                    type="text"
                                    value={validation.values.name}
                                    onChange={validation.handleChange}
                                  ></Input>
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup>
                                  <Label>{props.t("Group")}</Label>
                                  <SelectGroup
                                    onChange={groupChanged}
                                    multiple={false}
                                    // clearable={false}
                                    selection={validation.values.group}
                                  />
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup>
                                  <Label>{props.t("GSM")}</Label>
                                  <Input
                                    name="gsm"
                                    type="text"
                                    value={validation.values.gsm}
                                    onChange={validation.handleChange}
                                  ></Input>
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup>
                                  <Label>{props.t("Email")}</Label>
                                  <Input
                                    name="email"
                                    type="text"
                                    value={validation.values.email}
                                    onChange={validation.handleChange}
                                  ></Input>
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup>
                                  <Label>{props.t("Language")}</Label>
                                  <Input
                                    name="language"
                                    type="select"
                                    value={validation.values.language}
                                    onChange={validation.handleChange}
                                  >
                                    <option value="en">
                                      {props.t("English")}
                                    </option>
                                    <option value="fi-FI">
                                      {props.t("Finnish")}
                                    </option>
                                    <option value="sv-SE">
                                      {props.t("Swedish")}
                                    </option>
                                  </Input>
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup>
                                  <Label>{props.t("Unit info")}</Label>
                                  <Input
                                    name="unit"
                                    type="text"
                                    value={validation.values.unit}
                                    onChange={validation.handleChange}
                                  ></Input>
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup>
                                  <Label>{props.t("New password")}</Label>
                                  <Input
                                    name="password"
                                    type="text"
                                    value={validation.values.password}
                                    onChange={validation.handleChange}
                                  ></Input>
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row xs={1} md={2}>
                              <Col>
                                <FormGroup className="mt-2">
                                  <Label>{props.t("Relative")}</Label>
                                  <Switch
                                    className="d-block"
                                    name="relative"
                                    checked={validation.values.relative}
                                    onChange={v => {
                                      validation.setFieldValue("relative", v)
                                    }}
                                  />
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup className="mt-2">
                                  <Label>
                                    {props.t("Disable notifications")}
                                  </Label>
                                  <Switch
                                    className="d-block"
                                    name="notificationsDisabled"
                                    checked={
                                      validation.values.notificationsDisabled
                                    }
                                    onChange={v => {
                                      validation.setFieldValue(
                                        "notificationsDisabled",
                                        v
                                      )
                                    }}
                                  />
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup className="mt-2">
                                  <Label>
                                    {props.t("Disable and hide alarms")}
                                  </Label>
                                  <Switch
                                    className="d-block"
                                    name="alarmsDisabled"
                                    checked={validation.values.alarmsDisabled}
                                    onChange={v => {
                                      validation.setFieldValue(
                                        "alarmsDisabled",
                                        v
                                      )
                                    }}
                                  />
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup className="mt-2">
                                  <Label>
                                    {props.t(
                                      "Show only personal journal entries"
                                    )}
                                  </Label>
                                  <Switch
                                    className="d-block"
                                    name="personalJournalOnly"
                                    checked={
                                      validation.values.personalJournalOnly
                                    }
                                    onChange={v => {
                                      validation.setFieldValue(
                                        "personalJournalOnly",
                                        v
                                      )
                                    }}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>

                            <div className="mt-2 mb-3 d-flex justify-content-center">
                              <Button
                                color="success"
                                disabled={update}
                                style={{ minWidth: "150px" }}
                                onClick={() => {
                                  validation.submitForm()
                                }}
                              >
                                {props.t("Save")}
                              </Button>
                            </div>
                          </Form>
                        )}
                      </CardBody>
                    </Card>
                  </Col>
                  <Col xs={12} xl={5}>
                    <Card>
                      <CardBody>
                        <CardTitle className="mb-4">
                          {props.t("Send login credentials")}
                        </CardTitle>
                        <FormGroup>
                          <Label>{props.t("SMS")}</Label>
                          <Input
                            bsSize="sm"
                            name="sms"
                            type="textarea"
                            rows={4}
                            value={sms}
                            onChange={v => {
                              setSms(v.target.value)
                            }}
                          ></Input>
                        </FormGroup>

                        <div className="mt-2 mb-3 d-flex justify-content-center">
                          <Button
                            color="primary"
                            disabled={!userData?.gsm || update}
                            style={{ minWidth: "150px" }}
                            onClick={() => {
                              sendCredentials()
                            }}
                          >
                            {props.t("Send")}
                          </Button>
                        </div>

                        {userRole == "system_admin" && (
                          <div className="mb-3 d-flex justify-content-center">
                            <Button
                              color="secondary"
                              style={{ minWidth: "150px" }}
                              disabled={!userData?.gsm}
                              onClick={() => {
                                window.open(
                                  "/logs/sms?recipient=" + userData?.gsm,
                                  "_blank",
                                  "noreferrer"
                                )
                              }}
                            >
                              {props.t("View SMS history")}
                            </Button>
                          </div>
                        )}
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </TabPane>

              <TabPane tabId="2">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="clientKey"
                          columns={columns}
                          data={filteredClients}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row>
                                <Col className="col-auto pe-0">
                                  <Form
                                    className="mt-sm-0 d-flex align-items-center"
                                    onSubmit={e => {
                                      e.preventDefault()
                                      return false
                                    }}
                                  >
                                    <div className="search-box me-2 mb-2 d-inline-block float-end">
                                      <div className="position-relative">
                                        <Input
                                          type="text"
                                          onChange={event =>
                                            setSearchText(
                                              event.target.value.toLowerCase()
                                            )
                                          }
                                          placeholder={props.t("Search")}
                                        />
                                        <i className="bx bx-search-alt search-icon" />
                                      </div>
                                    </div>
                                  </Form>
                                </Col>
                                {/* TAGS */}
                                {userRole === "system_admin" && (
                                  <Col
                                    className="col-auto pe-0"
                                    style={{ minWidth: "300px" }}
                                  >
                                    <TagsTree
                                      loadData={true}
                                      expanded={true}
                                      selectionChanged={setSelectedTags}
                                    />
                                  </Col>
                                )}
                                <Col>
                                  <FormGroup>
                                    <SelectGroup
                                      onChange={groupChanged2}
                                      multiple={true}
                                      clearable={true}
                                      selection={linksGroup}
                                      selectedTags={selectedTags}
                                    />
                                    {/* <SelectGroup
                                      onChange={groupChanged2}
                                      multiple={true}
                                      clearable={false}
                                      selection={linksGroup}
                                    /> */}
                                  </FormGroup>
                                </Col>
                                <Col>
                                  <div className="mt-2 float-end">
                                    {filteredClients.length == 1 ? (
                                      <>
                                        {" "}
                                        {filteredClients.length +
                                          " " +
                                          props.t("selected client")}
                                      </>
                                    ) : (
                                      <>
                                        {" "}
                                        {filteredClients.length +
                                          " " +
                                          props.t("selected clients")}
                                      </>
                                    )}
                                  </div>
                                </Col>
                              </Row>

                              <Collapse isOpen={selectedClients.length > 0}>
                                <Row className="py-2">
                                  <Col className="col-auto">
                                    <Button
                                      color="success"
                                      disabled={
                                        selectedClients.length == 0 || update
                                      }
                                      onClick={() => {
                                        linkClients()
                                      }}
                                    >
                                      <i className="mdi mdi-link-variant-plus me-2" />
                                      {props.t("Link selected clients")}
                                    </Button>
                                    <Button
                                      color="danger"
                                      className="ms-3"
                                      disabled={
                                        selectedClients.length == 0 || update
                                      }
                                      onClick={() => {
                                        unlinkClients()
                                      }}
                                    >
                                      <i className="mdi mdi-link-variant-off me-2" />
                                      {props.t("Unlink selected clients")}
                                    </Button>
                                  </Col>
                                  <Col className="mt-2">
                                    {selectedClients.length == 1 ? (
                                      <>
                                        {" "}
                                        {selectedClients.length +
                                          " " +
                                          props.t("selected user")}
                                      </>
                                    ) : (
                                      <>
                                        {" "}
                                        {selectedClients.length +
                                          " " +
                                          props.t("selected users")}
                                      </>
                                    )}
                                  </Col>
                                </Row>
                              </Collapse>

                              {!loading && (
                                <>
                                  <div className="table-responsive mt-1 mb-2">
                                    <BootstrapTable
                                      id="clients-table"
                                      keyField={"clientKey"}
                                      responsive
                                      bordered={true}
                                      striped={true}
                                      hover={true}
                                      selectRow={selectRow}
                                      defaultSorted={defaultSorted}
                                      classes={
                                        "table align-middle table-nowrap"
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      headerClasses="header-class"
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />
                                  </div>
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </>
                              )}
                              {loading && (
                                <Lottie
                                  options={{
                                    loop: false,
                                    autoplay: true,
                                    animationData: loadingAnim,
                                    rendererSettings: {
                                      preserveAspectRatio: "xMidYMid slice",
                                    },
                                  }}
                                  height={100}
                                  width={100}
                                ></Lottie>
                              )}
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </TabPane>
            </TabContent>
          </>
        )}
      </Drawer>
    </>
  )
}

AppUserDrawer.propTypes = {
  t: PropTypes.any,
  showDrawer: PropTypes.bool,
  setShowDrawer: PropTypes.any,
  selectedRow: PropTypes.any,
  getUsers: PropTypes.func,
  selectionChanged: PropTypes.func,
}

export default withRouter(withTranslation()(AppUserDrawer))
