import React, { useEffect, useRef, useState } from "react"
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Button,
  Form,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  FormFeedback,
  Tooltip,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Label,
  Alert,
  UncontrolledTooltip,
  Collapse,
} from "reactstrap"
import PropTypes from "prop-types"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import moment from "moment"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import Lottie from "react-lottie"
import loadingAnim from "common/animations/loading-dots-blue"
import searchAnim from "common/animations/finddata"
import { showToast } from "utils/general"
import SelectGroup from "components/DropdownList/SelectGroupFilters"
import TagsTree from "components/DropdownList/TagsTree"
import {
  addAdminsToGroup,
  createAdminUser,
  createGroup,
  deleteGroup,
  getAdminUsers,
  removeAdminsFromGroup,
} from "helpers/backend_helper"
import AdminDrawer from "./admin-drawer"
import * as Yup from "yup"
import { useFormik } from "formik"
import { Switch } from "antd"
import XLSX from "xlsx"
import jsPDF from "jspdf"
import "jspdf-autotable"

const AdminUsersManagement = props => {
  const [group, setGroup] = useState(null)
  const [users, setUsers] = useState([])
  const [filteredUsers, setFilteredUsers] = useState([])
  const [addModal, setAddModal] = useState(false)
  const [groupAddModal, setGroupAddModal] = useState(false)
  const [groupDeleteModal, setGroupDeleteModal] = useState(false)
  const [selectedRow, setSelectedRow] = useState(null)
  const [loading, setLoading] = useState(false)
  const [showDrawer, setShowDrawer] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [ongoingAdd, setOngoingAdd] = useState(false)
  const [showLinked, setShowLinked] = useState(true)
  const [updateCounter, setUpdateCounter] = useState(0)
  const [selectedUsers, setSelectedUsers] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(50)
  const [TTdownloadstatstable, setTTdownloadstatstable] = useState(false) // Tooltip state for download button
  const [menu, setMenu] = useState(false) //Dropdown button state
  const [selectedTags, setSelectedTags] = useState([])

  const abortRef = useRef(null)
  useEffect(() => {
    document.title = props.t("Admin users") + " | Suvanto Care"
    abortRef.current = new AbortController()
    return () => abortRef.current.abort()
  }, [])

  useEffect(() => {
    //console.log("Group changed -> get users")
    if (group) getAdmins()
  }, [group, showLinked])

  useEffect(() => {
    console.log(group, "group", loading, "loading")
    if (showLinked == true) {
      setSelectedUsers([])
      // console.log(group, "groups")
    }
    if (!group || group.length == 0) {
      setFilteredUsers([])
    }
  }, [group])

  // Search or filter changed
  useEffect(() => {
    handleSearch()
  }, [searchText, users])

  // useEffect(() => {
  //   console.log("Selected users: ", selectedUsers)
  // }, [selectedUsers])

  // useEffect(() => {
  //   //console.log("Filtered users: ", filteredUsers)
  // }, [filteredUsers])

  // Handle search function
  const handleSearch = () => {
    setCurrentPage(1)
    let result = users.filter(
      data =>
        data.name?.toLowerCase().includes(searchText) ||
        data.description?.toLowerCase().includes(searchText) ||
        data.groups?.toLowerCase().includes(searchText) ||
        data.gsm?.toLowerCase().includes(searchText) ||
        data.email?.toLowerCase().includes(searchText) ||
        data.fullName?.toLowerCase().includes(searchText)
    )
    // console.log(users, result, searchText, "filtered : resultFiltered")
    setFilteredUsers(result)
  }

  // Get admin users for table
  const getAdmins = () => {
    setLoading(true)
    getAdminUsers(abortRef.current, group, showLinked)
      .then(result => {
        //console.log("Admins: ", result.entries)
        if (result.statusCode == 200 && result.entries) {
          result.entries.sort((a, b) => (a.name > b.name ? 1 : -1))
          setUsers(result.entries)
        }
      })
      .catch(err => {
        console.log(err)
        setUsers([])
      })
      .finally(() => {
        setLoading(false)
      })
  }

  // Link admins to a group
  const linkAdmins = () => {
    setLoading(true)
    addAdminsToGroup(abortRef.current, group, selectedUsers)
      .then(result => {
        //console.log("Link: ", result)
        if (result.statusCode == 200) {
          //setSelectedUsers([])
          getAdmins()
          showToast(props.t("Success"), "success", 3000)
        } else {
          showToast(props.t("Error"), "error")
        }
      })
      .catch(err => {
        console.log(err)
        showToast(props.t("Error"), "error")
      })
      .finally(() => {
        setLoading(false)
      })
  }

  // Unlink admins from a group
  const unlinkAdmins = () => {
    setLoading(true)
    removeAdminsFromGroup(abortRef.current, group, selectedUsers)
      .then(result => {
        //console.log("Unlink: ", result)
        if (result.statusCode == 200) {
          //setSelectedUsers([])
          getAdmins()
          showToast(props.t("Success"), "success", 3000)
        } else {
          showToast(props.t("Error"), "error")
        }
      })
      .catch(err => {
        console.log(err)
        showToast(props.t("Error"), "error")
      })
      .finally(() => {
        setLoading(false)
      })
  }

  // Reset selections
  const resetSelections = () => {
    setSelectedUsers([])
  }

  // Create user validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      username: "",
    },
    validationSchema: Yup.object({
      username: Yup.string()
        .matches(
          /^[a-z0-9]+[a-z0-9-]*[a-z0-9]+[.]{1}[a-z0-9]+[a-z0-9-]*[a-z0-9]$/,
          props.t("Invalid format")
        )
        .min(7, props.t("Too short"))
        .required(props.t("Required field")),
    }),
    onSubmit: values => {
      console.log("Submit: ", values)
      if (values.username && group) {
        setOngoingAdd(true)
        createAdminUser(abortRef.current, group, values.username.toLowerCase())
          .then(result => {
            if (result.statusCode == 200) {
              showToast(props.t("Created"), "success")
              setAddModal(false)
              validation.resetForm()
              getAdmins()
            } else {
              showToast(props.t("Error"), "error")
            }
          })
          .catch(err => {
            console.log(err)
            showToast(props.t("Error"), "error")
          })
          .finally(() => {
            setOngoingAdd(false)
          })
      }
    },
  })

  // Create group validation
  const groupValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      group: "",
    },
    validationSchema: Yup.object({
      group: Yup.string()
        .matches(
          /^[A-ZÄÖÅ0-9]+[a-zäöåA-ZÄÖÅ0-9- ]*[a-zäöåA-ZÄÖÅ0-9]$/,
          props.t("Invalid format")
        )
        .min(4, props.t("Too short"))
        .required(props.t("Required field")),
    }),
    onSubmit: values => {
      console.log("Submit: ", values)
      setOngoingAdd(true)
      createGroup(abortRef.current, values.group)
        .then(result => {
          if (result.statusCode == 200) {
            showToast(props.t("Created"), "success")
            setGroupAddModal(false)
            groupValidation.resetForm()
            setUpdateCounter(prevCount => prevCount + 1)
          } else {
            showToast(props.t("Error"), "error")
          }
        })
        .catch(err => {
          console.log(err)
          showToast(props.t("Error"), "error")
        })
        .finally(() => {
          setOngoingAdd(false)
        })
    },
  })

  // Delete selected group
  const groupDelete = () => {
    if (group) {
      setOngoingAdd(true)
      deleteGroup(abortRef.current, group)
        .then(result => {
          if (result.statusCode == 200) {
            showToast(props.t("Deleted"), "success")
            setGroupDeleteModal(false)
            setGroup(null)
            setUpdateCounter(prevCount => prevCount + 1)
          } else if (result.statusCode == 403) {
            showToast(
              props.t("Operation failed") +
                ", " +
                props.t("reason") +
                ": " +
                result.reason,
              "error"
            )
          } else {
            showToast(props.t("Error"), "error")
          }
        })
        .catch(err => {
          console.log(err)
          showToast(props.t("Error"), "error")
        })
        .finally(() => {
          setOngoingAdd(false)
        })
    }
  }

  const defaultSorted = [
    {
      dataField: "linked",
      order: "desc",
    },
  ]

  // Show selected number of rows
  const pageOptions = {
    custom: true,
    pageStartIndex: 1,
    totalSize: filteredUsers.length,
    sizePerPage: pageSize,
    page: currentPage,
    onPageChange: (page, sizePerPage) => {
      setCurrentPage(page)
    },
    onSizePerPageChange: (sizePerPage, page) => {
      setPageSize(sizePerPage)
      setCurrentPage(1)
    },
  }

  // Table structure
  const columns = [
    {
      dataField: "actions",
      isDummy: true,
      text: props.t("Actions"),
      sort: true,
      align: "center",
      formatter: editFormatter,
    },
    {
      dataField: "linked",
      text: props.t("Linked"),
      sort: false,
      align: "center",
      formatter: linkedFormatter,
      /*style: (cell, row, rowIndex, colIndex) => {
        if (row.linked == true) {
          return {
            color: '#0cc744'
          };
        }
      }*/
    },
    {
      dataField: "name",
      text: props.t("Username"),
      sort: true,
      formatter: usernameFormatter,
    },
    {
      dataField: "fullName",
      text: props.t("Name"),
      sort: true,
    },
    {
      dataField: "description",
      text: props.t("Description"),
      sort: true,
    },
    {
      dataField: "basicUser",
      text: props.t("Basic user"),
      sort: true,
    },
    {
      dataField: "groups",
      text: props.t("Groups"),
      sort: true,
    },
    {
      dataField: "email",
      text: props.t("Email"),
      sort: true,
    },
    {
      dataField: "gsm",
      text: props.t("GSM"),
      sort: true,
    },
    {
      dataField: "lastConnection",
      text: props.t("Connected"),
      sort: true,
      formatter: dateFormatter,
    },
  ]

  function editFormatter(cell, row) {
    return (
      <>
        <Button
          color="primary"
          outline
          size="sm"
          onClick={() => {
            setShowDrawer(true)
            setSelectedRow(row)
          }}
        >
          <i className="mdi mdi-pencil" />
        </Button>
      </>
    )
  }

  function usernameFormatter(cell, row) {
    return (
      <>
        <span>{row.name}</span>
        {row.videoRoomId && (
          <i className="ms-2 mdi mdi-account-voice text-primary" />
        )}
      </>
    )
  }

  function linkedFormatter(cell, row) {
    if (row.linked == true) {
      return <Input type="checkbox" disabled defaultChecked={true}></Input>
    } else return <></>
  }

  function dateFormatter(value) {
    if (!value) return ""
    var dateString = moment(value).format("DD.MM.YYYY HH:mm:ss")
    return dateString
  }

  const selectRow = {
    mode: "checkbox",
    selected: selectedUsers,
    clickToSelect: false,
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        setSelectedUsers(prevState => [...prevState, row.name])
      } else {
        setSelectedUsers(prevState =>
          prevState.filter(prevItem => prevItem !== row.name)
        )
      }
    },
    onSelectAll: (isSelect, rows, e) => {
      // console.log(isSelect, rows, e)
      if (isSelect) {
        rows.map(row => setSelectedUsers(prevState => [...prevState, row.name]))
      } else {
        setSelectedUsers([])
      }
    },
  }

  // Export PDF table
  const exportPDF = () => {
    let message = props.t("PDF file exported succesfully!")
    const unit = "pt"
    const size = "A4" // Use A1, A2, A3 or A4
    const orientation = "landscape" // portrait or landscape
    const theme = "grid" //striped,  plain

    const marginLeft = 40
    const doc = new jsPDF(orientation, unit, size, theme)

    doc.setFontSize(14)

    const data = filteredUsers.map(elt => [
      elt.name,
      elt.description,
      elt.basicUser,
      elt.groups,
      elt.linked,
      elt.email,
      elt.gsm,
      dateFormatter(elt.lastConnection),
    ])
    console.log(data, "pdf function")

    let content = {
      startY: 50,
      head: [
        [
          props.t("Username"),
          props.t("Description"),
          props.t("Basic user"),
          props.t("Groups"),
          props.t("Linked"),
          props.t("Email"),
          props.t("GSM"),
          props.t("Connected"),
        ],
      ],
      body: data,
    }

    doc.text(group, marginLeft, 40)
    doc.autoTable(content)
    doc.save(group + " (" + new Date() + ")" + ".pdf")

    //Show toast
    showToast(message, "success")
  }

  // Creating Excel workbook+worksheet+download
  const downloadExcel = () => {
    let message = props.t("Excel file exported succesfully!")
    const wb = XLSX.utils.book_new()

    const Heading = [
      [
        props.t("Username"),
        props.t("Description"),
        props.t("Basic user"),
        props.t("Groups"),
        props.t("Linked"),
        props.t("Email"),
        props.t("GSM"),
        props.t("Connected"),
      ],
    ]
    const data = filteredUsers.map(elt => [
      elt.name,
      elt.description,
      elt.basicUser,
      elt.groups,
      elt.linked,
      elt.email,
      elt.gsm,
      dateFormatter(elt.lastConnection),
    ])

    // creating sheet and adding data from 2nd row of column A.
    // Leaving first row to add Heading
    const ws = XLSX.utils.json_to_sheet(data, {
      origin: "A2",
      skipHeader: true,
    })
    XLSX.utils.sheet_add_aoa(ws, Heading, { origin: "A1" })
    XLSX.utils.book_append_sheet(wb, ws, group)
    XLSX.write(wb, { bookType: "xlsx", type: "buffer" })
    XLSX.writeFile(wb, group + " (" + new Date() + ")" + ".xlsx")

    //Show toast
    showToast(message, "success")
  }

  return (
    <>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title={props.t("Admin users")} />

          <Row className="mb-4">
            <Col xs="4">
              {/* TAGS */}
              {/* {userRole === "system_admin" && ( */}
              <div className="d-inline-block" style={{ width: "100%" }}>
                <TagsTree
                  loadData={true}
                  expanded={true}
                  selectionChanged={setSelectedTags}
                />
              </div>
              {/* )} */}
            </Col>
            <Col xs="4">
              <SelectGroup
                onChange={setGroup}
                multiple={false}
                selectedTags={selectedTags}
                updateCounter={updateCounter}
              />

              {/* <SelectGroup
                onChange={setGroup}
                multiple={false}
                updateCounter={updateCounter}
              /> */}
            </Col>
            {/* <Col className="col-auto d-flex"> */}
            <Col xs="4">
              <Button
                id="add-button"
                color="success"
                className="square-icon-button-sm"
                disabled={loading}
                onClick={() => {
                  setGroupAddModal(true)
                }}
              >
                <UncontrolledTooltip placement="bottom" target="add-button">
                  {props.t("Create a new group")}
                </UncontrolledTooltip>
                <i className="mdi mdi-account-multiple-plus font-size-18"></i>
              </Button>
              <Button
                id="delete-button"
                color="danger"
                className="square-icon-button-sm ms-3"
                disabled={!group || loading}
                onClick={() => {
                  setGroupDeleteModal(true)
                }}
              >
                <UncontrolledTooltip placement="bottom" target="delete-button">
                  {props.t("Delete group")}
                </UncontrolledTooltip>
                <i className="mdi mdi-trash-can font-size-18"></i>
              </Button>
            </Col>
          </Row>

          {group === null ? (
            <Card>
              <CardBody>
                <div className="text-center my-5">
                  <Lottie
                    options={{
                      loop: false,
                      autoplay: true,
                      animationData: searchAnim,
                      rendererSettings: {
                        preserveAspectRatio: "xMidYMid slice",
                      },
                    }}
                    height={150}
                    width={180}
                  ></Lottie>
                </div>
              </CardBody>
            </Card>
          ) : (
            <div>
              <Card>
                <CardBody>
                  {users != null && (
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="name"
                          columns={columns}
                          data={filteredUsers}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row>
                                <Col className="col-auto pe-0">
                                  <Form
                                    className="mt-sm-0 d-flex align-items-center"
                                    onSubmit={e => {
                                      e.preventDefault()
                                      return false
                                    }}
                                  >
                                    <div className="search-box me-2 mb-2 d-inline-block float-end">
                                      <div className="position-relative">
                                        <Input
                                          type="text"
                                          onChange={event =>
                                            setSearchText(
                                              event.target.value.toLowerCase()
                                            )
                                          }
                                          defaultValue={searchText}
                                          placeholder={props.t("Search")}
                                        />
                                        <i className="bx bx-search-alt search-icon" />
                                      </div>
                                    </div>
                                  </Form>
                                </Col>
                                <Col className="col-auto">
                                  <Button
                                    style={{ borderRadius: "100px" }}
                                    color="success"
                                    onClick={() => {
                                      setAddModal(true)
                                    }}
                                  >
                                    <i className="fas fa-user-plus"></i>
                                    <span className="d-none d-lg-inline-block ms-2">
                                      {props.t("Create a new user")}
                                    </span>
                                  </Button>
                                </Col>
                                <Col className="mt-2">
                                  <FormGroup>
                                    <Switch
                                      className="me-2"
                                      name="showLinked"
                                      checked={showLinked}
                                      onChange={v => {
                                        setShowLinked(v)
                                      }}
                                    ></Switch>
                                    <Label>
                                      {props.t("Show only linked users")}
                                    </Label>
                                  </FormGroup>
                                </Col>
                                <Col>
                                  <span className="float-end ms-2">
                                    <Tooltip
                                      placement="bottom"
                                      isOpen={TTdownloadstatstable}
                                      target="downloadstatstable"
                                      toggle={() => {
                                        setTTdownloadstatstable(
                                          !TTdownloadstatstable
                                        )
                                      }}
                                    >
                                      {props.t("Download statistics")}
                                    </Tooltip>
                                    <Dropdown
                                      id="printButtonServicesStats"
                                      isOpen={menu}
                                      toggle={() => setMenu(!menu)}
                                      className="download-btn text-center"
                                      type="button"
                                    >
                                      <DropdownToggle
                                        id="downloadstatstable"
                                        className="text-muted"
                                        tag="i"
                                      >
                                        <i className="mdi mdi-download" />
                                      </DropdownToggle>
                                      <DropdownMenu>
                                        {/* TODO: Can not choose directory file right now */}
                                        <DropdownItem
                                          onClick={() => downloadExcel()}
                                        >
                                          {props.t("Download .xlsx")}
                                        </DropdownItem>
                                        <DropdownItem
                                          onClick={() => exportPDF()}
                                        >
                                          {props.t("Download .pdf")}
                                        </DropdownItem>
                                      </DropdownMenu>
                                    </Dropdown>
                                  </span>
                                  <span className="mt-2 float-end">
                                    <p>
                                      {filteredUsers.length} {props.t("users")}
                                    </p>
                                  </span>
                                </Col>
                              </Row>

                              <Collapse isOpen={selectedUsers.length > 0}>
                                <Row className="py-2">
                                  <Col className="col-auto">
                                    <Button
                                      color="success"
                                      disabled={
                                        selectedUsers.length == 0 || loading
                                      }
                                      onClick={() => {
                                        linkAdmins()
                                      }}
                                    >
                                      <i className="mdi mdi-link-variant-plus me-2" />
                                      {props.t("Link selected users")}
                                    </Button>
                                    <Button
                                      color="danger"
                                      className="ms-3"
                                      disabled={
                                        selectedUsers.length == 0 || loading
                                      }
                                      onClick={() => {
                                        unlinkAdmins()
                                      }}
                                    >
                                      <i className="mdi mdi-link-variant-off me-2" />
                                      {props.t("Unlink selected users")}
                                    </Button>
                                    <Button
                                      color="primary"
                                      className="ms-3"
                                      onClick={() => {
                                        resetSelections()
                                      }}
                                    >
                                      <i className="mdi mdi-select-off me-2" />
                                      {props.t("Remove selections")}
                                    </Button>
                                  </Col>
                                  <Col className="mt-2">
                                    <p id="selections-text">
                                      {selectedUsers.length +
                                        " " +
                                        props.t("selected users")}
                                    </p>
                                    <UncontrolledTooltip
                                      placement="bottom-start"
                                      target="selections-text"
                                    >
                                      {"" + selectedUsers.join(", ")}
                                    </UncontrolledTooltip>
                                  </Col>
                                </Row>
                              </Collapse>

                              {!loading && (
                                <>
                                  <div className="table-responsive mt-1 mb-2">
                                    <BootstrapTable
                                      id="users-table"
                                      keyField={"name"}
                                      responsive
                                      bordered={true}
                                      striped={true}
                                      hover={true}
                                      selectRow={selectRow}
                                      defaultSorted={defaultSorted}
                                      classes={
                                        "table align-middle table-nowrap"
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      headerClasses="header-class"
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />
                                  </div>
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </>
                              )}
                              {loading && (
                                <Lottie
                                  options={{
                                    loop: false,
                                    autoplay: true,
                                    animationData: loadingAnim,
                                    rendererSettings: {
                                      preserveAspectRatio: "xMidYMid slice",
                                    },
                                  }}
                                  height={100}
                                  width={100}
                                ></Lottie>
                              )}
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  )}
                </CardBody>
              </Card>
            </div>
          )}

          {/**  User add modal */}
          <Modal
            isOpen={addModal}
            toggle={() => {
              setAddModal(!addModal)
            }}
            centered
          >
            <ModalHeader>{props.t("Create a new user")}</ModalHeader>
            <Form
              className="form-horizontal"
              onSubmit={e => {
                e.preventDefault()
                return false
              }}
            >
              <ModalBody>
                <Alert color={"primary"}>
                  <span>
                    {props.t("User will be linked to currently selected group")}
                  </span>
                </Alert>
                <Alert color={"primary"}>
                  <span>{props.t("UserAllowedChars")}</span>
                </Alert>
                <FormGroup>
                  <Label>{props.t("Username")}</Label>
                  <Input
                    name="username"
                    type="text"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    placeholder={"E.g. firstname.lastname"}
                    value={validation.values.username || ""}
                    invalid={
                      validation.touched.username && validation.errors.username
                        ? true
                        : false
                    }
                  ></Input>
                  {validation.touched.username && validation.errors.username ? (
                    <FormFeedback type="invalid">
                      {validation.errors.username}
                    </FormFeedback>
                  ) : null}
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="primary"
                  outline
                  onClick={() => {
                    setAddModal(false)
                  }}
                >
                  {props.t("Cancel")}
                </Button>
                <Button
                  color="success"
                  disabled={ongoingAdd}
                  onClick={() => {
                    validation.submitForm()
                  }}
                >
                  {props.t("Add")}
                </Button>{" "}
              </ModalFooter>
            </Form>
          </Modal>

          {/**  Group add modal */}
          <Modal
            isOpen={groupAddModal}
            toggle={() => {
              setGroupAddModal(!groupAddModal)
            }}
            centered
          >
            <ModalHeader>{props.t("Create a new group")}</ModalHeader>
            <Form
              className="form-horizontal"
              onSubmit={e => {
                e.preventDefault()
                return false
              }}
            >
              <ModalBody>
                <Alert color={"primary"}>
                  <span>{props.t("GroupAllowedChars")}</span>
                </Alert>
                <FormGroup>
                  <Label>{props.t("Name")}</Label>
                  <Input
                    name="group"
                    type="text"
                    onChange={groupValidation.handleChange}
                    onBlur={groupValidation.handleBlur}
                    value={groupValidation.values.group || ""}
                    invalid={
                      groupValidation.touched.group &&
                      groupValidation.errors.group
                        ? true
                        : false
                    }
                  ></Input>
                  {groupValidation.touched.group &&
                  groupValidation.errors.group ? (
                    <FormFeedback type="invalid">
                      {groupValidation.errors.group}
                    </FormFeedback>
                  ) : null}
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="primary"
                  outline
                  onClick={() => {
                    setGroupAddModal(false)
                  }}
                >
                  {props.t("Cancel")}
                </Button>
                <Button
                  color="success"
                  disabled={ongoingAdd}
                  onClick={() => {
                    groupValidation.submitForm()
                  }}
                >
                  {props.t("Add")}
                </Button>{" "}
              </ModalFooter>
            </Form>
          </Modal>

          {/**  Group delete modal */}
          <Modal
            isOpen={groupDeleteModal}
            toggle={() => {
              setGroupDeleteModal(!groupDeleteModal)
            }}
            centered
          >
            <ModalHeader>{props.t("Delete confirmation")}</ModalHeader>
            <ModalBody>
              <p>
                {props.t("Are you sure you want to delete") +
                  ": " +
                  group +
                  "?"}
              </p>
              <Alert color={"primary"}>
                <span>
                  {props.t(
                    "Deletion will fail if the group has any users or devices attached to it!"
                  )}
                </span>
              </Alert>
            </ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                outline
                onClick={() => {
                  setGroupDeleteModal(false)
                }}
              >
                {props.t("Cancel")}
              </Button>
              <Button
                disabled={ongoingAdd}
                color="danger"
                onClick={() => {
                  groupDelete()
                }}
              >
                {props.t("Delete")}
              </Button>
            </ModalFooter>
          </Modal>

          <AdminDrawer
            showDrawer={showDrawer}
            setShowDrawer={setShowDrawer}
            selectedRow={selectedRow}
            getAdmins={getAdmins}
          />
        </Container>
      </div>
    </>
  )
}

AdminUsersManagement.propTypes = {
  t: PropTypes.any,
  selectionChanged: PropTypes.func,
}

export default withTranslation()(AdminUsersManagement)
