import React, { useEffect, useRef, useState } from "react"
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Collapse,
  Form,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  UncontrolledTooltip,
} from "reactstrap"
import PropTypes from "prop-types"
import { Route } from "react-router-dom"
import { withTranslation } from "react-i18next"
import { Drawer, Switch } from "antd"
import * as Yup from "yup"
import { useFormik } from "formik"
import { showToast } from "utils/general"
import {
  addGroupsToAdmin,
  deleteAdminUser,
  getAdminsGroups,
  getAdminsInfo,
  getAdminsPrivateClients,
  linkPrivateClients,
  removeGroupsFromAdmin,
  sendLoginCredentials,
  unlinkPrivateClients,
  updateAdminUser,
  getClientList,
} from "helpers/backend_helper"
import Lottie from "react-lottie"
import loadingAnim from "common/animations/loading-dots-blue"
import classnames from "classnames"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import TagsTree from "components/DropdownList/TagsTree"
import withRouter from "components/Common/withRouter"

const AdminDrawer = props => {
  const [activeTab, setActiveTab] = useState("1")
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")
  const [update, setUpdate] = useState(false)
  const [loading, setLoading] = useState(false)
  const [ongoingDelete, setOngoingDelete] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [userData, setUserData] = useState(null)
  const [newPassword, setNewPassword] = useState("")
  const [groups, setGroups] = useState([])
  const [groupsOrig, setGroupsOrig] = useState([])
  const [privateClients, setPrivateClients] = useState([])
  const [filteredPrivateClients, setFilteredPrivateClients] = useState([])
  const [filteredGroups, setFilteredGroups] = useState([])
  const [selectedGroups, setSelectedGroups] = useState([])
  const [selectedClients, setSelectedClients] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [currentPage2, setCurrentPage2] = useState(1)
  const [pageSize, setPageSize] = useState(50)
  const [searchText, setSearchText] = useState("")
  const [searchText2, setSearchText2] = useState("")
  const [showAllClients, setShowAllClients] = useState(false)
  const [tempOptions, setTempOptions] = useState({})
  const [tingLoading, settingLoading] = useState(true)
  const [options, setOptions] = useState([])
  const [origOtions, setOrigOptions] = useState([])
  const [selectedTags, setSelectedTags] = useState([])
  const [temp, setTemp] = useState([])

  const abortRef = useRef(null)
  useEffect(() => {
    abortRef.current = new AbortController()
    return () => abortRef.current.abort()
  }, [])

  useEffect(() => {
    if (props.selectedRow) {
      setNewPassword("")
      getData()
    }
  }, [props.selectedRow])

  useEffect(() => {
    if (activeTab == "2" && props.selectedRow && groups.length == 0) {
      getGroups()
      getDataArr()
    } else if (
      activeTab == "3" &&
      props.selectedRow &&
      privateClients.length == 0
    ) {
      getPrivateClients()
    }
  }, [activeTab])

  useEffect(() => {
    if (activeTab == "3" && props.selectedRow) {
      getPrivateClients()
    }
  }, [showAllClients])

  useEffect(() => {
    if (!props.showDrawer) {
      setGroups([])
      setGroupsOrig([])
      setPrivateClients([])
      setActiveTab("1")
    }
  }, [props.showDrawer])

  // Search or filter changed
  useEffect(() => {
    setCurrentPage(1)
    handleSearch()
  }, [searchText, groups])

  useEffect(() => {
    if (selectedTags.length > 0) {
      setGroups(tempOptions)
    }
    if (selectedTags.length == 0) {
      setGroups(groupsOrig)
    }
  }, [tempOptions])

  useEffect(() => {
    if (options.length > 0) {
      let arr = []
      setTempOptions(arr)
      groupsOrig.filter(f => {
        let g = f.group
        for (let i = 0; i < options.length; i++) {
          if (g == options[i].name) {
            arr.push(f)
          }
        }
      })
      setTempOptions(arr)
    }
    if (options.length == 0) {
      setTempOptions(groupsOrig)
    }
  }, [options])

  useEffect(() => {
    setCurrentPage2(1)
    handlePrivatesSearch()
  }, [searchText2, privateClients])

  // Handle search function
  const handleSearch = () => {
    let result = groups.filter(data =>
      data.group?.toLowerCase().includes(searchText)
    )
    setFilteredGroups(result)
  }

  // Handle search function
  const handlePrivatesSearch = () => {
    let result = privateClients.filter(
      data =>
        data.group?.toLowerCase().includes(searchText2) ||
        data.name?.toLowerCase().includes(searchText2)
    )
    setFilteredPrivateClients(result)
  }

  // Get admins data
  const getData = () => {
    setLoading(true)
    setUserData(null)
    getAdminsInfo(abortRef.current, props.selectedRow.name)
      .then(result => {
        if (result.statusCode == 200 && result.data) {
          setUserData(result.data)
        }
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  // Get groups
  const getDataArr = () => {
    settingLoading(true)
    getClientList(abortRef.current)
      .then(result => {
        if (result.array !== null) {
          let opts = []
          let counter = 1
          result.array.forEach(group => {
            let groupObject = new Object()
            groupObject.id = counter++
            groupObject.name = group.name
            groupObject.value = group.name
            groupObject.tag = Settingtostring(group.tags)
            opts.push(groupObject)
          })
          setOrigOptions(opts)
          settingLoading(false)
        }
      })
      .catch(e => {
        console.log(e)
      })
  }

  // Get admins groups
  const getGroups = () => {
    getAdminsGroups(abortRef.current, props.selectedRow.name)
      .then(result => {
        console.log("GROUPS: ", result)
        if (result.statusCode == 200 && result.groups) {
          setGroups(result.groups)
          setGroupsOrig(result.groups)
        } else {
          showToast(props.t("Error"), "error")
        }
      })
      .catch(err => {
        console.log(err)
        showToast(props.t("Error"), "error")
      })
      .finally(() => {})
  }

  useEffect(() => {
    // console.log(selectedTags, "tags")
    if (selectedTags?.length > 0) {
      setTemp([])
      origOtions.map(o => {
        selectedTags.map(t => {
          t = t.toString()
          if (o.tag.includes(t)) {
            setTemp(filtered => [...filtered, o])
          }
        })
      })
    }
    if (selectedTags?.length == 0) {
      setOptions([])
      setGroups(groupsOrig)
    }
  }, [selectedTags])

  // Filter by selected tags
  useEffect(() => {
    let setNew = Array.from(new Set(temp))
    setOptions(setNew)
    // console.log(setNew, "new")
  }, [temp])

  // Set tags to string
  const Settingtostring = arr => {
    let string = []
    if (arr.length > 0) {
      arr.forEach(g => {
        let tag = g.key
        string.push(tag)
      })
    }
    string = string.toString()
    return string
  }

  // Get private clients
  const getPrivateClients = () => {
    getAdminsPrivateClients(
      abortRef.current,
      props.selectedRow.name,
      showAllClients
    )
      .then(result => {
        if (result.statusCode == 200 && result.clients) {
          result.clients.sort((a, b) => (a.name < b.name ? 1 : -1))
          result.clients.sort((a, b) => (a.group > b.group ? 1 : -1))
          setPrivateClients(result.clients)
        } else {
          showToast(props.t("Error"), "error")
        }
      })
      .catch(err => {
        console.log(err)
        showToast(props.t("Error"), "error")
      })
      .finally(() => {})
  }

  // Link private clients
  const linkClients = () => {
    if (!update && selectedClients.length > 0) {
      setUpdate(true)
      linkPrivateClients(
        abortRef.current,
        props.selectedRow.name,
        selectedClients
      )
        .then(result => {
          if (result.statusCode == 200) {
            showToast(props.t("Success"), "success")
            getPrivateClients()
            setSelectedClients([])
          } else {
            showToast(props.t("Error"), "error")
          }
        })
        .catch(err => {
          showToast(props.t("Error"), "error")
        })
        .finally(() => {
          setUpdate(false)
        })
    }
  }

  // Unlink private clients
  const unlinkClients = () => {
    if (!update && selectedClients.length > 0) {
      setUpdate(true)
      unlinkPrivateClients(
        abortRef.current,
        props.selectedRow.name,
        selectedClients
      )
        .then(result => {
          if (result.statusCode == 200) {
            showToast(props.t("Success"), "success")
            getPrivateClients()
            setSelectedClients([])
          } else {
            showToast(props.t("Error"), "error")
          }
        })
        .catch(err => {
          showToast(props.t("Error"), "error")
        })
        .finally(() => {
          setUpdate(false)
        })
    }
  }

  // Update client validation
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      description: userData?.description || "",
      firstName: userData?.firstName || "",
      lastName: userData?.lastName || "",
      gsmNumber: userData?.gsmNumber || "",
      email: userData?.email || "",
      language: userData?.language || "",
      videoRoomId: userData?.videoRoomId || "",
      videoRoomDemoId: userData?.videoRoomDemoId || "",
      videoNickname: userData?.videoNickname || "",
      alarmCenterType: userData?.alarmCenterType || "",
      basicUser: userData?.basicUser || false,
      alarmMonitoring: userData?.alarmMonitoring || false,
      videoSystem: userData?.videoSystem || false,
      locked: userData?.locked || false,
      supportType: userData?.supportType || "0",
      unit: userData?.unit || "",
      newPassword: "",
    },
    validationSchema: Yup.object({}),
    onSubmit: values => {
      console.log("Submit values: ", values)
      setSuccess("")
      setError("")
      if (props.selectedRow?.name) {
        let temp = JSON.parse(JSON.stringify(values))
        temp.supportType = parseInt(values.supportType, 10)

        setUpdate(true)
        updateAdminUser(abortRef.current, props.selectedRow?.name, temp)
          .then(result => {
            if (result.statusCode == 200) {
              setSuccess("Updated")
              getData()
              props.getAdmins()
            } else {
              setError("Error")
            }
          })
          .catch(err => {
            console.log(err)
            setError("Error")
          })
          .finally(() => {
            setUpdate(false)
          })
      }
    },
  })

  // Delete
  const deleteAdmin = () => {
    if (props.selectedRow?.name) {
      setOngoingDelete(true)
      deleteAdminUser(abortRef.current, props.selectedRow?.name)
        .then(result => {
          if (result.statusCode == 200) {
            setDeleteModal(false)
            props.setShowDrawer(false)
            showToast(props.t("Deleted"), "success")
            props.getAdmins()
          } else {
            showToast(props.t("Error"), "error")
          }
        })
        .catch(err => {
          console.log(err)
          showToast(props.t("Error"), "error")
        })
        .finally(() => {
          setOngoingDelete(false)
        })
    }
  }

  // Send credentials
  const sendCredentials = () => {
    if (props.selectedRow?.name && newPassword && newPassword.length >= 14) {
      setUpdate(true)
      sendLoginCredentials(
        abortRef.current,
        props.selectedRow.name,
        newPassword
      )
        .then(result => {
          if (result.statusCode == 200) {
            showToast(props.t("Sent"), "success")
          } else {
            showToast(props.t("Error"), "error")
          }
        })
        .catch(err => {
          console.log(err)
          showToast(props.t("Error"), "error")
        })
        .finally(() => {
          setUpdate(false)
        })
    }
  }

  // Link user to selected groups
  const linkGroups = () => {
    if (props.selectedRow && !update && selectedGroups.length > 0) {
      setUpdate(true)
      addGroupsToAdmin(abortRef.current, props.selectedRow.name, selectedGroups)
        .then(result => {
          if (result.statusCode == 200) {
            showToast(props.t("Success"), "success")
            setSelectedGroups([])
            getGroups()
            props.getAdmins()
          } else {
            showToast(props.t("Error"), "error")
          }
        })
        .catch(err => {
          console.log(err)
          showToast(props.t("Error"), "error")
        })
        .finally(() => {
          setUpdate(false)
        })
    }
  }

  // Unlink user from selected groups
  const unlinkGroups = () => {
    if (props.selectedRow && !update && selectedGroups.length > 0) {
      setUpdate(true)
      removeGroupsFromAdmin(
        abortRef.current,
        props.selectedRow.name,
        selectedGroups
      )
        .then(result => {
          if (result.statusCode == 200) {
            showToast(props.t("Success"), "success")
            setSelectedGroups([])
            getGroups()
            props.getAdmins()
          } else {
            showToast(props.t("Error"), "error")
          }
        })
        .catch(err => {
          console.log(err)
          showToast(props.t("Error"), "error")
        })
        .finally(() => {
          setUpdate(false)
        })
    }
  }

  function dec2hex(dec) {
    return dec.toString(16).padStart(2, "0")
  }

  const generatePassword = len => {
    var arr = new Uint8Array((len || 40) / 2)
    window.crypto.getRandomValues(arr)
    let pw = Array.from(arr, dec2hex).join("")
    setNewPassword(pw)
    validation.setFieldValue("newPassword", pw)
  }

  // Show selected number of rows
  const pageOptions = {
    custom: true,
    pageStartIndex: 1,
    totalSize: filteredGroups.length,
    sizePerPage: pageSize,
    page: currentPage,
    onPageChange: (page, sizePerPage) => {
      setCurrentPage(page)
    },
    onSizePerPageChange: (sizePerPage, page) => {
      setPageSize(sizePerPage)
      setCurrentPage(1)
    },
  }
  const pageOptions2 = {
    custom: true,
    pageStartIndex: 1,
    totalSize: privateClients.length,
    sizePerPage: pageSize,
    page: currentPage2,
    onPageChange: (page, sizePerPage) => {
      setCurrentPage2(page)
    },
    onSizePerPageChange: (sizePerPage, page) => {
      setPageSize(sizePerPage)
      setCurrentPage2(1)
    },
  }

  // Table structure
  const columns = [
    {
      dataField: "linked",
      text: props.t("Linked"),
      sort: false,
      align: "center",
      formatter: linkedFormatter,
    },
    {
      dataField: "group",
      text: props.t("Group"),
      sort: true,
    },
  ]
  const columns2 = [
    {
      dataField: "linked",
      text: props.t("Linked"),
      sort: false,
      align: "center",
      formatter: linkedFormatter,
    },
    {
      dataField: "group",
      text: props.t("Group"),
      sort: true,
    },
    {
      dataField: "name",
      text: props.t("Name"),
      sort: true,
    },
  ]

  function linkedFormatter(cell, row) {
    if (row.linked == true) {
      return <Input type="checkbox" disabled defaultChecked={true}></Input>
    } else return <></>
  }

  const selectRow = {
    mode: "checkbox",
    selected: selectedGroups,
    clickToSelect: true,
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        setSelectedGroups(prevState => [...prevState, row.group])
      } else {
        setSelectedGroups(prevState =>
          prevState.filter(prevItem => prevItem !== row.group)
        )
      }
    },
    onSelectAll: (isSelect, rows, e) => {
      if (isSelect) {
        rows.map(row =>
          setSelectedGroups(prevState => [...prevState, row.group])
        )
      } else {
        setSelectedGroups([])
      }
    },
  }
  const selectRow2 = {
    mode: "checkbox",
    selected: selectedClients,
    clickToSelect: true,
    onSelect: (row, isSelect, rowIndex, e) => {
      // console.log(isSelect, row, e)
      if (isSelect) {
        setSelectedClients(prevState => [...prevState, row.key])
      } else {
        setSelectedClients(prevState =>
          prevState.filter(prevItem => prevItem !== row.key)
        )
      }
    },
    onSelectAll: (isSelect, rows, e) => {
      // console.log(isSelect, rows, e)
      if (isSelect) {
        rows.map(row =>
          setSelectedClients(prevState => [...prevState, row.key])
        )
      } else {
        setSelectedClients([])
      }
    },
  }

  return (
    <>
      <Drawer
        title={props.selectedRow?.name}
        width={"80%"}
        placement="right"
        onClose={() => {
          props.setShowDrawer(false)
          setError("")
          setSuccess("")
        }}
        open={props.showDrawer}
        zIndex={1003}
        bodyStyle={{ backgroundColor: "#f8f8f8" }}
      >
        <div className="d-flex flex-wrap mb-3">
          <ul className="nav icon-tab" role="tablist">
            <NavItem>
              <NavLink
                to="#"
                className={classnames({ active: activeTab === "1" })}
                onClick={() => {
                  setActiveTab("1")
                }}
              >
                {props.t("User information")}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "2" })}
                onClick={() => {
                  setActiveTab("2")
                }}
              >
                {props.t("Groups")}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "3" })}
                onClick={() => {
                  setActiveTab("3")
                }}
              >
                {props.t("Private clients")}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "4" })}
                disabled
                onClick={() => {
                  setActiveTab("4")
                }}
              >
                {props.t("Permissions")}
              </NavLink>
            </NavItem>
          </ul>
        </div>

        <TabContent activeTab={activeTab} className="">
          <TabPane className="show" tabId="1">
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">
                      {props.t("Information")}
                    </CardTitle>
                    {loading && (
                      <Lottie
                        options={{
                          loop: true,
                          autoplay: true,
                          animationData: loadingAnim,
                          rendererSettings: {
                            preserveAspectRatio: "xMidYMid slice",
                          },
                        }}
                        height={100}
                        width={100}
                      ></Lottie>
                    )}
                    {!loading && (
                      <Form
                        className="form-horizontal"
                        onSubmit={e => {
                          e.preventDefault()
                          return false
                        }}
                      >
                        {error ? (
                          <Alert color="danger">{props.t(error)}</Alert>
                        ) : null}
                        {success ? (
                          <Alert color="success">{props.t(success)}</Alert>
                        ) : null}
                        <Row>
                          <Col>
                            <FormGroup>
                              <Label>{props.t("Description")}</Label>
                              <Input
                                bsSize="sm"
                                name="description"
                                type="text"
                                value={validation.values.description}
                                onChange={validation.handleChange}
                              ></Input>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row xs={1} xl={2}>
                          <Col>
                            <FormGroup>
                              <Label>{props.t("First name")}</Label>
                              <Input
                                bsSize="sm"
                                name="firstName"
                                type="text"
                                value={validation.values.firstName}
                                onChange={validation.handleChange}
                              ></Input>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label>{props.t("Last name")}</Label>
                              <Input
                                bsSize="sm"
                                name="lastName"
                                type="text"
                                value={validation.values.lastName}
                                onChange={validation.handleChange}
                              ></Input>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label>{props.t("GSM")}</Label>
                              <Input
                                bsSize="sm"
                                name="gsmNumber"
                                type="text"
                                value={validation.values.gsmNumber}
                                onChange={validation.handleChange}
                              ></Input>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label>{props.t("Email")}</Label>
                              <Input
                                bsSize="sm"
                                name="email"
                                type="text"
                                value={validation.values.email}
                                onChange={validation.handleChange}
                              ></Input>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label>{props.t("Language")}</Label>
                              <Input
                                bsSize="sm"
                                name="language"
                                type="select"
                                value={validation.values.language}
                                onChange={validation.handleChange}
                              >
                                <option value="en">{props.t("English")}</option>
                                <option value="fi-FI">
                                  {props.t("Finnish")}
                                </option>
                                <option value="sv-SE">
                                  {props.t("Swedish")}
                                </option>
                              </Input>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label>{props.t("Alarm center")}</Label>
                              <Input
                                bsSize="sm"
                                name="alarmCenterType"
                                type="select"
                                value={validation.values.alarmCenterType}
                                onChange={validation.handleChange}
                              >
                                <option value="">{props.t("Undefined")}</option>
                                <option value="Test">{props.t("Test")}</option>
                                <option value="K2">{"K2"}</option>
                                <option value="PNC">{"PNC AddSecure"}</option>
                                <option value="PNC_Essote">
                                  {"PNC Essote"}
                                </option>
                                <option value="Avarn">{"Avarn"}</option>
                                <option value="Verifi">{"Verifi"}</option>
                              </Input>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label>{props.t("Group host number")}</Label>
                              <Input
                                bsSize="sm"
                                name="videoRoomId"
                                type="text"
                                value={validation.values.videoRoomId}
                                onChange={validation.handleChange}
                              ></Input>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label>
                                {props.t("Group host number") + " (Demo)"}
                              </Label>
                              <Input
                                bsSize="sm"
                                name="videoRoomDemoId"
                                type="text"
                                value={validation.values.videoRoomDemoId}
                                onChange={validation.handleChange}
                              ></Input>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label>
                                {props.t("Video call display name")}
                              </Label>
                              <Input
                                bsSize="sm"
                                name="videoNickname"
                                type="text"
                                value={validation.values.videoNickname}
                                onChange={validation.handleChange}
                              ></Input>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label>{props.t("Support")}</Label>
                              <Input
                                bsSize="sm"
                                name="supportType"
                                type="select"
                                value={validation.values.supportType}
                                onChange={validation.handleChange}
                              >
                                <option value="0">{props.t("Default")}</option>
                                <option value="3">
                                  {props.t("Suvanto extended support")}
                                </option>
                                <option value="1">{"Elisa"}</option>
                                <option value="4">
                                  {props.t("Elisa extended support")}
                                </option>
                                <option value="2">{"2M-IT"}</option>
                                <option value="5">
                                  {props.t("2M-IT extended support")}
                                </option>
                              </Input>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label>
                                {props.t("Unit info")}
                              </Label>
                              <Input
                                bsSize="sm"
                                name="unit"
                                type="text"
                                value={validation.values.unit}
                                onChange={validation.handleChange}
                              ></Input>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup>
                              <Label>{props.t("New password")}</Label>
                              <Row>
                                <Col>
                                  <Input
                                    bsSize="sm"
                                    name="newPassword"
                                    type="text"
                                    placeholder={props.t("Set new password")}
                                    value={validation.values.newPassword}
                                    onChange={validation.handleChange}
                                  ></Input>
                                </Col>
                                <Col className="col-auto">
                                  <Button
                                    id="generatePasswordButtonTwo"
                                    className="p-0"
                                    color="transparent"
                                    outline
                                    size="sm"
                                    onClick={() => {
                                      generatePassword(14)
                                    }}
                                  >
                                    <i className="mdi mdi-shield-refresh text-primary font-size-18"></i>
                                    {/** 
                                    <UncontrolledTooltip
                                      placement="bottom"
                                      key={"pwButtonTwo"}
                                      target="generatePasswordButtonTwo"
                                    >
                                      {props.t("Generate new password")}
                                    </UncontrolledTooltip>*/}
                                  </Button>
                                </Col>
                              </Row>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row xs={1} xl={2}>
                          <Col>
                            <FormGroup className="mt-2">
                              <Switch
                                className="me-2"
                                name="basicUser"
                                checked={validation.values.basicUser}
                                onChange={v => {
                                  validation.setFieldValue("basicUser", v)
                                }}
                              ></Switch>
                              <Label>{props.t("Basic user")}</Label>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup className="mt-2">
                              <Switch
                                className="me-2"
                                name="alarmMonitoring"
                                checked={validation.values.alarmMonitoring}
                                onChange={v => {
                                  validation.setFieldValue("alarmMonitoring", v)
                                }}
                              ></Switch>
                              <Label>{props.t("Alarm monitoring")}</Label>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup className="mt-2">
                              <Switch
                                className="me-2"
                                name="videoSystem"
                                checked={validation.values.videoSystem}
                                onChange={v => {
                                  validation.setFieldValue("videoSystem", v)
                                }}
                              ></Switch>
                              <Label>{props.t("Video system")}</Label>
                            </FormGroup>
                          </Col>
                          <Col>
                            <FormGroup className="mt-2">
                              <Switch
                                className="me-2"
                                name="locked"
                                checked={validation.values.locked}
                                onChange={v => {
                                  validation.setFieldValue("locked", v)
                                }}
                              ></Switch>
                              <Label>{props.t("Locked")}</Label>
                            </FormGroup>
                          </Col>
                        </Row>

                        <div className="mt-2 mb-3 d-flex justify-content-center">
                          <Button
                            color="primary"
                            disabled={update}
                            style={{ minWidth: "150px" }}
                            onClick={() => {
                              validation.handleSubmit()
                            }}
                          >
                            {props.t("Save")}
                          </Button>
                        </div>
                      </Form>
                    )}
                  </CardBody>
                </Card>
              </Col>
              <Col>
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">{props.t("Actions")}</CardTitle>
                    <ListGroup className="list-group-flush">
                      <ListGroupItem className="pb-4">
                        <Row>
                          <Col>
                            <div>
                              <h6>{props.t("Send login credentials")}</h6>
                              <span className="text-muted">
                                {props.t("Email & GSM required")}
                              </span>
                              <Row className="mt-2">
                                <Col>
                                  <Input
                                    bsSize="sm"
                                    name="newPassword"
                                    type="text"
                                    placeholder={props.t("Set new password")}
                                    value={newPassword}
                                    onChange={v => {
                                      setNewPassword(v.target.value)
                                    }}
                                  ></Input>
                                </Col>
                                <Col className="col-auto">
                                  <Button
                                    id="generatePasswordButtonOne"
                                    className="p-0"
                                    color="transparent"
                                    outline
                                    size="sm"
                                    onClick={() => {
                                      generatePassword(14)
                                    }}
                                  >
                                    <i className="mdi mdi-shield-refresh text-primary font-size-18"></i>
                                    {/** 
                                    <UncontrolledTooltip
                                      placement="bottom"
                                      key={"pwButtonOne"}
                                      target="generatePasswordButtonOne"
                                    >
                                      {props.t("Generate new password")}
                                    </UncontrolledTooltip>*/}
                                  </Button>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                          <Col className="col-auto">
                            <Button
                              color="primary"
                              style={{ minWidth: "100px" }}
                              disabled={
                                update ||
                                !userData?.email ||
                                !userData?.gsmNumber ||
                                newPassword.length < 14
                              }
                              onClick={() => {
                                sendCredentials()
                              }}
                            >
                              {props.t("Send")}
                            </Button>
                          </Col>
                        </Row>
                      </ListGroupItem>
                      <ListGroupItem className="pb-4">
                        <Row>
                          <Col>
                            <div>
                              <h6>{props.t("View SMS history")}</h6>
                              <span className="text-muted">
                                {props.t("Opens in a new window")}
                              </span>
                            </div>
                          </Col>
                          <Col className="col-auto">
                            <Button
                              color="secondary"
                              style={{ minWidth: "100px" }}
                              disabled={!userData?.gsmNumber}
                              onClick={() => {
                                window.open(
                                  "/logs/sms?recipient=" + userData?.gsmNumber,
                                  "_blank",
                                  "noreferrer"
                                )
                              }}
                            >
                              {props.t("OpenCommand")}
                            </Button>
                          </Col>
                        </Row>
                      </ListGroupItem>
                      <ListGroupItem className="pb-4">
                        <Row>
                          <Col>
                            <div>
                              <h6>{props.t("Deletion")}</h6>
                              <span className="text-muted">
                                {props.t("Deletes the user permanently")}
                              </span>
                            </div>
                          </Col>
                          <Col className="col-auto">
                            <Button
                              color="danger"
                              style={{ minWidth: "100px" }}
                              disabled={ongoingDelete}
                              onClick={() => {
                                setDeleteModal(true)
                              }}
                            >
                              {props.t("Delete")}
                            </Button>
                          </Col>
                        </Row>
                      </ListGroupItem>
                    </ListGroup>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </TabPane>

          <TabPane className="show" tabId="2">
            <Card>
              <CardBody>
                <PaginationProvider pagination={paginationFactory(pageOptions)}>
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                      keyField="group"
                      columns={columns}
                      data={filteredGroups}
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          <Row>
                            <Col className="col-auto pe-0">
                              <Form
                                className="mt-sm-0 d-flex align-items-center"
                                onSubmit={e => {
                                  e.preventDefault()
                                  return false
                                }}
                              >
                                <div
                                  className="d-inline-block mt-0 me-3"
                                  style={{ minWidth: "300px" }}
                                >
                                  <TagsTree
                                    disabled={tingLoading}
                                    loadData={true}
                                    expanded={true}
                                    selectionChanged={setSelectedTags}
                                  />
                                </div>
                                <div className="search-box me-2 mb-2 mt-2 d-inline-block float-end">
                                  <div className="position-relative">
                                    <Input
                                      type="text"
                                      onChange={event =>
                                        setSearchText(
                                          event.target.value.toLowerCase()
                                        )
                                      }
                                      placeholder={props.t("Search")}
                                    />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Form>
                            </Col>
                            <Col>
                              <div className="mt-2 float-end">
                                <p>
                                  {filteredGroups.length} {props.t("groups")}
                                </p>
                              </div>
                            </Col>
                          </Row>

                          <Collapse isOpen={selectedGroups.length > 0}>
                            <Row className="py-2">
                              <Col className="col-auto">
                                <Button
                                  color="success"
                                  disabled={
                                    selectedGroups.length == 0 || update
                                  }
                                  onClick={() => {
                                    linkGroups()
                                  }}
                                >
                                  <i className="mdi mdi-link-variant-plus me-2" />
                                  {props.t("Link selected groups")}
                                </Button>
                                <Button
                                  color="danger"
                                  className="ms-3"
                                  disabled={
                                    selectedGroups.length == 0 || update
                                  }
                                  onClick={() => {
                                    unlinkGroups()
                                  }}
                                >
                                  <i className="mdi mdi-link-variant-off me-2" />
                                  {props.t("Unlink selected groups")}
                                </Button>
                              </Col>
                              <Col className="mt-2">
                                <p>
                                  {selectedGroups.length +
                                    " " +
                                    props.t("selected groups")}
                                </p>
                              </Col>
                            </Row>
                          </Collapse>
                          <div className="table-responsive mt-1 mb-2">
                            <BootstrapTable
                              id="groups-table"
                              keyField={"group"}
                              responsive
                              bordered={true}
                              striped={true}
                              hover={true}
                              selectRow={selectRow}
                              defaultSorted={[
                                {
                                  dataField: "linked",
                                  order: "desc",
                                },
                              ]}
                              classes={"table align-middle table-nowrap"}
                              headerWrapperClasses={"thead-light"}
                              headerClasses="header-class"
                              {...toolkitProps.baseProps}
                              {...paginationTableProps}
                            />
                          </div>
                          <SizePerPageDropdownStandalone {...paginationProps} />
                          <PaginationListStandalone {...paginationProps} />
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
              </CardBody>
            </Card>
          </TabPane>

          <TabPane className="show" tabId="3">
            <Card>
              <CardBody>
                <PaginationProvider
                  pagination={paginationFactory(pageOptions2)}
                >
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                      keyField="key"
                      columns={columns2}
                      data={filteredPrivateClients}
                      search
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          <Row>
                            <Col className="col-auto pe-0">
                              <Form
                                className="mt-sm-0 d-flex align-items-center"
                                onSubmit={e => {
                                  e.preventDefault()
                                  return false
                                }}
                              >
                                <div className="search-box me-2 mb-2 d-inline-block float-end">
                                  <div className="position-relative">
                                    <Input
                                      type="text"
                                      onChange={event =>
                                        setSearchText2(
                                          event.target.value.toLowerCase()
                                        )
                                      }
                                      placeholder={props.t("Search")}
                                    />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Form>
                            </Col>
                            <Col className="mt-2">
                              <FormGroup>
                                <Switch
                                  className="me-2"
                                  name="showAllClients"
                                  checked={showAllClients}
                                  onChange={v => {
                                    setShowAllClients(v)
                                  }}
                                ></Switch>
                                <Label>{props.t("Show all clients")}</Label>
                              </FormGroup>
                            </Col>
                            <Col>
                              <div className="mt-2 float-end">
                                <p>
                                  {filteredPrivateClients.length}{" "}
                                  {props.t("clients")}
                                </p>
                              </div>
                            </Col>
                          </Row>

                          <Collapse isOpen={selectedClients.length > 0}>
                            <Row className="py-2">
                              <Col className="col-auto">
                                <Button
                                  color="success"
                                  disabled={
                                    selectedClients.length == 0 || update
                                  }
                                  onClick={() => {
                                    linkClients()
                                  }}
                                >
                                  <i className="mdi mdi-link-variant-plus me-2" />
                                  {props.t("Link selected clients")}
                                </Button>
                                <Button
                                  color="danger"
                                  className="ms-3"
                                  disabled={
                                    selectedClients.length == 0 || update
                                  }
                                  onClick={() => {
                                    unlinkClients()
                                  }}
                                >
                                  <i className="mdi mdi-link-variant-off me-2" />
                                  {props.t("Unlink selected clients")}
                                </Button>
                              </Col>
                              <Col className="mt-2">
                                <p>
                                  {selectedClients.length +
                                    " " +
                                    props.t("selected clients")}
                                </p>
                              </Col>
                            </Row>
                          </Collapse>
                          <div className="table-responsive mt-1 mb-2">
                            <BootstrapTable
                              id="privates-table"
                              keyField={"key"}
                              responsive
                              bordered={true}
                              striped={true}
                              hover={true}
                              selectRow={selectRow2}
                              defaultSorted={[
                                {
                                  dataField: "linked",
                                  order: "desc",
                                },
                              ]}
                              classes={"table align-middle table-nowrap"}
                              headerWrapperClasses={"thead-light"}
                              headerClasses="header-class"
                              {...toolkitProps.baseProps}
                              {...paginationTableProps}
                            />
                          </div>
                          <SizePerPageDropdownStandalone {...paginationProps} />
                          <PaginationListStandalone {...paginationProps} />
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
              </CardBody>
            </Card>
          </TabPane>

          <TabPane className="show" tabId="4">
            TODO
          </TabPane>
        </TabContent>
      </Drawer>

      <Modal
        isOpen={deleteModal}
        toggle={() => {
          setDeleteModal(!deleteModal)
        }}
        centered
      >
        <ModalHeader>{props.t("Delete confirmation")}</ModalHeader>
        <ModalBody>
          <p>
            {props.t("Are you sure you want to delete") +
              " " +
              props.selectedRow?.name +
              "?"}
          </p>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            outline
            onClick={() => {
              setDeleteModal(false)
            }}
          >
            {props.t("Cancel")}
          </Button>
          <Button
            disabled={ongoingDelete}
            color="danger"
            onClick={() => {
              deleteAdmin()
            }}
          >
            {props.t("Delete")}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

AdminDrawer.propTypes = {
  t: PropTypes.any,
  showDrawer: PropTypes.bool,
  setShowDrawer: PropTypes.any,
  selectedRow: PropTypes.any,
  getAdmins: PropTypes.func,
}

export default withRouter(withTranslation()(AdminDrawer))
