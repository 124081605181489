import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import Chip from "@material-ui/core/Chip"
import { withTranslation } from "react-i18next"
import withRouter from "components/Common/withRouter"

const StatusReportItem = props => {
  return (
    <>
      <div className="d-flex">
        <div
          type="button"
          onClick={() => props.onOpenModal(props.item)}
          className="align-self-center overflow-hidden me-auto"
        >
          <div>
            <h5 className="font-size-14 text-truncate">
              <Link to="#" className="text-dark">
                {props.item.title}
              </Link>
            </h5>
            <p className="text-muted mb-0">{props.item.date}</p>

            <Chip
              size="small"
              key={props.item.id + "_chip"}
              className="font-size-12 me-2 my-2"
              style={{
                background: props.item.color,
                color: "white",
              }}
              //variant="outlined"
              label={props.t(props.item.status)}
            />

            {props.item.targets.map(target => (
              <Chip
                size="small"
                key={target + "_chip"}
                className="font-size-12 me-2 my-2"
                label={props.t(target)}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

StatusReportItem.propTypes = {
  t: PropTypes.any,
  item: PropTypes.any,
  onOpenModal: PropTypes.any,
}

export default withRouter(withTranslation()(StatusReportItem))
