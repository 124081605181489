/******************************************
 * DROPDOWN COMPONENT (TREE)
 * USED IN: Reporting (pages/reporting.js)
 *****************************************/

import React, { useState, useEffect } from "react"
import "antd/dist/antd.min.css"
import { TreeSelect, ConfigProvider } from "antd"
import PropTypes from "prop-types"
import { getClientList } from "../../helpers/backend_helper"

// Locale
import { withTranslation } from "react-i18next"

const { SHOW_PARENT } = TreeSelect

const DropdownTree = props => {
  const [disable, setDisable] = useState(props.disabled)
  // Custom empty/Data not found dropdown
  const customizeRenderEmpty = () => (
    <div className="mt-3" style={{ textAlign: "center" }}>
      <p>{props.t("No customers found")}</p>
    </div>
  )
  const [customize, setCustomize] = useState(true)
  let defaultTreeOptions = {
    treeData: [],
    value: [],
    onChange: null,
    loadData: null,
    treeCheckable: true,
    allowClear: true,
    multiple: true,
    maxTagCount: 10,
    showCheckedStrategy: SHOW_PARENT,
    placeholder: props.t("Choose group/customer"),
    style: { width: "100%" },
    treeDefaultExpandedKeys: ["all"],
    listHeight: 455, // dropdown-window larger
    dropdownStyle: {
      // maxHeight: "550px",
      maxHeight: "67vh",
      // width: "200%", // not working
      overflow: "visible",
    },
  }

  const [selectedOption, setSelectedOption] = useState(props.selection)
  // const [options, setOptions] = useState([])
  const [treeOptions, setTreeOptions] = useState(defaultTreeOptions)
  const [allGroups, setAllGroups] = useState([])
  const [selectAll, setSelectAll] = useState(false)

  const controller = new AbortController()

  // Filtered options (tags)
  useEffect(() => {
    if (props.options !== null && props.tags !== null) {
      let all = []
      if (props.tags.length == 0) {
        onChange([])
        console.log("no tags")
        all = [
          {
            id: 0,
            key: "all",
            value: "all",
            label: props.t("All groups"),
            children: props.options,
          },
        ]
      }
      if (props.tags.length > 0) {
        // console.log(" tags")
        all = props.options
      }
      // console.log(props.options, "options with selected tags")
      // TODO: More efficient way for updating options list
      setTreeOptions({ ...treeOptions, treeData: all })
    }
  }, [props.tags, props.options])

  // Get dropdown options from server
  useEffect(() => {
    getClientList(controller)
      .then(data => {
        if (data.array != null) {
          console.log(data.array)
          let datas = data.array
          let slicing = datas.map(obj => {
            const separatedGroups = separateGroups(obj.clients, 10)
            return { ...obj, clients: separatedGroups }
          })
          console.log(slicing, "sliced")

          let opts = []
          let counter = 1
          // Not in use right now
          // slicing.forEach(group => {

          data.array.forEach(group => {
            let groupValue = new Object()
            groupValue.group = group.name

            let groupObject = new Object()
            groupObject.id = counter++
            groupObject.label = group.name
            groupObject.value = JSON.stringify(groupValue)
            groupObject.tags = group.tags
            groupObject.children = []

            group.clients.sort((a, b) => (a.name > b.name ? 1 : -1))
            group.clients.forEach(client => {
              if (client.subgroup) {
                sg.group = sg.name
                let clientObject = new Object()
                let subgroup = client.subgroup(sg => {
                  sg.group = sg.name
                  // let clientObject = new Object()
                  clientObject.title = sg.name
                  clientObject.value = JSON.stringify(sg)
                  groupObject.children.push(clientObject)
                })

                groupObject.children.push(subgroup)
              } else {
                client.group = group.name
                let clientObject = new Object()
                clientObject.title = client.name
                clientObject.value = JSON.stringify(client)
                groupObject.children.push(clientObject)
              }
            })
            opts.push(groupObject)
            //setting placeholder off when customers found for other components (button etc.)
            setDisable(false)
          })

          let values = []
          values = opts.map(v => v.value)
          setAllGroups(values)

          let temp = treeOptions
          temp.onChange = onChange
          // temp.listHeight = values.length
          let all = [
            {
              id: 0,
              key: "all",
              value: "all",
              label: props.t("All groups"),
              children: opts,
            },
          ]
          // setOptions(all)
          temp.treeData = all
          temp.value = selectedOption
          setTreeOptions(temp)

          // Used only in reporting with tags
          props.allCustomers(opts)
        }
      })
      .catch(e => {
        console.log(e)
      })
    return () => {
      controller.abort()
    }
  }, [])

  const separateGroups = (clients, listSize) => {
    const separatedGroups = []
    if (clients.length <= listSize) {
      return clients
    }
    if (clients.length > listSize) {
      for (let i = 0; i < clients.length; i += listSize) {
        // const groupName = separatedGroups.length + 1
        // const group = clients.slice(i, i + listSize)
        const group = {
          [props.t("Subgroup") + separatedGroups.length + 1]: clients.slice(
            i,
            i + listSize
          ),
        }
        separatedGroups.push(group)
      }

      return separatedGroups
    }
  }

  // Show all
  useEffect(() => {
    if (selectAll == true) {
      console.log("All groups")
      let selections = []
      setSelectedOption(allGroups)

      if (allGroups != null) {
        allGroups.forEach(selection => {
          try {
            let temp = JSON.parse(selection)
            let newEntry = new Object()
            newEntry.group = temp.group
            newEntry.list = []
            selections.push(newEntry)
          } catch (err) {}
        })
      }

      console.log(
        "Final group/client selection format: " + JSON.stringify(selections)
      )
      props.setCustomers(selections)
    }
  }, [selectAll])

  const onChange = value => {
    // console.log("onChange ", value)

    setSelectedOption({ value })
    let selections = []

    if (value == "all") {
      //  Onchange does not get data from state
      setSelectAll(true)
    } else {
      setSelectAll(false)
      if (value != null) {
        value.forEach(selection => {
          try {
            let temp = JSON.parse(selection)
            // console.log(temp)
            if (temp.id != null) {
              let matchFound = false
              selections.forEach(entry => {
                if (temp.group == entry.group) {
                  entry.list.push(temp.id)
                  matchFound = true
                }
              })
              if (!matchFound) {
                let newEntry = new Object()
                newEntry.group = temp.group
                newEntry.list = []
                newEntry.list.push(temp.id)
                selections.push(newEntry)
              }
            } else {
              let newEntry = new Object()
              newEntry.group = temp.group
              newEntry.list = []
              selections.push(newEntry)
            }
          } catch (err) {}
        })
      }

      console.log(
        "Final group/client selection format: " + JSON.stringify(selections)
      )
      props.setCustomers(selections)
    }
  }

  return (
    <React.Fragment>
      <ConfigProvider renderEmpty={customize && customizeRenderEmpty}>
        <div className="config-provider">
          <TreeSelect disabled={disable} {...treeOptions}></TreeSelect>
        </div>
      </ConfigProvider>
    </React.Fragment>
  )
}
DropdownTree.propTypes = {
  success: PropTypes.any,
  selectedOption: PropTypes.string,
  t: PropTypes.any,
  selection: PropTypes.string,
  onChange: PropTypes.func,
  setCustomers: PropTypes.any,
  setDisabled: PropTypes.any,
  placeholder: PropTypes.any,
  disabled: PropTypes.any,
  allGroups: PropTypes.any,
  allCustomers: PropTypes.any,
  options: PropTypes.any,
  tags: PropTypes.any,
}

export default withTranslation()(DropdownTree)
