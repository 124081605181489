import React, { useState, useEffect, useRef } from "react"
import {
  Alert,
  Card,
  CardBody,
  CardTitle,
  Collapse,
  Button,
  ButtonGroup,
  Col,
  Row,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  FormGroup,
  Label,
  Input,
  UncontrolledTooltip,
} from "reactstrap"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import athome from "assets/images/devices/kotona.svg"
import athomeoffline from "assets/images/devices/kotona-danger.svg"
import athomeonline from "assets/images/devices/kotona-success.svg"
import noConnection from "assets/images/devices/kotona-no-connection.svg"

import { Select, Switch } from "antd"
import {
  getCombinedActivity,
  getGatewayData,
  getActivityTrend,
  updateClientInfo,
} from "helpers/backend_helper"
import PubSub from "pubsub-js"
import { deleteDuplicatedObject, formatDateFull } from "utils/general"

// Toast
import { showToast } from "utils/general"

// Open in another tab
import { Link } from "react-router-dom"

//Excel export
import XLSX from "xlsx"

import moment from "moment"

//Importing range calendar
import RangeCalendarServices from "../filter-calendar-services"

// Import NodeCards
import NodeCard from "./node-cards/node-card"

// Importing charts
import SmallDiagram from "pages/CustomerProfile/small-diagram"

import AtHomeSettings from "./at-home-settings"
import DaytimeViewChart from "./daytime-view-chart"

const AtHomeServices = props => {
  //
  const regex = /\./g
  const [showAlert, setShowAlert] = useState(false)
  const [alertContent, setAlertContent] = useState({
    title: "",
    message: "",
    icon: "",
    color: "",
  })

  const [gatewayKey, setGatewayKey] = useState(props.gatewayKey)
  const [gatewayData, setGatewayData] = useState({
    batteryOperated: "",
    connected: "",
    connectionIssue: "",
    historyDays: "",
    serialNumber: "",
  })
  const [nodes, setNodes] = useState([])
  const [nodesSelection, setNodesSelection] = useState([])

  // Datas
  const [chartData, setChartData] = useState([])
  const [chartDataDoor, setChartDataDoor] = useState([])
  const [chartDataBed, setChartDataBed] = useState([])
  const [chartDataMicro, setChartDataMicro] = useState([])
  const [chartDataWc, setChartDataWc] = useState([])

  // Loading diagram
  const [loadingData, setLoadingData] = useState(true)

  //Combined activity
  const [combinedActivity, setCombinedActivity] = useState(null)
  // Trends
  const [trends, setTrends] = useState(null)

  // date calendar
  const [rangePicker, setRangePicker] = useState(false)
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()

  // Data collecting range
  const [historyDays, setHistoryDays] = useState(14)

  //Dropdown button state
  const [menu, setMenu] = useState(false)

  //Settings card state
  const [settingsOpen, setSettingsOpen] = useState(false)

  // initialize state to default checked
  const [selected, setSelected] = useState(1)

  // Loading text
  const [loading, setLoading] = useState(false)

  // Chosen node category
  const [selectedNodeCategory, setSelectedNodeCategory] = useState(["All"])
  const [filteredNodes, setFilteredNodes] = useState([])

  // Setting compare
  const [compare, setCompare] = useState(false)

  // Gateway modal
  const [gatewayModal, setGateWayModal] = useState(false)
  const [retentionPeriod, setRetentionPeriod] = useState(0)
  const [clientId, setClientId] = useState(props.clientId)

  // New window
  const abortRef = useRef(null)
  useEffect(() => {
    document.title = props.t("Home") + " | Suvanto Care"
    abortRef.current = new AbortController()
    const token = PubSub.subscribe("page-refresh", (event, data) => {
      getGwData()
      updateActivity(moment())
    })
    return () => {
      abortRef.current.abort()
      PubSub.unsubscribe(token)
    }
  }, [])

  // update gateway key
  useEffect(() => {
    setGatewayKey(props.gatewayKey)
  }, [props.gatewayKey])

  // Get gateway + nodes data
  useEffect(() => {
    getGwData()
  }, [gatewayKey])

  // Gateway card data
  useEffect(() => {
    if (gatewayData != undefined) {
      console.log(gatewayData, "gateway data")
    }
  }, [gatewayData])

  // Activity trend (1<days)
  useEffect(() => {
    if (gatewayKey != undefined && selected != 1) {
      getActivityTrend(abortRef.current, gatewayKey, moment(), selected)
        .then(result => {
          console.log("TREND: ", result, selected)
          setTrends(result)
        })
        .catch(err => {
          console.log(err)
        })
    }
  }, [gatewayKey, selected])

  useEffect(() => {
    updateActivity()
  }, [endDate])

  // When category is changed
  useEffect(() => {
    if (nodes.length > 0) {
      if (selectedNodeCategory == "All") {
        setFilteredNodes(nodes)
      } else {
        let filtered = nodes.filter(
          n => settingToCategories(n.useCase) == selectedNodeCategory
        )
        setFilteredNodes(filtered)
      }
    }
  }, [selectedNodeCategory])

  // Set general category for nodes by useCase
  const settingToCategories = cat => {
    let value = ""
    switch (cat) {
      case "Microwave":
        value = "Dining"
        break
      case "Fridge":
        value = "Dining"
        break
      case "Dining":
        value = "Dining"
        break
      case "WC":
        value = "WC"
        break
      case "Sleep":
        value = "Sleep"
        break
      case "Outside Door":
        value = "Outside Door"
        break
      default:
        value = "Activity"
        break
    }
    return value
  }
  // Get combined activity
  const updateActivity = end => {
    let endTime = undefined
    if (end) {
      end = endTime
    } else {
      endTime = endDate
    }
    if (
      gatewayKey != undefined &&
      startDate != undefined &&
      endDate != undefined
    ) {
      setLoadingData(true)
      setChartData([])
      setChartDataDoor([])
      setChartDataBed([])
      setChartDataMicro([])
      getCombinedActivity(abortRef.current, gatewayKey, startDate, endTime)
        .then(data => {
          console.log("ATHOME ACT: ", data)
          setCombinedActivity(data)
          if (data.entries) {
            setChartData(data.entries?.map(item => [item.date, item.activity]))
            setChartDataDoor(data.entries?.map(item => [item.date, item.door]))
            setChartDataBed(data.entries?.map(item => [item.date, item.bed]))
            setChartDataMicro(
              data.entries?.map(item => [item.date, item.micro])
            )
            setChartDataWc(data.entries?.map(item => [item.date, item.toilet]))
          }
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          setLoadingData(false)
        })
    }
  }

  // Get GW and nodes data
  const getGwData = message => {
    if (!loading && gatewayKey) {
      setLoading(true)
      getGatewayData(abortRef.current, gatewayKey)
        .then(data => {
          if (data && data.hasOwnProperty("key")) {
            setGatewayData(data)
            setHistoryDays(data.historyDays)

            data.nodes.sort((a, b) => (a.useCase > b.useCase ? 1 : -1))

            let selectionNodes = [{ value: "All", label: props.t("All") }]
            if (data.nodes !== undefined && data.nodes.length > 0) {
              data.nodes.map(n => {
                let category = settingToCategories(n.useCase)
                selectionNodes.push({
                  value: category,
                  label: props.t(category),
                })
              })
            }

            setNodesSelection(deleteDuplicatedObject(selectionNodes))
            console.log("NODES:", data.nodes)
            setNodes(data.nodes)
            setFilteredNodes(data.nodes)

            // Gateway alerts
            if (data.connectionIssue == true) {
              const alert = {
                title: props.t("Gateway connection issue"),
                message: props.t("Gateway connection issue description"),
                icon: "mdi mdi-signal-off",
                color: "danger",
              }
              setAlertContent(alert)
              setShowAlert(true)
            } else if (data.batteryOperated == true) {
              const alert = {
                title: props.t("Gateway unplugged"),
                message: props.t("Gateway unplugged description"),
                icon: "bx bxs-plug",
                color: "warning",
              }
              setAlertContent(alert)
              setShowAlert(true)
            }
          }
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          setLoading(false)
          if (message != undefined) {
            //Show toast from nodecard
            showToast(message, "success")
          }
        })
    }
  }

  const onDismissAlert = () => {
    setShowAlert(false)
  }

  // Converting time from seconds to readable
  function timeFormatter(timestamp) {
    let date = moment(timestamp).format("DD.MM.yyyy")
    return date
  }

  // Formatting bed value
  function bedFormatter(value) {
    if (value == 1) {
      return props.t("In use")
    }
    if (value != 1) {
      return ""
    }
  }

  // Creating Excel workbook+worksheet+download
  const downloadExcel = () => {
    if (selected == 1) {
      //Excel and pdf name
      let titleExport = props.t("Days activity")
      let message = props.t("Excel file exported succesfully!")
      const wb = XLSX.utils.book_new()

      const Heading = [
        [
          props.t("Time"),
          props.t("Activity"),
          props.t("Door"),
          props.t("Micro"),
          props.t("Bed"),
        ],
      ]
      const data = combinedActivity.entries.map(elt => [
        timeFormatter(elt.date),
        elt.activity,
        elt.door,
        elt.micro,
        bedFormatter(elt.bed),
      ])

      // Leaving first row to add Heading
      const ws = XLSX.utils.json_to_sheet(data, {
        origin: "A2",
        skipHeader: true,
      })
      XLSX.utils.sheet_add_aoa(ws, Heading, { origin: "A1" })
      XLSX.utils.book_append_sheet(wb, ws, titleExport)
      XLSX.write(wb, { bookType: "xlsx", type: "buffer" })
      XLSX.writeFile(wb, titleExport + " (" + new Date() + ")" + ".xlsx")

      //Show toast
      showToast(message)
    }
    if (selected != 1) {
      // Activity trends
      let titleExport = props.t("Activity")
      let message = props.t("Excel file exported succesfully!")
      const wb = XLSX.utils.book_new()

      const Heading = [
        [
          props.t("Time"),
          props.t("Morning") + " min" + " (6-12)",
          props.t("Day") + " min" + " (12-18)",
          props.t("Evening") + " min" + " (18-24)",
          props.t("Night") + " min" + " (0-6)",
        ],
      ]
      const data = trends.entries.map(elt => [
        timeFormatter(elt.date),
        elt.activities[0].replace(regex, ","),
        elt.activities[1].replace(regex, ","),
        elt.activities[2].replace(regex, ","),
        elt.activities[3].replace(regex, ","),
      ])

      // Leaving first row to add Heading
      const ws = XLSX.utils.json_to_sheet(data, {
        origin: "A2",
        skipHeader: true,
      })
      XLSX.utils.sheet_add_aoa(ws, Heading, { origin: "A1" })
      XLSX.utils.book_append_sheet(wb, ws, titleExport)
      XLSX.write(wb, { bookType: "xlsx", type: "buffer" })
      XLSX.writeFile(wb, titleExport + " (" + new Date() + ")" + ".xlsx")

      //Show toast
      showToast(message, "success")
    }
  }

  // Handle node type change
  const onTypeChange = value => {
    console.log("selected", value)
    setSelectedNodeCategory(value)
  }

  // Time since last activity
  const getLastActivityDiff = date => {
    if (date === null) {
      return "-"
    }

    let current = moment()
    let prev = moment(date)
    let duration = moment.duration(current.diff(prev))

    if (duration.months() > 0) {
      return duration.months() + " " + props.t("mo") + " "
    } else if (duration.days() > 0) {
      return duration.days() + " " + props.t("d") + " "
    } else if (duration.hours() > 0) {
      return duration.hours() + " " + props.t("h") + " "
    } else {
      return duration.minutes() + " " + "min " + " "
    }
  }

  //
  const saveNewPeriod = () => {
    console.log(clientId, "local")
    // const lang = localStorage.getItem("i18nextLng")
    if (clientId) {
      updateClientInfo(abortRef.current, clientId, {
        storeHomeDataPeriod: retentionPeriod,
      })
        .then(result => {
          if (result.statusCode == 200) {
            setGateWayModal(false)
            showToast(props.t("Information updated"), "success")
            // Update SelectClientmagement.js getClients TODO: Delete
            // props.refreshClients()
          } else {
            showToast(props.t("Failed to update information"), "error")
          }
        })
        .catch(err => {
          console.log(err)
          // setUpdating(false)
          showToast(props.t("Failed to update information"), "error")
        })
        .finally(() => {
          getGwData()
        })
    }
  }

  return (
    <>
      <Modal size="sm" isOpen={gatewayModal} centered>
        <ModalHeader>{props.t("Set retention period")}</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label>{props.t("Activity data retention period")}</Label>
            <Row>
              <Col xs="10">
                <Input
                  name="storeHomeDataPeriod"
                  type="number"
                  min={0}
                  defaultValue={gatewayData.historyDays}
                  onChange={e => {
                    console.log(e.target.value),
                      setRetentionPeriod(e.target.value)
                  }}
                ></Input>
              </Col>
              <Col xs="2" className="py-2 ms-0 px-0">
                <div>{props.t("vrk")}</div>
              </Col>
            </Row>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            outline
            onClick={() => {
              setGateWayModal(false)
            }}
          >
            {props.t("Cancel")}
          </Button>
          <Button
            color="primary"
            className=""
            onClick={() => {
              saveNewPeriod()
            }}
          >
            {props.t("Save")}
          </Button>
        </ModalFooter>
      </Modal>
      <Collapse isOpen={settingsOpen}>
        <AtHomeSettings />
      </Collapse>
      {settingsOpen == false && (
        <div className="mt-1">
          <Alert
            color={alertContent.color}
            isOpen={showAlert}
            toggle={onDismissAlert}
            className="pb-1 mb-1"
          >
            <div className="d-flex">
              <div className="me-3">
                <i className={alertContent.icon + " font-size-24"}></i>
              </div>
              <div>
                <h5 className="alert-heading">{alertContent.title}</h5>
                <p>{alertContent.message}</p>
              </div>
            </div>
          </Alert>

          <Card>
            {loading ? (
              <>
                <div className="py-5 text-center">
                  <Link to="#" className="text-primary">
                    <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                    {props.t("Loading")}
                  </Link>
                </div>
              </>
            ) : (
              <CardBody>
                <CardTitle>
                  <Row>
                    <Col xs="6">{props.t("All activity")}</Col>
                    <Col xs="6">
                      <span className="float-end">
                        <Dropdown
                          id="printButtonServicesStats"
                          isOpen={menu}
                          toggle={() => setMenu(!menu)}
                          className="download-btn text-center mt-0"
                          type="button"
                        >
                          <DropdownToggle
                            id="downloadstatstable"
                            className="text-muted"
                            tag="i"
                          >
                            <i className="mdi mdi-download " />
                          </DropdownToggle>
                          <DropdownMenu>
                            {/* TODO: Can not choose directory file right now */}
                            <DropdownItem onClick={() => downloadExcel()}>
                              {props.t("Download .xlsx")}
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </span>
                    </Col>
                  </Row>
                </CardTitle>
                <CardTitle>
                  <Col lg="12">
                    <Row>
                      <Col xs="4" className="">
                        <ButtonGroup
                          size="sm"
                          className=""
                          style={{ width: 100 + "%" }}
                        >
                          <Button
                            color="primary"
                            active={selected === 1}
                            onClick={() => setSelected(1)}
                            style={{ padding: 6 + "px" }}
                          >
                            {props.t("1 day")}
                          </Button>
                          {historyDays >= 7 && (
                            <Button
                              color="primary"
                              active={selected === 7}
                              onClick={() => setSelected(7)}
                              style={{ padding: 6 + "px" }}
                            >
                              {props.t("7 days")}
                            </Button>
                          )}
                          {historyDays >= 14 && (
                            <Button
                              color="primary"
                              active={selected === 14}
                              onClick={() => setSelected(14)}
                              style={{ padding: 6 + "px" }}
                            >
                              {props.t("14 days")}
                            </Button>
                          )}
                          {historyDays >= 30 && (
                            <Button
                              color="primary"
                              active={selected === 30}
                              onClick={() => setSelected(30)}
                              style={{ padding: 6 + "px" }}
                            >
                              {props.t("30 days")}
                            </Button>
                          )}
                        </ButtonGroup>
                      </Col>
                      <Col xs="4">
                        {selected == 1 && (
                          <RangeCalendarServices
                            startDefault={historyDays}
                            rangePicker={rangePicker}
                            setCalendarStartDate={setStartDate}
                            setCalendarEndDate={setEndDate}
                          />
                        )}
                      </Col>
                    </Row>
                  </Col>
                </CardTitle>
                {selected == 1 && (
                  <div>
                    {loadingData ? (
                      <Col xs="12">
                        <div
                          className="text-center text-primary py-5"
                          style={{ height: "600xp" }}
                        >
                          <Link to="#" className="py-4">
                            <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                            {props.t("Loading")}
                          </Link>
                        </div>
                      </Col>
                    ) : (
                      <SmallDiagram
                        type={"home"}
                        startDate={startDate}
                        endDate={endDate}
                        data={chartData}
                        doorData={chartDataDoor}
                        microData={chartDataMicro}
                        bedData={chartDataBed}
                        toilet={chartDataWc}
                        size={400}
                      />
                    )}
                  </div>
                )}
                {selected != 1 && (
                  <DaytimeViewChart data={trends}></DaytimeViewChart>
                )}
              </CardBody>
            )}
          </Card>
          {loading ? (
            <></>
          ) : (
            <>
              <>
                <Row>
                  <span className="h5">{props.t("Gateway")}</span>
                  {/* <Col xs="12"> */}
                  {/* <Card>
                      <CardBody className="">*/}
                  {/* <Col md="12"> */}
                  <div className="mini-stats-container">
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="me-3 align-self-center">
                            {gatewayData.connectionIssue == false ? (
                              <div className="avatar-sm mx-lg-auto float-start float-lg-none rounded-circle">
                                <span className="avatar-title rounded-circle bg-soft bg-gateway text-gateway font-size-20">
                                  <img
                                    src={athomeonline}
                                    alt=""
                                    className="img-fluid p-2 pb-3"
                                  />
                                </span>
                              </div>
                            ) : (
                              <div className="avatar-sm mx-lg-auto float-start float-lg-none rounded-circle">
                                <span className="avatar-title rounded-circle bg-soft bg-danger text-danger font-size-20">
                                  <img
                                    src={noConnection}
                                    alt=""
                                    className="img-fluid p-2 pb-3"
                                  />
                                </span>
                              </div>
                            )}
                          </div>
                          <div className="flex-grow-1">
                            <p className="text-muted mb-2">
                              {props.t("Connection status")}
                            </p>
                            {gatewayData.connectionIssue == false ? (
                              <h5 className="text-success mb-0">
                                {props.t("Online")}
                              </h5>
                            ) : (
                              <h5 className="text-danger mb-0">
                                {props.t("Offline")}
                              </h5>
                            )}
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="me-3 align-self-center">
                            <i className="mdi mdi-clock-check-outline h2 text-primary mb-0" />
                          </div>
                          <div className="flex-grow-1">
                            <p className="text-muted mb-2">
                              {props.t("Last connection")}
                            </p>
                            <h5 className="mb-0">
                              {getLastActivityDiff(gatewayData.connected)}
                              <span className="font-size-14 text-muted">
                                {props.t("ago")}
                              </span>
                            </h5>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                    <Card className="mini-stats-wid">
                      <CardBody>
                        {gatewayData.batteryOperated == false ? (
                          <div className="d-flex">
                            <div className="me-3 align-self-center">
                              <i className="mdi mdi-power-plug-outline h2 text-primary mb-0" />
                            </div>
                            <div className="flex-grow-1">
                              <p className="text-muted mb-2">
                                {props.t("Power source")}
                              </p>
                              <h5 className="mb-0">
                                {props.t("In mains stream")}
                              </h5>
                            </div>
                          </div>
                        ) : (
                          <div className="d-flex">
                            <div className="me-3 align-self-center">
                              <i className="mdi mdi-battery-alert-variant h2 text-primary mb-0" />
                            </div>
                            <div className="flex-grow-1">
                              <p className="text-muted mb-2">
                                {props.t("Power source")}
                              </p>
                              <h5 className="mb-0">
                                {props.t("Battery operated")}
                              </h5>
                            </div>
                          </div>
                        )}
                      </CardBody>
                    </Card>
                    <Card className="mini-stats-wid">
                      <CardBody>
                        <div className="d-flex">
                          <div className="me-3 align-self-center">
                            <i className="bx bx-data h2 text-primary mb-0" />
                          </div>
                          <div className="flex-grow-1">
                            <span id="retentionCog" className="float-end">
                              <Button
                                className="p-0 text-primary"
                                size="sm"
                                color="transparent"
                                onClick={() => {
                                  setGateWayModal(true)
                                }}
                              >
                                <i className="bx bx-cog" />
                              </Button>
                            </span>
                            <UncontrolledTooltip
                              placement="bottom"
                              target="retentionCog"
                            >
                              {props.t("Set retention period")}
                            </UncontrolledTooltip>
                            <p className="text-muted mb-2">
                              {props.t("Data retention period")}
                            </p>
                            <h5 className="mb-0">
                              {gatewayData.historyDays}{" "}
                              <span className="font-size-14 text-muted">
                                {props.t("days")}
                              </span>
                            </h5>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                    <Card className="mini-stats-wid last">
                      <CardBody>
                        <div className="d-flex">
                          <div className="me-3 align-self-center">
                            <i className="mdi mdi-identifier h2 text-primary mb-0" />
                          </div>
                          <div className="flex-grow-1">
                            <p className="text-muted mb-2">
                              {props.t("Serial number")}
                            </p>
                            <h5 className="mb-0">
                              {gatewayData.serialNumber}{" "}
                            </h5>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                  {/* </Col> */}
                  {/* </CardBody>
                    </Card> */}
                  {/* </Col> */}
                </Row>
              </>
              <div className="pb-2">
                <Row>
                  <Col xs="6">
                    <div className=" mb-2 d-inline-block">
                      <span className="h5">{props.t("Nodes")}</span>
                      <span className="ms-2">{filteredNodes.length}</span>
                    </div>
                  </Col>
                  {nodes.length > 0 && (
                    <Col xs="6">
                      <div className="pb-2 ">
                        <Select
                          className="float-end"
                          defaultValue={selectedNodeCategory}
                          style={{ minWidth: "150px" }}
                          options={nodesSelection}
                          onChange={onTypeChange}
                        ></Select>
                        {/* <span className="float-end mt-1">
                        <span className="py-4">
                          {props.t("Compare measurements")}
                        </span>
                        <Switch
                          className="ms-1 me-3"
                          onChange={() => setCompare(!compare)}
                          checked={compare}
                        ></Switch>
                      </span> */}
                      </div>
                    </Col>
                  )}
                </Row>
              </div>
              <Row>
                <NodeCard
                  getNodes={getGwData}
                  data={filteredNodes}
                  gatewayKey={gatewayKey}
                  historyDays={historyDays}
                  compare={compare}
                />
              </Row>
            </>
          )}
        </div>
      )}
    </>
  )
}

AtHomeServices.propTypes = {
  t: PropTypes.any,
  startUnix: PropTypes.any,
  endUnix: PropTypes.any,
  gatewayKey: PropTypes.string,
  setCalendarStartDate: PropTypes.any,
  setCalendarEndDate: PropTypes.any,
  combinedActivity: PropTypes.any,
  data: PropTypes.any,
  setStartDate: PropTypes.any,
  setEndDate: PropTypes.any,
  range: PropTypes.string,
  setCalendarStartDateNode: PropTypes.any,
  setCalendarEndDateNode: PropTypes.any,
  nodeDate: PropTypes.any,
  selectedHistoryNode: PropTypes.any,
  refreshClient: PropTypes.any,
  openDeleteModal: PropTypes.any,
  clientId: PropTypes.string,
}

export default withTranslation()(AtHomeServices)
