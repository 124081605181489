import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Offcanvas,
  OffcanvasHeader,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  OffcanvasBody,
  UncontrolledTooltip,
} from "reactstrap"
import { withTranslation } from "react-i18next"
import LandingPage from "./Instructions/landing-page"
import Video from "./Instructions/customer-management-video"
import InstructionsCustomerManagement from "./Instructions/customer-management"
import InstructionsGroupVideoCall from "./Instructions/group-video-call"
import InstructionsAppointments from "./Instructions/appointments"
import SimpleBar from "simplebar-react"

const Manual = props => {
  const [manualOpen, setManualOpen] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [tooltip, setTooltip] = useState(false)
  const [tab, setTab] = useState(props.tab)
  useEffect(() => {
    setTab(props.tab)
    console.log(props.tab, "tab")
  }, [props.tab])

  // DOWNLOAD PDF
  const onButtonClick = () => {
    fetch("/files/file.pdf").then(response => {
      response.blob().then(blob => {
        const fileURL = window.URL.createObjectURL(blob)
        let alink = document.createElement("a")
        alink.href = fileURL
        alink.download = props.t("Instructions") + ".pdf"
        alink.click()
      })
    })
  }

  const sourcePage = v => {
    console.log(v, "type")
    switch (v) {
      case "landing-page":
        return <LandingPage />
      case "customer-management":
        return <InstructionsCustomerManagement tab={tab} />
      case "group-video-call":
        return <InstructionsGroupVideoCall />
      case "appointments":
        return <InstructionsAppointments />
      default:
        return null
    }
  }
  return (
    <span className="mb-0">
      <span>
        <Dropdown
          isOpen={manualOpen}
          toggle={() => {
            if (props.source == "landing-page") {
              setManualOpen(!manualOpen)
            } else {
              setIsOpen(!isOpen)
            }
          }}
        >
          <DropdownToggle
            id="info-button"
            className="btn nav-btn"
            tag="a"
            style={{ marginTop: "2px" }}
          >
            <i className="bx bx-book-content font-size-16"></i>
          </DropdownToggle>
          {props.source == "landing-page"}
          {
            <DropdownMenu className="dropdown-menu-end">
              <DropdownItem href="#" onClick={() => setIsOpen(true)}>
                {props.t("Show side manual")}
              </DropdownItem>
              <DropdownItem href="#" onClick={onButtonClick}>
                {props.t("Download manual")}
              </DropdownItem>
              <DropdownItem
                href={"https://suvanto.info"}
                target="_blank"
                rel="noopener noreferrer"
              >
                {props.t("Instructional videos")}
              </DropdownItem>
              {/* TODO: if ISTEK support group */}
              <DropdownItem
                href={"https://suvanto.help/doku.php?id=public:istekki-ohjeet"}
                target="_blank"
                rel="noopener noreferrer"
              >
                Istekki {props.t("help portal")}
              </DropdownItem>
            </DropdownMenu>
          }
        </Dropdown>
      </span>
      <UncontrolledTooltip placement="bottom" target="info-button">
        {props.t("Manual")}
      </UncontrolledTooltip>

      <Offcanvas
        isOpen={isOpen}
        direction="end"
        scrollable
        backdrop={false}
        toggle={() => setIsOpen(!isOpen)}
      >
        <OffcanvasHeader
          toggle={() => {
            setIsOpen(!isOpen)
            setTooltip(false)
          }}
        >
          <i className="bx bx-book-content me-2"></i> {props.t("Manual")}
        </OffcanvasHeader>
        <hr className="py-0 my-0"></hr>
        <SimpleBar style={{ maxHeight: "92vh", marginRight: "5px" }}>
          <OffcanvasBody>{sourcePage(props.source)}</OffcanvasBody>
        </SimpleBar>
      </Offcanvas>
    </span>
  )
}

Manual.propTypes = {
  t: PropTypes.any,
  breadcrumbItem: PropTypes.any,
  title: PropTypes.string,
  source: PropTypes.any,
}

export default withTranslation()(Manual)
