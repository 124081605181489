import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import moment from "moment"
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Row,
  Accordion,
  AccordionItem,
  AccordionBody,
  AccordionHeader,
  CardImg,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Form,
  Label,
  Alert,
  FormFeedback,
  Input,
  FormGroup,
  UncontrolledTooltip,
} from "reactstrap"
import { Switch } from "antd"
import Box from "@mui/material/Box"
import Slider from "@mui/material/Slider"
import * as Yup from "yup"
import { useFormik } from "formik"
import { withTranslation } from "react-i18next"
import {
  getJournalEntries,
  getVideoCallToken,
  getVideoDeviceStatus,
  addVideoMessage,
} from "helpers/backend_helper"
import PubSub from "pubsub-js"
import loadingAnim from "common/animations/loading-dots-blue"
import nodataAnim from "common/animations/nodata"
import Lottie from "react-lottie"
import StatusCardHeader from "../status-card-header"
import { ReactComponent as VideoCallIcon } from "../../../assets/images/devices/videopuhelu-ikoni.svg"
import withRouter from "components/Common/withRouter"

const VideoCallServices = props => {
  const [deviceData, setDeviceData] = useState(null)
  const [callLoading, setCallLoading] = useState(false)
  const [callHistoryLoading, setCallHistoryLoading] = useState(false)
  const [dataLoading, setDataLoading] = useState(false)
  const [calls, setCalls] = useState([])
  const [open, setOpen] = useState("")
  const [modeLayout, setMode] = useState(
    document.body.getAttribute("data-layout-mode")
  )

  // Testing dynamic value svg
  const [iconColor, setIconColor] = useState("deepskyblue")
  const [accordionText, setAccordionText] = useState("Show all device info")
  const toggleInfo = id => {
    open === id
      ? (setOpen(), setAccordionText("Show all device info"))
      : (setOpen(id), setAccordionText("Hide info"))
  }

  const abortRef = useRef(null)
  useEffect(() => {
    document.title = props.t("Customer management") + " | Suvanto Care"
    abortRef.current = new AbortController()
    if (props.suvantoKey != undefined && props.suvantoKey.length > 0) {
      getData()
      getCallLogs()
    }
    const token = PubSub.subscribe("page-refresh", (event, data) => {
      getData()
      getCallLogs()
    })
    return () => {
      abortRef.current.abort()
      PubSub.unsubscribe(token)
    }
  }, [])

  useEffect(() => {
    if (document.body.getAttribute("data-layout-mode") !== modeLayout) {
      setMode(document.body.getAttribute("data-layout-mode"))
      if (document.body.getAttribute("data-layout-mode") == "light") {
        setIconColor("deepskyblue")
      }
      if (document.body.getAttribute("data-layout-mode") == "dark") {
        setIconColor("indigo")
      }

      console.log(
        "color changed",
        document.body.getAttribute("data-layout-mode")
      )
    }
  }, [document.body.getAttribute("data-layout-mode")])

  // Get device status etc.
  const getData = () => {
    if (!dataLoading) {
      setDataLoading(true)
      getVideoDeviceStatus(abortRef.current, props.suvantoKey)
        .then(data => {
          data[0].roomStatusTimeNice = moment(data[0].roomStatusTime).format(
            "DD.MM.yyyy HH:mm:ss"
          )
          switch (data[0].roomStatus) {
            case "online":
              data[0].roomStatusDescription = "Available"
              //setRoomStatusImage() //TODO: Set image
              break
            case "offline":
              data[0].roomStatusDescription = "Unavailable"
              //setRoomStatusImage() //TODO: Set image
              break
            case "group":
              data[0].roomStatusDescription = "Ongoing group call"
              //setRoomStatusImage() //TODO: Set image
              break
            case "call":
              data[0].roomStatusDescription = "Ongoing video call"
              //setRoomStatusImage() //TODO: Set image
              break
            default:
              data[0].roomStatusDescription = "Unknown"
              //setRoomStatusImage() //TODO: Set image
              break
          }
          setDeviceData(data[0])
        })
        .catch(e => {
          console.log(e)
        })
        .finally(() => {
          setDataLoading(false)
        })
    }
  }

  // Get call logs
  const getCallLogs = () => {
    if (!callHistoryLoading) {
      let to = new Date()
      let from = moment().subtract(1, "month")
      setCallHistoryLoading(true)
      getJournalEntries(
        abortRef.current,
        props.suvantoKey,
        from,
        to,
        10,
        "call_video",
        false
      )
        .then(result => {
          if (result.entries) {
            result.entries.forEach(call => {
              call.dateNice = moment(call.date).format("DD.MM.yyyy HH:mm:ss")
              if (call.duration) {
                let timeString = ""
                let hours = Math.floor(call.duration / 3600)
                let minsOver = Math.floor(call.duration % 60)
                let mins = Math.floor(call.duration / 60)
                let secs = call.duration % 60

                if (hours > 0)
                  timeString = hours + "h " + minsOver + "min " + secs + "s"
                else if (mins > 0) timeString = mins + "min " + secs + "s"
                else timeString = call.duration + "s"

                call.durationNice = timeString
              }
            })
            setCalls(result.entries)
          } else {
            setCalls([])
          }
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          setCallHistoryLoading(false)
        })
    }
  }

  // Start video call
  const startCall = () => {
    setCallLoading(true)
    getVideoCallToken(abortRef.current, props.suvantoKey)
      .then(result => {
        if (result.link != null && result.link.length > 0) {
          window.open(result.link, "_blank", "noreferrer")
        }
        setCallLoading(false)
      })
      .catch(err => {
        console.log(err)
        setCallLoading(false)
      })
  }

  const lottieLoadingOpts = {
    loop: true,
    autoplay: true,
    animationData: loadingAnim,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }

  const lottieNoneOpts = {
    loop: false,
    autoplay: true,
    animationData: nodataAnim,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }

  return (
    <>
      {deviceData && (
        <>
          <div className="mt-1">
            <Card style={{ borderRadius: "40px 0 0 0" }}>
              <StatusCardHeader
                service="video"
                status={deviceData.status}
                battery={deviceData.batteryLevel}
                date={deviceData.statusTime}
              />
              <CardBody style={{ padding: "0px" }}>
                <Accordion flush open={open} toggle={toggleInfo}>
                  <AccordionItem>
                    <AccordionBody
                      accordionId="1"
                      className="pt-0"
                      style={{ paddingTop: "0px" }}
                    >
                      <Row xs={1} sm={2} lg={3} className="mt-3">
                        <Col>
                          <div className="text-muted mb-4">
                            <h6>{props.t("Serial")}:</h6>{" "}
                            {deviceData.serial
                              ? deviceData.serial
                              : props.t("Undefined")}
                          </div>
                        </Col>
                        <Col>
                          <div className="text-muted mb-4">
                            <h6>{props.t("IMEI")}:</h6>{" "}
                            {deviceData.imei
                              ? deviceData.imei
                              : props.t("Undefined")}
                          </div>
                        </Col>
                      </Row>
                    </AccordionBody>
                    <AccordionHeader targetId="1">
                      {props.t(accordionText)}
                    </AccordionHeader>
                  </AccordionItem>
                </Accordion>
              </CardBody>
            </Card>

            <Row xs={1} xl={2}>
              <Col className="order-2 order-xl-1">
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">
                      {props.t("Recent video calls")}
                    </CardTitle>
                    {callHistoryLoading && (
                      <Lottie
                        options={lottieLoadingOpts}
                        height={100}
                        width={100}
                      />
                    )}
                    {!callHistoryLoading && calls.length > 0 && (
                      <ul className="list-group list-group-flush">
                        {calls.map(call => (
                          <li key={call.key} className="list-group-item py-3">
                            <Row>
                              <Col>
                                <div>
                                  <h6>{call.fullName}</h6>
                                  <span className="text-muted">
                                    {call.dateNice}
                                  </span>
                                </div>
                              </Col>
                              <Col
                                className="col-auto d-flex"
                                style={{ alignItems: "center" }}
                              >
                                <span>{call.durationNice}</span>
                              </Col>
                            </Row>
                          </li>
                        ))}
                      </ul>
                    )}
                    {!callHistoryLoading &&
                      (calls == null || calls.length == 0) && (
                        <div className="text-center mb-4">
                          <Lottie
                            options={lottieNoneOpts}
                            height={150}
                            width={150}
                          />
                          <h5 className="text-muted mt-2">
                            {props.t("No calls found")}
                          </h5>
                        </div>
                      )}
                  </CardBody>
                </Card>
              </Col>
              <Col className="order-1 order-xl-2">
                <Card>
                  <CardBody className="p-4">
                    <Row>
                      <Col xs="6">
                        <div className="text-center mt-5">
                          <h4 className="mt-2">
                            {props.t(deviceData.roomStatusDescription)}
                          </h4>
                          <span className="text-muted">
                            {deviceData.roomStatusTimeNice}
                          </span>
                        </div>
                      </Col>
                      <Col xs="6">
                        {/* <CardImg
                          top
                          width="auto"
                          height="150px"

                          // style={{ opacity: "0.5" }}
                          // src={}
                        ></CardImg> */}

                        <VideoCallIcon
                          fill={iconColor}
                          stroke={iconColor}
                          style={{
                            width: "auto",
                            height: "150px",
                            paddingLeft: "8vh",
                          }}
                          className="m-3"
                        />
                      </Col>
                    </Row>
                    <div className="text-center mt-2">
                      <Row>
                        <Col xs="6">
                          {!callLoading && (
                            <Button
                              className="mt-3 me-1"
                              type="button"
                              style={{ width: "100%" }}
                              onClick={() => startCall()}
                            >
                              <i className="mdi mdi-message-video me-2"></i>
                              {props.t("Start video call")}
                            </Button>
                          )}
                          {callLoading && (
                            <Lottie
                              options={lottieLoadingOpts}
                              height={100}
                              width={100}
                            />
                          )}
                        </Col>
                        <Col xs="6">
                          <ModalSendMessage
                            t={props.t}
                            clientId={props.clientId}
                          ></ModalSendMessage>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>

                {/** 
                <Card>
                  <CardBody>
                    <div className="text-center mb-4">
                      <Lottie
                        options={lottieGroupOpts}
                        height={150}
                        width={150}
                      ></Lottie>
                      <div>
                        <h4 className="mt-2">
                          {props.t(deviceData.roomStatusDescription)}
                        </h4>
                        <span className="text-muted">{deviceData.roomStatusTimeNice}</span>
                      </div>
                      {!callLoading && 
                        <Button
                          className="mt-4"
                          type="button"
                          style={{minWidth: "180px"}}
                          onClick={() => startCall()}
                        >
                          {props.t("Start video call")}
                        </Button>
                      }
                      {callLoading &&
                        <Lottie
                          options={lottieLoadingOpts}
                          height={100}
                          width={100}
                        />
                      }
                    </div>
                  </CardBody>
                </Card>
                */}
              </Col>
            </Row>
          </div>
        </>
      )}
    </>
  )
}

const ModalSendMessage = props => {
  const abortRef = useRef(null)
  const [clientId, setClientId] = useState(props.clientId)
  // Disable button when saving
  const [saveDisable, setSaveDisable] = useState(false)
  // Video message entry
  const [newMessageVideo, setNewMessageVideo] = useState("")
  const [newResponseOption, setNewResponseOption] = useState(1)
  const [newExpirationTime, setNewExpirationTime] = useState(15)
  const [newAlarmExpiration, setNewAlarmExpiration] = useState(false)
  const [audioAlert, setAudioAlert] = useState(true)
  // Modal
  const [modal, setModal] = useState(false)
  // Validation
  const [errorMessage, setErrorMessage] = useState(null)
  const [successMessage, setSuccessMessage] = useState(null)
  //
  useEffect(() => {
    abortRef.current = new AbortController()
    return () => abortRef.current.abort()
  }, [])

  const handleResponseOptions = e => {
    console.log(e, "response")
    setNewResponseOption(e)
  }
  // Setting alarm
  const settingExAlarm = () => {
    setNewAlarmExpiration(!newAlarmExpiration)
  }
  // Setting audio alarm
  const settingAudioAlarm = () => {
    setAudioAlert(!audioAlert)
  }
  // Slider expiration time
  const marks = [
    {
      value: 0,
      label: "0",
    },
    {
      value: 15,
      label: "15",
    },
    {
      value: 30,
      label: "30",
    },
    {
      value: 45,
      label: "45",
    },
    {
      value: 60,
      label: "60",
    },
  ]
  // format slider
  const handleSlider = (event, newValue) => {
    if (typeof newValue === "number") {
      setNewExpirationTime(newValue)
    }
  }
  // Formatting minutes
  const formatMinutes = value => {
    let Hours = Math.floor(value / 60)
    let minutes = value % 60

    let resultH = ""
    let resultM = ""

    if (Hours > 0) {
      resultH = Hours + "h"
    }
    if (Hours == 0) {
      resultH = ""
    }
    if (minutes > 0) {
      resultM = minutes + "min"
    }
    if (minutes == 0) {
      resultM = ""
    }
    if (minutes == 0 && Hours == 0) {
      resultM = minutes + "min"
    }

    return resultH + " " + resultM
  }
  //  ** START OF MODAL FUNCTIONS ** //
  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }
  const openMessageModal = () => {
    removeBodyCss()
    setNewMessageVideo("")
    setModal(!modal)
  }

  // New video message
  const validationVideoMessage = useFormik({
    enableReinitialize: true,
    initialValues: {
      newMessageVideo: newMessageVideo,
    },
    validationSchema: Yup.object({
      newMessageVideo: Yup.string()
        .required(props.t("Please enter your message"))
        .min(5, props.t("Message is too short"))
        .max(50, props.t("Message is too long")),
    }),
    onSubmit: values => {
      setErrorMessage(null)
      setSuccessMessage(null)
      setSaveDisable(true)
      addVideoMessage(
        abortRef.current,
        clientId,
        values.newMessageVideo,
        newResponseOption,
        newExpirationTime,
        newAlarmExpiration,
        audioAlert
      )
        .then(result => {
          console.log(result)
          if (result.statusCode != null && result.statusCode == 200) {
            setSuccessMessage("Success")
            openMessageModal()
            // setEndDate(moment())

            // Set to formik
            setNewMessageVideo("")
            setNewResponseOption(1)
            setNewExpirationTime(15)
            setNewAlarmExpiration(false)
            setAudioAlert(true)
          } else {
            setErrorMessage("Error")
          }
        })
        .catch(err => {
          console.log(err)
          setErrorMessage("Error")
        })
        .finally(() => {
          setSaveDisable(false)
        })
    },
  })
  return (
    <>
      <Button
        className="mt-3 ms-1"
        color="primary"
        type="button"
        style={{ width: "100%" }}
        onClick={() => setModal(!modal)}
      >
        <i className="mdi mdi-message-arrow-right-outline me-2"></i>
        {props.t("Send a message to video device")}
      </Button>
      <Modal
        isOpen={modal}
        toggle={() => {
          setModal(!modal)
        }}
        centered
      >
        <ModalHeader>{props.t("Send a message to video device")}</ModalHeader>

        <Form
          onSubmit={e => {
            e.preventDefault()
            return false
          }}
        >
          <ModalBody>
            <div className="mt-1">
              <Label>{props.t("Message")}</Label>
              {errorMessage ? (
                <Alert color="danger">{props.t(errorMessage)}</Alert>
              ) : null}
              {successMessage ? (
                <Alert color="success">{props.t(successMessage)}</Alert>
              ) : null}
              <Input
                type="text"
                maxLength="50"
                name="newMessageVideo"
                onChange={validationVideoMessage.handleChange}
                onBlur={validationVideoMessage.handleBlur}
                value={validationVideoMessage.values.newMessageVideo || ""}
                invalid={
                  validationVideoMessage.touched.newMessageVideo &&
                  validationVideoMessage.errors.newMessageVideo
                    ? true
                    : false
                }
                className="form-control"
                placeholder={props.t("max. 50 characters")}
              />
              {validationVideoMessage.touched.newMessageVideo &&
              validationVideoMessage.errors.newMessageVideo ? (
                <FormFeedback type="invalid">
                  {validationVideoMessage.errors.newMessageVideo}
                </FormFeedback>
              ) : null}
            </div>
            <div className="mt-4">
              <Row>
                <Col xs={12}>
                  <FormGroup>
                    <Label>{props.t("Response options")}</Label>
                    <Input
                      name="stage"
                      type="select"
                      onChange={e => handleResponseOptions(e.target.value)}
                    >
                      <option value={1}>{props.t("Ok")}</option>
                      <option value={2}>{props.t("Yes / No")}</option>
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
            </div>
            <div className="mt-2">
              <Label>
                {props.t("Message expiration time")}{" "}
                {formatMinutes(newExpirationTime)}
              </Label>
              <div className="p-2">
                <Box sx={{ width: "100%" }}>
                  <Slider
                    size="small"
                    valueLabelDisplay="auto"
                    aria-labelledby="non-linear-slider"
                    defaultValue={30}
                    getAriaValueText={formatMinutes}
                    valueLabelFormat={formatMinutes}
                    step={1}
                    onChange={handleSlider}
                    marks={marks}
                    min={0}
                    value={newExpirationTime}
                    max={60}
                  />
                </Box>
              </div>
            </div>
            <Row>
              <Col xs={6}>
                <div className="mt-4 mb-0">
                  <FormGroup>
                    <Label>{props.t("Expiration alarm")}</Label>
                    <Switch
                      className="ms-2"
                      name="arrange_time"
                      checked={newAlarmExpiration}
                      onChange={settingExAlarm}
                      checkedChildren={
                        <i className="mdi mdi-bell-check-outline align-middle font-size-14" />
                      }
                      unCheckedChildren={
                        <i className="mdi mdi-bell-off-outline align-middle font-size-14" />
                      }
                    ></Switch>
                  </FormGroup>
                </div>
              </Col>
              <Col xs={6}>
                <div className="mt-4 mb-0">
                  <FormGroup>
                    <Label>{props.t("Message tone")}</Label>
                    <Switch
                      className="ms-2"
                      name="arrange_time"
                      checked={audioAlert}
                      onChange={settingAudioAlarm}
                      checkedChildren={
                        <i className="mdi mdi-bell-check-outline align-middle font-size-14" />
                      }
                      unCheckedChildren={
                        <i className="mdi mdi-bell-off-outline align-middle font-size-14" />
                      }
                    ></Switch>
                    <div id="info-circle" className=" d-md-inline-block">
                      <Button
                        className="pz-2 py-0"
                        size="sm"
                        style={{ cursor: "default" }}
                        color="tranparent"
                        onClick={() => {}}
                      >
                        <i className="bx bx-info-circle font-size-10 " />
                      </Button>
                    </div>
                    <UncontrolledTooltip placement="right" target="info-circle">
                      {props.t("Video device message tone on/off")}
                    </UncontrolledTooltip>
                  </FormGroup>
                </div>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter className="">
            <div className="float-end">
              <button
                type="button"
                onClick={() => {
                  openMessageModal()
                }}
                className="btn btn-outline-primary "
                data-dismiss="modal"
              >
                {props.t("Cancel")}
              </button>

              <button
                disabled={saveDisable}
                className="btn btn-primary ms-2"
                onClick={() => {
                  validationVideoMessage.submitForm()
                }}
              >
                {props.t("Send")}
              </button>
            </div>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  )
}

VideoCallServices.propTypes = {
  t: PropTypes.any,
  suvantoKey: PropTypes.string,
  clientId: PropTypes.any,
}

ModalSendMessage.propTypes = {
  t: PropTypes.any,
  clientId: PropTypes.any,
}

export default withRouter(withTranslation()(VideoCallServices))
