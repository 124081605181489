/***********************
 * LOGIN (PAGE)
 ************************/

import PropTypes from "prop-types"
import React, {
  useState,
  useContext,
  useEffect,
  KeyboardEvent,
  useRef,
} from "react"
import {
  Row,
  Button,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
  CardTitle,
  UncontrolledTooltip,
} from "reactstrap"
import { Switch } from "antd"

import { Link, useNavigate } from "react-router-dom"
import GlobalState from "../../contexts/GlobalState"
import LoginLanguageDropdown from "./LoginLanguageDropdown"

// Locale
import { withTranslation } from "react-i18next"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

// import images
// import pikkulogo from "assets/images/logo_suvanto_pyorea.png"
import pikkulogo from "../../assets/images/logo_suvanto_pyorea.png"
import { suvantoLogin, changePassword } from "../../helpers/backend_helper"

const Login = props => {
  const [state, setState] = useContext(GlobalState)
  const [error, setError] = useState(null)
  const [eye, seteye] = useState(true)
  const [password, setpassword] = useState("password")
  const navigate = useNavigate()
  const [settingPassword, showSettingPassword] = useState(false)
  const [errorChange, setErrorChange] = useState(null)
  const [id, setId] = useState("")
  const [pw, setPw] = useState("")
  const [capsLock, setCapsLock] = useState(false)
  const [numLock, setNumLock] = useState(false)
  const abortRef = useRef(null)

  // Reset all data storages just in case
  useEffect(() => {
    document.title = props.t("Login") + " | Suvanto Care"
    setState({})
    localStorage.removeItem("session_id")
    abortRef.current = new AbortController()
    // TODO: check if  abortRef.current is plausible here
    return () => abortRef.current.abort()
  }, [])

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required(props.t("Please Enter Your Username")),
      password: Yup.string().required(props.t("Please Enter Your Password")),
    }),
    onSubmit: values => {
      setError(null)
      loginConnection(values)
    },
  })

  // Login connection
  const loginConnection = values => {
    // Trim values
    const keys = Object.keys(values)
    keys.forEach((key, index) => {
      values[key] = values[key].trim()
    })
    suvantoLogin(values)
      .then(result => {
        if (result.sessionId == null || result.sessionId.length == 0) {
          setError("Not permitted to login")
        } else {
          setState(state => ({ ...state, authUser: JSON.stringify(result) }))

          // Setting right theme by supportgroup, not permanent solution
          // settingTheme(result.supportType)

          if (result.passwordChangeRequired === true) {
            setPw(values.password)
            setId(result.sessionId)
            showSettingPassword(true)
          } else {
            localStorage.setItem("session_id", result.sessionId)
            navigate("/main")
          }
        }
      })
      .catch(err => {
        console.log(err)
        setError(err)
      })
  }
  // Show password
  const Eye = () => {
    if (password == "password") {
      setpassword("text")
      seteye(false)
    } else {
      setpassword("password")
      seteye(true)
    }
  }

  // Password change
  const pwValidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      currentpassword: pw,
      newpassword: "",
      // newpasswordconfirmed: "",
    },
    validationSchema: Yup.object({
      currentpassword: Yup.string().required(
        props.t("Please enter current password")
      ),
      newpassword: Yup.string()
        .min(14, props.t("Password is too short"))
        .max(50, props.t("Password is too long"))
        .matches(
          /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9])(?=.*[!?#@$€%&*^ˆ+;:,.<>=|/(){}\[\]\-])[a-zA-Z0-9!?#@$€%&*^ˆ+;:,.<>=|/(){}\[\]\-]{14,50}$/, // OLD: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{14,})/
          props.t("Password requirements")
        )
        .required(props.t("Please enter new password")),
    }),

    onSubmit: (values, { resetForm }) => {
      console.log(abortRef.current, values.currentpassword, values.newpassword)
      setError(null)
      changePassword(
        abortRef.current,
        values.currentpassword,
        values.newpassword
      )
        .then(result => {
          if (result.statusCode != null && result.statusCode == 200) {
            localStorage.setItem("session_id", id)
            setPw("")
            setId("")
            resetForm()
            navigate("/main")
          } else {
            setError("Password change failed")
          }
        })
        .catch(err => {
          console.log(err)
          setError("Password change failed")
        })
    },
  })

  // Detect caps/num lock
  function handleKeyPress(event) {
    const capsLockOn = event.getModifierState("CapsLock")
    const numLockOn = event.getModifierState("NumLock")
    setCapsLock(capsLockOn)
    setNumLock(numLockOn)
  }

  // // Setting right theme by supportgroup, not permanent solution
  // const settingTheme = g => {
  //   console.log(g)
  //   document.body.setAttribute("data-style", g)
  // }

  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <div className="mb-4">
                <img
                  src={pikkulogo}
                  style={{
                    height: "57px",
                    alignSelf: "center",
                    marginLeft: "45%",
                  }}
                ></img>
              </div>

              {settingPassword == true ? (
                <Card
                  style={{
                    paddingLeft: "30px",
                    paddingRight: "30px",
                    paddingTop: "20px",
                  }}
                >
                  <CardBody>
                    <CardTitle
                      className="mb-4 "
                      style={{ alignItems: "center" }}
                    >
                      <Row>
                        <Col>{props.t("Change password")}</Col>
                      </Row>
                    </CardTitle>

                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault()
                        return false
                      }}
                    >
                      <div className="mb-4">
                        <p>
                          {props.t(
                            "The password must be changed at the first login"
                          )}
                          .
                        </p>
                        <p>
                          <span>{props.t("Password requirements")}</span>
                          <Button
                            id="requirements"
                            className="py-0 px-2 m-0 font-size-10"
                            color="transparent"
                            style={{ cursor: "default" }}
                          >
                            <i className="dripicons-question text-muted"></i>
                          </Button>
                        </p>
                        <UncontrolledTooltip
                          placement="right"
                          target="requirements"
                        >
                          {props.t("Acceptable special characters")}:{" "}
                          {"!?#@$€%&*^ˆ+;:,.<>=|/(){}[]-"}
                        </UncontrolledTooltip>
                      </div>
                      {errorChange ? (
                        <Alert color="danger">{props.t(errorChange)}</Alert>
                      ) : null}
                      {/* {success ? (
                        <Alert color="success">{props.t(success)}</Alert>
                      ) : null} */}

                      <div className="mt-2 mb-2">
                        <div className="input-group">
                          <Input
                            onKeyUp={e => handleKeyPress(e)}
                            name="newpassword"
                            className="form-control"
                            type={eye ? "password" : ""}
                            onChange={pwValidation.handleChange}
                            onBlur={pwValidation.handleBlur}
                            value={pwValidation.values.newpassword || ""}
                            placeholder={props.t("Set new password")}
                            // invalid={
                            //   pwValidation.touched.newpassword &&
                            //   pwValidation.errors.newpassword
                            //     ? true
                            //     : false
                            // }
                          />
                          <Button
                            className="input-eye-btn"
                            color="light"
                            type="button"
                            id="input-eye-btn"
                          >
                            <i
                              onClick={Eye}
                              className={`float-end text-muted fa ${
                                eye ? "fa-eye" : "fa-eye-slash"
                              }`}
                            ></i>
                          </Button>
                        </div>
                        {capsLock == true && (
                          <Alert
                            color="info"
                            className="mt-1 mb-1 p-2 px-3 font-size-12"
                          >
                            <i className="bx bx-info-circle me-1" />
                            {props.t("Caps Lock is on")}
                          </Alert>
                        )}
                        {numLock == true && (
                          <Alert
                            color="info"
                            className="mt-1 p-2 px-3 font-size-12"
                          >
                            <i className="bx bx-info-circle me-1" />
                            {props.t("Num Lock is on")}
                          </Alert>
                        )}
                      </div>
                      <div>
                        {/* custom-list css marks checked */}
                        <ul id="custom-list" className="font-size-12">
                          <Row>
                            <Col xs="6">
                              <li
                                className={
                                  pwValidation.values.newpassword.length > 13
                                    ? "cheched"
                                    : "uncheched"
                                }
                              >
                                <span>
                                  {props.t("at least")} 14{" "}
                                  {props.t("characters")}
                                </span>
                              </li>
                              <li
                                className={
                                  pwValidation.values.newpassword.match(
                                    /[a-z?]/
                                  )
                                    ? "cheched"
                                    : "uncheched"
                                }
                              >
                                <span>{props.t("lowercase letter")}</span>
                              </li>
                              <li
                                className={
                                  pwValidation.values.newpassword.match(/[A-Z]/)
                                    ? "cheched"
                                    : "uncheched"
                                }
                              >
                                <span>{props.t("uppercase letter")}</span>
                              </li>
                            </Col>

                            <Col xs="6">
                              <li
                                className={
                                  pwValidation.values.newpassword.match(/[0-9]/)
                                    ? "cheched"
                                    : "uncheched"
                                }
                              >
                                <span>{props.t("number")}</span>
                              </li>
                              <li
                                className={
                                  pwValidation.values.newpassword.match(
                                    /[!?#@$€%&*^ˆ+;:,.<>=|/(){}\[\]\-]/
                                    // /[!?#@$€%&*^ˆ+;:,.<>=|/(){}\[\]\-]/
                                    // TODO: Check acceptable special marks
                                    // /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?´`]/
                                  )
                                    ? "cheched"
                                    : "uncheched"
                                }
                              >
                                <span>{props.t("special character")}</span>
                              </li>
                              <li
                                className={
                                  pwValidation.values.newpassword.match(
                                    /[äÄöÖåÅøØæÆ]/
                                  )
                                    ? "uncheched"
                                    : "cheched"
                                }
                              >
                                <span>
                                  <b>{props.t("no")}</b>{" "}
                                  {props.t("nordic letters")}
                                </span>
                              </li>
                            </Col>
                          </Row>
                        </ul>
                      </div>
                      <div className="mt-3 mb-3 d-grid">
                        <Button
                          className="mt-3 mb-2"
                          color="primary"
                          onClick={() => {
                            console.log("clicked")
                            pwValidation.submitForm()
                          }}
                        >
                          {props.t("Change password")}
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              ) : (
                <Card
                  className=""
                  style={{
                    paddingLeft: "30px",
                    paddingRight: "30px",
                    paddingTop: "20px",
                  }}
                >
                  <CardBody className="pt-0">
                    <div className="p-3 text-center">
                      <h4 className=" mb-3 mt-2">{props.t("Welcome back!")}</h4>
                      <p className="text-muted mb-3 mt-4 ">
                        {props.t(
                          "Enter your username and password to access your account."
                        )}
                      </p>
                    </div>
                    <div className="p-1">
                      <Form
                        className="form-horizontal"
                        onSubmit={e => {
                          e.preventDefault()
                          return false
                        }}
                      >
                        {error ? (
                          <Alert color="danger">{props.t(error)}</Alert>
                        ) : null}
                        <div className="mb-3 app-input">
                          <div className="position-relative">
                            <span className="bx bx-user text-primary" />
                            <Input
                              name="email"
                              placeholder={props.t("Username")}
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.email || ""}
                              invalid={
                                validation.touched.email &&
                                validation.errors.email
                                  ? true
                                  : false
                              }
                            ></Input>

                            {validation.touched.email &&
                            validation.errors.email ? (
                              <FormFeedback type="invalid">
                                {validation.errors.email}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </div>

                        <div className="mb-4 app-input">
                          <div className="input-group">
                            <span className="mdi mdi-key-variant text-primary" />
                            <Input
                              style={{
                                borderRadius: "5px 0px 0px 5px",
                              }}
                              name="password"
                              value={validation.values.password || ""}
                              type={eye ? "password" : ""}
                              placeholder={props.t("Password")}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              invalid={
                                validation.touched.password &&
                                validation.errors.password
                                  ? true
                                  : false
                              }
                            ></Input>
                            <Button
                              className="input-eye-btn"
                              color="light"
                              type="button"
                              id="input-eye-btn"
                            >
                              <i
                                onClick={Eye}
                                className={`float-end text-muted fa ${
                                  eye ? "fa-eye" : "fa-eye-slash"
                                }`}
                              ></i>
                            </Button>

                            {validation.touched.password &&
                            validation.errors.password ? (
                              <FormFeedback type="invalid">
                                {validation.errors.password}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </div>

                        <div className="mt-3 d-grid">
                          <button
                            className="btn btn-primary btn-block"
                            onClick={() => {
                              validation.submitForm()
                            }}
                          >
                            {props.t("Log in")}
                          </button>
                        </div>

                        <div className="mt-4 text-center">
                          <Link to="/forgot-password" className="">
                            {props.t("Forgot your password?")}
                          </Link>
                        </div>
                      </Form>
                    </div>
                    <div type="button" className="text-center text-muted ms-2">
                      <LoginLanguageDropdown />
                    </div>
                  </CardBody>
                </Card>
              )}
              <div className="mt-5 text-center">
                <div>
                  <a
                    className="mb-3"
                    href="https://www.suvantocare.fi/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    © {new Date().getFullYear()} Suvanto Care
                  </a>
                </div>
                <div className="mt-3">
                  <a
                    href="https://www.suvantocare.fi/saavutettavuusseloste/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {props.t("Accessibility statement")}
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Login.propTypes = {
  history: PropTypes.object,
  t: PropTypes.any,
}

export default withTranslation()(Login)
