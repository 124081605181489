/***********************
 * CUSTOMER DASHBOARD (CARDS)
 ************************/

import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { withTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import {
  Alert,
  Card,
  CardBody,
  Col,
  Row,
  Button,
  CardTitle,
  NavLink,
} from "reactstrap"
import iconVideo from "assets/images/icons/videocall.svg"
import iconGo from "assets/images/icons/mukana.svg"
import iconLock from "assets/images/icons/alylukko-web.png"
import iconSafety from "assets/images/icons/turvapuhelin.svg"
import googlemaps from "assets/images/google-maps.png"
import moment from "moment"
import "assets/scss/custom.scss"
import SimpleBar from "simplebar-react"
import {
  getCombinedActivity,
  getGatewayData,
  getVideoCallToken,
} from "../../helpers/backend_helper"
import SmallDiagramActivity from "./small-diagram"
import { CircularProgressbarWithChildren } from "react-circular-progressbar"
import Lottie from "react-lottie"
import successAnim from "common/animations/success"
import warningAnim from "common/animations/warning"
import dangerAnim from "common/animations/danger"
import { getLottieLoadingOptions } from "utils/general"

const ClientDashboard = props => {
  const [clientData, setClientData] = useState(null)
  const [chartData, setChartData] = useState([])
  const [chartDataDoor, setChartDataDoor] = useState([])
  const [chartDataBed, setChartDataBed] = useState([])
  const [chartDataMicro, setChartDataMicro] = useState([])
  const [chartDataWc, setChartDataWc] = useState([])
  const [awayFromHome, setAwayFromHome] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const [alertContent, setAlertContent] = useState({
    title: "",
    message: "",
    icon: "",
    color: "",
  })
  const [loadingData, setLoadingData] = useState(true)
  const [doorTimes, setDoorTimes] = useState(0)
  const [microTimes, setMicroTimes] = useState(0)
  const [wcTimes, setWcTimes] = useState(0)
  const [status, setStatus] = useState({ off: true, opacity: 0.5 })
  const [charging, setCharging] = useState("")

  //Formatting dates
  const startOfDay = moment().subtract(24, "hours")
  const from = new Date(startOfDay)
  const to = new Date()

  const abortRef = useRef(null)
  useEffect(() => {
    abortRef.current = new AbortController()
    return () => abortRef.current.abort()
  }, [])

  // Setting status etc
  useEffect(() => {
    // console.log("CLIENT DATA: ", props.clientData)
    if (props.clientData.gatewayKey) {
      fetchGatewayData()
      fetchCombinedActivity()
    }

    setAwayFromHome(
      props.clientData.away != undefined ? props.clientData.away : false
    )
    if (
      props.clientData.video != null &&
      props.clientData.video.deviceStatus != null
    ) {
      if (
        props.clientData.video.deviceStatus === "off" ||
        props.clientData.video.deviceStatus === ""
      ) {
        setStatus({ off: true, opacity: 0.5 })
        setCharging("")
      }
      if (
        props.clientData.video.deviceStatus != "off" &&
        props.clientData.video.deviceStatus != ""
      ) {
        setStatus({ off: false, opacity: 1 })
        if (props.clientData.video.deviceStatus == "charging") {
          setCharging("-charging")
        }
        if (props.clientData.video.deviceStatus != "charging") {
          setCharging("")
        }
      }
    }
    // if (
    //   props.clientData.journal != null &&
    //   props.clientData.journal.latest != null
    // ) {
    //   props.clientData.journal.latest.forEach(element => {
    //     let date = new Date(element.date)
    //     element.dateNice = moment(date).format("DD.MM. HH:mm:ss")

    //     switch (element.type) {
    //       case "call_video":
    //         element.typeDescription = "Video call"
    //         break
    //       case "call_group":
    //         element.typeDescription = "Group video call"
    //         break
    //       case "call_tracker":
    //         element.typeDescription = "Call to tracker"
    //         break
    //       case "call_carephone":
    //         element.typeDescription = "Call to carephone"
    //         break
    //       case "call_phone":
    //         element.typeDescription = "Call to phone"
    //         break
    //       case "message":
    //         element.typeDescription = "Message"
    //         break
    //       case "message_client":
    //         element.typeDescription = "Message to client"
    //         break
    //     }
    //   })
    // }
    setClientData(props.clientData)
  }, [props.clientData])

  // HOME CARD START
  // Door usage
  useEffect(() => {
    let times = 0
    if (chartDataDoor.length != 0) {
      times = chartDataDoor.map(c => c[1])

      let result = times.reduce((a, b) => {
        return a + b
      })
      setDoorTimes(result)
    }
  }, [chartDataDoor])

  // Microwave usage
  useEffect(() => {
    let times = 0
    if (chartDataMicro.length != 0) {
      times = chartDataMicro.map(c => c[1])
      let result = times.reduce((a, b) => {
        return a + b
      })
      setMicroTimes(result)
    }
  }, [chartDataMicro])

  // Wc usage
  useEffect(() => {
    if (chartDataWc.length != 0) {
      let result = 0
      let row = []
      let count = 0
      chartDataWc.map(c => {
        if (c[1] > 0) {
          count++
        } else {
          count = 0
        }
        row.push(count)
      })
      row = row.filter(num => num == 1)
      result = row.length
      setWcTimes(result)
    }
  }, [chartDataWc])

  // Fetch gateway and node data
  const fetchGatewayData = () => {
    if (props.clientData.gatewayKey) {
      setShowAlert(false)
      getGatewayData(abortRef.current, props.clientData.gatewayKey)
        .then(data => {
          if (data && data.hasOwnProperty("key")) {
            // Gateway alerts
            if (data.connectionIssue == true) {
              const alert = {
                title: props.t("Gateway connection issue"),
                message: props.t("Gateway connection issue description"),
                icon: "mdi mdi-signal-off",
                color: "danger",
              }
              setAlertContent(alert)
              setShowAlert(true)
            } else if (data.batteryOperated == true) {
              const alert = {
                title: props.t("Gateway unplugged"),
                message: props.t("Gateway unplugged description"),
                icon: "bx bxs-plug",
                color: "warning",
              }
              setAlertContent(alert)
              setShowAlert(true)
            }
          }
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
  // Fetch activity data from server
  const fetchCombinedActivity = () => {
    if (props.clientData.gatewayKey) {
      setLoadingData(true)
      setChartData([])
      setChartDataDoor([])
      setChartDataBed([])
      setChartDataMicro([])
      setChartDataWc([])
      getCombinedActivity(
        abortRef.current,
        props.clientData.gatewayKey,
        from,
        to
      )
        .then(data => {
          //console.log("DASH ACT: ", data)
          if (data.entries) {
            setChartData(data.entries?.map(item => [item.date, item.activity]))
            setChartDataDoor(data.entries?.map(item => [item.date, item.door]))
            setChartDataBed(data.entries?.map(item => [item.date, item.bed]))
            setChartDataMicro(
              data.entries?.map(item => [item.date, item.micro])
            )
            setChartDataWc(data.entries?.map(item => [item.date, item.toilet]))
          }
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          setLoadingData(false)
        })
    }
  }
  // HOME CARD END

  //
  // Start video call (VIDEO CARD)
  const startVideoCall = () => {
    getVideoCallToken(abortRef.current, clientData.key)
      .then(result => {
        if (result.link != null && result.link.length > 0) {
          window.open(result.link, "_blank", "noreferrer")
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  const lottieSuccessOpts = {
    loop: false,
    autoplay: true,
    animationData: successAnim,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }

  const lottieDangerOpts = {
    loop: false,
    autoplay: true,
    animationData: dangerAnim,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }

  const lottieWarningOpts = {
    loop: false,
    autoplay: true,
    animationData: warningAnim,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }

  // format duration seconds (JOURNAL CARD)
  const formatSeconds = value => {
    let hours = Math.floor(value / 3600) // get hours
    let minutes = Math.floor((value - hours * 3600) / 60) // get minutes
    let seconds = value - hours * 3600 - minutes * 60 //  get seconds
    if (hours > 0) {
      hours = hours + "" + props.t("h") + " "
    }
    if (hours == 0) {
      hours = ""
    }
    if (minutes > 0) {
      minutes = minutes + "" + props.t("min") + " "
    }
    if (minutes == 0) {
      minutes = ""
    }
    if (seconds > 0) {
      seconds = seconds + "" + props.t("s")
    }
    if (seconds == 0) {
      seconds = ""
    }
    return value ? hours + minutes + seconds : ""
  }

  // Setting icon for devices battery life
  const settingIcon = s => {
    switch (true) {
      case s < 2:
        return "-alert-variant-outline"
      case s < 11:
        return "-10"
      case s < 21:
        return "-20"
      case s < 31:
        return "-30"
      case s < 41:
        return "-40"
      case s < 51:
        return "-50"
      case s < 61:
        return "-60"
      case s < 71:
        return "-70"
      case s < 81:
        return "-80"
      case s < 91:
        return "-90"
      case s > 91:
        return ""
    }
  }

  return (
    <>
      <div>
        {props.clientDataLoading && (
          <Lottie
            options={getLottieLoadingOptions()}
            height={100}
            width={100}
          />
        )}
        {props.clientData && !props.clientDataLoading && (
          <div className="mt-1">
            <Alert color="primary" isOpen={awayFromHome}>
              <div className="d-flex">
                <div className="me-3">
                  <i className={"mdi mdi-door-open font-size-24"}></i>
                </div>
                <div>
                  <h5 className="alert-heading">{props.t("Away from home")}</h5>
                  <span>{props.t("Away from home description")}</span>
                </div>
              </div>
            </Alert>
            <Row>
              {props.clientData.gatewayKey != undefined &&
                props.clientData.gatewayKey.length > 0 && (
                  <Col className="dashboard-card-1" lg={12}>
                    <Card>
                      <div className="card-header bg-transparent border-bottom px-0">
                        <span className="text-uppercase ms-2 h5">
                          <i
                            style={{
                              marginTop: "-5px",
                            }}
                            className="fas fa-house-user font-size-18 text-suvanto-blue me-3"
                          ></i>
                          {props.t("Navigation.Suvanto Home")}
                        </span>
                        <span className="float-end me-2">
                          <Link
                            to="#"
                            // to={"/clients/" + props.clientId + 1}
                            className="card-link ms-3"
                            onClick={() => {
                              props.setTab(1)
                            }}
                          >
                            <span className="hidden-text-dashboard">
                              {props.t("Page")}
                            </span>
                            <i className="mdi mdi-arrow-right ms-1"></i>
                          </Link>
                        </span>
                      </div>

                      <CardBody>
                        <div>
                          <Alert color={alertContent.color} isOpen={showAlert}>
                            <i className={alertContent.icon + " me-2"}></i>
                            {alertContent.title}
                          </Alert>
                          {/*
                              TODO:SET IF CUSTOMER IS IN BED OR DOOR OPEN
                         */}
                          <Row>
                            <Col lg="9">
                              {loadingData ? (
                                <>
                                  <CardBody
                                    className="text-center"
                                    style={{ height: 350 }}
                                  >
                                    <div className="py-5">
                                      <Link to="#" className="text-primary">
                                        <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                                        {props.t("Loading")}
                                      </Link>
                                    </div>
                                  </CardBody>
                                </>
                              ) : (
                                <SmallDiagramActivity
                                  data={chartData}
                                  doorData={chartDataDoor}
                                  microData={chartDataMicro}
                                  bedData={chartDataBed}
                                  toilet={chartDataWc}
                                  size={350}
                                />
                              )}
                            </Col>
                            <Col lg="3">
                              <div className="ms-2 py-1">
                                <CardTitle className="mb-2">
                                  {props.t("Last day")}
                                </CardTitle>
                                {/* <div className="d-flex flex-wrap py-3 px-2">
                                  <div className="avatar-sm ">
                                    <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                                      <i className="bx bx-bed"></i>
                                    </div>
                                  </div>
                                  <div className="ms-3">
                                    <p className="text-muted mb-2">
                                      {props.t("Bed")}
                                    </p>
                                    <span className="h5 font-size-18">
                                      120 h
                                    </span>
                                  </div>
                                </div>{" "} */}
                                {/* <div className="d-flex flex-wrap p-2">
                                  <div className="avatar-sm ">
                                    <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                                      <i className=" bx bx-run"></i>
                                    </div>
                                  </div>
                                  <div className="ms-3">
                                    <p className="text-muted mb-2">
                                      {props.t("Activity")}
                                    </p>
                                    <span className="h5 font-size-18">120</span>
                                  </div>
                                </div> */}
                                <div className="d-flex flex-wrap py-3 px-2 mt-1">
                                  <div className="avatar-sm ">
                                    <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                                      <i className="mdi mdi-door-open"></i>
                                    </div>
                                  </div>
                                  <div className="ms-3">
                                    <p className="text-muted mb-1">
                                      {props.t("Outside door opened")}
                                    </p>
                                    <span className="h5 font-size-20 mt-0">
                                      {doorTimes}
                                    </span>
                                  </div>
                                </div>
                                <div className="d-flex flex-wrap py-3 px-2 mt-1">
                                  <div className="avatar-sm ">
                                    <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                                      <i className="mdi mdi-microwave"></i>
                                    </div>
                                  </div>
                                  <div className="ms-3">
                                    <p className="text-muted  mb-1">
                                      {props.t("Microwave used")}
                                    </p>
                                    <span className="h5 font-size-20 mt-0">
                                      {microTimes}
                                    </span>
                                  </div>
                                </div>
                                <div className="d-flex flex-wrap py-3 px-2 mt-1">
                                  <div className="avatar-sm ">
                                    <div className="avatar-title bg-light rounded-circle text-primary font-size-20">
                                      <i className="mdi mdi-toilet"></i>
                                    </div>
                                  </div>
                                  <div className="ms-3">
                                    <p className="text-muted mb-1">
                                      {props.t("Bathroom visits")}
                                    </p>
                                    <span className="h5 font-size-20 mt-0">
                                      {wcTimes}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                )}

              {!props.clientData.lockedTabs?.alarms && (
                <Col lg={3}>
                  <Card className="dashboard-card-2">
                    <div className="card-header bg-transparent border-bottom px-0">
                      <span className="text-uppercase ms-2 h5">
                        <i
                          style={{
                            marginTop: "-5px",
                          }}
                          className="mdi mdi-bell-ring-outline font-size-18 text-suvanto-blue me-2"
                        ></i>
                        {props.t("Navigation.Alarms")}
                      </span>
                      <span className="float-end me-2">
                        <Link
                          to="#"
                          // to={"/clients/" + props.clientId + 21}
                          className="card-link ms-3"
                          onClick={() => {
                            props.setTab(21)
                          }}
                        >
                          <span className="hidden-text-dashboard">
                            {props.t("Page")}
                          </span>
                          <i className="mdi mdi-arrow-right ms-1"></i>
                        </Link>
                      </span>
                    </div>
                    <CardBody className="text-center">
                      {props.clientData.alarms.unassigned == 0 &&
                        props.clientData.alarms.assigned == 0 && (
                          <div className="mt-2">
                            <Lottie
                              options={lottieSuccessOpts}
                              height={120}
                              width={120}
                            />
                            <h6>
                              <Alert className="mt-4 mb-0" color="success">
                                {props.t("No unresolved alarms")}
                              </Alert>
                            </h6>
                          </div>
                        )}
                      {props.clientData.alarms.unassigned != 0 && (
                        <div className="mt-1">
                          <Lottie
                            options={lottieDangerOpts}
                            height={120}
                            width={120}
                          />
                          <h6 className="mt-2 mb-0">
                            <Alert className="mt-1 mb-0" color="danger">
                              <strong className="me-2">
                                {props.clientData.alarms.unassigned}
                              </strong>
                              {props.clientData.alarms.unassigned > 1 && (
                                <span>{props.t("unassigned alarms")} </span>
                              )}
                              {props.clientData.alarms.unassigned == 1 && (
                                <span>{props.t("unassigned alarm")} </span>
                              )}
                            </Alert>
                          </h6>
                          {props.clientData.alarms.assigned != 0 && (
                            <h6 className="mt-0 mb-0">
                              <Alert className="mt-1 mb-0" color="warning">
                                <strong className="me-2">
                                  {props.clientData.alarms.assigned}
                                </strong>
                                {props.clientData.alarms.assigned > 1 && (
                                  <span>{props.t("assigned alarms")} </span>
                                )}
                                {props.clientData.alarms.assigned == 1 && (
                                  <span>{props.t("assigned alarm")} </span>
                                )}
                              </Alert>
                            </h6>
                          )}
                        </div>
                      )}
                      {props.clientData.alarms.assigned != 0 &&
                        props.clientData.alarms.unassigned == 0 && (
                          <div className="mt-2">
                            <Lottie
                              options={lottieWarningOpts}
                              height={120}
                              width={120}
                            />
                            <Alert className="mt-4 mb-0" color="warning">
                              <strong className="me-2">
                                {props.clientData.alarms.assigned}
                              </strong>
                              {props.clientData.alarms.assigned > 1 && (
                                <span>{props.t("assigned alarms")} </span>
                              )}
                              {props.clientData.alarms.assigned == 1 && (
                                <span>{props.t("assigned alarm")} </span>
                              )}
                            </Alert>
                          </div>
                        )}
                    </CardBody>
                  </Card>
                </Col>
              )}

              {!props.clientData.lockedTabs?.journal && (
                <Col lg={6}>
                  <Card className="dashboard-card-2">
                    <div className="card-header bg-transparent border-bottom px-0">
                      <span className="text-uppercase h5 ms-2">
                        <i
                          style={{
                            marginTop: "-5px",
                          }}
                          className="mdi mdi-book-open-page-variant-outline font-size-18 text-suvanto-blue me-2"
                        ></i>
                        {props.t("Navigation.Journal")}
                      </span>
                      <span className="float-end me-2">
                        <Link
                          to="#"
                          // to={"/clients/" + props.clientId + 6}
                          className="card-link ms-3"
                          onClick={() => {
                            props.setTab(6)
                          }}
                        >
                          <span className="hidden-text-dashboard">
                            {props.t("Page")}
                          </span>
                          <i className="mdi mdi-arrow-right ms-1"></i>
                        </Link>
                      </span>
                    </div>
                    <CardBody className="overflow-hidden">
                      <CardTitle>
                        {props.t("Latest messages and calls")}
                      </CardTitle>
                      <Row>
                        {/* <Col lg="1"> </Col> */}
                        <Col lg="12" className="px-3">
                          <SimpleBar className="dashboard-journal p-3">
                            <ul className="list-unstyled">
                              {props.clientData.journal.latest?.map(
                                (entry, key) => (
                                  <li key={key} className="event-list">
                                    {entry.message == "" ? (
                                      <></>
                                    ) : (
                                      <>
                                        <div className="media">
                                          <Col sm={12}>
                                            <Row>
                                              <Col sm={4}>
                                                <div className="text-uppercase d-flex">
                                                  <span className="mb-2 font-size-12">
                                                    {props.t(
                                                      moment(entry.date).format(
                                                        "dddd"
                                                      )
                                                    )}
                                                  </span>{" "}
                                                  <span className="font-size-12 ms-2">
                                                    <i className="bx bx-time-five me-1 font-size-10"></i>
                                                    {moment(entry.date).format(
                                                      "HH:mm:ss"
                                                    )}{" "}
                                                  </span>
                                                </div>
                                                <div className="text-uppercase h6">
                                                  <span className="ms-2">
                                                    {moment(entry.date).format(
                                                      "D"
                                                    )}
                                                    .
                                                  </span>
                                                  <span className="text-lowercase ms-1">
                                                    {props.t(
                                                      moment(entry.date).format(
                                                        "MMMM"
                                                      )
                                                    )}{" "}
                                                    {moment(entry.date).format(
                                                      "YYYY"
                                                    )}
                                                  </span>
                                                </div>
                                              </Col>
                                              <Col sm={8}>
                                                <span className="float-end hidden-media-norm">
                                                  <p>
                                                    <i className="bx bx-user-circle me-1 text-primary font-size-11"></i>
                                                    {entry.user}
                                                  </p>
                                                </span>
                                                <div className="ms-2">
                                                  <div className="mt-0">
                                                    <div className="h6">
                                                      {props.t(
                                                        entry.typeDescription
                                                      )}
                                                    </div>
                                                    {entry.title ? (
                                                      <h6 className="text-muted">
                                                        {props.t(entry.title)}
                                                      </h6>
                                                    ) : (
                                                      <span className="text-muted"></span>
                                                    )}
                                                    {entry.message !=
                                                      undefined && (
                                                      <>
                                                        {entry.message}{" "}
                                                        {entry.responseCount >
                                                          0 && (
                                                          <span className="text-muted ms-3">
                                                            <i
                                                              className={
                                                                "bx bx-chat font-size-12 me-1"
                                                              }
                                                            ></i>
                                                            +
                                                            {
                                                              entry.responseCount
                                                            }
                                                          </span>
                                                        )}
                                                      </>
                                                    )}
                                                    {entry.duration ? (
                                                      <p className="text-muted mt-3 font-size-12">
                                                        {props.t("Duration")}{" "}
                                                        {formatSeconds(
                                                          entry.duration
                                                        )}
                                                      </p>
                                                    ) : (
                                                      <div></div>
                                                    )}
                                                  </div>
                                                </div>
                                              </Col>
                                            </Row>
                                          </Col>
                                        </div>
                                        <hr className="mt-1" />
                                      </>
                                    )}
                                  </li>
                                )
                              )}
                            </ul>
                          </SimpleBar>
                        </Col>
                      </Row>{" "}
                      {props.clientData.journal.latest?.length == 0 && (
                        <h6 className="text-muted text-center py-4">
                          {props.t("No recent messages or calls")}
                        </h6>
                      )}
                    </CardBody>
                  </Card>
                </Col>
              )}

              {props.clientData.videoCallRoomName != undefined &&
                props.clientData.video != undefined &&
                props.clientData.videoCallRoomName.length > 0 && (
                  <Col lg={3}>
                    <Card className="dashboard-card-2">
                      <div className="card-header bg-transparent border-bottom px-0">
                        <span className="text-uppercase ms-2 h5">
                          <img
                            style={{
                              height: "18px",
                              display: "inline",
                              marginTop: "-4px",
                            }}
                            src={iconVideo}
                            alt=""
                            className="img-fluid me-2"
                          />
                          {props.t("Navigation.Video")}
                        </span>
                        <span className="float-end me-2">
                          <Link
                            to="#"
                            className="card-link ms-3"
                            onClick={() => {
                              props.setTab(4)
                            }}
                          >
                            <span className="hidden-text-dashboard">
                              {props.t("Page")}
                            </span>
                            <i className="mdi mdi-arrow-right ms-1"></i>
                          </Link>
                        </span>
                      </div>
                      <CardBody>
                        {/* <Row> */}
                        <Col xs={12} className="">
                          <CardTitle className="mt-0 mb-1">
                            {props.t("Status")}{" "}
                            {props.clientData.video.roomStatus ? (
                              <span className="h7 float-end font-weight-semibold">
                                {props.clientData.video.roomStatus ==
                                  "offline" && (
                                  <span className="badge badge-soft-danger font-size-12">
                                    {props.t("Unavailable")}
                                  </span>
                                )}
                                {props.clientData.video.roomStatus ==
                                  "online" && (
                                  <span className="badge badge-soft-success font-size-12">
                                    {props.t("Available")}
                                  </span>
                                )}
                                {props.clientData.video.roomStatus ==
                                  "call" && (
                                  <span className="badge badge-soft-warning font-size-12">
                                    {props.t("Ongoing video call")}
                                  </span>
                                )}
                                {props.clientData.video.roomStatus ==
                                  "group" && (
                                  <span className="badge badge-soft-warning font-size-12">
                                    {props.t("Ongoing group call")}
                                  </span>
                                )}
                              </span>
                            ) : null}
                          </CardTitle>

                          {props.clientData.video.groupGuest == true && (
                            <div className="mb-1">
                              <i className="mdi mdi-account-group font-size-14 me-2 "></i>
                              <span className="text-muted font-size-12">
                                {props.t("Group calls possible")}
                              </span>
                            </div>
                          )}
                          <Link
                            to="#"
                            className="font-size-12 text-primary"
                            onClick={() => {
                              startVideoCall()
                            }}
                          >
                            <i className="fas fa-video font-size-13 me-2"></i>
                            <span className="">
                              {props.t("Start video call")}
                            </span>
                          </Link>
                        </Col>

                        <Col xs={12} className="">
                          <CardTitle className="mt-3 mb-2">
                            {props.t("Device")}{" "}
                            {status.off ? (
                              <span className="badge badge-soft-danger float-end font-size-12">
                                {props.t("Turned off")}
                              </span>
                            ) : (
                              <span className="badge badge-soft-success float-end font-size-12">
                                {props.t("Device is on")}
                              </span>
                            )}
                          </CardTitle>
                          <Row>
                            <Col xs="7">
                              {props.clientData.video.battery < 6 &&
                                props.clientData.video.battery != 0 && (
                                  <span className="font-size-12 text-muted mt-2">
                                    <i className="mdi mdi-alert-circle-outline font-size-14 me-2 "></i>
                                    {props.t("Battery very low")}
                                  </span>
                                )}
                              {props.clientData.video.battery < 1 && (
                                <span className="font-size-12 text-muted ">
                                  <i className="mdi mdi-alert-circle-outline font-size-14 me-2 "></i>
                                  {props.t("Battery empty")}
                                </span>
                              )}
                              {props.clientData.video.deviceStatus ==
                                "discharging" && (
                                <span className="font-size-12 text-muted">
                                  <i className="mdi mdi-battery-alert-variant font-size-14 me-2 "></i>
                                  {props.t("Using battery")}
                                </span>
                              )}
                              {props.clientData.video.deviceStatus ==
                                "charging" && (
                                <span className="font-size-12 text-muted">
                                  <i className="mdi mdi-lightning-bolt font-size-14 me-2 "></i>
                                  {props.t("Charging")}
                                </span>
                              )}
                            </Col>{" "}
                            <Col xs="5">
                              <div
                                className="text-center float-end"
                                style={{
                                  width: 100,
                                  height: 100,
                                  opacity: status.opacity,
                                }}
                              >
                                <CircularProgressbarWithChildren
                                  value={props.clientData.video.battery}
                                  strokeWidth={6}
                                >
                                  <i
                                    style={{ marginTop: -5 }}
                                    className={
                                      "mdi mdi-battery" +
                                      charging +
                                      settingIcon(
                                        props.clientData.video.battery
                                      ) +
                                      " font-size-18"
                                    }
                                  ></i>
                                  <div style={{ fontSize: 16, marginTop: -5 }}>
                                    <strong>
                                      {props.clientData.video.battery}%
                                    </strong>
                                  </div>
                                </CircularProgressbarWithChildren>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </CardBody>
                    </Card>
                  </Col>
                )}

              {props.clientData.trackerKey != undefined &&
                props.clientData.tracker != undefined &&
                props.clientData.trackerKey.length > 0 && (
                  <Col lg={3}>
                    <Card className="dashboard-card-3">
                      <div className="card-header bg-transparent border-bottom px-0">
                        <span className="text-uppercase ms-2 h5">
                          <img
                            style={{
                              height: "18px",
                              display: "inline",
                              marginTop: "-4px",
                            }}
                            src={iconGo}
                            alt=""
                            className="img-fluid me-2"
                          />
                          {props.t("Navigation.Suvanto OnTheGo")}
                        </span>
                        <span className="float-end me-2">
                          <Link
                            to="#"
                            className="card-link"
                            onClick={() => {
                              props.setTab(2)
                            }}
                          >
                            <span className="hidden-text-dashboard">
                              {props.t("Page")}
                            </span>
                            <i className="mdi mdi-arrow-right ms-1"></i>
                          </Link>
                        </span>
                      </div>
                      <CardBody className="py-5">
                        <Row>
                          <Col xs="7" className="text-center mt-3">
                            <div
                              className="text-xl-start"
                              style={{
                                maxHeight: "130px",
                                maxWidth: "120px",
                                margin: "auto",
                                width: "65%",
                              }}
                            >
                              <div style={{ width: "100%", height: "100%" }}>
                                <CircularProgressbarWithChildren
                                  value={props.clientData.tracker.battery}
                                  strokeWidth={6}
                                >
                                  <i
                                    style={{ marginTop: -5 }}
                                    className={
                                      "mdi mdi-battery" +
                                      settingIcon(
                                        props.clientData.tracker.battery
                                      ) +
                                      " font-size-18"
                                    }
                                  ></i>
                                  <div style={{ fontSize: 16, marginTop: -5 }}>
                                    <div className="text-center mb-0 font-size-18">
                                      <strong>
                                        {props.clientData.tracker.battery}%
                                      </strong>
                                    </div>
                                  </div>
                                </CircularProgressbarWithChildren>
                              </div>
                            </div>
                            {props.clientData.tracker.status == "off" ? (
                              <>
                                <div className="badge badge-soft-danger mt-2 font-size-12">
                                  {props.t("Device is turned off")}
                                </div>
                              </>
                            ) : (
                              <>
                                {props.clientData.tracker.status == "lowbat" ? (
                                  <div className="badge badge-soft-warning mt-2 font-size-12">
                                    <i className="mdi mdi-alert-circle-outline me-1"></i>
                                    {props.t("Battery low")}
                                  </div>
                                ) : (
                                  <>
                                    {props.clientData.tracker.battery < 6 &&
                                      props.clientData.tracker.battery != 0 && (
                                        <div className="badge badge-soft-danger mt-2 font-size-12">
                                          <i className="mdi mdi-alert-circle me-1"></i>
                                          {props.t("Battery very low")}
                                        </div>
                                      )}
                                  </>
                                )}
                                {props.clientData.tracker.status ==
                                  "charging" && (
                                  <div className="badge badge-soft-primary mt-2 font-size-12">
                                    <i className="mdi mdi-lightning-bolt-outline me-1"></i>
                                    {props.t("Device is charging")}
                                  </div>
                                )}
                                {props.clientData.tracker.status != "lowbat" &&
                                  props.clientData.tracker.battery > 25 && (
                                    <p className="mb-1"></p>
                                  )}
                                {props.clientData.tracker.battery < 1 && (
                                  <div className="badge badge-soft-danger mt-2  font-size-12">
                                    <i className="mdi mdi-alert-circle me-1"></i>
                                    {props.t("Battery empty")}
                                  </div>
                                )}
                              </>
                            )}
                          </Col>
                          <Col
                            xs="5"
                            className="text-center"
                            style={{ marginTop: "10%" }}
                          >
                            {props.clientData.tracker.location && (
                              <>
                                <a
                                  href={
                                    "https://www.google.com/maps/place/" +
                                    props.clientData.tracker.location.latitude +
                                    "," +
                                    props.clientData.tracker.location.longitude
                                  }
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <img
                                    src={googlemaps}
                                    style={{
                                      height: "60px",
                                      alignSelf: "center",
                                    }}
                                  />
                                  <div className="text-muted mt-2 font-size-12">
                                    {props.t("Show location on Google Maps")}
                                    <i className="mdi mdi-arrow-right ms-1"></i>
                                  </div>
                                </a>
                              </>
                            )}
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                )}

              {props.clientData.carephoneKey != undefined &&
                props.clientData.carephone != undefined &&
                props.clientData.carephoneKey.length > 0 && (
                  <Col lg={4} xl={3}>
                    <Card className="dashboard-card-3">
                      <div className="card-header bg-transparent border-bottom px-0">
                        <span className="text-uppercase ms-2 h5">
                          <img
                            style={{
                              height: "18px",
                              display: "inline",
                              marginTop: "-4px",
                            }}
                            src={iconSafety}
                            alt=""
                            className="img-fluid me-2"
                          />
                          {props.t("Navigation.Carephone")}
                        </span>
                        <span className="float-end text-truncate me-2">
                          <Link
                            to="#"
                            className="card-link"
                            onClick={() => {
                              props.setTab(3)
                            }}
                          >
                            <span className="hidden-text-dashboard">
                              {props.t("Page")}
                            </span>
                            <i className="mdi mdi-arrow-right ms-1"></i>
                          </Link>
                        </span>
                      </div>
                      <CardBody className="text-center mt-2">
                        {props.clientData.carephone.status == "online" && (
                          <div>
                            <Lottie
                              options={lottieSuccessOpts}
                              height={120}
                              width={120}
                            />
                            <div className="h6 mt-3">
                              <Alert color="success">
                                {props.t("Connection ok")}
                              </Alert>
                            </div>
                          </div>
                        )}
                        {props.clientData.carephone.status == "offline" && (
                          <div className="">
                            <Lottie
                              options={lottieDangerOpts}
                              height={120}
                              width={120}
                            />
                            <div className="h6 mt-3">
                              <Alert color="danger">
                                {props.t("Connection issue")}
                              </Alert>
                            </div>
                          </div>
                        )}
                        {props.clientData.carephone.status == "discharging" && (
                          <div>
                            <Lottie
                              options={lottieWarningOpts}
                              height={120}
                              width={120}
                            />
                            <div className="h6 mt-3">
                              <Alert color="warning">
                                {props.t("Battery usage")}
                              </Alert>
                            </div>
                          </div>
                        )}
                      </CardBody>
                    </Card>
                  </Col>
                )}
              {props.clientData.pindoraId != undefined &&
                props.clientData.smartlock != undefined &&
                props.clientData.pindoraId.length > 0 && (
                  <Col lg={4}>
                    <Card className="dashboard-card-3">
                      <div className="card-header bg-transparent border-bottom px-0">
                        <span className="text-uppercase ms-2 h5">
                          <img
                            style={{
                              height: "18px",
                              display: "inline",
                              marginTop: "-4px",
                            }}
                            src={iconLock}
                            alt=""
                            className="img-fluid me-2"
                          />
                          {props.t("Navigation.Smartlock")}
                        </span>
                        <span className="float-end me-2">
                          <Link
                            to="#"
                            className="card-link"
                            onClick={() => {
                              props.setTab(8)
                            }}
                          >
                            <span className="hidden-text-dashboard">
                              {props.t("Page")}
                            </span>
                            <i className="mdi mdi-arrow-right ms-1"></i>
                          </Link>
                        </span>
                      </div>
                      <CardBody className="mt-3">
                        <Row>
                          <Col xs="6" className="mt-1">
                            <div
                              className="text-center float-end"
                              style={{
                                width: 110,
                                height: 110,
                                opacity: 1,
                              }}
                            >
                              <CircularProgressbarWithChildren
                                value={props.clientData.smartlock.battery}
                                strokeWidth={6}
                              >
                                <i
                                  style={{ marginTop: -5 }}
                                  className={
                                    "mdi mdi-battery" +
                                    // charging +
                                    settingIcon(
                                      props.clientData.smartlock.battery
                                    ) +
                                    " font-size-18"
                                  }
                                ></i>
                                <div style={{ fontSize: 16, marginTop: -0 }}>
                                  <strong>
                                    {props.clientData.smartlock.battery}%
                                  </strong>
                                </div>
                              </CircularProgressbarWithChildren>
                            </div>
                            {/*<Link to={'/video-call/' + props.clientData.key} target="_blank"> 
                              <Button>{props.t("Call now")}</Button>
                            </Link>*/}
                          </Col>
                          <Col xs="6" className="mt-3">
                            <div>
                              {props.clientData.smartlock.status ==
                                "online" && (
                                <>
                                  <div className="badge badge-soft-primary font-size-12">
                                    <i className="mdi mdi-check me-1"></i>
                                    {props.clientData.smartlock.isCharging ==
                                    true
                                      ? props.t("Charging")
                                      : props.t("Online")}
                                  </div>
                                  <div className="py-2">
                                    {props.clientData.smartlock.doorState ==
                                      1 && (
                                      <div className="badge badge-soft-primary mt-0 mb-2 font-size-12">
                                        <i className="mdi mdi-door me-1"></i>
                                        {props.t("Door closed")}
                                      </div>
                                    )}
                                    {props.clientData.smartlock.doorState ==
                                      0 && (
                                      <div className="badge badge-soft-danger mt-0 mb-2 font-size-12">
                                        <i className="mdi mdi-door-open me-1"></i>
                                        {props.t("Door open")}
                                      </div>
                                    )}
                                  </div>
                                </>
                              )}
                              {props.clientData.smartlock.status ==
                                "offline" && (
                                <div className="badge badge-soft-danger mt-0 mb-2 font-size-12">
                                  {props.t("Offline")}
                                </div>
                              )}
                            </div>

                            {props.clientData.smartlock.battery < 6 &&
                              props.clientData.smartlock.battery != 0 && (
                                <div className="badge badge-soft-danger mt-0 mb-2 font-size-12">
                                  <i className="mdi mdi-battery-alert-variant-outline me-1"></i>
                                  <span className="font-size-11">
                                    {props.t("Battery very low")}
                                  </span>
                                </div>
                              )}
                            {props.clientData.smartlock.battery == 0 && (
                              <div className="badge badge-soft-danger mt-0 mb-2 font-size-12">
                                <i className="mdi mdi-battery-alert-variant-outline me-1"></i>
                                <span className="font-size-11">
                                  {props.t("Battery empty")}
                                </span>
                              </div>
                            )}
                          </Col>
                          <Col xs={12}>
                            <div className="mt-5">
                              <Button
                                disabled
                                color="primary"
                                type="button"
                                style={{ width: "100%" }}
                                className="font-size-12"
                                to={"/video-call/" + props.clientData.key}
                                target="_blank"
                              >
                                {props.t("Open door")}
                                <i className="mdi mdi-lock-open ms-2"></i>
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                )}
            </Row>
          </div>
        )}
      </div>
    </>
  )
}

ClientDashboard.propTypes = {
  t: PropTypes.any,
  setTab: PropTypes.any,
  clientData: PropTypes.any,
  services: PropTypes.any,
  data: PropTypes.any,
  clientDataLoading: PropTypes.bool,
  refreshClient: PropTypes.func,
  clientId: PropTypes.string,
}

export default withTranslation()(ClientDashboard)
