import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Button, Col, Row } from "reactstrap"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import moment from "moment"
import { withTranslation } from "react-i18next"

const AlarmServicesTable = props => {
  const [entries, setEntries] = useState([])
  const [pageSize, setPageSize] = useState(50)

  useEffect(() => {
    setEntries(props.data)
  }, [props.data])

  // table columns
  const columns = [
    {
      dataField: "status",
      text: props.t("Status"),
      sort: true,
      formatter: statusFormatter,
    },
    {
      dataField: "received_at",
      text: props.t("Time"),
      sort: true,
      formatter: timeFormatter,
    },
    {
      dataField: "map_link",
      text: props.t("Location"),
      isDummyField: true,
      align: "center",
      sort: true,
      formatter: locationFormatter,
    },
    {
      dataField: "alarm_id",
      text: props.t("Type"),
      sort: true,
      formatter: typeFormatter,
    },
    {
      dataField: "extra_info",
      text: props.t("Info"),
      sort: true,
    },
    {
      dataField: "false_alarm",
      text: props.t("False alarm"),
      sort: true,
      formatter: falseFormatter,
    },
    {
      dataField: "comment",
      text: props.t("Comments"),
      sort: true,
    },
    {
      dataField: "acked_by",
      text: props.t("Handler"),
      sort: true,
    },
    {
      dataField: "acked_at",
      text: props.t("Handling time"),
      sort: true,
      formatter: timeFormatter,
    },
    {
      dataField: "closed_by",
      text: props.t("Closed by"),
      sort: true,
    },
    {
      dataField: "closed_at",
      text: props.t("Closed at"),
      sort: true,
      formatter: timeFormatter,
    },
  ]

  // Modified status
  function statusFormatter(cell) {
    if (cell == "CLOSED") {
      return <i className="bx bx-check-circle font-size-20 text-success me-1" />
    } else if (cell == "ASSIGNED") {
      return <i className="bx bxs-calendar-heart font-size-20 text-warning" />
    } else {
      return <i className="bx bxs-calendar-heart font-size-20 text-danger" />
    }
  }

  // False alarm formatter
  function falseFormatter(cell) {
    if (cell == true) {
      return <span>{props.t("Yes")}</span>
    } else {
      /*else if (cell == false) {
      return <span>{props.t("No")}</span>
    }*/
      return <></>
    }
  }

  // Google map link formatter
  function locationFormatter(cell, row) {
    if (row.map_link) {
      return (
        <a href={row.map_link} target="_blank" rel="noopener noreferrer">
          <Button color="secondary" size="sm">
            <i className="mdi mdi-google-maps" />
          </Button>
        </a>
      )
    } else {
      return <></>
    }
  }

  // Converting time from seconds to readable
  function timeFormatter(value) {
    if (value) return moment(value).format("DD.MM.YYYY HH:mm:ss")
    else return ""
  }

  // Can be used to modify type
  function typeFormatter(cell) {
    return props.t("AlarmTitles." + cell)
  }

  const { SearchBar } = Search
  // Sort data in table
  const defaultSorted = [
    {
      dataField: "received_at",
      order: "desc",
    },
  ]

  // Show selected number of rows
  const pageOptions = {
    custom: true,
    totalSize: entries.length,
    sizePerPage: pageSize,
    onSizePerPageChange: (sizePerPage, page) => {
      setPageSize(sizePerPage)
    },
  }

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
    clickToSelect: true,
    style: { background: "#def3ff", cursor: "pointer" },
    //prettier-ignore
    onSelectAll: (isSelect, rows, e) => {
      // console.log(isSelect, rows, e)
      if (isSelect) {
        // rows.map(row=> props.setSelectedAlarms(prevState => [...prevState, row.key]))
        rows.map(row=> props.setSelectedAlarmsObj(prevState => [...prevState, row]))
      } else {
        // props.setSelectedAlarms([])
        props.setSelectedAlarmsObj([])
      }
    },
    onSelect: (row, isSelect, rowIndex, e) => {
      // console.log(row, isSelect)
      if (isSelect) {
        // props.setSelectedAlarms(prevState => [...prevState, row.key])
        props.setSelectedAlarmsObj(prevState => [...prevState, row])
      } else {
        // props.setSelectedAlarms(prevState =>
        //   prevState.filter(prevItem => prevItem !== row.key)
        // )
        props.setSelectedAlarmsObj(prevState =>
          prevState.filter(prevItem => prevItem !== row)
        )
      }
    },
  }

  return (
    <>
      <span>
        <Row>
          {entries.length > 0 && (
            <Col className="col-12">
              <PaginationProvider
                pagination={paginationFactory(pageOptions)}
                keyField="key"
                columns={columns}
                data={entries}
              >
                {({ paginationProps, paginationTableProps }) => (
                  <ToolkitProvider
                    keyField="key"
                    columns={columns}
                    data={entries}
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive table-scroll-window">
                              <BootstrapTable
                                keyField={"received_at"}
                                responsive
                                bordered={false}
                                striped={false}
                                //pagination={sizePerPageList}
                                defaultSorted={defaultSorted}
                                selectRow={selectRow}
                                classes={"table align-middle table-nowrap"}
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                                {...paginationTableProps}
                              />
                            </div>
                          </Col>
                        </Row>

                        <Row className="align-items-md-center mt-3">
                          <Col className="inner-custom-pagination d-flex">
                            <div className="d-inline">
                              <SizePerPageDropdownStandalone
                                {...paginationProps}
                              />
                            </div>
                            <div className="text-md-right ms-auto">
                              <PaginationListStandalone {...paginationProps} />
                            </div>
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                )}
              </PaginationProvider>
            </Col>
          )}
        </Row>
      </span>
    </>
  )
}

AlarmServicesTable.propTypes = {
  t: PropTypes.any,
  suvantoKey: PropTypes.any,
  data: PropTypes.any,
  selectedAlarms: PropTypes.array,
  setSelectedAlarms: PropTypes.any,
}

export default withTranslation()(AlarmServicesTable)
