/***********************
 * Status Monitoring
 ************************/

import React, { Component } from "react"
import { Container, Breadcrumb } from "reactstrap"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"

class StatusMonitoring extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* <h4>Dashboard</h4> */}
            {/* <Breadcrumb></Breadcrumb> */}
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

StatusMonitoring.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
  type: PropTypes.string,
}
export default withTranslation()(StatusMonitoring)
