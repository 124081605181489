import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import Chip from "@material-ui/core/Chip"
import { Route } from "react-router-dom"
import { withTranslation } from "react-i18next"
import withRouter from "components/Common/withRouter"

const ReleaseListItem = props => {
  return (
    <>
      <div className="d-flex">
        <div className="me-4">
          <img
            src={props.item.img}
            alt=""
            className="avatar-sm d-block rounded pb-1 pt-1"
            style={{ objectFit: "contain" }}
          />
        </div>
        <div
          type="button"
          onClick={() => props.onOpenModal(props.item)}
          className="align-self-center overflow-hidden me-auto"
        >
          <div>
            <h5 className="font-size-14 text-truncate">
              <Link to="#" className="text-dark">
                {props.t(props.item.target) + " v." + props.item.version}
              </Link>
            </h5>
            <p className="text-muted mb-0">{props.item.date}</p>
            <Chip
              size="small"
              key="chip"
              className="font-size-12 me-2 my-2"
              label={props.t(props.item.type)}
            />
            <Chip
              size="small"
              key="chip2"
              className="font-size-12 me-2 my-2"
              label={props.t(props.item.status)}
            />
          </div>
        </div>
      </div>
    </>
  )
}

ReleaseListItem.propTypes = {
  t: PropTypes.any,
  item: PropTypes.any,
  onOpenModal: PropTypes.any,
}

export default withRouter(withTranslation()(ReleaseListItem))
